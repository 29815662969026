import { Component, OnInit, ViewChild } from '@angular/core'
import { ToolsService } from '../../_services/tools.service'
import { DatePipe } from '@angular/common'
import { DateAdapter } from '@angular/material/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import {
	UntypedFormControl,
	UntypedFormGroup,
	Validators,
} from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { Router, ActivatedRoute } from '@angular/router'
import { first } from 'rxjs/operators'
import { ProviderService } from '../../_services'

export interface ItemList {
	Value: string
	Name: string
}

@Component({
	templateUrl: 'history.component.html',
	styleUrls: ['../reports/reports.scss'],
	providers: [DatePipe],
})
export class InvoiceMonitoringHistoryComponent implements OnInit {
	constructor(
		private toolsService: ToolsService,
		private datePipe: DatePipe,
		private dateAdapter: DateAdapter<Date>,
		private translate: TranslateService,
		private router: Router,
		private providerService: ProviderService,

		public route: ActivatedRoute,
		public toastr: ToastrService
	) {
		dateAdapter.setLocale('en-EN') // change this for change format date in select
	}
	ngOnInit(): void {
		this.paginationItemPerPage = 10
		this.companyID = this.route.snapshot.paramMap.get('id')

		this.FilterHistoryForm = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTimeEnd, disabled: true },
				Validators.required
			),
			ServiceDrop: new UntypedFormControl(''),
		})
		this.InitProviderFilter()
		this.getHistory()
	}

	InitProviderFilter() {
		let p = localStorage.getItem('servicesAll')
		if (p === null) {
			this.toolsService
				.getServicesList()
				.pipe(first())
				.subscribe(
					(data) => {
						if (data.ReturnObject != null) {
							localStorage.setItem(
								'servicesAll',
								JSON.stringify(data.ReturnObject)
							)
							this.services = data.map((x) => ({
								Value: x.ID.toString(),
								Name: x.Name,
							}))
							this.services.unshift({ Value: '', Name: 'All' })
							this.SetServiceDrop(
								this.services.filter(
									(x) => x.Value == this.companyID
								)[0].Value
							)
						}
					},
					(error) => {
						console.log(error)
					},
					() => {}
				)
		} else {
			let prvs = JSON.parse(p)
			this.services = prvs.map((x) => ({
				Value: x.CompanyID.toString(),
				Name: x.UtilityCompanyName,
			}))

			this.SetServiceDrop(
				this.services.filter((x) => x.Value == this.companyID)[0].Value
			)
		}
		if (this.services != null)
			this.services.unshift({ Value: '', Name: 'All' })
	}

	SetServiceDrop(selectedService) {
		this.FilterHistoryForm.patchValue({
			['ServiceDrop']: selectedService,
		})
	}

	companyID: string

	//Table data
	dataSource = new MatTableDataSource()
	displayedColumns: string[] = [
		'Date',
		'Services',
		'Status',
		'UserLogin',
		'FileName',
		'Count',
		'FileInfo',
		'LogMessage',
	]

	//Paging
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	currentPage = 0

	@ViewChild(MatPaginator) paginator: MatPaginator

	//DateTime
	currentTime = new Date(new Date().setHours(0, 0, 0))
	currentTimeEnd = new Date(new Date().setHours(23, 59, 59))
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date

	loading = false
	pageEvent

	//Filters
	services: ItemList[]
	serviceSelected // Service selected

	//Filter settings
	FilterHistoryForm: UntypedFormGroup //Form initial
	radioModel = 1

	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}

	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getHistory()
	}

	getHistory(period?) {
		if (typeof period == 'number') {
			this.radioModel = period
		}
		this.loading = true

		let filter = this.FilterHistoryForm.value

		let dateFrom = this.datePipe.transform(
			this.FilterHistoryForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let dateFinish = this.datePipe.transform(
			this.FilterHistoryForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)

		let OUT = {
			PeriodType: this.radioModel,
			DateStart: dateFrom,
			DateFinish: dateFinish,
			ProviderID: filter.ServiceDrop,
			CurrentPage: this.currentPage + 1,
			ItemPerPage: this.paginationItemPerPage,
		}

		this.toolsService.getHistory(OUT).subscribe(
			(data) => {
				console.log('Data: ', data)
				if (data.ResultCode === 0) {
					let invoicesList = []
					let itemTotal = 0
					if (data.ReturnObject !== null) {
						invoicesList = data.ReturnObject.InvoicesList
						itemTotal = data.ReturnObject.PagingInfo.TotalItems
					}
					this.paginationItems = itemTotal
					this.dataSource = new MatTableDataSource(invoicesList)
				}
				this.loading = false
			},
			(error) => {
				this.loading = false
				console.log('Error: ', error)
			}
		)
	}
}

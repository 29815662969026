import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';



// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
@Injectable()
export class DashboardService {
    constructor(private http: HttpClient) { }
    basUrl: string = environment.apiUrl;
    // Transactions Datas

    getMainChartDatas(date_from, date_to, period){
        return this.http.get<any>(this.basUrl+`/api/dashboard/Chart?&PeriodType=` + period + `&DateStart=` + date_from + `&DateEnd=` + date_to)
        .pipe(map(response => {
            return response;
        }));
    }

    getFile(date_from, date_to, period, format) {
        if(format === 'pdf'){
            return this.http.get(this.basUrl+`/api/dashboard/TransactionListToPDFDashbord?DateStart=` + date_from + `&DateEnd=` + date_to + '&PeriodType=' + period, {
                responseType: 'blob'
            });
        }
        return this.http.get(this.basUrl+`/api/dashboard/TransactionListToExcelDashbord?DateStart=` + date_from + `&DateEnd=` + date_to + '&PeriodType=' + period, {
            responseType: 'blob'
        });
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';



import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChangePasswordComponent } from './changepassword.component';
import { RouterModule } from '@angular/router';
import {ProfileComponent} from './profile.component';


@NgModule({
  imports: [
      CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,		
    RouterModule,
	TranslateModule,
    MatSelectModule,
  ],
  declarations: [
    ChangePasswordComponent, ProfileComponent
  ]
})
export class SettingsModule { }

import { Component, OnInit, ViewChild } from '@angular/core'
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { ProviderService } from '../../_services'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'
import { DateAdapter } from '@angular/material/core'
import { MatSort } from '@angular/material/sort'

import { DatePipe } from '@angular/common'
import { AdministrationService } from '../../_services/administration.service'
import { ReportsDatasService } from '../../_services/reportsdata.service'
import { KioskService } from '../../_services/kiosk.service'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { saveAs } from 'file-saver'

export interface standartType {
	value: string
	viewValue: string
} // standart Interface

export interface ItemList {
	Value: string
	Name: string
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
}
@Component({
	templateUrl: 'kiosk-cashrest.component.html',
	styleUrls: ['../reports/reports.scss'],
	providers: [DatePipe],
})
export class KioskCashRestComponent implements OnInit {
	dataSource = new MatTableDataSource() // array of datas
	secondTableSource = new MatTableDataSource() // array of datas

	stopClick(event) {
		event.preventDefault()
	}
	isDownloadingFile: boolean = false

	public totalAmountBody = 0
	public totalAmountFee = 0
	public totalAmountTotal = 0
	public totalRevenue = 0

	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	saleFilteredOptions: Observable<ItemList[]> // salePoints

	public transactionCurrentID
	public transactionCurrentDealerName
	public transactionCurrentServiceName
	public currentPage = 0
	isLoadingDetailInfo: boolean = false
	modalDatas = {} // for display datas in modal

	//-------------------------------------
	providersList
	Transactions_Datas
	dealerListOptions: ItemList[]
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	radioModel = 1
	services: ItemList[]

	cashRestId = ''
	cardHolderName = ''
	refStan = '' // refStan input
	defaultServices: any
	serviceGroupSelected: any = '' // Service selected
	serviceSubSelected: any = ''
	serviceGroups: any = []
	servicesToShow: any = []
	userLogin = '' // User Login input
	UserComment = new UntypedFormControl()
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date

	currentTime = new Date(new Date().setHours(0, 0, 0))
	endTime = new Date(new Date().setHours(23, 59, 59))
	//----------------- list of columns
	displayedColumns: string[] = [
		'Id',
		'Stan',
		'ExternalStan',
		'Status',
		'UserLogin',
		'RegDate',
		'UpdatedDate',
		'Amount',
		'CashRestId',
	]
	isUsed = false
	statusesAll = [
		{
			Value: '',
			Name: 'All',
			Color: '',
		},
		{
			Value: '0',
			Name: 'Issued',
			Color: 'text-warning',
		},
		{
			Value: '1',
			Name: 'Used',
			Color: 'text-success',
		},
		{
			Value: '2',
			Name: 'Blocked',
			Color: 'text-danger',
		},
	]

	statuses = [
		{ Value: 0, Name: 'Select status' },
		{ Value: 1, Name: 'Used' },
		{ Value: 2, Name: 'Blocked' },
	]
	SelectedStatus = new UntypedFormControl(this.statuses[0].Value)

	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private providerService: ProviderService,
		private administrationService: AdministrationService,
		public kioskService: KioskService,
		private dateAdapter: DateAdapter<Date>,
		private reportsDatasService: ReportsDatasService,
		private translate: TranslateService,
		public toastr: ToastrService
	) {
		dateAdapter.setLocale('en-EN') // change this for change format date in select
	}

	FilterForm: UntypedFormGroup // Form initial

	@ViewChild(MatSort) sort: MatSort

	//----------------------- get a provider list and put to option's

	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getSecondDatas(this.radioModel)
	}

	getSecondDatas(period?) {
		if (typeof period === 'number') {
			this.radioModel = period
		}
		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		// Take datas from form
		let filter = this.FilterForm.value
		this.kioskService
			.getKioskCashRestReport(
				start,
				finish,
				this.radioModel,
				filter,
				this.currentPage + 1,
				this.paginationItemPerPage
			)
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.paginationItems =
								data.ReturnObject.Pagination.ItemTotal
							this.dataSource = new MatTableDataSource(
								data.ReturnObject.CashRestData
							)
							if (this.dataSource.filteredData != null) {
								this.dataSource.filteredData.forEach(function (
									el: any
								) {
									switch (el.Status) {
										case 0:
											el.Color = 'text-warning'
											el.StatusName =
												'KIOSK.STATUS_ISSUED'
											break
										case 1:
											el.Color = 'text-success'
											el.StatusName = 'KIOSK.STATUS_USED'
											break
										case 2:
											el.Color = 'text-danger'
											el.StatusName =
												'KIOSK.STATUS_BLOCKED'
											break
										default:
											break
									}
								})
							}
						} else {
							console.log('ITS NULL DATA!')
						}
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}
	onSubmit() {
		this.getSecondDatas(this.radioModel)
	}

	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}

	get f() {
		return this.FilterForm.controls
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}
	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		this.UserComment.patchValue('')
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
			minHeight: '580px',
		})
	}
	selectRow(templateRef, row) {
		this.isLoadingDetailInfo = true
		this.UserComment.patchValue('')

		this.kioskService
			.getKioskCashRestDetails(row.Id)
			.pipe(first())
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas = data.ReturnObject
							this.isLoadingDetailInfo = false
							if (data.ReturnObject.CashRestData != null) {
								this.SelectedStatus.patchValue(
									data.ReturnObject.CashRestData.Status
								)
								if (data.ReturnObject.CashRestData.Status != 0)
									this.SelectedStatus.disable({
										onlySelf: true,
									})
								else
									this.SelectedStatus.enable({
										onlySelf: true,
									})
							}
						} else {
							this.isLoadingDetailInfo = false
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					this.isLoadingDetailInfo = false
					console.log(error)
				}
			)
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}

	ngOnInit() {
		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.endTime, disabled: true },
				Validators.required
			),
			UserLogin: new UntypedFormControl(''),
			CashRestId: new UntypedFormControl(''),
			IsUsed: new UntypedFormControl(''),
			Status: new UntypedFormControl(this.statusesAll[0].Value),
		})
	}
	sendEmails(row) {
		this.isLoadingDetailInfo = true
		this.kioskService
			.getKioskCashRestSendEmails(row.Id)
			.pipe(first())
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						this.isLoadingDetailInfo = false
						let theme = ''
						let body = ''
						this.translate
							.get('KIOSK.THEME_SEND_EMAIL_SUCCESS')
							.subscribe((text) => {
								theme = text
							})
						this.translate
							.get('KIOSK.BODY_SEND_EMAIL_SUCCESS')
							.subscribe((text) => {
								body = text
							})
						this.toastr.success(body, theme)
					}
				},
				(error) => {
					this.isLoadingDetailInfo = false
					let theme = ''
					this.translate
						.get('KIOSK.THEME_SEND_EMAIL_ERROR')
						.subscribe((text) => {
							theme = text
						})
					this.toastr.error(error, theme)
				}
			)
	}
	changeStatus(id) {
		this.isLoadingDetailInfo = true
		let statusError = false
		let commentError = false
		this.SelectedStatus.setErrors(null)
		if (this.SelectedStatus.value == 0) {
			this.SelectedStatus.markAsTouched()
			this.SelectedStatus.setErrors({ incorrect: true })

			this.isLoadingDetailInfo = false
			statusError = true
		}
		if (this.UserComment.value == null || this.UserComment.value == '') {
			this.UserComment.markAsTouched()
			this.UserComment.setErrors({ incorrect: true })

			this.isLoadingDetailInfo = false
			commentError = true
		}
		if (statusError || commentError) return
		this.kioskService
			.getKioskCashRestChangeStatus(
				id,
				this.SelectedStatus.value,
				this.UserComment.value
			)
			.pipe(first())
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						this.isLoadingDetailInfo = false
						let theme = ''
						let body = ''
						this.translate
							.get('KIOSK.THEME_CHANGE_STATUS_SUCCESS')
							.subscribe((text) => {
								theme = text
							})
						this.translate
							.get('KIOSK.BODY_CHANGE_STATUS_SUCCESS')
							.subscribe((text) => {
								body = text
							})
						this.toastr.success(body, theme)
					}
				},
				(error) => {
					this.isLoadingDetailInfo = false
					let theme = ''
					this.translate
						.get('KIOSK.THEME_CHANGE_STATUS_ERROR')
						.subscribe((text) => {
							theme = text
						})
					this.toastr.error(error, theme)
				}
			)
	}

	download() {
		this.isDownloadingFile = true
		this.loading = true

		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		// Take datas from form
		let filter = this.FilterForm.value
		this.kioskService
			.getKioskCashRestReportExport(
				start,
				finish,
				this.radioModel,
				filter,
				this.currentPage + 1,
				this.paginationItemPerPage
			)
			.subscribe(
				(res) => {
					this.isDownloadingFile = false
					this.loading = false
					saveAs(res, finish + '.' + 'xlsx', {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
				},
				(error) => {
					console.log(error)
					this.loading = false
					this.isDownloadingFile = false
				}
			)
	}
}

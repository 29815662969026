import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators'
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class ToolsService {
	constructor(private http: HttpClient,
		private authService: AuthenticationService) {}
	basUrl: string = environment.apiUrl

	getList() {
		const params = new HttpParams().set('pSearchDate', null)
		return this.http
			.get<any>(`${this.basUrl}/api/InvoiceMonitoring/Gets`, { params ,	
					headers: {
				 
				"Accept-Language": this.GetLang(),
			  }}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getServicesList() {
		const params = new HttpParams().set('pCompanyId', null)
		return this.http
			.get<any>(`${this.basUrl}/api/InvoiceMonitoring/HystoryGet`, { params ,	
					headers: {
				 
				"Accept-Language": this.GetLang(),
			  }}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getHistory(filterData) {
		const params = new HttpParams()
			.set('pPeriodType', filterData.PeriodType)
			.set('pDateStart', filterData.DateStart)
			.set('pDateEnd', filterData.DateFinish)
			.set('pProviderId', filterData.ProviderID)
			.set('pCurPage', filterData.CurrentPage)
			.set('pItemPerPage', filterData.ItemPerPage)

		return this.http
			.get<any>(`${this.basUrl}/api/InvoiceMonitoring/HystoryGets`, {
				params,
				headers: {
				 
					"Accept-Language": this.GetLang(),
				  }
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	upload(file, fileName, providerId, comment) {
		let body = {
			Content: file,
			Name: fileName,
			ProviderId: providerId,
			Comment: comment
		  } 
		  return this.http
			.post<any>(`${this.basUrl}/api/InvoiceMonitoring/Upload`, body, {
			  headers: {
				"Content-Type": "application/json",
				"Accept-Language": this.GetLang(),
			  },
			})
			.pipe(
			  map((response) => {
				if (this.authService.checkTokenResponse()) {
				  return response
				}
			  })
			)
	}

	private GetLang() {
		if (localStorage.getItem('currentLang') === 'tr') {
			return 'tr-TR'
		}
		return 'en-US'
	}
}

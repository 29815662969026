import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

import { PayService } from '../../_services/pay.service';

import { saveAs } from 'file-saver';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";
export interface ItemList {
  Value: string;
  Name: string;
}


@Component({
  templateUrl: "pay-checkout.component.html",
  styleUrls: ["pay.scss"],
  providers: [DatePipe]
})
export class PayCheckoutComponent implements OnInit {
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api

  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner

  //-------------------------------------
  Transactions_Datas;
  loading = false; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  // For change pages

  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  events: string[] = []; // Date picker
  payIdControl = new UntypedFormControl(); // Dealer
  currentpayId = 1;
  radioModel = 1; 

 

  changeCheckStatusError = ''; // Validation on User
  currentTime = new Date();
  //----------------- list of columns
  displayedColumns: string[] = [
    "Id",
    "OperId",
    "FinId",
    "RegDate",
    "PayDate",
    "ServiceName",
    "Amount",
    "Status"
  ];

  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    private translate: TranslateService,
    private datePipe: DatePipe,
    private payService: PayService,
    public toastr: ToastrService
  ) { }
  isDownloadingFile: boolean = false;

  FilterForm: UntypedFormGroup; // Form initial
  EditForm: UntypedFormGroup; // Form edit
  //----------------------
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }
  download(format) {
    this.isDownloadingFile = true;
    this.loading = true;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    let payId = this.FilterForm.value.payIdControl,
      account = null,
      companyId = null,
      userName = null;

    // format, period, dateFrom, dateTo, Id, curPage, itemPerPage
    this.payService
      .getCOFile(
        format,
        this.radioModel,
        start,
        finish,
        payId,
        this.currentPage + 1,
        this.paginationItemPerPage
      )
      .subscribe(
        res => {
          this.isDownloadingFile = false;
          this.loading = false;
          saveAs(res, finish + "." + format, {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
        },
        error => {
          console.log(error);
          this.loading = false;
          this.isDownloadingFile = false;
        }
      );
  }
  CloseDialog(): void {
    this.dialog.closeAll();
  }
  // function for change tables page
  selectRow(templateRef, row) {
    this.isLoadingDetailInfo = true;
    this.changeCheckStatusError = '';
    this.EditForm.setValue({ 
      Comment: ""
    });
    this.payService.getCheckout(row.Id).subscribe(
      data => {
        if (data.ResultCode === 0) {
          if (data.ReturnObject !== null) {
            console.log("Select data row", data);
            this.modalDatas = data.ReturnObject; 
            this.isLoadingDetailInfo = false;
          } else {
            this.isLoadingDetailInfo = false;
          }
        } else {
          this.CloseDialog();
        }
      },
      error => {
        this.isLoadingDetailInfo = false;
        console.log(error);
      }
    );
    const dialogRef = this.dialog.open(templateRef, {
      panelClass: "modal_transaction"
    });
  }
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  //----------------------- get a dealer list and put to option's

  totalAmount = 0;
  getFirstDatas(period?) {
    if (typeof period === "number") this.radioModel = period;
    this.loading = true;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    ),
      finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      ),
      payId = this.FilterForm.value.payIdControl,
      checkId = this.FilterForm.value.CheckId;
    console.log("Pay ID:", payId);
    // let account = '', companyId = '', userName = '';
    // dateFrom, dateTo, account, status, id, companyId, userName, curPage, itemPerPage
    this.payService
      .getCheckOutList(
        this.radioModel,
        start,
        finish,
        checkId,
        payId,
        this.currentPage + 1,
        this.paginationItemPerPage
      )
      .pipe(first())
      .subscribe(
        data => {
          console.log(data);
          if (data.ResultCode === 0) {
            let datas = [];
            let itemTotal = 0;
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.ItemsReport;
              itemTotal = data.ReturnObject.Pagination.ItemTotal;
              this.totalAmount = data.ReturnObject.Total.AmountBody;
            }
            this.paginationItems = itemTotal;
            console.log("Get First Datas:", datas); 
            this.dataSource = new MatTableDataSource(datas); // array of datas
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.getFirstDatas(this.radioModel);
  }

  toInt(input) {
    if (!input.target.value.slice(-1).match(/[0-9]/)) {
      input.target.value = input.target.value.slice(0, -1);
      console.log("Filter value:", input.target.value);
    }
  }
  toIntBlur(input) {
    input.target.value = input.target.value.replace(/[^0-9]/g, "");
  }

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }
  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }

  modalDatas = {}; // for display datas in modal

  ngOnInit() {
    this.changeCheckStatusError = '';
    this.paginationItemPerPage = 10;
    this.EditForm = new UntypedFormGroup({
      Comment: new UntypedFormControl("", [
        Validators.required 
])
     
    });
    this.FilterForm = new UntypedFormGroup({
      CheckId: new UntypedFormControl(""),
      payIdControl: new UntypedFormControl(""), //, [this.dealerDropValidator(this.options)]),
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      )
    });
    this.getFirstDatas();
  }

  checkoutChangeStatus(checkId, opId, amount) {
    this.loading = true;
    this.changeCheckStatusError = '';
    this.payService.changeCheckoutStatus(checkId, opId, amount, this.EditForm.controls.Comment.value).subscribe(
      data => {
        if (data.ResultCode === 0) {
          console.log('Translate was Created');
          let theme = "";
          let body = "";
          this.translate
            .get("PAY.MODAL_THEME_SUCCESS_" + status)
            .subscribe(text => {
              theme = text;
            });
          this.translate
            .get("PAY.MODAL_BODY_SUCCESS_" + status)
            .subscribe(text => {
              body = text;
            });
          this.toastr.success(body, theme);
          this.loading = false;
          this.getFirstDatas();
          this.CloseDialog();
        } else {
          let theme = "";
          this.translate.get("PAY.MODAL_BODY_ERROR").subscribe(text => {
            theme = text;
          }); // Text in component
          data.ResultMessage === null
            ? (this.changeCheckStatusError = "")
            : (this.changeCheckStatusError = data.ResultMessage);
          this.toastr.error(this.changeCheckStatusError, theme);
        //  this.changeCheckStatusError = data.ResultMessage;
        }
      },
      error => { 
        console.log(error);
        this.changeCheckStatusError = error;
      },
      () => {
        this.loading = false;
      });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';


@Injectable()

export class PaymentService {

	constructor(private http: HttpClient, private authService: AuthenticationService) {

	}
	baseUrl: string = environment.apiUrl;
	getDealerDeposit(fileId) {
		  
		return this.http
		  .get<any> (this.baseUrl+`/api/payment/DealerDeposit?pFileId=`+ fileId, {
			headers: {
			  "Content-Type": "application/json",
			  "Accept-Language": this.GetLang(),
			},
		  })
		  .pipe(
			map((response) => {
			  if (this.authService.checkTokenResponse()) {
				return response
			  }
			})
		  )
	  }
	proccessPay(fileId) {
		  
		return this.http
		  .get<any> (this.baseUrl+`/api/payment/ProcessPay?pFileId=`+ fileId, {
			headers: {
			  "Content-Type": "application/json",
			  "Accept-Language": this.GetLang(),
			},
		  })
		  .pipe(
			map((response) => {
			  if (this.authService.checkTokenResponse()) {
				return response
			  }
			})
		  )
	  }
	  proccessPause(fileId) {
		  
		return this.http
		  .get<any> (this.baseUrl+`/api/payment/ProcessPause?pFileId=`+ fileId, {
			headers: {
			  "Content-Type": "application/json",
			  "Accept-Language": this.GetLang(),
			},
		  })
		  .pipe(
			map((response) => {
			  if (this.authService.checkTokenResponse()) {
				return response
			  }
			})
		  )
	  }
	  proccessReject(fileId) {
		  
		return this.http
		  .get<any> (this.baseUrl+`/api/payment/ProcessReject?pFileId=`+fileId , {
			headers: {
			  "Content-Type": "application/json",
			  "Accept-Language": this.GetLang(),
			},
		  })
		  .pipe(
			map((response) => {
			  if (this.authService.checkTokenResponse()) {
				return response
			  }
			})
		  )
	  }
	 getMassPaymentFile(fileId): Observable<HttpResponse<Blob>>{
		 
		return this.http.get<HttpResponse<Blob>> (this.baseUrl+`/api/payment/CashlessPaymentFile?pFileId=` + fileId,  { 
			responseType: 'blob' as 'json',
			observe: 'response' as 'body',
				headers: { 'Accept-Language': this.GetLang() } })
			  .pipe(map(response => {
				// login successful if there's a jwt token in the response
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				if (this.authService.checkTokenResponse()) {
					return response;
				}
			}));  
	}

getCashlessPayment( period, dateFrom, dateTo,fileName,userName, curPage, itemPerPage,dealerId){
	return this.http.get<any>(this.baseUrl+`/api/payment/CashlessPaymentListDate?`+
	`		pPeriodType=`+period+`&pDateFrom=`+dateFrom+`&pDateTo=`+dateTo+`&pFileName=`+fileName+`&pUserName=`+userName+`&pCurPage=`+curPage+`&pItemPerPage=`+itemPerPage+`&pDealerId=`+dealerId,
	 { headers: { 'Accept-Language': this.GetLang() } })
			.pipe(map(response => {
				if (this.authService.checkTokenResponse()) {
					return response;
				}
				// login successful if there's a jwt token in the response
				// store user details and jwt token in local storage to keep user logged in between page refreshes

			}));
}
uploadCashlessFile(file, fileName, dealerId) {
    let body = {
      Content: file,
      Name: fileName,
	  DealerId: dealerId
    }
    console.log("here:", body)
    return this.http
      .post<any>(this.baseUrl+`/api/payment/UploadCashlessFile`, body, {
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.GetLang(),
        },
      })
      .pipe(
        map((response) => {
          if (this.authService.checkTokenResponse()) {
            return response
          }
        })
      )
  }




	getProductPage(serviceId: number) {
		let user = JSON.parse(localStorage.getItem('currentUser'));
		return this.http.get<any>(this.baseUrl+`/api/payment/start/` + serviceId, { headers: { 'Accept-Language': this.GetLang() } })
			.pipe(map(response => {
				if (this.authService.checkTokenResponse()) {
					return response;
				}
				// login successful if there's a jwt token in the response
				// store user details and jwt token in local storage to keep user logged in between page refreshes

			}));
	}

	checkPayment(serviceId: number, subService, items, salePoint) {
		let body = {
			"SubService": subService,
			"Items": items,
			"SalePoint": salePoint
		};
		return this.http.post<any>(this.baseUrl+`/api/payment/check/` + serviceId, body, { headers: { 'Accept-Language': this.GetLang() } })
			.pipe(map(response => {
				// login successful if there's a jwt token in the response
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				if (this.authService.checkTokenResponse()) {
					return response;
				}
			}));
	}

	sendPayment(serviceId: number, data) {
		let body = data;
		return this.http.post<any>(this.baseUrl+`/api/payment/pay/` + serviceId, body, { headers: { 'Accept-Language': this.GetLang() } })
			.pipe(map(response => {
				// login successful if there's a jwt token in the response
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				if (this.authService.checkTokenResponse()) {
					return response;
				}
			}));
	}

	downloadPayment(refstan) {
        return this.http.get(this.baseUrl+`/api/payment/TransactionPdf/` + refstan, {
            headers: { 'Accept-Language': this.GetLang() },
			responseType: 'blob'
		});

	}
	getCancelPayments(dealerId, dateFrom, dateTo, curPage, itemPerPage) {
        return this.http.get<any>(this.baseUrl+`/api/report/CancelTransactions?DateFrom=` + dateFrom + '&DateTo=' + dateTo + '&Account&UserName&DealerId=' + dealerId + '&CompanyId&Status&RefStan&CurPage=' + curPage + '&ItemPerPage=' + itemPerPage)
            .pipe(map(response => {
                return response;
            }));
    }

	private GetLang() {
		if (localStorage.getItem('currentLang') === 'tr') {
            return 'tr-TR';
		}
		return 'en-US';
	}
}



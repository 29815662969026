import { Component, OnInit, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	FormGroupDirective,
	NgForm,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'

import { AdministrationService } from '../../_services/administration.service'
import { DatePipe, DecimalPipe } from '@angular/common'
import { saveAs } from 'file-saver'
import { NestedTreeControl } from '@angular/cdk/tree'
import { MatTreeNestedDataSource } from '@angular/material/tree'
/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'

export interface ItemList {
	Value: string
	Name: string
}
interface LicenseList {
	Name: string
	Id: string
}

@Component({
	templateUrl: 'license.component.html',
	styleUrls: ['administration.scss'],
	providers: [DatePipe, DecimalPipe],
})
export class LicenseComponent implements OnInit {
	permissions

	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	public currentPage = 0
	isLoadingDetailInfo: boolean = true // spinner
	loading = true // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	events: string[] = [] // Date picker

	//----------------- list of columns
	displayedColumns: string[] = [
		'Index',
		'LicenseName',
		'LicenseDescription',
		'Actions',
	]

	dataSource = new MatTableDataSource() // array of datas

	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private decimalPipe: DecimalPipe,

		public route: ActivatedRoute,
		private administrationService: AdministrationService,
		private formBuilder: UntypedFormBuilder,
		private translate: TranslateService,
		public toastr: ToastrService
	) {}

	FilterForm: UntypedFormGroup // Form initial
	licenses: LicenseList[] = []

	getFirstDatas() {
		this.loading = true
		this.licenses = []
		this.administrationService
			.getLicenses(
				this.currentPage + 1,
				this.paginationItemPerPage,
				this.FilterForm.controls.License.value
			)
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						console.log('Data Lic::', data.ReturnObject)
						if (data.ReturnObject !== null) {
							this.licenses = data.ReturnObject.DmsLecenceList
							this.dataSource = new MatTableDataSource(
								this.licenses
							) // array of datas
							this.paginationItems =
								data.ReturnObject.PagingInfo.TotalItems
						} else {
							this.paginationItems = 0
							this.dataSource = new MatTableDataSource([]) // array of datas
						}
					} else {
						this.paginationItems = 0
						this.dataSource = new MatTableDataSource([]) // array of datas
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}
	// filterTable() {
	//   let filterValue = this.FilterForm.controls.License.value;
	//   if (filterValue.length > 0) {
	//     this.loading = true;
	//     let filtered = [],
	//       tableData = this.licenses;
	//     tableData.map((x: any) => {
	//       if (x.Name.match(filterValue)) {
	//         filtered.push(x);
	//       }
	//     });
	//     setTimeout(() => {
	//       this.dataSource = new MatTableDataSource(filtered);
	//       this.loading = false;
	//     }, 300);
	//   } else {
	//     this.dataSource = new MatTableDataSource(this.licenses);
	//   }
	// }

	onSubmit() {
		this.getFirstDatas()
	}

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}

	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	getTranslations() {
		this.translate
			.get([
				'PAGINATOR.ITEMS_PER_PAGE',
				'PAGINATOR.NEXT_PAGE',
				'PAGINATOR.PREVIOUS_PAGE',
				'PAGINATOR.RANGE',
			])
			.subscribe((translation) => {})
	}
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas()
	}

	openModal(templateRef) {
		this.isLoadingDetailInfo = false

		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
			minHeight: templateRef !== 'treeModal' ? '580px' : '',
		})
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}

	ngOnInit() {
		let ls = new SecureLS()
		this.permissions =
			ls.get('permissions')[this.route.data['value'].pathCheck]

		this.paginationItemPerPage = 10

		this.FilterForm = new UntypedFormGroup({
			License: new UntypedFormControl(''),
			UserName: new UntypedFormControl(''),
		}) // for header Filter
		this.getFirstDatas()
	}
}

import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, FormControl, Validators, AbstractControl, ValidatorFn, FormGroupDirective, NgForm} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, first} from 'rxjs/operators';
import {AlertService, UserService, ProviderService} from '../../_services';
import { DateAdapter, ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import {DataSource} from '@angular/cdk/table';
import {DatePipe} from '@angular/common';
/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {ProfileService} from '../../_services/profile.service';
import {AdministrationService} from '../../_services/administration.service';


@Component({
	templateUrl: 'profile.component.html',
	styleUrls: ['changepassword.scss'],
	providers: [DatePipe]
})


export class ProfileComponent implements OnInit {
	private markFormGroupTouched(formGroup: UntypedFormGroup) {
		(<any>Object).values(formGroup.controls).forEach(control => {
			control.markAsTouched();

			if (control.controls) {
				this.markFormGroupTouched(control);
			}
		});
	}

	constructor(
		public translate: TranslateService,
		private formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private profileService: ProfileService,
		private administrationService: AdministrationService,
		public toastr: ToastrService,
		public datePipe: DatePipe,
	) {

	}
	modalDatas;

	loading: boolean = false;
	Info;
	submitted: boolean = false;
	profile_error = '';
	loading_page: boolean = false;
	ProfileInfo: UntypedFormGroup;
	serviceProviders;
	Languages = [
		{Culture: '', Name: ''},
	];
	saveProfile() {
		this.markFormGroupTouched(this.ProfileInfo);
		this.profile_error = '';
		this.submitted = true;
		if (this.ProfileInfo.valid === true) {
			this.submitted = false;
			console.log(this.ProfileInfo.value);
			this.loading = true;
			this.profileService.changePersonalDatas(this.ProfileInfo.value)
				.pipe(first())
				.subscribe(
					data => {
						console.log(data);
						if (data.ResultCode === 0) {
							let theme = '';
							let body = '';
							this.translate.get('PROFILE.THEME_FORM_SUCCESS').subscribe((text) => {
								theme = text;
							});
							this.translate.get('PROFILE.BODY_FORM_SUCCESS').subscribe((text) => {
								body = text;
							});
							this.toastr.success(body, theme);
						} else {
							data.ResultMessage === null ? this.profile_error = '' : this.profile_error = data.ResultMessage;
							let theme = '';
							this.translate.get('PROFILE.THEME_FORM_ERROR').subscribe((text) => {
								theme = text;
							});
							this.toastr.error(this.profile_error, theme);
						}
						this.loading = false;
					},
					error => {
						this.loading = false;
						console.log(error);
					});
		}
	}

	getProfileSettings() {
		this.loading = true;
		this.administrationService.getLanguagesList().pipe(first())
			.subscribe(
				data => {
					console.log(data);
					if (data.ResultCode === 0) {
						this.Languages = data.ReturnObject;
					}
				},
				error => {
					console.log(error);
				},
				() => {
					this.profileService.getProfileSettings().pipe(first())
						.subscribe(
							data => {
								console.log(data);
								if (data.ResultCode === 0) {
									this.modalDatas = data.ReturnObject;
									this.ProfileInfo.patchValue({['Name']: data.ReturnObject.User.Name});
									this.ProfileInfo.patchValue({['Email']: data.ReturnObject.User.Email});
									this.ProfileInfo.patchValue({['Language']: data.ReturnObject.User.LanguageCode});
									this.loading = false;
								}
							},
							error => {
								console.log(error);
							},
							() => {
								this.loading_page = true;
							});
				});
	}

	ngOnInit() {
		this.ProfileInfo = this.formBuilder.group({
			'Name': ['', Validators.required],
			'Email': ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
			'Language': ['', Validators.required],
		});
		let ls = new SecureLS(); // for Secure Local storage
		if (ls.get('info') !== null) {
			this.Info = ls.get('info');
		}
		this.getProfileSettings();
	}
}

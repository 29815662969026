import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { ProviderService } from '../../_services'
import { DateAdapter } from '@angular/material/core'
import { DatePipe } from '@angular/common'
import { AdministrationService } from '../../_services/administration.service'
import { ReportsDatasService } from '../../_services/reportsdata.service'
import { saveAs } from 'file-saver'
import { TranslateService } from '@ngx-translate/core'
import { NestedTreeControl } from '@angular/cdk/tree'
import { MatTreeNestedDataSource } from '@angular/material/tree'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'

import { Sort } from '@angular/material/sort'
import { ToastrService } from 'ngx-toastr'
import { now } from 'moment'

/** Constants used to fill up our data base. */
interface DealerNode {
	name: string
	Id: number
	ParentId: number
	completed: boolean
	children?: DealerNode[]
}

export interface standartType {
	value: string
	viewValue: string
} // standart Interface

export interface ItemList {
	Value: string
	Name: string
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
}
@Component({
	templateUrl: 'transaction-report.component.html',
	styleUrls: ['reports.scss'],
	providers: [DatePipe],
})
export class TransactionReportComponent implements OnInit {
	dataSource = new MatTableDataSource() // array of datas
	secondTableSource = new MatTableDataSource() // array of datas

	hasChild = (_: number, node: DealerNode) =>
		!!node.children && node.children.length > 0
	treeControl = new NestedTreeControl<DealerNode>((node) => node.children)
	dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
	treeData

	showTree: boolean = false
	currentDealer = {
		Name: '',
		Value: '',
	}

	fiscList = [
		{
			Name: 'All',
			Value: '',
		},
		{
			Name: 'fiscTrue',
			Value: 'true',
		},
		{
			Name: 'fiscFalse',
			Value: 'false',
		},
	]

	growTree(arr) {
		let storageData = JSON.parse(sessionStorage.getItem('dealerCBTreeList'))
		this.dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
		let mainArray = arr
		if (!storageData) {
			mainArray.map((x) => {
				x.children = []
			})
			mainArray.map((z) => {
				if (z.Id !== 0) {
					let currentEl = mainArray.find((x) => x.Id === z.ParentId)
					if (currentEl) {
						currentEl.children.push(z)
					}
				}
			})
			let result = mainArray.splice(0, 1)
			this.treeData = result
			sessionStorage.setItem('dealerCBTreeList', JSON.stringify(result))
			this.dataTreeSource = result
		} else {
			this.dataTreeSource = storageData
		}
	}
	chooseDealer(smth) {
		console.log('TreeData', this.treeData)
		// this.CloseDialog();
		// this.dealerFControl.patchValue(smth.Name);
		// this.currentDealer = { Value: smth.Id, Name: smth.Name };
		// this.showTree = false;
		// this.getFirstDatas();
	}
	checkDealer(event, node) {
		let state = event.checked

		node.completed = state
		if (node.children) {
			this.addToList(node)
			node.children.map((cnode) => {
				cnode.completed = state
				this.addToList(cnode)

				if (cnode.children) {
					cnode.children.map((ccnode) => {
						ccnode.completed = state
						this.addToList(ccnode)

						if (ccnode.children) {
							ccnode.children.map((cccnode) => {
								cccnode.completed = state
								this.addToList(cccnode)

								cccnode.children.map((ccccnode) => {
									ccccnode.completed = state
									this.addToList(ccccnode)
								})
							})
						}
					})
				}
			})
		}
	}

	addToList(node) {
		if (node.completed) {
			if (!this.checkedArray.includes(node.Id)) {
				this.checkedArray.push(node.Id)
			}
		} else {
			if (this.checkedArray.includes(node.Id)) {
				this.checkedArray = this.checkedArray.filter(
					(x) => x !== node.Id
				)
			}
		}
	}
	public checkedArray = []

	onClickTreeAll() {
		;(<HTMLElement>document.querySelector('#mat-checkbox-1 label')).click()
	}
	onFocusOutTree(event) {
		this.showTree = false
		console.log('closed', event)
	}
	stopClick(event) {
		event.preventDefault()
	}
	public firstDealerClick: boolean = true
	//----------------------- get a dealer list and put to option's
	InitDealerFilter(): void {
		this.administrationService.getDealerDatasAsList().subscribe(
			(data) => {
				this.treeData = data.ReturnObject
				this.dealerFControl.setValue(this.currentDealer.Name)
				this.treeData.map((x) => (x.completed = true))
				this.treeData.forEach((x) => this.checkedArray.push(x.Id))
				this.growTree(this.treeData)
			},
			(error) => {
				console.log(error)
			},
			() => {
				this.getFirstDatas(this.radioModel)
			}
		)
	}
	checkFirstClick(): void {
		this.showTree = !this.showTree
		if (this.firstDealerClick) {
			setTimeout(() => {
				;(<HTMLElement>(
					document.querySelector('#mat-checkbox-1 label')
				)).click()
			}, 0)

			this.firstDealerClick = false
		}
	}
	public defaultTime = [new Date().getHours(), 0o0, 0o0]
	public totalAmountBody = 0
	public totalAmountFee = 0
	public totalAmountTotal = 0
	public totalRevenue = 0

	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	saleFilteredOptions: Observable<ItemList[]> // salePoints

	public transactionCurrentID
	public transactionCurrentDealerName
	public transactionCurrentServiceName
	public currentPage = 0
	isLoadingDetailInfo: boolean = false
	modalDatas = {} // for display datas in modal

	//-------------------------------------
	providersList
	isDownloadingFile: boolean = false
	Transactions_Datas
	dealerListOptions: ItemList[]
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	radioModel = 1
	services: ItemList[]

	account = '' // Account input
	refStan = '' // refStan input
	defaultServices: any
	serviceGroupSelected: any = '' // Service selected
	serviceSubSelected: any = ''
	serviceGroups: any = []
	channel: any = []
	servicesToShow: any = []
	userLogin = '' // User Login input

	minDate = new Date(2000, 0, 1, 0, 0, 0) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	dealerFControl = new UntypedFormControl() // Dealer
	saleListOptions: ItemList[]

	paidTypes: standartType[] = [
		{ value: 'all', viewValue: 'All transactions' },
		{ value: 'cash', viewValue: 'Cash' },
		{ value: 'ewallet', viewValue: 'E-Wallet' },
	] // Select Channel

	operations: standartType[] = [
		{ value: 'in', viewValue: 'Cash In' },
		{ value: 'out', viewValue: 'Cash Out' },
	] // Select Channel
	dealerFilteredOptions: Observable<ItemList[]> // dealer
	currentTime = new Date(new Date().setHours(0, 0, 0))
	currentTimeEnd = new Date(new Date().setHours(23, 59, 59))
	//----------------- list of columns
	displayedColumns: string[] = [
		'RefStan',
		'Fisc',
		'Status',
		'DealerName',
		'User',
		'CompanyName',
		'CompanyId',
		'TrnDate',
		'Account',
		'PaySourceType',
		'SalesPointType',
		'Amount',
		'TransfAmount',
		'Currency',
		'Commision',
	]

	//Filter

	filterPaymentType = [
		{
			Name: 'All',
			Value: '',
		},
		{
			Name: 'Cash',
			Value: '1',
		},
		{
			Name: 'Credit card',
			Value: '2',
		},
	]

	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private providerService: ProviderService,
		private administrationService: AdministrationService,
		private dateAdapter: DateAdapter<Date>,
		private reportsDatasService: ReportsDatasService,
		private translate: TranslateService,
		public toastr: ToastrService
	) {
		dateAdapter.setLocale('en-EN') // change this for change format date in select
	}

	FilterForm: UntypedFormGroup // Form initial

	@ViewChild(MatSort) sort: MatSort

	InitChannelFilter() {
		this.providerService
			.getSalePoints()
			// .pipe(first())
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						this.channel = data.ReturnObject.SalePoints
						console.log('Sale Points', this.channel)
						if (this.channel.length > 0) {
					 		this.channel.unshift({ Id: '', Name: 'All' })
						 	this.FilterForm.patchValue({
						 		['SalePointId']: this.channel[0].Id,
						 	})
						}
					}
				},
				(error) => {
					console.log(error)
				}
			)
	}

	//----------------------- get a provider list and put to option's
	InitProviderFilter() {
		this.providerService.ProviderAllGroups().subscribe(
			(data) => {
				this.serviceGroups = data.map((x) => ({
					ID: x.ID.toString(),
					Name: x.Name,
				}))
			},
			(error) => {
				console.log(error)
			},
			() => {
				this.InitDealerFilter()
			}
		)
		this.providerService.AllList().subscribe(
			(data) => {
				this.defaultServices = data.map((x) => x)

				this.servicesToShow = this.defaultServices
			},
			(error) => {
				console.log(error)
			}
		)
	}
	sortData(sort: Sort) {
		const data = this.currentSecondData.slice()
		if (!sort.active || sort.direction === '') {
			this.currentSecondData = data
			return
		}

		this.currentSecondData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc'
			if (sort.active.slice(-1) !== 'e') {
				let compareIndex = sort.active.match(/\d+/)[0]
				return compare(
					Number(a.ls[compareIndex]),
					Number(b.ls[compareIndex]),
					isAsc
				)
			} else {
				return compare(a.dname, b.dname, isAsc)
			}
		})
	}
	activeFirstTab = true
	allGroupsSelected: boolean = false
	firstTimeGroups: boolean = true
	firstTimeServices: boolean = true
	allServicesSelected: boolean = true

	groupsClicked() {
		if (this.firstTimeGroups) {
			let allIDs = this.serviceGroups.map((x) => x.ID)
			this.allGroupsSelected = true
			this.serviceGroupSelected = allIDs
		}
	}
	serviceSubClicked() {
		if (this.firstTimeServices) {
			let allIDs = this.servicesToShow.map((x) => x.ID)
			this.allServicesSelected = true
			this.serviceSubSelected = allIDs
			this.firstTimeServices = false
		}
	}
	deselectorGroups() {
		let allIDs = this.serviceGroups.map((x) => x.ID)

		if (this.allGroupsSelected) {
			this.serviceGroupSelected = []
		} else {
			this.serviceGroupSelected = allIDs
			this.serviceHandler()
			setTimeout(() => {
				this.FilterForm.value.ServiceDrop = ''
			}, 0)
			this.firstTimeGroups = false
		}

		this.allGroupsSelected = !this.allGroupsSelected
	}
	deselectorServices() {
		let allIDs = this.servicesToShow.map((x) => x.ID)
		if (this.allServicesSelected) {
			this.serviceSubSelected = []
		} else {
			this.serviceSubSelected = allIDs
			setTimeout(() => {
				this.FilterForm.value.ServiceSub = ''
			}, 0)
		}
		this.allServicesSelected = !this.allServicesSelected
	}
	serviceHandler() {
		this.serviceSubSelected = []
		this.servicesToShow = this.defaultServices.filter((x) => {
			return this.serviceGroupSelected.includes(String(x.GroupID))
		})
		this.serviceSubSelected = this.servicesToShow.map((x) => x.ID)
		this.firstTimeServices = true
	}

	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas(this.radioModel)
	}

	displaySale(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}

	private sale_filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.saleListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}
	changeTab(number?) {
		if (number === 1 && !this.activeFirstTab && !this.loading) {
			this.activeFirstTab = true
			this.getFirstDatas(this.radioModel)
		} else if (number === 2 && this.activeFirstTab && !this.loading) {
			this.activeFirstTab = false
			this.getSecondDatas(this.radioModel)
		}
	}
	getFirstDatas(period?) {
		if (typeof period === 'number') {
			this.radioModel = period
		}

		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		if (this.checkVals()) {
			this.loading = true
			this.showTree = false

			let dealerArray = this.checkedArray.join(',')
			let dealerId = this.currentDealer.Value
			let filter = this.FilterForm.value

			console.log(filter.IsFisc)
			let serviceNow =
				this.serviceSubSelected[0] !== '' ? this.serviceSubSelected : ''
			this.reportsDatasService
				.getTransactionsDatasCurrentPage(
					start,
					finish,
					this.radioModel,
					filter,
					serviceNow,
					dealerId,
					dealerArray,
					this.currentPage + 1,
					this.paginationItemPerPage
				)
				.subscribe(
					(data) => {
						if (data.ResultCode === 0) {
							let datas = []
							let itemTotal = 0
							let amountBody = 0
							let amountFee = 0
							let amountTotal = 0
							let totalRevenue = 0
							if (data.ReturnObject !== null) {
								datas = data.ReturnObject.ItemsReport
								console.log(datas)
								this.dataSource.sort = this.sort // activate sort after insert datas
								itemTotal =
									data.ReturnObject.Pagination.ItemTotal
								amountBody = data.ReturnObject.Total.AmountBody
								amountFee = data.ReturnObject.Total.AmountFee
								amountTotal =
									data.ReturnObject.Total.AmountTotal
							}
							this.paginationItems = itemTotal
							this.totalAmountBody = amountBody
							this.totalAmountFee = amountFee
							this.totalAmountTotal = amountTotal
							this.dataSource = new MatTableDataSource(datas) // array of datas
						}
						this.loading = false
					},
					(error) => {
						this.loading = false
						console.log(error)
					}
				)
		} else {
			let theme = ''
			let body = ''
			this.translate
				.get('REPORT.THEME_TRANSACTION_DATE')
				.subscribe((text) => {
					theme = text
				})
			this.translate
				.get('REPORT.BODY_TRANSACTION_DATE')
				.subscribe((text) => {
					body = text
				})
			this.toastr.warning(body, theme)
		}
	}
	maxColumns = 3
	columnArray: any = []
	displayedSecondColumns: string[] = []
	currentSecondData: any[any] = []
	currentSecondDataTotal = { dname: '', ls: [] }
	displayedCompanyColumns = []
	checkDivide(number) {
		return number % 3 == 0
			? 'pivot_col_color_id_' + (number % 3) + ' border-it'
			: 'pivot_col_color_id_' + (number % 3)
	}
	columnTextColor(number) {
		return 'pivot_col_tcolor_id_' + (number % 3)
	}
	checkVals() {
		return true;
		let dateFrom = new Date(
				this.FilterForm.controls.dateFrom.value
			).getTime(),
			dateTo = new Date(this.FilterForm.controls.dateTo.value).getTime(),
			oneMonth = 2682000000

		if (this.radioModel === 0) {
			return dateTo - dateFrom <= oneMonth
		} else {
			return true
		}
	}

	FiscDetails(templateRef: any, row: any) {
		this.openModal(templateRef)

		this.reportsDatasService._getFiscDetails(row.RefStan).subscribe(
			(data) => {
				console.log(data)
				if (data.ResultCode === 0) {
					if (data.ReturnObject !== null) {
						this.modalDatas = data.ReturnObject
						this.isLoadingDetailInfo = false
						console.log('ModalDatas::', this.modalDatas)
					} else {
						this.isLoadingDetailInfo = false
					}
				} else {
					this.isLoadingDetailInfo = false
				}
			},
			(error) => {
				this.isLoadingDetailInfo = false
				console.log(error)
			}
		)
	}

	isLoadingTabInfo: boolean = false
	panelClosed: boolean = true
	sysInterctionData: [] = []
	sysResponseData: []
	getSysResponse(event, refId) {
		this.panelClosed = true
		if (event.index === 3) {
			this.isLoadingTabInfo = true
			this.reportsDatasService.getSystemTransaction(refId).subscribe(
				(res) => {
					if (res['ResultCode'] === 0) {
						if (res['ReturnObject'] !== null) {
							this.sysResponseData = res['ReturnObject'].Items
							this.isLoadingTabInfo = false
						} else {
							this.isLoadingTabInfo = false
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					this.isLoadingTabInfo = false
					console.log(error)
				}
			)
		} else if (event.index === 1) {
			if (this.sysInterctionData.length == 0) {
				this.isLoadingInterInfo = true
				this.reportsDatasService.getSystemInteraction(refId).subscribe(
					(res) => {
						if (res['ResultCode'] === 0) {
							if (res['ReturnObject'] !== null) {
								this.sysInterctionData =
									res['ReturnObject'].Items
								this.isLoadingInterInfo = false
							} else {
								this.isLoadingInterInfo = false
							}
						} else {
							this.CloseDialog()
						}
					},
					(error) => {
						this.isLoadingInterInfo = false
						console.log(error)
					}
				)
			}
		}
	}
	interLogData = {}
	isLoadingInterInfo: boolean = false
	getInterLog(event, key, index) {
		index = index.toString()
		if (!this.interLogData.hasOwnProperty(index)) {
			this.reportsDatasService.getSystemInteractionLog(key).subscribe(
				(res) => {
					if (res['ResultCode'] === 0) {
						if (res['ReturnObject'] !== null) {
							this.interLogData[index] =
								res['ReturnObject'].Details
						} else {
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					console.log(error)
				}
			)
			this.panelClosed = false
		} else {
			console.log('IT HAS!')
		}
	}

	getSecondDatas(period?) {
		if (typeof period === 'number') {
			this.radioModel = period
		}
		if (this.checkVals()) {
			this.loading = true
			let start = this.datePipe.transform(
				this.FilterForm.controls.dateFrom.value,
				'yyyy-MM-ddTHH:mm:ss'
			)
			let finish = this.datePipe.transform(
				this.FilterForm.controls.dateTo.value,
				'yyyy-MM-ddTHH:mm:ss'
			)
			this.showTree = false

			let dealerArray = this.checkedArray.join(',')

			// Take datas from form
			let dealerId = this.currentDealer.Value
			let filter = this.FilterForm.value
			let serviceNow =
				this.serviceSubSelected[0] !== '' ? this.serviceSubSelected : ''
			this.reportsDatasService
				.getTransactionsPivot(
					start,
					finish,
					this.radioModel,
					filter,
					serviceNow,
					dealerId,
					dealerArray
				)
				.subscribe(
					(data) => {
						if (data.ResultCode === 0) {
							if (data.ReturnObject !== null) {
								this.currentSecondData = data.ReturnObject.rows
								this.currentSecondData.forEach((el) => {
									if (el.dname === 'TOTAL') {
										this.currentSecondDataTotal = {
											dname: el.dname,
											ls: el.ls,
										}
									}
								})
								this.currentSecondData =
									this.currentSecondData.slice(0, -1)
								this.displayedCompanyColumns = []
								this.displayedSecondColumns = []
								for (
									let i = 0;
									i < data.ReturnObject.srvs.length;
									i++
								) {
									this.displayedCompanyColumns.push(
										data.ReturnObject.srvs[i].name
									)
									this.displayedSecondColumns.push('1')
									this.displayedSecondColumns.push('2')
									this.displayedSecondColumns.push('3')
								}
							} else {
								console.log('ITS NULL DATA!')
							}
						}
						this.loading = false
					},
					(error) => {
						this.loading = false
						console.log(error)
					}
				)
		} else {
			let theme = ''
			let body = ''
			this.translate
				.get('REPORT.THEME_TRANSACTION_DATE')
				.subscribe((text) => {
					theme = text
				})
			this.translate
				.get('REPORT.BODY_TRANSACTION_DATE')
				.subscribe((text) => {
					body = text
				})
			this.toastr.warning(body, theme)
		}
	}
	download(format) {
		if (this.checkVals()) {
			this.isDownloadingFile = true
			this.loading = true
			let start = this.datePipe.transform(
				this.FilterForm.controls.dateFrom.value,
				'yyyy-MM-ddTHH:mm:ss'
			)
			let finish = this.datePipe.transform(
				this.FilterForm.controls.dateTo.value,
				'yyyy-MM-ddTHH:mm:ss'
			)
			this.showTree = false
			let dealerArray = this.checkedArray.join(',')

			let dealerId = this.currentDealer.Value
			let serviceNow =
				this.serviceSubSelected[0] !== '' ? this.serviceSubSelected : ''
			if (this.activeFirstTab) {
				this.reportsDatasService
					.getFile(
						start,
						finish,
						this.radioModel,
						this.FilterForm.value,
						serviceNow,
						dealerId,
						dealerArray,
						this.currentPage + 1,
						this.paginationItemPerPage,
						format
					)
					.subscribe(
						(res) => {
							this.isDownloadingFile = false
							this.loading = false
							saveAs(res, finish + '.' + format, {
								type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							})
						},
						(error) => {
							console.log(error)
							this.loading = false
							this.isDownloadingFile = false
						}
					)
			} else {
				this.reportsDatasService
					.getFilePivot(
						start,
						finish,
						this.radioModel,
						this.FilterForm.value,
						serviceNow,
						dealerId,
						dealerArray,

						this.currentPage + 1,
						this.paginationItemPerPage,
						format
					)
					.subscribe(
						(res) => {
							this.isDownloadingFile = false
							this.loading = false
							saveAs(res, finish + '.' + format, {
								type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
							})
						},
						(error) => {
							console.log(error)
							this.loading = false
							this.isDownloadingFile = false
						}
					)
			}
		} else {
			let theme = ''
			let body = ''
			this.translate
				.get('REPORT.THEME_TRANSACTION_DATE')
				.subscribe((text) => {
					theme = text
				})
			this.translate
				.get('REPORT.BODY_TRANSACTION_DATE')
				.subscribe((text) => {
					body = text
				})
			this.toastr.warning(body, theme)
		}
	}

	onSubmit() {
		this.activeFirstTab
			? this.getFirstDatas(this.radioModel)
			: this.getSecondDatas(this.radioModel)
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}

	private _filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.dealerListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}

	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}
	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
			minHeight: '680px',
		})
	}
	selectRow(templateRef, row) {
		this.isLoadingDetailInfo = true
		this.sysResponseData = []
		this.sysInterctionData = []
		this.interLogData = {}
		this.reportsDatasService
			.getAdvanceTransaction(row.RefStan)
			.pipe(first())
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas = data.ReturnObject
							this.isLoadingDetailInfo = false
						} else {
							this.isLoadingDetailInfo = false
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					this.isLoadingDetailInfo = false
					console.log(error)
				}
			)
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}

	ngOnInit() {
		this.translate.get('REPORT.FILTER_FISC_ALL').subscribe((text) => {
			this.fiscList[0].Name = text
		})
		this.translate.get('REPORT.FILTER_FISC_TRUE').subscribe((text) => {
			this.fiscList[1].Name = text
		})
		this.translate.get('REPORT.FILTER_FISC_FALSE').subscribe((text) => {
			this.fiscList[2].Name = text
		})

		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			Dealer: new UntypedFormControl('', Validators.required),
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTimeEnd, disabled: true },
				Validators.required
			),
			Account: new UntypedFormControl(''),
			RefStan: new UntypedFormControl(''),
			UserName: new UntypedFormControl(''),
			ServiceDrop: new UntypedFormControl(''),
			ServiceSub: new UntypedFormControl(''),
			ChannelDrop: new UntypedFormControl(''),
			PaidTypeDrop: new UntypedFormControl(''),
			OperationDrop: new UntypedFormControl(''),
			PaymentSourceType: new UntypedFormControl(''
				//this.filterPaymentType[0].Value
			),
			SalePointId: new UntypedFormControl(''),
			IsFisc: new UntypedFormControl(this.fiscList[0].Value),
		})
		this.InitProviderFilter()
		this.InitChannelFilter()

	 
	}
}

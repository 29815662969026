import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Cacheable } from 'ngx-cacheable'
import { AuthenticationService } from './authentication.service'
import { environment } from './../../environments/environment'
import { Key } from 'protractor'

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
@Injectable()
export class ReportsDatasService {
	constructor(
		private http: HttpClient,
		private authService: AuthenticationService
	) {}
	basUrl: string = environment.apiUrl

	getStatusResponse(refStan, method, comment, account) {
		return this.http
			.get(
				this.basUrl +
					'/api/ProblemReport/GetStatus?refstan=' +
					refStan +
					'&type=' +
					method +
					'&account=' +
					account +
					'&description=' +
					comment
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	saveModalStatus(userLogin, account, action, refstan, comment, companyId) {
		//flag = true - new, false = edit.
		let body
		body = {
			userLogin: userLogin,
			Account: account,
			Status: action,
			RefStan: refstan,
			Note: comment,
			CompanyId: companyId,
		}

		return this.http
			.post<any>(
				this.basUrl + `/api/ProblemReport/ChangingStatus/`,
				body,
				{
					headers: { 'Accept-Language': this.GetLang() },
				}
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	getTransactionsDatasCurrentPage(
		date_from,
		date_to,
		period,
		filter,
		serviceName,
		dealerId,
		dealerArray,
		currentPage,
		item_per_page
	) {
		dealerId = dealerId ? dealerId : 1

		return this.http
			.get<any>(
				this.basUrl +
					`/api/TransactionReport/TransactionListData?DateFrom=` +
					date_from +
					`&DateTo=` +
					date_to +
					'&PeriodType=' +
					period +
					'&Account=' +
					encodeURIComponent(filter.Account) +
					'&UserLogin=' +
					filter.UserName +
					'&UserDealerId=' +
					dealerId +
					'&UserDealerIdArray=' +
					dealerArray +
					'&Srv=' +
					serviceName +
					'&Status=&RefStan=' +
					filter.RefStan +
					'&SalePointId=' +
					filter.SalePointId +
					'&OpType=' +
					filter.PaidTypeDrop +
					'&IsFiscReport=' +
					filter.IsFisc +
					'&PaymentSourceType=' +
					filter.PaymentSourceType +
					'&CurPage=' +
					currentPage +
					'&ItemPerPage=' +
					item_per_page
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getTransactionsPivot(
		date_from,
		date_to,
		period,
		filter,
		serviceName,
		dealerId,
		dealerArray
	) {
		dealerId = dealerId ? dealerId : 1
		return this.http
			.get<any>(
				this.basUrl +
					`/api/TransactionReport/TransactionPivot?DateFrom=` +
					date_from +
					`&DateTo=` +
					date_to +
					'&PeriodType=' +
					period +
					'&Account=' +
					filter.Account +
					'&UserLogin=' +
					filter.UserName +
					'&UserDealerId=' +
					dealerId +
					'&UserDealerIdArray=' +
					dealerArray +
					'&Srv=' +
					serviceName +
					'&Status=&RefStan=' +
					filter.RefStan +
					'&SalePointId=' +
					filter.ChannelDrop +
					'&OpType=' +
					filter.PaidTypeDrop
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	_getFiscDetails(refStan: number) {
		return this.http
			.get<any>(this.basUrl + `/api/Fisc/Get?pRefstanID=` + refStan)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getTransactionsPivotEx(
		date_from,
		date_to,
		period,
		filter,
		serviceName,
		dealerId,
		dealerArray
	) {
		dealerId = dealerId ? dealerId : 1
		return this.http
			.get<any>(
				this.basUrl +
					`/api/TransactionReport/TransactionPivotEx?DateFrom=` +
					date_from +
					`&DateTo=` +
					date_to +
					'&PeriodType=' +
					period +
					'&Account=' +
					filter.Account +
					'&UserLogin=' +
					filter.UserName +
					'&UserDealerId=' +
					dealerId +
					'&UserDealerIdArray=' +
					dealerArray +
					'&Srv=' +
					serviceName +
					'&Status=&RefStan=' +
					filter.RefStan +
					'&SalePointId=' +
					filter.ChannelDrop +
					'&OpType=' +
					filter.PaidTypeDrop
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	// For get advance datas in modal.
	getAdvanceTransaction(refstan) {
		return this.http
			.get<any>(
				this.basUrl + `/api/TransactionReport/Transaction/` + refstan
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getFile(
		date_from,
		date_to,
		period,
		filter,
		service,
		dealerId,
		dealerArray,
		currentPage,
		item_per_page,
		format
	) {
		dealerId = dealerId ? dealerId : 1
		if (format === 'pdf') {
			return this.http.get(
				this.basUrl +
					`/api/TransactionReport/TransactionListToPDF?DateFrom=` +
					date_from +
					`&DateTo=` +
					date_to +
					'&PeriodType=' +
					period +
					'&Account=' +
					filter.Account +
					'&UserLogin=' +
					filter.UserName +
					'&UserDealerId=' +
					dealerId +
					'&UserDealerIdArray=' +
					dealerArray +
					'&Srv=' +
					service +
					'&Status=&RefStan=' +
					filter.RefStan +
					'&SalePointId=' +
					filter.ChannelDrop +
					'&OpType=' +
					filter.PaidTypeDrop +
					'&CurPage=' +
					currentPage +
					'&ItemPerPage=' +
					item_per_page,
				{
					responseType: 'blob',
					headers: { 'Accept-Language': this.GetLang() },
				}
			)
		}
		return this.http.get(
			this.basUrl +
				`/api/TransactionReport/TransactionListToExcel?DateStart=` +
				date_from +
				`&DateEnd=` +
				date_to +
				'&PeriodType=' +
				period +
				'&Account=' +
				filter.Account +
				'&UserLogin=' +
				filter.UserName +
				'&UserDealerId=' +
				dealerId +
				'&UserDealerIdArray=' +
				dealerArray +
				'&Srv=' +
				service +
				'&Status=&RefStan=' +
				filter.RefStan +
				'&SalePointId=' +
				filter.ChannelDrop +
				'&OpType=' +
				filter.PaidTypeDrop +
				'&CurPage=' +
				currentPage +
				'&ItemPerPage=' +
				item_per_page,
			{
				responseType: 'blob',
				headers: { 'Accept-Language': this.GetLang() },
			}
		)
		// .pipe(map(res => {
		//     console.log("getExcell !!!");
		//     console.log(res);
		//     return {
		//         filename: 'filename.pdf',
		//         data: res.blob()
		//     };
		// }));
	}
	getFilePivot(
		date_from,
		date_to,
		period,
		filter,
		service,
		dealerId,
		dealerArray,
		currentPage,
		item_per_page,
		format
	) {
		dealerId = dealerId ? dealerId : 1

		if (format === 'pdf') {
			return this.http.get(
				this.basUrl +
					`/api/TransactionReport/TransactionListToPDF?DateFrom=` +
					date_from +
					`&DateTo=` +
					date_to +
					'&PeriodType=' +
					period +
					'&Account=' +
					filter.Account +
					'&UserLogin=' +
					filter.UserName +
					'&UserDealerId=' +
					dealerId +
					'&UserDealerIdArray=' +
					dealerArray +
					'&Srv=' +
					service +
					'&Status=&RefStan=' +
					filter.RefStan +
					'&SalePointId=' +
					filter.ChannelDrop +
					'&OpType=' +
					filter.PaidTypeDrop +
					'&CurPage=' +
					currentPage +
					'&ItemPerPage=' +
					item_per_page,
				{
					responseType: 'blob',
					headers: { 'Accept-Language': this.GetLang() },
				}
			)
		}
		return this.http.get(
			this.basUrl +
				`/api/TransactionReport/TransactionPivotToExcel?DateFrom=` +
				date_from +
				`&DateTo=` +
				date_to +
				'&PeriodType=' +
				period +
				'&Account=' +
				filter.Account +
				'&UserLogin=' +
				filter.UserName +
				'&UserDealerId=' +
				dealerId +
				'&UserDealerIdArray=' +
				dealerArray +
				'&Srv=' +
				service +
				'&Status=&RefStan=' +
				filter.RefStan +
				'&SalePointId=' +
				filter.ChannelDrop +
				'&OpType=' +
				filter.PaidTypeDrop,
			{
				responseType: 'blob',
				headers: { 'Accept-Language': this.GetLang() },
			}
		)
		// .pipe(map(res => {
		//     console.log("getExcell !!!");
		//     console.log(res);
		//     return {
		//         filename: 'filename.pdf',
		//         data: res.blob()
		//     };
		// }));
	}
	getDepositFile(
		dealerId,
		dateFrom,
		dateTo,
		period,
		format,
		curPage,
		itemPerPage
	) {
		if (format === 'pdf') {
			return this.http.get(
				this.basUrl +
					`/api/DepositReport/DepositReportToPDF?pDealerId=` +
					dealerId +
					'&pDateStart=' +
					dateFrom +
					'&pDateFinish=' +
					dateTo +
					`&PeriodType=` +
					period +
					'&CurPage=' +
					curPage +
					'&ItemPerPage=' +
					itemPerPage,
				{
					responseType: 'blob',
					headers: { 'Accept-Language': this.GetLang() },
				}
			)
		}
		return this.http.get(
			this.basUrl +
				`/api/DepositReport/DepositReportToExcel?pDealerId=` +
				dealerId +
				'&pDateStart=' +
				dateFrom +
				'&pDateFinish=' +
				dateTo +
				`&PeriodType=` +
				period +
				'&CurPage=' +
				curPage +
				'&ItemPerPage=' +
				itemPerPage,
			{
				responseType: 'blob',
				headers: { 'Accept-Language': this.GetLang() },
			}
		)
	}

	getSystemRequestFile(
		date_from,
		date_to,
		period,
		filter,
		resCode,
		dealerId,
		format
	) {
		if (format === 'pdf') {
			return this.http.get(
				this.basUrl +
					`/api/SystemReport/SystemRequestsToPDF?DateFrom=` +
					date_from +
					`&DateTo=` +
					date_to +
					`&PeriodType=` +
					period +
					'&Account=' +
					filter.Account +
					'&UserLogin=' +
					filter.UserLogin +
					'&DealerId=' +
					dealerId +
					'&CompanyId=' +
					filter.ServiceDrop +
					`&ResponseCode=${resCode}&ExternalId=${filter.ExternalId}&MethodType=${filter.Method}`,
				{
					responseType: 'blob',
					headers: { 'Accept-Language': this.GetLang() },
				}
			)
		}
		return this.http.get(
			this.basUrl +
				`/api/SystemReport/SystemRequestsToExcel?DateFrom=` +
				date_from +
				`&DateTo=` +
				date_to +
				`&PeriodType=` +
				period +
				'&Account=' +
				filter.Account +
				'&UserLogin=' +
				filter.UserLogin +
				'&DealerId=' +
				dealerId +
				'&CompanyId=' +
				filter.ServiceDrop +
				`&ResponseCode=${resCode}&ExternalId=${filter.ExternalId}&MethodType=${filter.Method}`,
			{
				responseType: 'blob',
				headers: { 'Accept-Language': this.GetLang() },
			}
		)
	}
	getSystemTransaction(id) {
		return this.http
			.get(
				this.basUrl +
					`/api/SystemReport/ShowTransactionInfo?stanid=${id}`
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	getSystemInteraction(id) {
		return this.http
			.get(
				this.basUrl +
					`/api/SystemReport/ProviderInteraction?Refstan=${id}`
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	getSystemInteractionLog(key) {
		return this.http
			.get(this.basUrl + `/api/SystemReport/ProviderLog?Key=${key}`)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	//Fisc report
	// DateTime pStartDate,
	// 		DateTime pEndDate,
	// 		long? pRefstan = null,
	// 		string pFiscNumber = null,
	// 		short? pStatus = null,
	// 		DashboardViewModel.ChartPeriodType PeriodType = DashboardViewModel.ChartPeriodType.CustomPeriod
	getFiscReport(
		dateFrom,
		dateTo,
		period,
		filter,
		currentPage,
		item_per_page
	) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/Fisc/Gets?pStartDate=` +
					dateFrom +
					`&pEndDate=` +
					dateTo +
					`&PeriodType=` +
					period +
					'&pRefstan=' +
					filter.RefStan +
					'&pFiscNumber=' +
					filter.FiscNumber +
					'&pLoginUser=' +
					filter.UserLogin +
					'&pStatus=' +
					filter.Status +
					'&pFiscalMode=' +
					filter.FiscalMode +
					'&pFiscOperationTypes=' +
					filter.OpType +
					'&pFetchPage=' +
					currentPage +
					'&pFetchCount=' +
					item_per_page
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getFiscFile(dateFrom, dateTo, period, filter, currentPage, item_per_page) {
		return this.http.get(
			this.basUrl +
				`/api/Fisc/DownloadFile?pStartDate=` +
				dateFrom +
				`&pEndDate=` +
				dateTo +
				`&PeriodType=` +
				period +
				'&pRefstan=' +
				filter.RefStan +
				'&pFiscNumber=' +
				filter.FiscNumber +
				'&pStatus=' +
				filter.Status +
				'&pFetchPage=' +
				currentPage +
				'&pFetchCount=' +
				item_per_page,
			{
				responseType: 'blob',
				headers: { 'Accept-Language': this.GetLang() },
			}
		)
	}
	getProblemrequestDatas(
		dateFrom,
		dateTo,
		period,
		filter,
		service,
		dealerId,
		currentPage,
		item_per_page
	) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/ProblemReport/ProblemPaymentsReport?DateFrom=` +
					dateFrom +
					`&DateTo=` +
					dateTo +
					`&PeriodType=` +
					period +
					'&Account=' +
					encodeURIComponent(filter.Account) +
					'&Dealeruserid=' +
					dealerId +
					'&Srv=' +
					service +
					'&Status=' +
					filter.Status +
					'&RefStan=' +
					filter.RefStan +
					'&UserLogin=' +
					filter.UserLogin +
					'&CurPage=' +
					currentPage +
					'&ItemPerPage=' +
					item_per_page+
					'&PaymentSourceType=' +
					filter.PaymentSourceType

			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getAdvanceProblem(refstan) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/ProblemReport/ProblemPaymentInfo?RefStan=` +
					refstan
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getFiscDetails(id: number) {
		return this.http
			.get<any>(this.basUrl + `/api/Fisc/Get?pFiscID=` + id)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getAdvanceDeposit(id, dealerId, start, finish) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/DepositReport/DepositDetailsReport?pIdent=` +
					id +
					`&pDealerId=` +
					dealerId +
					`&pDateStart=` +
					start +
					`&pDateFinish=` +
					finish
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	// Deposit operations

	getAllDeposit(dealerId, dateFrom, dateTo, period, curPage, itemPerPage) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/DepositReport/DepositReport?pDealerId=` +
					dealerId +
					'&pDateStart=' +
					dateFrom +
					'&pDateFinish=' +
					dateTo +
					'&PeriodType=' +
					period +
					'&CurPage=' +
					curPage +
					'&ItemPerPage=' +
					itemPerPage
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getSystemDatas(
		dateFrom,
		dateTo,
		period,
		filter,
		resCode,
		dealerId,
		currentPage,
		item_per_page
	) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/SystemReport/SystemRequestsReport?DateFrom=` +
					dateFrom +
					`&DateTo=` +
					dateTo +
					`&PeriodType=` +
					period +
					`&Account=${encodeURIComponent(filter.Account)}` +
					'&ExternalId=' +
					filter.ExternalId +
					'&UserLogin=' +
					filter.UserLogin +
					'&DealerId=' +
					dealerId +
					'&CompanyId=' +
					filter.ServiceDrop +
					`&ResponseCode=${resCode}&MethodType=${filter.Method}&RefStan=${filter.RefStan}&CurPage=` +
					currentPage +
					'&ItemPerPage=' +
					item_per_page
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getSystemData(
		dateFrom,
		dateTo,
		period,
		filter,
		currentPage,
		item_per_page
	) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/SystemReport/SystemErrorsReport?DateFrom=` +
					dateFrom +
					`&DateTo=` +
					dateTo +
					`&PeriodType=` +
					period +
					'&UserLogin=' +
					filter.UserLogin +
					'&AppMethod=' +
					filter.AppMethod +
					// '&Status=' +
					// filter.Status +
					'&CurPage=' +
					currentPage +
					'&ItemPerPage=' +
					item_per_page +
					'&StateCode=' +
					filter.StateCode
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getErrorInfo(key) {
		return this.http
			.get<any>(
				this.basUrl + `/api/SystemReport/SystemErrorInfo?key=` + key,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getAllResCodes() {
		return this.http
			.get<any>(this.basUrl + `/api/entries/ResponseCodes`)

			.pipe(
				map((response) => {
					return response
				})
			)
	}

	// // For get advance datas in modal.
	getAdvanceSystem(pKey) {
		return this.http
			.get<any>(
				this.basUrl + `/api/SystemReport/SystemRequestInfo?pKey=` + pKey
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	// Roles-report

	getRoleInfo(id) {
		return this.http
			.get<any>(
				this.basUrl + `/api/Administration/GetRoleInfo?RoleKey=` + id,
				{
					headers: { 'Accept-Language': this.GetLang() },
				}
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getRights() {
		return this.http
			.get<any>(this.basUrl + `/api/Administration/GetRights`, {
				headers: { 'Accept-Language': this.GetLang() },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	saveRoles(previous_datas, new_datas, roleList, flag) {
		//flag = true - new, false = edit.
		let body
		if (flag === false) {
			body = {
				Name: new_datas.Name,
				Order: previous_datas.Order,
				CodeNum: previous_datas.CodeNum,
				Description: new_datas.Description,
				Key: previous_datas.Key,
				Code: new_datas.Code,
				Rights: roleList,
			}
		} else {
			body = {
				Name: new_datas.Name,
				Order: new_datas.Order,
				CodeNum: new_datas.CodeNum,
				Description: new_datas.Description,
				Key: null,
				Code: new_datas.Code,
				Rights: roleList,
			}
		}
		return this.http
			.post<any>(this.basUrl + `/api/Administration/SaveRole/`, body, {
				headers: { 'Accept-Language': this.GetLang() },
			})
			.pipe(
				map((response) => {
					// login successful if there's a jwt token in the response
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getRoles(code, orderBy, itemsPerPage, currentPage) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/Administration/GetRoles?Code=` +
					code +
					`&OrderBy=` +
					orderBy +
					`&PageNumber=` +
					currentPage +
					`&ItemCount=` +
					itemsPerPage,
				{ headers: { 'Accept-Language': this.GetLang() } }
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	private GetLang() {
		if (localStorage.getItem('currentLang') === 'tr') {
			return 'tr-TR'
		}
		return 'en-US'
	}
}

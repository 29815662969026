import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { ProviderService } from "../../_services";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';

import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

export interface ItemList {
  Value: string;
  Name: string;
}

@Component({
  templateUrl: "daily-report.component.html",
  styleUrls: ["provider.scss"],
  providers: [DatePipe]
})
export class DailyReportComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private providerService: ProviderService,
    private dateAdapter: DateAdapter<Date>,
    public toastr: ToastrService
  ) {}
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api
  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner

  //-------------------------------------
  providersList;
  loading = false; // for disable fast response click
  pageEvent;
  panelOpenState = true;
  services: ItemList[];
  operations: [string, string] = ["Increase", "Decrease"];

  account = ""; // Account input
  refStan = ""; // refStan input
  serviceSelected: any; // Service selected
  userLogin = ""; // User Login input

  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  events: string[] = []; // Date picker
  currentTime = new Date();
  //----------------- list of columns
  displayedColumns: string[] = [
    "Date",
    "BalanceStart",
    "BalanceEnd",
    "Difference",
    "OperCount",
    "OperAmount",
    "DepCount",
    "DepAmount",
    "Currency",
    "CrossCheck"
  ];

  dataSource = new MatTableDataSource(); // array of datas

  stopClick(event) {
    event.preventDefault();
  }
  //----------------------- get a dealer list and put to option's

  openModal(templateRef) {
    this.isLoadingDetailInfo = false;
    this.checkName();
    this.dialog.open(templateRef, {
      panelClass: "modal_transaction",
      minHeight: "380px"
    });
  }
  checkCondition(one, two) {
    if (one !== two) {
      return true;
    } else {
      return false;
    }
  }
  currentProvider: any;
  checkName() {
    this.ModalForm.controls.AmountModal.setValue("");
    this.ModalForm.controls.CommentModal.setValue("");
    this.ModalForm.controls.dateModal.setValue(this.currentTime);
    this.ModalForm.controls.OperationModal.patchValue(this.operations[0]);

    this.services.forEach(el => {
      if (el.Value === this.serviceSelected) this.currentProvider = el;
    });
  }
  formatAmount(number) {
    return number.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
  }

  checkInput(event) {
    let value = event.target.value;
      if (value.search(/[^0-9.]/g) != "-1") {
        this.ModalForm.controls.AmountModal.setValue(
          value.replace(/[^0-9.]/g, "")
        );
      }
    if (value.substr(-3).search(/\./) != "-1" && value.length > 10) {
      this.ModalForm.controls.AmountModal.setValue(value.slice(0, 10));
    } else if (value.substr(-3).search(/\./) == "-1" && value.length > 7) {
      this.ModalForm.controls.AmountModal.setValue(value.slice(0, 7));
    }
  }
  saveRow() {
    let form = this.ModalForm.controls,
      date = form.dateModal.value,
      providerId = this.currentProvider.Value,
      amount = form.AmountModal.value
        ? form.AmountModal.value.replace(/[^0-9]/, "")
        : "0",
      note = form.CommentModal.value,
      type = form.OperationModal.value === this.operations[0] ? "1" : "2";
    console.log("Sendind data:", date, providerId, amount, type, note);
    if (this.ModalForm.valid) {
      this.isLoadingDetailInfo = true;
      date.setHours(12);
      this.providerService
        .saveNewProvider(date, providerId, amount, type, note)
        .subscribe(
          data => {
            if (data.ResultCode === 0) {
              let theme = "";
              let body = "";
              if (type == "1") {
                this.translate
                  .get("PROVIDER.THEME_SAVEROW_SUCCESS_INCR")
                  .subscribe(text => {
                    theme = text;
                  });
                this.translate
                  .get("PROVIDER.BODY_SAVEROW_SUCCESS_INCR")
                  .subscribe(text => {
                    body = text;
                  });
              } else {
                this.translate
                  .get("PROVIDER.THEME_SAVEROW_SUCCESS_DECR")
                  .subscribe(text => {
                    theme = text;
                  });
                this.translate
                  .get("PROVIDER.BODY_SAVEROW_SUCCESS_DECR")
                  .subscribe(text => {
                    body = text;
                  });
              }

              this.toastr.success(body, theme);
              this.getFirstDatas();
              this.CloseDialog();
            } else {
              let theme = "";
              this.translate.get("PROVIDER.SAVEROW_ERROR").subscribe(text => {
                theme = text;
              }); // Text in component

              this.toastr.error(data.ResultMessage, theme);
            }
            this.isLoadingDetailInfo = false;
          },
          error => {
            let theme = "";
            this.translate.get("PROVIDER.SAVEROW_ERROR").subscribe(text => {
              theme = text;
            }); // Text in component
            this.toastr.error(error, theme);
            this.isLoadingDetailInfo = false;
            console.log(error);
          }
        );
    }
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  FilterForm: UntypedFormGroup; // Form initial
  ModalForm: UntypedFormGroup;
  radioModel = 0;
  //----------------------
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }
  // function for change tables page
  InitProviderFilter() {
    this.providerService.GetAllProviders().subscribe(
      data => {
        console.log("Get all providers:", data.ReturnObject);
        this.services = data.ReturnObject.map(x => ({
          Value: x.ProviderId.toString(),
          Name: x.Name
        }));
        this.FilterForm.controls.ServiceDrop.setValue(this.services[0].Value);
        this.serviceSelected = this.services[0].Value;
        // this.serviceSelected = this.services[0];
        console.log("FForm", this.FilterForm.controls);
      },
      error => {
        console.log(error);
      },
      () => {
        this.getFirstDatas(this.radioModel);
      }
    );
  }
  //----------------------- get a dealer list and put to option's

  statusLists = [
    {},
    {},
    {
      text: "Problem",
      value: 2,
      color: "badge-danger"
    },
    {},
    {},
    {
      text: "Inqueue",
      value: 5,
      color: "badge-warning"
    },
    {
      text: "Close",
      value: 6,
      color: "badge-info"
    }
  ];

  getFirstDatas(period?) {
    if (typeof period === "number") this.radioModel = period;
    this.loading = true;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    // Take datas from form
    let providerId = this.serviceSelected;
    console.log("Service selected", this.serviceSelected);

    this.providerService
      .getDailyList(start, finish, this.radioModel, providerId)
      .subscribe(
        data => {
          console.log("Provider data list:", data);
          if (data.ResultCode === 0) {
            let datas = [];

            if (data.ReturnObject !== null) {
              datas = data.ReturnObject;
            }
            this.dataSource = new MatTableDataSource(datas); // array of datas
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.getFirstDatas(this.radioModel);
  }

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }
  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }

  modalDatas = {
    Status: 0
  }; // for display datas in modal

  statusStates = [
    { value: 2, viewValue: "Problem" },
    { value: 5, viewValue: "Inqueue" },
    { value: 6, viewValue: "Close" }
  ];

  CloseDialog(): void {
    this.dialog.closeAll();
  }

  convertOnBlur(searchValue, formName, formValue) {
    if (searchValue > 0) {
      this[formName].patchValue({
        [formValue]: parseFloat(searchValue).toFixed(2)
      });
    }
  }

  ngOnInit() {
    this.paginationItemPerPage = 10;
    this.translate.get("PROVIDER.PROBLEM_ST_PROBLEM").subscribe(text => {
      this.statusLists[2].text = text;
    });
    this.translate.get("PROVIDER.PROBLEM_ST_INQUEUE").subscribe(text => {
      this.statusLists[5].text = text;
    });
    this.translate.get("PROVIDER.PROBLEM_ST_CLOSE").subscribe(text => {
      this.statusLists[6].text = text;
    });
    this.ModalForm = new UntypedFormGroup({
      CompanyModal: new UntypedFormControl({ disabled: true }),
      OperationModal: new UntypedFormControl(this.operations[0]),
      dateModal: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      AmountModal: new UntypedFormControl("", Validators.required),
      CommentModal: new UntypedFormControl("")
    });
    this.translate.get("PROVIDER.MODAL_INCREASE").subscribe(text => {
      this.operations[0] = text;
      this.ModalForm.controls.OperationModal.patchValue(this.operations[0]);
    });
    this.translate
      .get("PROVIDER.MODAL_DECREASE")
      .subscribe(text => (this.operations[1] = text));
    this.FilterForm = new UntypedFormGroup({
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      Account: new UntypedFormControl(""),
      RefStan: new UntypedFormControl(""),
      UserName: new UntypedFormControl(""),
      ServiceDrop: new UntypedFormControl("")
    });

    this.InitProviderFilter();
  }
}

import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';

import { AdministrationService } from "../../_services/administration.service";
import { DatePipe, DecimalPipe } from "@angular/common";
import { saveAs } from "file-saver";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { environment } from './../../../environments/environment';

export interface ItemList {
  Value: string;
  Name: string;
}
interface LicenseList {
  Name: string;
  Id: string;
}

@Component({
  templateUrl: "license-edit.component.html",
  styleUrls: ["administration.scss"],
  providers: [DatePipe, DecimalPipe],
})
export class LicenseEditComponent implements OnInit {
  permissions;
	baseUrl: string = environment.apiUrl;

  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api
  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner
  loading = true; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  events: string[] = []; // Date picker

  //----------------- list of columns
  displayedColumns: string[] = ["Index", "LicenseName", "Actions"];

  dataSource = new MatTableDataSource(); // array of datas

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private router: Router,

    public route: ActivatedRoute,
    private administrationService: AdministrationService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    public toastr: ToastrService
  ) {}

  // End Errors
  CardForm: UntypedFormGroup; // Form initial

  services: LicenseList[] = [];
  currentLicense: string = "";
  currentLicenseD: string = "";

  currentLicenseEn: string = "";
  currentLicenseDEn: string = "";
  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  getFirstDatas() {
    console.log(this.services);
    this.services = [];
    if (this.newLicense) {
      this.licenseId = null;
    }
    this.loading = true;

    this.administrationService.getLicensesServices(this.licenseId).subscribe(
      (data) => {
        if (data.ResultCode === 0) {
          console.log("Data Lic::", data.ReturnObject);
          let rO = data.ReturnObject;
          if (rO !== null) {
            if (!this.newLicense) {
              this.saveInfo["KeyLecence"] = rO.Licence.KeyLicence;
            }
            this.saveInfo["Status"] = "1";
            this.saveInfo["LicenceList"] = [{}, {}];
            this.saveInfo["LicenceList"][0]["KeyLanguage"] =
              rO.LicenceDescription[0].KeyLanguage;
              if( rO.LicenceDescription.length >1){
            this.saveInfo["LicenceList"][1]["KeyLanguage"] =   rO.LicenceDescription[1].KeyLanguage;
            
            this.saveInfo["LicenceList"][1]["Name"] =rO.LicenceDescription[1].Name;
            this.saveInfo["LicenceList"][1]["Description"] =rO.LicenceDescription[1].Description;
            this.currentLicense = rO.LicenceDescription[1].Name;
         
            this.currentLicenseD = rO.LicenceDescription[1].Description;

          }
            this.saveInfo["LicenceList"][0]["Name"] =rO.LicenceDescription[0].Name;
            this.saveInfo["LicenceList"][0]["Description"] =rO.LicenceDescription[0].Description;
         
            this.currentLicenseEn = rO.LicenceDescription[0].Name;
            this.currentLicenseDEn = rO.LicenceDescription[0].Description;
            this.CardForm.patchValue({
              License: this.currentLicense,
              LicenseEn: this.currentLicenseEn,
              LicenseD: this.currentLicenseD,
              LicenseDEn: this.currentLicenseDEn,
            });

            this.services = rO.Company;
            console.log("Services::", this.services);
            console.log("Svai::", this.saveInfo);

            // this.dataSource = new MatTableDataSource(this.services); // array of datas
          } else {
            // this.dataSource = new MatTableDataSource([]); // array of datas
          }
        }
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.log(error);
        setTimeout(() => {
          alert("Sorry, no such license");
          this.router.navigate(["/license"]);
        }, 500);
      }
    );
  }

  onSubmit() {
    this.saveDatas();
  }
  objToString(obj) {
    return JSON.parse(obj);
  }
  stringToObj(str) {
    return JSON.stringify(str);
  }
  saveInfo = {};
  saveDatas() {
    console.log("saving");
    let saveObj = {};
    if (this.newLicense) {
      this.saveInfo["LicenceList"] = [
        {
          KeyLanguage: "A270D233-1C45-4550-B48F-68C7424303C2",
        },
        {
          KeyLanguage: "AE10158F-17CD-4974-AD98-C15774B2B3E8",
        },
      ];
    }
    let allSelected = [],
      all = document.querySelectorAll('input[type="checkbox"]') as NodeListOf<
        HTMLInputElement
      >;
    all.forEach((el) => {
      if (el.checked) {
        allSelected.push(this.objToString(el.value));
      }
    });
    console.log("Name::", this.currentLicense);
    if (this.saveInfo["LicenceList"].length == 0) {
      this.saveInfo["LicenceList"].push({}, {});
    }
    this.saveInfo["LicenceList"][0]["Name"] = this.currentLicense;
    this.saveInfo["LicenceList"][1]["Name"] = this.currentLicenseEn;
    this.saveInfo["LicenceList"][0]["Description"] = this.currentLicenseD;
    this.saveInfo["LicenceList"][1]["Description"] = this.currentLicenseDEn;
    this.saveInfo["LicenceList"][0]["Name"] = this.currentLicense;

    this.saveInfo["LicenceList"][0]["KeyLicence"] = this.licenseId;
    this.saveInfo["LicenceList"][1]["KeyLicence"] = this.licenseId;
    this.saveInfo["CompaniesList"] = allSelected;

    this.loading = true;
    if (this.newLicense) {
      this.administrationService
        .saveServicesNewLicense(this.saveInfo)
        .subscribe(
          (data) => {
            if (data.ResultCode === 0) {
              let theme = "";
              let body = "";
              this.translate
                .get("LICENSE.THEME_CREATE_SUCCESS")
                .subscribe((text) => {
                  theme = text;
                });
              this.translate
                .get("LICENSE.BODY_CREATE_SUCCESS")
                .subscribe((text) => {
                  body = text;
                });
                this.loading = true;

              setTimeout(() => {
                this.router.navigate(["/license"]);
              }, 2000);
              this.toastr.success(body, theme);
            } else {
              let theme = "";
              this.translate.get("LICENSE.ERROR").subscribe((text) => {
                theme = text;
              }); // Text in component

              this.toastr.error(data.ResultMessage, theme);
              this.loading = false;
            }
          },
          (error) => {
            let theme = "";
            this.translate.get("LICENSE.SERVER_ERROR").subscribe((text) => {
              theme = text;
            }); // Text in component
            this.toastr.error(error, theme);
            this.loading = false;
            console.log(error);
          }
        );
    } else {
      this.administrationService.saveServicesLicense(this.saveInfo).subscribe(
        (data) => {
          if (data.ResultCode === 0) {
            let theme = "";
            let body = "";
            this.translate
              .get("LICENSE.THEME_UPDATE_SUCCESS")
              .subscribe((text) => {
                theme = text;
              });
            this.translate
              .get("LICENSE.BODY_UPDATE_SUCCESS")
              .subscribe((text) => {
                body = text;
              });
            setTimeout(() => {
              this.router.navigate(["/license"]);
            }, 2000);
            this.toastr.success(body, theme);
            this.loading = false;
          } else {
            let theme = "";
            this.translate.get("LICENSE.ERROR").subscribe((text) => {
              theme = text;
            }); // Text in component

            this.toastr.error(data.ResultMessage, theme);
            this.loading = false;
          }
        },
        (error) => {
          let theme = "";
          this.translate.get("LICENSE.SERVER_ERROR").subscribe((text) => {
            theme = text;
          }); // Text in component
          this.toastr.error(error, theme);
          this.loading = false;
          console.log(error);
        }
      );
    }

    console.log("Saving data::", this.saveInfo);
  }

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }

  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.CardForm.controls;
  }

  showValue(event) {
    console.log(event.target.value);
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas();
  }

  openModal(templateRef) {
    this.isLoadingDetailInfo = false;

    this.dialog.open(templateRef, {
      panelClass: "modal_transaction",
      minHeight: templateRef !== "treeModal" ? "580px" : "",
    });
  }

  CloseDialog(): void {
    this.dialog.closeAll();
  }
  licenseId: string = ''  ;
  newLicense: boolean = false;
  ngOnInit() {
    let ls = new SecureLS(),
      paramId = this.route.snapshot.paramMap.get("id");
    this.permissions = ls.get("permissions")[
      this.route.data["value"].pathCheck
    ];
    if (paramId == "new") {
      console.log("new");
      this.newLicense = true;
    } else {
      this.licenseId = paramId;
    }
    this.paginationItemPerPage = 10;

    this.CardForm = new UntypedFormGroup({
      License: new UntypedFormControl("", Validators.required),
      LicenseEn: new UntypedFormControl("", Validators.required),
      LicenseD: new UntypedFormControl("", Validators.required),
      LicenseDEn: new UntypedFormControl("", Validators.required),

      UserName: new UntypedFormControl(""),
    }); // for header Filter
    this.getFirstDatas();

    console.log("Route params::", paramId);
  }
}

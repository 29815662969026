import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { ProviderService, PayService } from "../../_services";
import { ToastrService } from "ngx-toastr";
import { saveAs } from "file-saver";
import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";

import { Router, ActivatedRoute } from "@angular/router";
import * as SecureLS from "secure-ls";

export interface ItemList {
  Value: string;
  Name: string;
}

@Component({
  templateUrl: "pay-operations.component.html",
  styleUrls: ["pay.scss"],
  providers: [DatePipe]
})
export class PayOperationsComponent implements OnInit {
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api

  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner

  //-------------------------------------
  Transactions_Datas;
  loading = false; // for disable fast response click
  pageEvent; // for fix error of pageEvent in Visual Studio
  // For change pages

  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  events: string[] = []; // Date picker
  payIdControl = new UntypedFormControl(); // Dealer
  currentpayId = 1;
  radioModel = 1;
  services: ItemList[];

  currentTime = new Date();
  //----------------- list of columns
  displayedColumns: string[] = [
    "OperId",
    "CheckId",
    "RegDate",
    "PayDate",
    "PayType",
    "ServiceName",
    "ServiceAccount",
    "AmountBody",
    "Amount",
    "Fee",
    "OperStatus"
  ];

  dataSource = new MatTableDataSource(); // array of datas
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    private translate: TranslateService,
    public route: ActivatedRoute,

    private datePipe: DatePipe,
    private payService: PayService,
    private providerService: ProviderService,
    public toastr: ToastrService
  ) {}
  isDownloadingFile: boolean = false;
  permissions;
  statusPermission;
  StatusForm: UntypedFormGroup;
  FilterForm: UntypedFormGroup; // Form initial
  serviceSelected = ""; // Service selected
  statusSelected = "";

  //----------------------
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }
  // Init = 0,
  // Check = 1,
  // EcomConfirm = 2,
  // Completed = 3,
  // Close = 4,
  // MpsProble = 5,
  // MpsClose = 6

  states = [
    {
      Name: "All",
      Value: null
    },
    {
      Name: "Init",
      Value: 0
    },
    {
      Name: "Check",
      Value: 1
    },
    {
      Name: "EcomConfirm",
      Value: 2
    },
    {
      Name: "Completed",
      Value: 3
    },
    {
      Name: "Close",
      Value: 4
    },
    {
      Name: "MpsProble",
      Value: 5
    },
    {
      Name: "MpsClose",
      Value: 6
    }
  ];
  modalStatusSelected: any;
  modalStates = [
    {
      Name: "Confirm",
      Value: 3
    },
    {
      Name: "Repaid",
      Value: 8
    },
    {
      Name: "Close",
      Value: 4
    }
  ];
  showRefStan: boolean = false;
  checkRequired(event) {
    console.log("Status Form", this.StatusForm);
    if (this.modalStatusSelected == this.modalStates[1].Value) {
      this.showRefStan = true;
    } else {
      this.showRefStan = false;
    }
  }
  modalStError: any = "";
  currentRefStan: any = "";
  stFormSubmit() {
    console.log(
      "This Modal Status Selected::",
      this.modalStatusSelected,
      this.modalStates[1].Value
    );
    let form = this.StatusForm;
    if (form.valid) {
      if (
        this.showRefStan &&
        this.StatusForm.controls.RefStan.value.length > 0
      ) {
        this.loading = true;
        let status = this.StatusForm.value.Status,
          refStan = this.StatusForm.value.RefStan,
          comment = this.StatusForm.value.Comment,
          operId = this.modalDatas["Key"];
        this.payService.saveStatus(operId, status, refStan, comment).subscribe(
          data => {
            if (data.ResultCode === 0) {
              let theme = "";
              let body = "";
              this.translate
                .get("PAY.MODAL_THEME_SUCCESS_" + status)
                .subscribe(text => {
                  theme = text;
                });
              this.translate
                .get("PAY.MODAL_BODY_SUCCESS_" + status)
                .subscribe(text => {
                  body = text;
                });
              this.toastr.success(body, theme);
              this.loading = false;
              this.getFirstDatas();
              this.CloseDialog();
            } else {
              let theme = "";
              this.translate.get("PAY.MODAL_BODY_ERROR").subscribe(text => {
                theme = text;
              }); // Text in component
              data.ResultMessage === null
                ? (this.modalStError = "")
                : (this.modalStError = data.ResultMessage);
              this.toastr.error(this.modalStError, theme);
              this.loading = false;
            }
          },
          error => {
            let theme = "";
            this.translate.get("PAY.MODAL_SERVER_ERROR").subscribe(text => {
              theme = text;
            }); // Text in component
            this.toastr.error(error, theme);
            this.loading = false;
            console.log(error);
          }
        );
      } else {
        this.loading = true;
        let status = this.StatusForm.value.Status,
          refStan = null,
          comment = this.StatusForm.value.Comment,
          operId = this.modalDatas["Key"];
        this.payService.saveStatus(operId, status, refStan, comment).subscribe(
          data => {
            if (data.ResultCode === 0) {
              let theme = "";
              let body = "";
              this.translate
                .get("PAY.MODAL_THEME_SUCCESS_" + status)
                .subscribe(text => {
                  theme = text;
                });
              this.translate
                .get("PAY.MODAL_BODY_SUCCESS_" + status)
                .subscribe(text => {
                  body = text;
                });
              this.toastr.success(body, theme);
              this.loading = false;
              this.getFirstDatas();
              this.CloseDialog();
            } else {
              let theme = "";
              this.translate.get("PAY.MODAL_BODY_ERROR").subscribe(text => {
                theme = text;
              }); // Text in component
              data.ResultMessage === null
                ? (this.modalStError = "")
                : (this.modalStError = data.ResultMessage);
              this.toastr.error(this.modalStError, theme);
              this.loading = false;
            }
          },
          error => {
            let theme = "";
            this.translate.get("PAY.MODAL_SERVER_ERROR").subscribe(text => {
              theme = text;
            }); // Text in component
            this.toastr.error(error, theme);
            this.loading = false;
            console.log(error);
          }
        );
      }
    }
  }
  checkTab(event) {
    if (event.index === 2) {
    }
  }
  InitStateTranslation() {
    this.states.forEach(x =>
      this.translate
        .get(`PAY.OPR_ST_${x.Value === null ? "ALL" : x.Value}`)
        .subscribe(text => (x.Name = text))
    );
  }
  InitProviderFilter() {
    let p = localStorage.getItem("providerAll");
    if (p === null) {
      this.providerService
        .AllList()
        .pipe(first())
        .subscribe(
          data => {
            localStorage.setItem("providerAll", JSON.stringify(data));
            this.services = data.map(x => ({
              Value: x.ID.toString(),
              Name: x.Name
            }));
            this.translate.get("PAY.SERVICE_ALL").subscribe(text => {
              this.services.unshift({ Value: "", Name: text });
            });
          },
          error => {
            console.log(error);
          },
          () => {}
        );
    } else {
      let prvs = JSON.parse(p);
      this.services = prvs.map(x => ({ Value: x.ID.toString(), Name: x.Name }));
      this.translate.get("PAY.SERVICE_ALL").subscribe(text => {
        this.services.unshift({ Value: "", Name: text });
      });
    }
  }
  download(format) {
    this.isDownloadingFile = true;
    this.loading = true;
    let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      ),
      finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      ),
      payId = this.FilterForm.value.payIdControl,
      account = "",
      companyId = this.FilterForm.value.ServiceDrop,
      status = this.FilterForm.value.Status,
      userName = "";
    this.payService
      .getPayFile(
        format,
        this.radioModel,
        start,
        finish,
        account,
        status,
        payId,
        companyId,
        userName,
        this.currentPage + 1,
        this.paginationItemPerPage
      )
      .subscribe(
        res => {
          this.isDownloadingFile = false;
          this.loading = false;
          saveAs(res, finish + "." + format, {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          });
        },
        error => {
          console.log(error);
          this.loading = false;
          this.isDownloadingFile = false;
        }
      );
  }
  CloseDialog(): void {
    this.dialog.closeAll();
  }
  // function for change tables page
  selectRow(templateRef, row) {
    this.isLoadingDetailInfo = true;
    this.modalStatusSelected = 3;
    this.currentRefStan = "";
    this.StatusForm.setValue({
      Status: this.modalStates[0].Value,
      RefStan: "",
      Comment: ""
    });
    this.showRefStan = false;
    this.payService.getPay(row.OperationId).subscribe(
      data => {
        if (data.ResultCode === 0) {
          if (data.ReturnObject !== null) {
            this.modalDatas = data.ReturnObject;
            this.isLoadingDetailInfo = false;
          } else {
            this.isLoadingDetailInfo = false;
          }
        } else {
          this.CloseDialog();
        }
      },
      error => {
        this.isLoadingDetailInfo = false;
        console.log(error);
      }
    );
    const dialogRef = this.dialog.open(templateRef, {
      panelClass: "modal_transaction"
    });
  }
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }
  //----------------------- get a dealer list and put to option's

  totalAmountBody = 0;
  totalAmount = 0;
  totalFee = 0;
  getFirstDatas(period?) {
    if (typeof period === "number") this.radioModel = period;
    this.loading = true;
    let start = this.datePipe.transform(
        this.FilterForm.controls.dateFrom.value,
        "yyyy-MM-ddT00:00:00"
      ),
      finish = this.datePipe.transform(
        this.FilterForm.controls.dateTo.value,
        "yyyy-MM-ddT23:59:59"
      ),
      // Take datas from form
      payId = this.FilterForm.value.payIdControl,
      account = this.FilterForm.value.Account,
      companyId = this.FilterForm.value.ServiceDrop,
      userName = "",
      status = this.FilterForm.value.Status,
      checkId = this.FilterForm.value.CheckId;

    // dateFrom, dateTo, account, status, id, companyId, userName, curPage, itemPerPage
    this.payService
      .getOperationsList(
        this.radioModel,
        start,
        finish,
        checkId,
        account,
        status,
        payId,
        companyId,
        userName,
        this.currentPage + 1,
        this.paginationItemPerPage
      )
      .subscribe(
        data => {
          if (data.ResultCode === 0) {
            let datas = [];
            let itemTotal = 0;
            (this.totalAmountBody = 0),
              (this.totalAmount = 0),
              (this.totalFee = 0);
            if (data.ReturnObject !== null) {
              datas = data.ReturnObject.ItemsReport;
              itemTotal = data.ReturnObject.Pagination.ItemTotal;
              (this.totalAmountBody = data.ReturnObject.Total.AmountBody),
                (this.totalAmount = data.ReturnObject.Total.AmountTotal),
                (this.totalFee = data.ReturnObject.Total.AmountFee);
            }
            this.paginationItems = itemTotal;
            this.dataSource = new MatTableDataSource(datas); // array of datas
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.getFirstDatas(this.radioModel);
  }

  toInt(input) {
    if (!input.target.value.slice(-1).match(/[0-9]/)) {
      input.target.value = input.target.value.slice(0, -1);
    }
    this.currentRefStan = input.target.value;
  }
  toIntBlur(input) {
    input.target.value = input.target.value.replace(/[^0-9]/g, "");
    this.currentRefStan = input.target.value;
  }
  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }
  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }
  modalDatas = {}; // for display datas in modal
  ngOnInit() {
    this.paginationItemPerPage = 10;
    let ls = new SecureLS();
    this.permissions = ls.get("permissions")[
      this.route.data["value"].pathCheck
    ];
    this.statusPermission =
      ls.get("permissions")["changing_status"] === null
        ? false
        : ls.get("permissions")["changing_status"].write;
    this.InitStateTranslation();
    this.FilterForm = new UntypedFormGroup({
      payIdControl: new UntypedFormControl(""), //, [this.dealerDropValidator(this.options)]),
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      ServiceDrop: new UntypedFormControl(""),
      Status: new UntypedFormControl(""),
      Account: new UntypedFormControl(""),
      CheckId: new UntypedFormControl("")
    });
    this.StatusForm = new UntypedFormGroup({
      Comment: new UntypedFormControl(""),
      Status: new UntypedFormControl(this.modalStates[0].Value),
      RefStan: new UntypedFormControl("")
    });

    this.translate
      .get("PAY.MODAL_STS_CONFIRM")
      .subscribe(text => (this.modalStates[0].Name = text));
    this.translate
      .get("PAY.MODAL_STS_REPAID")
      .subscribe(text => (this.modalStates[1].Name = text));

    this.translate
      .get("PAY.MODAL_STS_CLOSE")
      .subscribe(text => (this.modalStates[2].Name = text));

    this.InitProviderFilter();
    this.getFirstDatas();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core'
import { ToolsService } from '../../_services/tools.service'
import { DatePipe } from '@angular/common'
import { DateAdapter } from '@angular/material/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { ActivatedRoute } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { InvoiceMonitoringUploadComponent } from './upload.component'

@Component({
	templateUrl: 'invoice-monitoring.component.html',
	styleUrls: ['../reports/reports.scss'],
	providers: [DatePipe],
})
export class InvoiceMonitoringComponent implements OnInit {
	permissions
	constructor(
		public dialog: MatDialog,
		private toolsService: ToolsService,
		private datePipe: DatePipe,
		private dateAdapter: DateAdapter<Date>,
		private translate: TranslateService,
		public toastr: ToastrService,
		public route: ActivatedRoute
	) {
		dateAdapter.setLocale('en-EN') // change this for change format date in select
	}
	ngOnInit(): void {
		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
		})
		this.FilterHistoryForm = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			Services: new UntypedFormControl(''),
			ProviderID: new UntypedFormControl(''),
		})

		this.getList()
	}

	//Table data
	dataSource = new MatTableDataSource()

	displayedColumns: string[] = [
		'No',
		'Provider',
		'Date',
		'Status',
		'NumberOfRecords',
		'Actions',
	]

	//Paging
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	currentPage = 0
	cnt: number[]
	@ViewChild(MatPaginator) paginator: MatPaginator

	//DateTime
	currentTime = new Date()
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date

	loading = false
	pageEvent

	//Filter settings
	FilterForm: UntypedFormGroup //Form initial
	radioModel = 1
	FilterHistoryForm: UntypedFormGroup

	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getList()
	}

	getList() {
		this.loading = true
		this.toolsService.getList().subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					let serviceResponse = []
					if (data.ReturnObject != null) {
						serviceResponse = data.ReturnObject
					}
					this.dataSource = new MatTableDataSource(serviceResponse)
				}
				this.loading = false
			},
			(error) => {
				this.loading = false
			}
		)
	}

	openDialog(CompanyID,CompanyName): void {
		const dialogRef = this.dialog.open(InvoiceMonitoringUploadComponent, {
			data: { id: CompanyID,
				   name:CompanyName },
		})

		dialogRef.afterClosed().subscribe((result) => {
			console.log('The dialog was closed')
		})
	}

	closeDialog(): void {
		this.dialog.closeAll()
	}
	 
}

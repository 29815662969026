import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, FormGroup, ReactiveFormsModule } from '@angular/forms'

import { AgmCoreModule } from '@agm/core'
import { RouterModule } from '@angular/router'

import { CdkTableModule } from '@angular/cdk/table'
import { CdkTreeModule } from '@angular/cdk/tree'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatBadgeModule } from '@angular/material/badge'
import { MatBottomSheetModule } from '@angular/material/bottom-sheet'
import { MatButtonModule } from '@angular/material/button'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatChipsModule } from '@angular/material/chips'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatGridListModule } from '@angular/material/grid-list'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import {
	MatNativeDateModule,
	MatRippleModule,
	DateAdapter,
	MAT_DATE_FORMATS,
} from '@angular/material/core'
import {
	MatPaginatorModule,
	MatPaginatorIntl,
} from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatRadioModule } from '@angular/material/radio'
import { MatSelectModule } from '@angular/material/select'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatSliderModule } from '@angular/material/slider'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatSortModule } from '@angular/material/sort'
import { MatStepperModule } from '@angular/material/stepper'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatTreeModule } from '@angular/material/tree'
import { TranslateModule } from '@ngx-translate/core'

import { DragDropModule } from '@angular/cdk/drag-drop'
import { KioskMonitoringComponent } from './kiosk-monitoring.component'
import { KioskOverviewComponent } from './kiosk-overview.component'
import { KioskEncashmentComponent } from './kiosk-encashment.component'
import { KioskCreditCardComponent } from './kiosk-credit-card.component'
import { KioskReconciliationComponent } from './kiosk-reconciliation.component'
import { KioskCashRestComponent } from './kiosk-cashrest.component'

import { CustomPipeModule } from '../../_helpers/CustomPipes'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { MyDateAdapter, APP_DATE_FORMATS } from '../../_helpers/myDateAdapter'

import { MAT_DATE_LOCALE } from '@angular/material/core'
import { NgxMatMomentAdapter } from '@angular-material-components/moment-adapter'
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'

import {
	NGX_MAT_DATE_FORMATS,
	NgxMatDateAdapter,
	NgxMatDateFormats,
	NgxMatDatetimePickerModule,
	NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker'

const MY_NGX_DATE_FORMATS: NgxMatDateFormats = {
	parse: {
		dateInput: 'l, LTS',
	},
	display: {
		dateInput: 'DD.MM.yyyy HH:mm:ss',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
}

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		CdkTableModule,
		BrowserModule,
		CdkTreeModule,
		ReactiveFormsModule,
		DragDropModule,
		MatAutocompleteModule,
		MatBadgeModule,
		MatBottomSheetModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatStepperModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatTreeModule,
		NgxMatDatetimePickerModule,
		NgxMatNativeDateModule,
		CustomPipeModule,
		BsDropdownModule,
		TranslateModule,
		AgmCoreModule.forRoot({
			apiKey: 'AIzaSyD3J0keGVntfEiwvFGGeiHH0jeaayyL61o',
		}),
	],
	declarations: [
		KioskMonitoringComponent,
		KioskOverviewComponent,
		KioskEncashmentComponent,
		KioskCreditCardComponent,
		KioskReconciliationComponent,
		KioskCashRestComponent,
	],
	providers: [
		{
			provide: NgxMatDateAdapter,
			useClass: NgxMatMomentAdapter, //Moment adapter
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
		},
		{ provide: NGX_MAT_DATE_FORMATS, useValue: MY_NGX_DATE_FORMATS },
	],
	exports: [
		CommonModule,
		CdkTableModule,
		CdkTreeModule,
		DragDropModule,
		MatAutocompleteModule,
		MatBadgeModule,
		MatBottomSheetModule,
		MatButtonModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatStepperModule,
		MatDatepickerModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatNativeDateModule,
		MatPaginatorModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatRippleModule,
		MatSelectModule,
		MatSidenavModule,
		MatSliderModule,
		MatSlideToggleModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatTreeModule,
	],
})
export class KiosksModule {}

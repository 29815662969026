import { Component, OnInit, ViewChild } from '@angular/core'
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatDialog } from '@angular/material/dialog'
import { ReportsDatasService } from '../../_services/reportsdata.service'
import { AdministrationService } from '../../_services/administration.service'
import { saveAs } from 'file-saver'
import { DatePipe } from '@angular/common'
import { TranslateService } from '@ngx-translate/core'
/** Constants used to fill up our data base. */
import { NestedTreeControl } from '@angular/cdk/tree'
import { MatTreeNestedDataSource } from '@angular/material/tree'

interface DealerNode {
	name: string
	children?: DealerNode[]
}
export interface ItemList {
	Value: string
	Name: string
}
@Component({
	templateUrl: 'deposit-operations.component.html',
	styleUrls: ['reports.scss'],
	providers: [DatePipe],
})
export class DepositOperationsComponent implements OnInit {
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api

	public currentPage = 0
	isLoadingDetailInfo: boolean = true // spinner

	//-------------------------------------
	Transactions_Datas
	dealerListOptions: ItemList[] // dealer List
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	// For change pages

	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	dealerFControl = new UntypedFormControl() // Dealer
	currentDealerId: any = ''

	dealerFilteredOptions: Observable<ItemList[]> // dealer
	radioModel = 1

	currentTime = new Date(new Date().setHours(0, 0, 0))
	currentTimeEnd = new Date(new Date().setHours(23, 59, 59))
	//----------------- list of columns
	displayedColumns: string[] = [
		'Ident',
		'TransactionDate',
		'Source',
		'Destination',
		'TransferedBy',
		'Amount',
		'TransactionType',
		'TransactionDescription',
	]

	dataSource = new MatTableDataSource() // array of datas

	hasChild = (_: number, node: DealerNode) =>
		!!node.children && node.children.length > 0
	treeControl = new NestedTreeControl<DealerNode>((node) => node.children)
	dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
	treeData

	showTree: boolean = false
	currentDealer = {
		Name: '',
		Value: '1',
	}
	growTree(arr) {
		let storageData = JSON.parse(sessionStorage.getItem('dealerTreeList'))
		this.dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
		let mainArray = arr
		if (!storageData) {
			mainArray.map((x) => {
				x.children = []
			})
			mainArray.map((z) => {
				if (z.Id !== 0) {
					let currentEl = mainArray.find((x) => x.Id === z.ParentId)
					if (currentEl) {
						currentEl.children.push(z)
					}
				}
			})
			let result = mainArray.splice(0, 1)
			this.treeData = result
			sessionStorage.setItem('dealerTreeList', JSON.stringify(result))
			this.dataTreeSource = result
		} else {
			this.dataTreeSource = storageData
		}
	}
	chooseDealer(smth) {
		this.CloseDialog()
		this.dealerFControl.patchValue(smth.Name)
		this.currentDealer = { Value: smth.Id, Name: smth.Name }
		this.showTree = false
		console.log('Choose Dealer value:', this.currentDealer)
		this.getFirstDatas()
	}
	stopClick(event) {
		event.preventDefault()
	}
	//----------------------- get a dealer list and put to option's
	InitDealerFilter() {
		this.administrationService
			.getDealerDatasAsList()
			.pipe(first())
			.subscribe(
				(data) => {
					this.treeData = data.ReturnObject
					this.dealerFControl.setValue(this.currentDealer.Name)
					this.growTree(data.ReturnObject)
				},
				(error) => {
					console.log(error)
				},
				() => {
					this.getFirstDatas(this.radioModel)
				}
			)
	}
	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
			minHeight: '580px',
		})
	}
	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		private translate: TranslateService,
		private datePipe: DatePipe,
		private reportsDataService: ReportsDatasService,
		private administrationService: AdministrationService
	) {}
	isDownloadingFile: boolean = false

	FilterForm: UntypedFormGroup // Form initial
	//dealerDropValidator(controls): ValidatorFn {
	//    return (control: AbstractControl): { [key: string]: boolean } | null => {
	//        if ((control.value.length > 0) && (controls.indexOf(control.value) < 0)) {
	//            return { 'dealer': true };
	//        }
	//        return null;
	//    };
	//}

	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas(this.radioModel)
	}
	download(format) {
		this.isDownloadingFile = true
		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let dealerId = this.currentDealer.Value
		this.reportsDataService
			.getDepositFile(
				dealerId,
				start,
				finish,
				this.radioModel,
				format,
				this.currentPage + 1,
				this.paginationItemPerPage
			)
			.subscribe(
				(res) => {
					this.isDownloadingFile = false
					this.loading = false
					saveAs(res, finish + '.' + format, {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
				},
				(error) => {
					console.log(error)
					this.loading = false
					this.isDownloadingFile = false
				}
			)
	}
	CloseDialog(): void {
		this.dialog.closeAll()
	}
	// function for change tables page
	selectRow(templateRef, row) {
		this.isLoadingDetailInfo = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		// Take datas from form
		let dealerId = this.currentDealer.Value
		this.reportsDataService
			.getAdvanceDeposit(row.TransactionId, dealerId, start, finish)
			.pipe(first())
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas =
								data.ReturnObject.DepositOperations[0]
							this.isLoadingDetailInfo = false
						} else {
							this.isLoadingDetailInfo = false
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					this.isLoadingDetailInfo = false
					console.log(error)
				}
			)
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}
	private markFormGroupTouched(formGroup: UntypedFormGroup) {
		;(<any>Object).values(formGroup.controls).forEach((control) => {
			control.markAsTouched()

			if (control.controls) {
				this.markFormGroupTouched(control)
			}
		})
	}

	findCurrentDealerId() {
		this.administrationService.getCurrentProfileId().subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					if (data !== null) {
						this.currentDealerId = data.ReturnObject.Dealer.Id // array of datas
						console.log('currentDealerid:', this.currentDealerId)
					} else {
						this.currentDealerId = '' //empty array of datas
					}
				}
				this.loading = false
			},
			(error) => {
				this.loading = false
				console.log(error)
			}
		)
	}

	getFirstDatas(period?) {
		if (typeof period === 'number') this.radioModel = period

		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		// Take datas from form
		let dealerId = this.currentDealer.Value
		this.reportsDataService
			.getAllDeposit(
				dealerId,
				start,
				finish,
				this.radioModel,
				this.currentPage + 1,
				this.paginationItemPerPage
			)
			.pipe(first())
			.subscribe(
				(data) => {
					console.log(data)
					if (data.ResultCode === 0) {
						let datas = []
						let itemTotal = 0
						if (data.ReturnObject !== null) {
							datas = data.ReturnObject.DepositOperations
							itemTotal = data.ReturnObject.Pagination.ItemTotal
						}
						this.paginationItems = itemTotal
						this.dataSource = new MatTableDataSource(datas) // array of datas
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	onSubmit() {
		this.getFirstDatas(this.radioModel)
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}

	private _filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.dealerListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}
	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}
	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	modalDatas = {} // for display datas in modal

	ngOnInit() {
		this.paginationItemPerPage = 10
		this.findCurrentDealerId()

		this.FilterForm = new UntypedFormGroup({
			Dealer: new UntypedFormControl('', Validators.required), //, [this.dealerDropValidator(this.options)]),
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTimeEnd, disabled: true },
				Validators.required
			),
		})

		this.InitDealerFilter()
	}
}

import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';

import {AlertService, AuthenticationService} from '../../_services';
import {$} from 'protractor';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {Title} from '@angular/platform-browser';

@Component({
    selector: 'app-dashboard',
    templateUrl: 'login.component.html',
    styleUrls: ['login.scss']
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error_current = '';
    loadingLogin = false;
    current_language;
    error_redirect = '';

    constructor(
        private formBuilder: UntypedFormBuilder,
        private titleService: Title,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        public toastr: ToastrService,
        private alertService: AlertService,
        public translate: TranslateService) {
        let title = route.data['title'];
        if (title) {
            this.setTitle(title + ' | Dealer management system (DMS)');
        } else {
            this.setTitle('Login | Dealer management system (DMS)');
        }
        // console.log("browser lang is " + translate.getBrowserLang() + " --> " + translate.getBrowserCultureLang());
        console.log(translate.getLangs());
        translate.addLangs(['en','tr' ]);
        console.log(translate.getLangs());
        // translate.setDefaultLang('en');
        if (localStorage.getItem('currentLang')) {
            console.log('Cookie');
            this.current_language = localStorage.getItem('currentLang');
        } else {
            this.current_language = translate.getBrowserLang();
        }
        const browserLang = this.current_language; // This variable change lang
        translate.use(browserLang.match(/en|tr/) ? browserLang : 'tr');

        translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.current_language = event.lang;
        });
        this.route.queryParams.subscribe(params => {
            if (typeof params['error'] !== 'undefined') {
                this.error_redirect = params['error'];
            }
        });
    }

    public setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }

    ngOnInit() {
        // Reset login status
        this.authenticationService.logout(); // Reset Logout
        sessionStorage.removeItem("dealerTreeList");

        if (this.error_redirect.length && this.error_redirect === '401') {
            let body = '';
            let theme = '';
            this.translate.get('HOME').subscribe((home) => {
                body = home.SESSION_EXPIRED;
                theme = home.SESSION_EXPIRED_THEME;
                console.log(home);
                this.toastr.warning(body, theme);
                console.log(body);
                console.log(theme);
                this.loadingLogin = true;
            }); // Text in component
        } else {
            this.loadingLogin = true;
        }
        // Initial form
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true; // uncomment
        this.authenticationService.login(this.f.username.value, this.f.password.value, this.current_language)
            .pipe(first())
            .subscribe(
                data => {
                    this.error_current = '';

                    console.log(data);
                    if (data.resultCode === 0) {
                        //-------------------- clear data from localStore --
                        localStorage.removeItem('providerTop');
                        localStorage.removeItem('providerGroup');
                        this.router.navigate(['/dashboard']); // Uncomment this after...
                    } else {
                        //  alert("error code --> " + data.responseCode);
                        this.loading = false;
                        this.error_current = data.ResultMessage;
                        //console.log("22222");
                        //this.translate.get('HOME.TITLE').subscribe((text) => {console.log(text);}); // Text in component
                        // setTimeout(function () { this.alertService.error("ERROR"); }, 1);
                    }
                },
                error => {
                    this.translate.get('LOGIN.MAIN_ERROR').subscribe((text) => {
                        this.error_current = text;
                    }); // Text in component
                    // this.error_current = error; // Or create custom text on exist user
                    console.log(error);
                    // this.alertService.error(error);
                    this.loading = false;
                });
    }

    //----------------- helpers
    onClickErrorClear() {
        this.error_current = null;
    }
}

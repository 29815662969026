import { BrowserModule, Title } from '@angular/platform-browser'
import { NgModule } from '@angular/core'

import {
	HttpClient,
	HttpClientModule,
	HTTP_INTERCEPTORS,
} from '@angular/common/http'

// used to create fake backend
import { fakeBackendProvider } from './_helpers'

import { AppComponent } from './app.component'
import { routing } from './app.routing'

import { AlertComponent } from './_directives'
import { AuthGuard } from './_guards'
import { JwtInterceptor, ErrorInterceptor } from './_helpers'
import {
	AlertService,
	AuthenticationService,
	UserService,
	PaymentService,
} from './_services'

import { LocationStrategy, HashLocationStrategy } from '@angular/common'

import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar'
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
import { environment } from './../environments/environment'

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: true,
}

// Import containers

import { P404Component } from './views/error/404.component'
import { P500Component } from './views/error/500.component'
import { RegisterComponent } from './views/register/register.component'

import {
	AppAsideModule,
	AppBreadcrumbModule,
	AppHeaderModule,
	AppFooterModule,
	AppSidebarModule,
} from '@coreui/angular'

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { TabsModule } from 'ngx-bootstrap/tabs'
import { ChartsModule } from 'ng2-charts'
// Import 3rd party modules
import { SettingsModule } from './views/settings/settings.module'
import { PaymentsModule } from './views/payments/payments.module'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ReportsModule } from './views/reports/reports.module'
import { ProviderModule } from './views/provider/provider.module'

// Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { from } from 'rxjs'
import { DefaultLayoutModule } from './containers/default-layout/default-layout.module'
import { DefaultLayoutComponent } from './containers'
import { ReportsDatasService } from './_services/reportsdata.service'
import { ProviderService } from './_services/provider.service'
import { KioskService } from './_services/kiosk.service'
import { PayService } from './_services/pay.service'

import { LoginModule } from './views/login/login.module'
import { LoginComponent } from './views/login/login.component'
import { AdministrationModule } from './views/administation/administration.module'
import { KiosksModule } from './views/kiosk/kiosk.module'
import { PayModule } from './views/pay/pay.module'

import { AdministrationService } from './_services/administration.service'
import { ProfileService } from './_services/profile.service'
import { MainService } from './_services/main.service'
import { DashboardService } from './_services/dashboard.service'
import { CustomPipeModule } from './_helpers/CustomPipes'
import { RoleGuard } from './_guards/role.guard'
//import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ToastrModule } from 'ngx-toastr'
import { ProviderReportsDataService } from './_services/providerreportsdata.service'
import { ToolsService } from './_services/tools.service'
import { ToolsModule } from './views/tools/tools.module'

// import {
//   NgxMatMomentModule,
//   NgxMatMomentAdapter,
//   NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
// } from "@angular-material-components/moment-adapter";

// import {
// 	NgxMatDatetimePickerModule,
// 	NgxMatTimepickerModule,
// 	NgxMatNativeDateModule,
// 	NGX_MAT_DATE_FORMATS,
// 	NgxMatDateAdapter,
// } from '@angular-material-components/datetime-picker'

export const CUSTOM_MOMENT_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY HH:mm:ss',
	},
	display: {
		dateInput: 'DD/MM/YYYY HH:mm:ss',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
}
export function HttpLoaderFactory(httpClient: HttpClient) {
	return new TranslateHttpLoader(
		httpClient,
		environment.apiUrl + '/home/lang/',
		'json'
	)
}

@NgModule({
	imports: [
		BrowserModule,
		AppAsideModule,
		AppBreadcrumbModule.forRoot(),
		AppFooterModule,
		AppHeaderModule,
		AppSidebarModule,
		PerfectScrollbarModule,
		BsDropdownModule.forRoot(),
		TabsModule.forRoot(),
		ChartsModule,
		ReactiveFormsModule,
		HttpClientModule,
		PaymentsModule,
		ReportsModule,
		ProviderModule,
		SettingsModule,
		routing,
		BrowserAnimationsModule,
		LoginModule,
		AdministrationModule,
		KiosksModule,
		PayModule,
		FormsModule,
		// NgxMatMomentModule,
		// NgxMatDatetimePickerModule,
		// NgxMatTimepickerModule,
		// NgxMatNativeDateModule,
		ToastrModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		CustomPipeModule,
		ToolsModule,
	],
	declarations: [
		AppComponent,
		AlertComponent,
		DefaultLayoutComponent,
		P404Component,
		P500Component,
		LoginComponent,
		RegisterComponent,
	],
	providers: [
		/* {
      provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: false },
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
    { provide: NgxMatDateAdapter, useClass: NgxMatMomentAdapter },
    */
		{
			provide: LocationStrategy,
			useClass: HashLocationStrategy,
		},
		AuthGuard,
		RoleGuard,
		AlertService,
		AuthenticationService,
		ReportsDatasService,
		UserService,
		ProviderService,
		PaymentService,
		AdministrationService,
		ProfileService,
		MainService,
		DashboardService,
		KioskService,
		PayService,
		ProviderReportsDataService,
		ToolsService,
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		Title,
		// provider used to create fake backend
		fakeBackendProvider,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map, first } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
//import { Observable } from 'rxjs';
import { environment } from './../../environments/environment'

export interface ItemList {
	Value: string
	Name: string
}

@Injectable()
export class ProviderService {
	constructor(
		private http: HttpClient,
		private authService: AuthenticationService
	) {}
	baseUrl: string = environment.apiUrl
	GetAllProviders() {
		return this.http
			.get<any>(this.baseUrl + `/api/Provider/GetProviders`, {
				headers: { 'Accept-Language': this.GetLang() },
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getDailyList(dateStart, dateEnd, period, providerId) {
		return this.http
			.get<any>(
				this.baseUrl +
					`/api/Provider/DepositListData?pPeriodType=${period}&pDateStart=${dateStart}&pDateEnd=${dateEnd}&pProviderId=${providerId}`,
				{
					headers: { 'Accept-Language': this.GetLang() },
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	saveNewProvider(date, providerId, amount, type, note) {
		let body = {
			pDate: date,
			pProviderId: providerId,
			pAmount: amount,
			pType: type,
			pNote: note,
		}
		// date, providerId, amount, note;
		return this.http
			.post<any>(
				this.baseUrl + `/api/Provider/DepositTransferSave`,
				body,
				{
					headers: { 'Accept-Language': this.GetLang() },
				}
			)
			.pipe(
				map((response) => {
					// login successful if there's a jwt token in the response
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	//--------------- use only into your component
	GetAllProvidersFromStorage() {
		//: Observable<ItemList[]> {
		let prvTop = localStorage.getItem('providerAll')
		if (prvTop === null) {
			this.AllList()
				.pipe(first())
				.subscribe(
					(data) => {
						localStorage.setItem(
							'providerAll',
							JSON.stringify(data)
						)
						return data
					},
					(error) => {
						console.log(error)
					}
				)
		} else return JSON.parse(prvTop)
	}
	//----------------- TopList of provider
	TopList() {
		return this.http
			.get<any>(this.baseUrl + `/api/provider/GetTop?count=6`, {
				headers: { 'Accept-Language': this.GetLang() },
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	//----------------- get groups of provider
	GroupList() {
		return this.http
			.get<any>(this.baseUrl + `/api/provider/getGroup?id=all`, {
				headers: { 'Accept-Language': this.GetLang() },
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	//---------------- get all providers
	AllList() {
		return this.http
			.get<any>(this.baseUrl + `/api/provider/get`, {
				headers: { 'Accept-Language': this.GetLang() },
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	// Providers page
	ProviderAllFilter() {
		return this.http
			.get<any>(this.baseUrl + `/api/Provider/ProviderGetAll`, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	ProviderAllGroups() {
		return this.http
			.get<any>(this.baseUrl + `/api/Provider/GetGroup/all`, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getSalePoints(all?: string) {
		if (all) {
			return this.http
				.get<any>(
					this.baseUrl + `/api/Entries/GetSalePointList?id=` + all
				)
				.pipe(
					map((response) => {
						return response
					})
				)
		}
		return this.http
			.get<any>(this.baseUrl + `/api/Entries/GetSalePointList`)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	private GetLang() {
		if (localStorage.getItem('currentLang') === 'tr') {
			return 'tr-TR'
		}
		return 'en-US'
	}
}

import { Component, OnInit, ViewChild } from '@angular/core'
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first, expand } from 'rxjs/operators'
import { ProviderService } from '../../_services'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { DatePipe } from '@angular/common'
import { saveAs } from 'file-saver'
import { AdministrationService } from '../../_services/administration.service'
import { ReportsDatasService } from '../../_services/reportsdata.service'
import { TranslateService } from '@ngx-translate/core'
import { NestedTreeControl } from '@angular/cdk/tree'
import { MatTreeNestedDataSource } from '@angular/material/tree'

interface DealerNode {
	name: string
	children?: DealerNode[]
}

export interface ItemList {
	Value: string
	Name: string
}

@Component({
	templateUrl: 'system-errors.component.html',
	styleUrls: ['reports.scss'],
	providers: [DatePipe],
})
export class SystemErrorsComponent implements OnInit {
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	isDownloadingFile: boolean = false
	dealerFControl = new UntypedFormControl() // Dealer

	public currentPage = 0
	isLoadingDetailInfo: boolean = false
	modalDatas = {
		Request: {},
		Response: {},
	}
	//status: ItemList[]
	statusSelected = '' //Status Selected
	methodSelected = ''
	userLogin = ''
	pageEvent
	loading = false
	radioModel = 1

	showTree: boolean = false
	currentDealer = {
		Name: '',
		Value: '',
	}

	currentTime = new Date(new Date().setHours(0, 0, 0))
	currentTimeEnd = new Date(new Date().setHours(23, 59, 59))

	displayedColumns: string[] = [
		'Info',
		'ApplicationName',
		'UserLogin',
		'TransactionDate',
		'Description',
	]
	dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
	treeData
	dataSource = new MatTableDataSource()

	growTree(arr) {
		let storageData = JSON.parse(sessionStorage.getItem('dealerTreeList'))
		this.dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
		let mainArray = arr
		if (!storageData) {
			mainArray.map((x) => {
				x.children = []
			})
			mainArray.map((z) => {
				if (z.Id !== 0) {
					let currentEl = mainArray.find((x) => x.Id === z.ParentId)
					if (currentEl) {
						currentEl.children.push(z)
					}
				}
			})
			let result = mainArray.splice(0, 1)
			this.treeData = result
			this.dataTreeSource = result
		} else {
			this.dataTreeSource = storageData
		}
	}

	chooseDealer(smth) {
		this.CloseDialog()
		this.dealerFControl.patchValue(smth.Name)
		this.currentDealer = { Value: smth.Id, Name: smth.Name }
		this.showTree = false
		this.getFirstDatas()
	}

	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
			minHeight: '580px',
		})
	}

	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private reportsDataService: ReportsDatasService,
		private providerService: ProviderService,
		private translate: TranslateService,
		private administrationService: AdministrationService
	) {}

	FilterForm: UntypedFormGroup // Form initial

	checkIntBlur(target) {
		let targetV = target.value.replace(/[^0-9]/g, '')
		this.FilterForm.controls.RefStan.setValue(targetV)
	}
	status = [
		{
			Name: 'All',
			Value: '',
		},
		{
			Name: 'Only Error',
			Value: 2,
		},
		{
			Name: 'Log',
			Value: 3,
		},
	]

	methods = [
		{
			Name: 'All',
			Value: '',
		},
		/* {
      Name: "CheckPayment",
      Value: "CheckPayment"
    },
    {
      Name: "ExecutePayment",
      Value: "ExecutePayment"
    },*/
		{
			Name: 'GetServerDateTime',
			Value: 'GetServerDateTime',
		},
		{
			Name: 'KioskIdentifyGet',
			Value: 'KioskIdentifyGet',
		},
		{
			Name: 'CheckPayment',
			Value: 'CheckPayment',
		},
		{
			Name: 'ExecutePayment',
			Value: 'ExecutePayment',
		},
		{
			Name: 'GetCompanyInfo',
			Value: 'GetCompanyInfo',
		},
		{
			Name: 'GetTransaction',
			Value: 'GetTransaction',
		},
		{
			Name: 'GetTransactionReport',
			Value: 'GetTransactionReport',
		},
	]

	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas(this.radioModel)
	}

	getFirstDatas(period?) {
		if (typeof period === 'number') {
			this.radioModel = period
		}

		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let userLogin = this.currentDealer.Value
		let filter = this.FilterForm.value
		this.reportsDataService
			.getSystemData(
				start,
				finish,
				this.radioModel,
				filter,
				this.currentPage + 1,
				this.paginationItemPerPage
			)
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						console.log('First datas:', data)
						let datas = []
						let itemTotal = 0
						if (data.ReturnObject !== null) {
							datas = data.ReturnObject.Items
							itemTotal = data.ReturnObject.Pagination.ItemTotal
						}
						this.paginationItems = itemTotal
						this.dataSource = new MatTableDataSource(datas) // array of datas
					} else {
						this.dataSource = new MatTableDataSource([])
						this.paginationItems = 0
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}

	isLoadingTabInfo: boolean = false
	panelClosed: boolean = true

	sysInterctionData: []
	sysResponseData: []
	interLogData = {}
	getInfo(event, key, index) {
		if (!this.interLogData.hasOwnProperty(index)) {
			this.reportsDataService.getErrorInfo(key).subscribe(
				(res) => {
					if (res['ResultCode'] === 0) {
						if (res['ReturnObject'] !== null) {
							this.interLogData[index] =
								res['ReturnObject'].Details
						} else {
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					console.log(error)
				}
			)
			// const dialogRef = this.dialog.open(templateRef, {
			// 	panelClass: 'modal_transaction',
			// })
		}
	}

	openInfoModal(templateRef, row) {
		this.isLoadingDetailInfo = true
		this.sysResponseData = []
		this.sysInterctionData = []
		// this.interLogData = {}
		this.reportsDataService
			.getErrorInfo(row.Key)
			.pipe(first())
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas = data.ReturnObject
							this.isLoadingDetailInfo = false
							this.modalDatas = data.ReturnObject.Details
							console.log(this.modalDatas)
						} else {
							this.isLoadingDetailInfo = false
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					this.isLoadingDetailInfo = false
					console.log(error)
				}
			)
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}

	ngOnInit() {
		this.paginationItemPerPage = 10

		this.FilterForm = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTimeEnd, disabled: true },
				Validators.required
			),
			AppMethod: new UntypedFormControl(''),
			UserLogin: new UntypedFormControl(''),
			StateCode: new UntypedFormControl(''),
		})
	}
}

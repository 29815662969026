import {Component, OnInit, ViewChild, Input} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn, FormGroupDirective, NgForm} from '@angular/forms';
import {Observable, generate} from 'rxjs';
import {map, startWith, first} from 'rxjs/operators';
import {AlertService, UserService, ProviderService} from '../../_services';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import {  ErrorStateMatcher } from '@angular/material/core';

import {DataSource} from '@angular/cdk/table';
import {ReportsDatasService} from '../../_services/reportsdata.service';
import {AdministrationService} from '../../_services/administration.service';
import { environment } from "../../../environments/environment";

import {DatePipe} from '@angular/common';
import { CKEditor4 } from "ckeditor4-angular/ckeditor";
//import * as ClassicEditor from '@ck';
/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls';

//import {ChangeEvent} from '@ckeditor/ckeditor5-angular/ckeditor.component';

// For custom Error Validations Messages
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
		const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

		return (invalidCtrl || invalidParent);
	}
}

@Component({
	templateUrl: 'translate.component.html',
	styleUrls: ['administration.scss'],
	providers: [DatePipe]
})


export class TranslateComponent implements OnInit {
	matcher = new MyErrorStateMatcher(); // for my Error state | Colors and other
	permissions;
	ckEditor: number = 0; // for display CK Editor
	public paginationItems; // for display pagination from api
	public paginationItemPerPage; // for display pagination from api
	editor_data_eng = '';
	editor_data_my = '';
	baseUrl: string = environment.apiUrl;

	public transactionCurrentID;
	public transactionCurrentDealerName;
	public transactionCurrentServiceName;
	public currentPage = 0;
	isLoadingDetailInfo: boolean = true; // spinner
	translateLoading: boolean = false;

	// CK EDITOR


	public onChange(event, target) { 
		const data = event.editor?event.editor.getData():event.target.value;
		if (target === "en") {
		  this.editor_data_eng = data;
		} else {
		  this.editor_data_my = data;
		}
	  }
	// for visible preview image
	preview_image = {
		en: {
			code: "",
			type: "",
			link: ""
		},
		tr: {
			code: "",
			type: "",
			link: ""
		}
	};

	//-------------------------------------
	Table_Datas;
	loading = true; // for disable fast response click
	pageEvent; // for fix error of pageEvent in Visual Studio
	// For change pages
	selected_type_text = '';
	account = ''; // Account input
	refStan = ''; // refStan input
	serviceSelected = ''; // Service selected
	userLogin = ''; // User Login input

	events: string[] = []; // Date picker
	createTranslateError = ''; // Validation on User

	currentTime = new Date();
	//----------------- list of columns 
	displayedColumns: string[] = ['Code', 'NrCode', 'Name', 'Description'];
	dataSource = new MatTableDataSource(); // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(public dialog: MatDialog,
				// Make service for post api datas
				private datePipe: DatePipe,
				private reportsDataService: ReportsDatasService,
				private providerService: ProviderService,
				private administrationService: AdministrationService,
				public route: ActivatedRoute,
				private formBuilder: UntypedFormBuilder
	) {

	}

	FilterForm: UntypedFormGroup; // Form initial
	CreateTranslate: UntypedFormGroup;
	EditForm: UntypedFormGroup; // Form initial
	//----------------------  
	public handlePage(e: any) {
		this.currentPage = e.pageIndex;
		this.paginationItemPerPage = e.pageSize;
		this.getTranslateList();

	}

	// For visible element for selected value
	selectedType(event) {
		let selected = event.source.selected._element.nativeElement.innerText.trim();
		this.selected_type_text = selected;
		this.getTranslateList();
	}

	takeDropdown(): void {
		this.administrationService.getFirstDropdown()
			.pipe(first())
			.subscribe(
				data => {
					if (data.ResultCode === 0) {
						this.reportTypes = [];
						for (let i in data.ReturnObject.ObjectTypes) {
							this.reportTypes.push({value: data.ReturnObject.ObjectTypes[i], viewValue: i});
						}
					}
				},
				error => {
					this.loading = false;
					console.log(error);
				},
				() => {
					this.translateLoading = true;
					this.generateDropdown(null);
				});
	}

	onSelectFile(event, lang) {
		if (event.target.files && event.target.files[0]) {
			let type = event.target.files[0].type;
			let reader: any;
			target: EventTarget;
			reader = new FileReader();

			reader.readAsDataURL(event.target.files[0]); // read file as data url

			reader.onload = (event) => { // called once readAsDataURL is completed
				let code = event.target.result.split(',')[1];
				this.preview_image[lang].code = code;
				this.preview_image[lang].type = type;
				this.preview_image[lang].link = "data:" + type + ";base64," + code;
			};
		}
	}

	generateDropdown(target): void {
		this.loading = true;
		this.EditForm.reset();
		this.CreateTranslate.reset();
		if (target === null) {
			this.administrationService.generateDropdown(this.reportTypes[0].value)
				.pipe(first())
				.subscribe(
					data => {
						this.types = [];
						if (data.ResultCode === 0) {
							data.ReturnObject.SysTypes.forEach(systemType => {
								this.types.push({value: systemType.Key, viewValue: systemType.Name});
							});
							if(this.types.length>0)
								this.selected_type_text = this.types[0].viewValue;
							else 	
							this.selected_type_text=''
							// for(let i in data.ReturnObject.ObjectTypes){
							// 	// console.log(data.ReturnObject.ObjectTypes[i]);
							// 	this.reportTypes.push({value: data.ReturnObject.ObjectTypes[i], viewValue: i});
							// }
						}
						this.loading = false;
					},
					error => {
						this.loading = false;
						console.log(error);
					},
					() => {
						if (this.types.length > 0) {
							this.FilterForm.patchValue({['Type']: this.types[0].value});
						}
						this.getTranslateList();
					});
		} else {
			this.administrationService.generateDropdown(target)
				.pipe(first())
				.subscribe(
					data => {
						this.types = [];
						if (data.ResultCode === 0 ){if( data.ReturnObject.SysTypes.length > 0) {
							data.ReturnObject.SysTypes.forEach(systemType => {
								this.types.push({value: systemType.Key, viewValue: systemType.Name});
							});
							this.selected_type_text = this.types[0].viewValue;
							// for(let i in data.ReturnObject.ObjectTypes){
							// 	// console.log(data.ReturnObject.ObjectTypes[i]);
							// 	this.reportTypes.push({value: data.ReturnObject.ObjectTypes[i], viewValue: i});
							// }
						}
						else
						 this.selected_type_text = '';
					}
						this.loading = false;
					},
					error => {
						this.loading = false;
						console.log(error);
					},
					() => {
						if (this.types.length > 0) {
							this.FilterForm.patchValue({['Type']: this.types[0].value});
						}
						this.getTranslateList();
					});
		}
	}

	getTranslateList() {
		this.loading = true;
		console.log('getTranslateList');
		let currentPage = this.currentPage + 1;
		this.administrationService.getTranslates(this.FilterForm.value, currentPage, this.paginationItemPerPage)
			.pipe(first())
			.subscribe(
				data => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.Table_Datas = data.ReturnObject.Simples;
							this.dataSource = new MatTableDataSource(this.Table_Datas); // array of datas	
							//this.dataSource.sort = this.sort; // activate sort after insert datas
							// this.dataSource.paginator = this.paginator; // activate paginator after insert datas
							this.paginationItems = data.ReturnObject.Paging.TotalItems;
						} else {
							this.paginationItems = 0;
							this.Table_Datas = [];
							this.dataSource = new MatTableDataSource(this.Table_Datas); // array of datas	
						}
					}
					this.loading = false;
				},
				error => {
					this.loading = false;
					console.log(error);
				});
	}

	getFirstDatas() {
		this.takeDropdown();
	}

	onSubmit() {
		this.getTranslateList();
	}

	//------------------- private helpers

	modalDatas = {
		'UserId': ''
	};// for display datas in modal


	CloseDialog(): void {
		this.dialog.closeAll();
		this.ckEditor = 0;
		this.preview_image = {
			en: {
				code: '',
				type: '',
				link: ''
			},
			tr: {
				code: '',
				type: '',
				link: ''
			},
		};
	}

	convertToCode(target, form) {
		let convert: String = target;
		convert = convert.toUpperCase().replace(/[^A-Z0-9]+/ig, '_');
		if (target.length > 0) {
			if (form === 'create') {
				this.CreateTranslate.patchValue({['Code']: convert});
			} else {
				this.EditForm.patchValue({['Code']: convert});
			}
		}
	}

	openCreateTranslate(templateRef) {
		this.createTranslateError = '';
		this.editor_data_eng = '';
		this.editor_data_my = '';
		if (this.loading === false) {
			this.openModal(templateRef);
			this.CreateTranslate.patchValue({['Type']: this.selected_type_text});
			this.CreateTranslate.patchValue({['NrCode']: this.paginationItems + 1});
		}
	}

	openEdit(templateRef, row) {
		this.editor_data_eng = '';
		this.editor_data_my = '';
		if (this.loading === false) {
			this.loading = true;
			this.openModal(templateRef);
			this.isLoadingDetailInfo = true;
			this.administrationService.openEditTranslate(row.Key)
				.pipe(first())
				.subscribe(
					data => {
						if (data.ResultCode === 0) {
							if (data.ReturnObject !== null) {
								this.modalDatas = data.ReturnObject;
							}
							console.log(data.ReturnObject);
						} else {
							this.loading = false;
						}
					},
					error => {
						this.isLoadingDetailInfo = false;
						this.loading = false;
						console.log(error);
					},
					() => {
						this.administrationService.getEditStrings(row.Key)
							.pipe(first())
							.subscribe(
								data => {
									if (data.ResultCode === 0 && data.ReturnObject !== null) {
										this.modalDatas['Strings'] = data.ReturnObject.Strings;
										this.editDatasPush(this.modalDatas);
									}
								},
								error => {
									this.isLoadingDetailInfo = false;
									this.loading = false;
									console.log(error);
								});
					});
		}
	}

	// First Tab
	editDatasPush(datas) {
		this.EditForm.patchValue({['Type']: datas.TypeName});
		this.EditForm.patchValue({['Key']: datas.Key});
		this.EditForm.patchValue({['Code']: datas.Code});
		this.EditForm.patchValue({['NrCode']: datas.CodeNum});
		this.EditForm.patchValue({['Name']: datas.NameDef});
		this.EditForm.patchValue({['Description']: datas.DescriptionDef});

		datas.Strings.forEach(lang_str => {
			if (lang_str.LanguageCode === 'en') {
				this.EditForm.patchValue({['English']: lang_str.Name});
				this.EditForm.patchValue({['En_Desc']: lang_str.Description});
			} else {
				this.EditForm.patchValue({['Myanmar']: lang_str.Name});
				this.EditForm.patchValue({['My_Desc']: lang_str.Description});
			  }
		});
		if (this.FilterForm.value.ReportType === 2) {
			datas.Strings.forEach(lang_str => {
				if (lang_str.LanguageCode === 'en') {
					this.editor_data_eng = lang_str.Description;
				}
				else{
					this.editor_data_my = lang_str.Description;
				}
			});
			this.loading = false;
			this.isLoadingDetailInfo = false;
		} else if (this.FilterForm.value.ReportType === 1) {
			this.getImages(datas.Key, this.FilterForm.value.Type);
		} else {
			this.loading = false;
			this.isLoadingDetailInfo = false;
		}

	}

	getImages(key, type) {
		console.log("Preview Images::", this.preview_image);

		for (let image in this.preview_image) {
			this.preview_image[image].link = this.baseUrl+'/api/Entity/GetImage?SimpleKey=' + key + '&pType=' + type + '&pLangCode=' + image;
		} 
		this.isLoadingDetailInfo = false;
		this.loading = false;
	}

	changeTranslate() {
		this.markFormGroupTouched(this.EditForm);
		this.createTranslateError = '';
		if (this.EditForm.valid === true) {
			this.loading = true;
			this.administrationService.changeTranslate(this.EditForm.value, this.FilterForm.value.Type, this.modalDatas, this.editor_data_eng, this.editor_data_my)
				.pipe(first())
				.subscribe(
					data => {
						if (data.ResultCode === 0) {
							console.log('Translate was Changed');
						} else {
							this.loading = false;
							this.createTranslateError = data.ResultMessage;
						}
					},
					error => {
						this.loading = false;
						console.log(error);
					},
					() => {
						if (this.FilterForm.value.ReportType === 1) {
							this.saveImage(this.EditForm.value.Key);
						} else {
							this.loading = false;
							this.EditForm.reset();
							this.CloseDialog();
							this.getTranslateList();
						}
					});
		}
	}

	openModal(templateRef) {
		this.isLoadingDetailInfo = false;
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		});
		dialogRef.afterClosed().subscribe(result => {
			this.ckEditor = 0;
			this.preview_image = {
				en: {
					code: "",
					type: "",
					link: ""
				},
				tr: {
					code: "",
					type: "",
					link: ""
				}
			};
		});
	}

	/**
	 * Marks all controls in a form group as touched
	 * @param formGroup - The form group to touch
	 */
	private markFormGroupTouched(formGroup: UntypedFormGroup) {
		(<any>Object).values(formGroup.controls).forEach(control => {
			control.markAsTouched();

			if (control.controls) {
				this.markFormGroupTouched(control);
			}
		});
	}

	newTranslate() {
		this.markFormGroupTouched(this.CreateTranslate);
		this.createTranslateError = '';
		if (this.CreateTranslate.valid === true) {
			this.loading = true;
			let simple_key = null;
			this.administrationService.newTranslate(this.CreateTranslate.value, this.FilterForm.value.Type, this.editor_data_eng, this.editor_data_my)
				.pipe(first())
				.subscribe(
					data => {
						if (data.ResultCode === 0) {
							console.log('Translate was Created');
							simple_key = data.ReturnObject;
						} else {
							this.createTranslateError = data.ResultMessage;
						}
					},
					error => {
						this.loading = false;
						console.log(error);
					},
					() => {
						if (this.FilterForm.value.ReportType === 1) {
							this.saveImage(simple_key);
						} else {
							this.CreateTranslate.reset();
							this.CloseDialog();
							this.getTranslateList();
						}
					});
		}
	}

	saveImage(simple_key) {
		let count = 0;
		for (let image in this.preview_image) {
			this.administrationService.saveImage(simple_key, this.FilterForm.value.Type, image, this.preview_image[image])
				.pipe(first())
				.subscribe(
					data => {
						if (data.ResultCode === 0) {
							console.log('Image was Loaded');
						} else {
							this.createTranslateError = data.ResultMessage;
						}
						if (count < 1) {
							count++;
						} else {
							console.log('Form with image has been sended');
							this.loading = false;
							this.EditForm.reset();
							this.CreateTranslate.reset();
							this.CloseDialog();
							this.getTranslateList();
							count = 0;
						}
					},
					error => {
						this.loading = false;
						console.log(error);
					},
					() => {
						this.createTranslateError = '';
					});
		}
	}

	reportTypes = [];

	types = [];

	sorts = [
		{value: 0, viewValue: 'Default'},
		{value: 1, viewValue: 'Code'},
		{value: 2, viewValue: 'CodeNum'},
		{value: 3, viewValue: 'NameDefault'}
	];

	ngOnInit() {
		let ls = new SecureLS();

		this.permissions = ls.get('permissions')[this.route.data['value'].pathCheck];
		if (this.permissions.write === true) {
			this.displayedColumns.push('Actions');
		}

		this.paginationItemPerPage = 10;

		this.FilterForm = this.formBuilder.group({
			'ReportType': [0],
			'Name': [''],
			'Type': [0],
			'NrCode': [''],
			'Code': [''],
			'Sort': [0],
		});

		this.CreateTranslate = this.formBuilder.group({
			Type: [{value: null, disabled: true}],
			Code: ['', Validators.required],
			NrCode: ['', [Validators.required, Validators.pattern(/^-?[0-9][^\.]*$/)]],
			Name: ['', Validators.required],
			Description: ['', Validators.required],
			Myanmar: [''],
			My_Desc: [''],
			English: [''],
			En_Desc: [''],
		});

		this.EditForm = this.formBuilder.group({
			'Type': [{value: null, disabled: true}],
			'Key': ['', Validators.required],
			'Code': ['', Validators.required],
			'NrCode': ['', [Validators.required, Validators.pattern(/^-?[0-9][^\.]*$/)]],
			'Name': ['', Validators.required],
			'Description': ['', Validators.required],
			'Myanmar': [''],
			'My_Desc': [''],
			'English': [''],
			'En_Desc': [''],
		});

		this.getFirstDatas();
		// this.ChangePassword = new FormGroup({
		//     'Password': new FormControl('', [Validators.required, Validators.minLength(6)]),
		//     'RepeatPassword': new FormControl('', Validators.required),
		// });
		// for Status modal form
	}
}

import { Component, OnInit, ViewChild } from '@angular/core'
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms'
import { ProviderService } from '../../_services'
import { DateAdapter } from '@angular/material/core'
import { DatePipe } from '@angular/common'
import { AdministrationService } from '../../_services/administration.service'
import { ReportsDatasService } from '../../_services/reportsdata.service'
import { saveAs } from 'file-saver'
import { TranslateService } from '@ngx-translate/core'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { map, startWith, first } from 'rxjs/operators'
import { ToastrService } from 'ngx-toastr'
import { ProviderReportsDataService } from '../../_services/providerreportsdata.service'
export interface ItemList {
	Id: string
	Name: string
}
@Component({
	templateUrl: 'provider-transaction-report.component.html',
	styleUrls: ['../reports/reports.scss'],
	providers: [DatePipe],
})
export class ProviderTransactionReportComponent implements OnInit {
	constructor(
		//Services:
		public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private dateAdapter: DateAdapter<Date>,
		private administrationService: AdministrationService,
		private providerService: ProviderService,
		private reportsDatasService: ReportsDatasService,
		private providerReportsDataService: ProviderReportsDataService,
		private translate: TranslateService,
		public toastr: ToastrService
	) {
		dateAdapter.setLocale('en-EN') // change this for change format date in select
	}

	ngOnInit(): void {
		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTimeEnd, disabled: true },
				Validators.required
			),
			Channel: new UntypedFormControl(''),
			UserLogin: new UntypedFormControl(''),
			Account: new UntypedFormControl(''),
			PaymentType: new UntypedFormControl(
				this.filterPaymentType[0].Value
			),
			RefStan: new UntypedFormControl(''),
		})
		this.getSalePoint()
	}

	//Table data
	dataSource = new MatTableDataSource()
	secondTableSource = new MatTableDataSource()

	//Columns
	displayedColumns: string[] = [
		'UserName',
		'UserID',
		'City',
		'Address',
		'Institution',
		'CompanyName',
		'Info',
		'RegistrationDate',
		'PaymentType',
		'Account',
		'Currency',
		'Amount',
		'AmountTransfered',
		'Commission',
		'RefStan',
	]

	//Filters
	filterPaymentType = [
		{
			Name: 'All transactions',
			Value: '',
		},
		{
			Name: 'Cash',
			Value: '1',
		},
		{
			Name: 'Credit card',
			Value: '2',
		},
	]

	salePointsList: ItemList[]
	//Paging
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api

	public currentPage = 0
	@ViewChild(MatPaginator) paginator: MatPaginator

	//DateTime
	currentTime = new Date(new Date().setHours(0, 0, 0))
	currentTimeEnd = new Date(new Date().setHours(23, 59, 59))
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date

	loading = false
	isDownloadingFile: boolean = false
	pageEvent

	//Filter settings
	FilterForm: UntypedFormGroup // Form initial
	radioModel = 1
	activeFirstTab = true

	@ViewChild(MatSort) sort: MatSort

	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.onSubmit(this.radioModel)
	}

	collectFilterData() {
		let filterData = this.FilterForm.value

		let dateFrom = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let dateFinish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)

		let OUT = {
			DateFrom: dateFrom,
			DateTo: dateFinish,
			Channel: filterData.Channel,
			Period: this.radioModel,
			UserLogin: filterData.UserLogin,
			Account: filterData.Account,
			PaymentType: filterData.PaymentType,
			RefStan: filterData.RefStan,
			CurrentPage: this.currentPage + 1,
			ItemPerPage: this.paginationItemPerPage,
		}
		console.log('DateStart: ', dateFrom)
		console.log('DateFinish: ', dateFinish)
		console.log('Channel: ', OUT.Channel)
		return OUT
	}

	onSubmit(period?) {
		if (typeof period == 'number') {
			this.radioModel = period
		}

		this.loading = true
		let OUT = this.collectFilterData()

		this.providerReportsDataService.getList(OUT).subscribe(
			(data) => {
				console.log('Data: ', data)
				if (data.ResultCode === 0) {
					let serviceResponse = []
					let itemTotal = 0
					if (data.ReturnObject !== null) {
						console.log(data.ReturnObject)
						serviceResponse = data.ReturnObject.Transactions
						itemTotal = data.ReturnObject.PagingInfo.TotalItems
					}
					this.paginationItems = itemTotal
					this.dataSource = new MatTableDataSource(serviceResponse)
				}
				this.loading = false
			},
			(error) => {
				this.loading = false
				console.log('Error: ', error)
			}
		)
	}

	exportExcel(period?) {
		let OUT = this.collectFilterData()

		this.providerReportsDataService.exportExcel(OUT).subscribe(
			(data) => {
				console.log('Data: ', data)
				this.isDownloadingFile = false
				this.loading = false
				saveAs(data, OUT.DateTo + '.' + 'xlsx', {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				})
			},
			(error) => {
				console.log('Error: ', error)
			}
		)
	}

	getSalePoint() {
		this.administrationService
			.getSalePoints()
			.pipe(first())
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						this.salePointsList = data.ReturnObject.SalePoints
						if (this.salePointsList.length > 0) {
							this.salePointsList.unshift({ Id: '', Name: 'All' })
							this.FilterForm.patchValue({
								['Channel']: this.salePointsList[0].Id,
							})
						}
					}
				},
				(error) => {
					console.log(error)
				},
				() => {}
			)
	}
}

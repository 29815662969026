import { Component, OnInit, ViewChild } from '@angular/core'
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { ProviderService } from '../../_services'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'
import { DateAdapter } from '@angular/material/core'
import { MatSort } from '@angular/material/sort'

import { DatePipe } from '@angular/common'
import { AdministrationService } from '../../_services/administration.service'
import { ReportsDatasService } from '../../_services/reportsdata.service'
import { saveAs } from 'file-saver'
import { TranslateService } from '@ngx-translate/core'
import { NestedTreeControl } from '@angular/cdk/tree'
import { MatTreeNestedDataSource } from '@angular/material/tree'
import { Sort } from '@angular/material/sort'
import { KioskService } from '../../_services/kiosk.service'

/** Constants used to fill up our data base. */
interface DealerNode {
	name: string
	children?: DealerNode[]
}

export interface standartType {
	value: string
	viewValue: string
} // standart Interface

export interface ItemList {
	Value: string
	Name: string
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
}
@Component({
	templateUrl: 'kiosk-overview.component.html',
	styleUrls: ['../reports/reports.scss'],
	providers: [DatePipe],
})
export class KioskOverviewComponent implements OnInit {
	dataSource = new MatTableDataSource() // array of datas
	secondTableSource = new MatTableDataSource() // array of datas

	hasChild = (_: number, node: DealerNode) =>
		!!node.children && node.children.length > 0
	treeControl = new NestedTreeControl<DealerNode>((node) => node.children)
	dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
	treeData

	showTree: boolean = false
	currentDealer = {
		Name: '',
		Value: '',
	}
	growTree(arr) {
		let storageData = JSON.parse(sessionStorage.getItem('dealerTreeList'))
		this.dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
		let mainArray = arr
		if (!storageData) {
			mainArray.map((x) => {
				x.children = []
			})
			mainArray.map((z) => {
				if (z.Id !== 0) {
					let currentEl = mainArray.find((x) => x.Id === z.ParentId)
					if (currentEl) {
						currentEl.children.push(z)
					}
				}
			})
			let result = mainArray.splice(0, 1)
			this.treeData = result
			sessionStorage.setItem('dealerTreeList', JSON.stringify(result))
			this.dataTreeSource = result
		} else {
			this.dataTreeSource = storageData
		}
	}
	chooseDealer(smth) {
		this.CloseDialog()
		this.dealerFControl.patchValue(smth.Name)
		this.currentDealer = { Value: smth.Id, Name: smth.Name }
		this.showTree = false
		this.getSecondDatas()
	}
	stopClick(event) {
		event.preventDefault()
	}
	//----------------------- get a dealer list and put to option's
	InitDealerFilter() {
		this.administrationService
			.getDealerDatasAsList()
			.pipe(first())
			.subscribe(
				(data) => {
					this.treeData = data.ReturnObject
					this.dealerFControl.setValue(this.currentDealer.Name)
					this.growTree(data.ReturnObject)
				},
				(error) => {
					console.log(error)
				},
				() => {
					this.getSecondDatas(this.radioModel)
				}
			)
	}

	public totalAmountBody = 0
	public totalAmountFee = 0
	public totalAmountTotal = 0
	public totalRevenue = 0

	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	saleFilteredOptions: Observable<ItemList[]> // salePoints

	public transactionCurrentID
	public transactionCurrentDealerName
	public transactionCurrentServiceName
	public currentPage = 0
	isLoadingDetailInfo: boolean = false
	modalDatas = {} // for display datas in modal

	//-------------------------------------
	providersList
	isDownloadingFile: boolean = false
	Transactions_Datas
	dealerListOptions: ItemList[]
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	radioModel = 1
	services: ItemList[]

	account = '' // Account input
	refStan = '' // refStan input
	defaultServices: any
	serviceGroupSelected: any = '' // Service selected
	serviceSubSelected: any = ''
	serviceGroups: any = []
	servicesToShow: any = []
	userLogin = '' // User Login input

	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	dealerFControl = new UntypedFormControl() // Dealer
	saleListOptions: ItemList[]

	paidTypes: standartType[] = [
		{ value: 'all', viewValue: 'All transactions' },
		{ value: 'cash', viewValue: 'Cash' },
		{ value: 'ewallet', viewValue: 'E-Wallet' },
	] // Select Channel

	operations: standartType[] = [
		{ value: 'in', viewValue: 'Cash In' },
		{ value: 'out', viewValue: 'Cash Out' },
	] // Select Channel

	dealerFilteredOptions: Observable<ItemList[]> // dealer
	currentTime = new Date(new Date().setHours(0, 0, 0))
	currentTimeEnd = new Date(new Date().setHours(23, 59, 59))
	//----------------- list of columns
	displayedColumns: string[] = [
		'RefStan',
		'Status',
		'DealerName',
		'User',
		'CompanyName',
		'TrnDate',
		'Account',
		'Amount',
		'TransfAmount',
		'Currency',
		'Commision',
	]

	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private providerService: ProviderService,
		private administrationService: AdministrationService,
		public kioskService: KioskService,
		private dateAdapter: DateAdapter<Date>,
		private reportsDatasService: ReportsDatasService
	) {
		dateAdapter.setLocale('en-EN') // change this for change format date in select
	}

	FilterForm: UntypedFormGroup // Form initial

	@ViewChild(MatSort) sort: MatSort

	//----------------------- get a provider list and put to option's
	InitProviderFilter() {
		this.providerService.ProviderAllGroups().subscribe(
			(data) => {
				this.serviceGroups = data.map((x) => ({
					ID: x.ID.toString(),
					Name: x.Name,
				}))
			},
			(error) => {
				console.log(error)
			},
			() => {
				this.InitDealerFilter()
			}
		)
		this.providerService.AllList().subscribe(
			(data) => {
				this.defaultServices = data.map((x) => x)

				this.servicesToShow = this.defaultServices
			},
			(error) => {
				console.log(error)
			}
		)
	}
	activeFirstTab = true
	allGroupsSelected: boolean = false
	firstTimeGroups: boolean = true
	firstTimeServices: boolean = true
	allServicesSelected: boolean = true
	sortData(sort: Sort) {
		const data = this.currentSecondData.slice()
		if (!sort.active || sort.direction === '') {
			this.currentSecondData = data
			return
		}

		this.currentSecondData = data.sort((a, b) => {
			const isAsc = sort.direction === 'asc'
			if (sort.active.slice(-1) !== 'e') {
				let compareIndex = sort.active.match(/\d+/)[0]
				return compare(
					Number(a.ls[compareIndex]),
					Number(b.ls[compareIndex]),
					isAsc
				)
			} else {
				return compare(a.dname, b.dname, isAsc)
			}
		})
	}
	groupsClicked() {
		if (this.firstTimeGroups) {
			let allIDs = this.serviceGroups.map((x) => x.ID)
			this.allGroupsSelected = true
			this.serviceGroupSelected = allIDs
		}
	}
	serviceSubClicked() {
		if (this.firstTimeServices) {
			let allIDs = this.servicesToShow.map((x) => x.ID)
			this.allServicesSelected = true
			this.serviceSubSelected = allIDs
			this.firstTimeServices = false
		}
	}
	deselectorGroups() {
		let allIDs = this.serviceGroups.map((x) => x.ID)

		if (this.allGroupsSelected) {
			this.serviceGroupSelected = []
		} else {
			this.serviceGroupSelected = allIDs
			this.serviceHandler()
			setTimeout(() => {
				this.FilterForm.value.ServiceDrop = ''
			}, 0)
			this.firstTimeGroups = false
		}

		this.allGroupsSelected = !this.allGroupsSelected
	}
	deselectorServices() {
		let allIDs = this.servicesToShow.map((x) => x.ID)
		if (this.allServicesSelected) {
			this.serviceSubSelected = []
		} else {
			this.serviceSubSelected = allIDs
			setTimeout(() => {
				this.FilterForm.value.ServiceSub = ''
			}, 0)
		}
		this.allServicesSelected = !this.allServicesSelected
	}
	serviceHandler() {
		this.serviceSubSelected = []
		this.servicesToShow = this.defaultServices.filter((x) => {
			return this.serviceGroupSelected.includes(String(x.GroupID))
		})
		this.serviceSubSelected = this.servicesToShow.map((x) => x.ID)
		this.firstTimeServices = true
	}

	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getSecondDatas(this.radioModel)
	}

	displaySale(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}

	private sale_filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.saleListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}

	maxColumns = 3
	columnArray: any = []
	displayedSecondColumns: string[] = []
	currentSecondData: any[any] = []
	currentSecondDataTotal = { dname: '', ls: [] }
	displayedCompanyColumns = []
	checkDivide(number) {
		return number % 3 == 0
			? 'pivot_col_color_id_' + (number % 3) + ' border-it'
			: 'pivot_col_color_id_' + (number % 3)
	}
	columnTextColor(number) {
		return 'pivot_col_tcolor_id_' + (number % 3)
	}

	getSecondDatas(period?) {
		if (typeof period === 'number') {
			this.radioModel = period
		}

		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		// Take datas from form
		let dealerId = this.currentDealer.Value
		let filter = this.FilterForm.value
		let serviceNow =
			this.serviceSubSelected[0] !== '' ? this.serviceSubSelected : ''
		this.kioskService
			.getKioskPivot(
				start,
				finish,
				this.radioModel,
				filter,
				serviceNow,
				dealerId
			)
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.currentSecondData = data.ReturnObject.rows
							this.currentSecondData.forEach((el) => {
								if (el.dname === 'TOTAL') {
									this.currentSecondDataTotal = {
										dname: el.dname,
										ls: el.ls,
									}
								}
							})
							this.currentSecondData =
								this.currentSecondData.slice(0, -1)
							this.displayedCompanyColumns = []
							this.displayedSecondColumns = []
							for (
								let i = 0;
								i < data.ReturnObject.srvs.length;
								i++
							) {
								this.displayedCompanyColumns.push(
									data.ReturnObject.srvs[i].name
								)
								this.displayedSecondColumns.push('1')
								this.displayedSecondColumns.push('2')
								this.displayedSecondColumns.push('3')
							}
						} else {
							console.log('ITS NULL DATA!')
						}
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}
	download(format) {
		this.isDownloadingFile = true
		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let dealerId = this.currentDealer.Value
		let serviceNow =
			this.serviceSubSelected[0] !== '' ? this.serviceSubSelected : ''
		this.kioskService
			.getFile(
				start,
				finish,
				this.radioModel,
				this.FilterForm.value,
				serviceNow,
				dealerId,
				this.currentPage + 1,
				this.paginationItemPerPage,
				format
			)
			.subscribe(
				(res) => {
					this.isDownloadingFile = false
					this.loading = false
					saveAs(res, finish + '.' + format, {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
				},
				(error) => {
					console.log(error)
					this.loading = false
					this.isDownloadingFile = false
				}
			)
	}
	onSubmit() {
		this.getSecondDatas(this.radioModel)
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}

	private _filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.dealerListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}

	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}
	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
			minHeight: '580px',
		})
	}
	selectRow(templateRef, row) {
		this.isLoadingDetailInfo = true
		this.reportsDatasService
			.getAdvanceTransaction(row.RefStan)
			.pipe(first())
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas = data.ReturnObject
							this.isLoadingDetailInfo = false
						} else {
							this.isLoadingDetailInfo = false
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					this.isLoadingDetailInfo = false
					console.log(error)
				}
			)
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}

	ngOnInit() {
		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			Dealer: new UntypedFormControl('', Validators.required),
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTimeEnd, disabled: true },
				Validators.required
			),
			Account: new UntypedFormControl(''),
			RefStan: new UntypedFormControl(''),
			UserName: new UntypedFormControl(''),
			ServiceDrop: new UntypedFormControl(''),
			ServiceSub: new UntypedFormControl(''),
			ChannelDrop: new UntypedFormControl(''),
			PaidTypeDrop: new UntypedFormControl(''),
			OperationDrop: new UntypedFormControl(''),
		})
		this.InitProviderFilter()
	}
}

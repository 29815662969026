import { Component, OnInit, ViewChild } from '@angular/core'
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first, expand } from 'rxjs/operators'
import { ProviderService } from '../../_services'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { DatePipe } from '@angular/common'
import { saveAs } from 'file-saver'
import { AdministrationService } from '../../_services/administration.service'
import { ReportsDatasService } from '../../_services/reportsdata.service'
import { TranslateService } from '@ngx-translate/core'
import { NestedTreeControl } from '@angular/cdk/tree'
import { MatTreeNestedDataSource } from '@angular/material/tree'

interface DealerNode {
	name: string
	children?: DealerNode[]
}
export interface ItemList {
	Value: string
	Name: string
}
export interface Interaction {
	string: {
		Key: any
		Ident: any
		ActionType: any
		ActionName: any
		SystemType: any
		Info: any
		ExecutingTime: any
		Reference: any
		LogType: any
		ResponseMessage: any
		ResponseCode: any
		Serviceid: any
		DateRegistered: any
	}
}
@Component({
	templateUrl: 'system-request.component.html',
	styleUrls: ['reports.scss'],
	providers: [DatePipe],
})
export class SystemRequestComponent implements OnInit {
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	isDownloadingFile: boolean = false

	public transactionCurrentID
	public transactionCurrentDealerName
	public transactionCurrentServiceName
	public currentPage = 0
	isLoadingDetailInfo: boolean = false
	modalDatas = {
		Request: {},
		Response: {},
	} // for display datas in modal

	//-------------------------------------
	providersList
	Transactions_Datas
	dealerListOptions: ItemList[]
	resCodesOptions: ItemList[]
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	// For change pages

	services: ItemList[]

	account = '' // Account input
	refStan = '' // refStan input
	serviceSelected = '' // Service selected
	methodSelected = ''
	userLogin = '' // User Login input

	radioModel = 1
	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	dealerFControl = new UntypedFormControl() // Dealer

	dealerFilteredOptions: Observable<ItemList[]> // dealer
	resCodesList: Observable<ItemList[]> // dealer

	currentTime = new Date(new Date().setHours(0, 0, 0))
	currentTimeEnd = new Date(new Date().setHours(23, 59, 59))
	//----------------- list of columns
	displayedColumns: string[] = [
		'ExternalId',
		'RefStan',
		'ResponseCode',
		'UserName',
		'MethodType',
		'CompanyId',
		'Account',
		'Amount',
		'ExternalDate',
		'RegDate',
	]

	dataSource = new MatTableDataSource() // array of datas

	hasChild = (_: number, node: DealerNode) =>
		!!node.children && node.children.length > 0
	treeControl = new NestedTreeControl<DealerNode>((node) => node.children)
	dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
	treeData

	showTree: boolean = false
	currentDealer = {
		Name: '',
		Value: '',
	}

	growTree(arr) {
		let storageData = JSON.parse(sessionStorage.getItem('dealerTreeList'))
		this.dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
		let mainArray = arr
		if (!storageData) {
			mainArray.map((x) => {
				x.children = []
			})
			mainArray.map((z) => {
				if (z.Id !== 0) {
					let currentEl = mainArray.find((x) => x.Id === z.ParentId)
					if (currentEl) {
						currentEl.children.push(z)
					}
				}
			})
			let result = mainArray.splice(0, 1)
			this.treeData = result
			this.dataTreeSource = result
		} else {
			this.dataTreeSource = storageData
		}
	}
	chooseDealer(smth) {
		this.CloseDialog()
		this.dealerFControl.patchValue(smth.Name)
		this.currentDealer = { Value: smth.Id, Name: smth.Name }
		this.showTree = false
		this.getFirstDatas()
	}
	stopClick(event) {
		event.preventDefault()
	}
	//----------------------- get a dealer list and put to option's
	InitDealerFilter() {
		this.administrationService
			.getDealerDatasAsList()
			.pipe(first())
			.subscribe(
				(data) => {
					this.treeData = data.ReturnObject
					this.dealerFControl.setValue(this.currentDealer.Name)
					this.growTree(data.ReturnObject)
				},
				(error) => {
					console.log(error)
				},
				() => {
					this.getFirstDatas(this.radioModel)
				}
			)
	}

	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
			minHeight: '580px',
		})
	}
	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private reportsDataService: ReportsDatasService,
		private providerService: ProviderService,
		private translate: TranslateService,
		private administrationService: AdministrationService
	) {}

	FilterForm: UntypedFormGroup // Form initial

	checkIntBlur(target) {
		let targetV = target.value.replace(/[^0-9]/g, '')
		this.FilterForm.controls.RefStan.setValue(targetV)
	}

	methods = [
		{
			Name: 'All',
			Value: '',
		},
		/* {
      Name: "CheckPayment",
      Value: "CheckPayment"
    },
    {
      Name: "ExecutePayment",
      Value: "ExecutePayment"
    },*/
		{
			Name: 'GetServerDateTime',
			Value: 'GetServerDateTime',
		},
		{
			Name: 'KioskIdentifyGet',
			Value: 'KioskIdentifyGet',
		},
		{
			Name: 'CheckPayment',
			Value: 'CheckPayment',
		},
		{
			Name: 'ExecutePayment',
			Value: 'ExecutePayment',
		},
		{
			Name: 'GetCompanyInfo',
			Value: 'GetCompanyInfo',
		},
		{
			Name: 'GetTransaction',
			Value: 'GetTransaction',
		},
		{
			Name: 'GetTransactionReport',
			Value: 'GetTransactionReport',
		},
	]

	InitMethodFilter() {
		let _text = ''
		this.translate
			.get('SYSRQ.FILTER_METHOD_ALL')
			.subscribe((text) => (this.methods[0].Name = text))
		/*
    this.translate
      .get("SYSRQ.FILTER_METHOD_CHECK")
      .subscribe(text => (_text = text));

    let _vl = _text.split('|');
    this.methods[1].Name = _vl[0];
    
    this.translate
      .get("SYSRQ.FILTER_METHOD_PAY")
      .subscribe(text => (this.methods[2].Name = text));
      */
		this.translate
			.get('SYSRQ.FILTER_METHOD_GETSDATE')
			.subscribe((text) => (this.methods[1].Name = text))
		this.translate
			.get('SYSRQ.FILTER_METHOD_GETKIOSK')
			.subscribe((text) => (this.methods[2].Name = text))
		this.translate
			.get('SYSRQ.FILTER_METHOD_CHECKP')
			.subscribe((text) => (this.methods[3].Name = text))
		this.translate
			.get('SYSRQ.FILTER_METHOD_EXECP')
			.subscribe((text) => (this.methods[4].Name = text))
		this.translate
			.get('SYSRQ.FILTER_METHOD_GETCOMP')
			.subscribe((text) => (this.methods[5].Name = text))
		this.translate
			.get('SYSRQ.FILTER_METHOD_GETTRANS')
			.subscribe((text) => (this.methods[6].Name = text))
		this.translate
			.get('SYSRQ.FILTER_METHOD_GETTRANSREP')
			.subscribe((text) => (this.methods[7].Name = text))
	}
	InitProviderFilter() {
		let p = localStorage.getItem('providerAll')
		if (p === null) {
			this.providerService
				.AllList()
				.pipe(first())
				.subscribe(
					(data) => {
						localStorage.setItem(
							'providerAll',
							JSON.stringify(data)
						)
						this.services = data.map((x) => ({
							Value: x.ID.toString(),
							Name: x.Name,
						}))
						this.services.unshift({ Value: '', Name: 'All' })
					},
					(error) => {
						console.log(error)
					},
					() => {
						this.InitDealerFilter()
						this.InitMethodFilter()
					}
				)
		} else {
			let prvs = JSON.parse(p)
			this.services = prvs.map((x) => ({
				Value: x.ID.toString(),
				Name: x.Name,
			}))
			this.services.unshift({ Value: '', Name: 'All' })
			this.InitDealerFilter()
			this.InitMethodFilter()
		}
		this.InitResCodesFilter()
	}

	InitResCodesFilter() {
		this.reportsDataService.getAllResCodes().subscribe(
			(data) => {
				this.resCodesOptions = data.ReturnObject.map((x) => ({
					Value: x.Key,
					Name: x.Desc,
				}))
				this.translate.get('REPORT.RESCODES_ALL').subscribe((text) => {
					this.resCodesOptions.unshift({ Value: '', Name: text })
				})
			},
			(error) => {
				console.log(error)
			},
			() => {
				this.getFirstDatas(this.radioModel)
			}
		)
	}
	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas(this.radioModel)
	}

	getFirstDatas(period?) {
		if (typeof period === 'number') {
			this.radioModel = period
		}

		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let dealerId = this.currentDealer.Value
		let filter = this.FilterForm.value
		this.reportsDataService
			.getSystemDatas(
				start,
				finish,
				this.radioModel,
				filter,
				filter.ResCodes,
				dealerId,
				this.currentPage + 1,
				this.paginationItemPerPage
			)
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						console.log('First datas:', data)
						let datas = []
						let itemTotal = 0
						if (data.ReturnObject !== null) {
							datas = data.ReturnObject.Items
							itemTotal = data.ReturnObject.Pagination.ItemTotal
						}
						this.paginationItems = itemTotal
						this.dataSource = new MatTableDataSource(datas) // array of datas
					} else {
						this.dataSource = new MatTableDataSource([])
						this.paginationItems = 0
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	displayDealer(item?: ItemList): string {
		return item ? item.Name : ''
	}
	displayResCodes(item?: ItemList): string {
		return item ? item.Name : ''
	}
	private _filter(item: string): ItemList[] {
		const filterDValue = item.toLowerCase()
		return this.dealerListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterDValue) === 0
		)
	}
	private _filterRes(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.resCodesOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}
	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}
	isLoadingTabInfo: boolean = false
	panelClosed: boolean = true

	sysInterctionData: []
	sysResponseData: []
	getSysResponse(event, refId) {
		this.panelClosed = true
		if (event.index === 3) {
			this.isLoadingTabInfo = true
			this.reportsDataService.getSystemTransaction(refId).subscribe(
				(res) => {
					if (res['ResultCode'] === 0) {
						if (res['ReturnObject'] !== null) {
							this.sysResponseData = res['ReturnObject'].Items
							this.isLoadingTabInfo = false
						} else {
							this.isLoadingTabInfo = false
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					this.isLoadingTabInfo = false
					console.log(error)
				}
			)
		} else if (event.index === 2) {
			if (this.sysInterctionData.length == 0) {
				this.isLoadingInterInfo = true
				this.reportsDataService.getSystemInteraction(refId).subscribe(
					(res) => {
						if (res['ResultCode'] === 0) {
							if (res['ReturnObject'] !== null) {
								this.sysInterctionData =
									res['ReturnObject'].Items
								this.isLoadingInterInfo = false
							} else {
								this.isLoadingInterInfo = false
							}
						} else {
							this.CloseDialog()
						}
					},
					(error) => {
						this.isLoadingInterInfo = false
						console.log(error)
					}
				)
			}
		}
	}
	interLogData = {}
	isLoadingInterInfo: boolean = false
	getInterLog(event, key, index) {
		index = index.toString()
		if (!this.interLogData.hasOwnProperty(index)) {
			this.reportsDataService.getSystemInteractionLog(key).subscribe(
				(res) => {
					if (res['ResultCode'] === 0) {
						if (res['ReturnObject'] !== null) {
							this.interLogData[index] =
								res['ReturnObject'].Details
						} else {
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					console.log(error)
				}
			)
			this.panelClosed = false
		} else {
			console.log('IT HAS!')
		}
	}
	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}

	download(format) {
		this.isDownloadingFile = true
		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let dealerId = this.currentDealer.Value

		this.reportsDataService
			.getSystemRequestFile(
				start,
				finish,
				this.radioModel,
				this.FilterForm.value,
				this.FilterForm.value.ResCodes,
				dealerId,
				format
			)
			.subscribe(
				(res) => {
					this.isDownloadingFile = false
					this.loading = false
					saveAs(res, finish + '.' + format, {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
				},
				(error) => {
					console.log(error)
					this.loading = false
					this.isDownloadingFile = false
				}
			)
	}

	selectRow(templateRef, key) {
		this.isLoadingDetailInfo = true
		this.sysResponseData = []
		this.sysInterctionData = []
		this.interLogData = {}
		this.reportsDataService.getAdvanceSystem(key).subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					if (data.ReturnObject !== null) {
						this.modalDatas = data.ReturnObject
						this.isLoadingDetailInfo = false
						console.log(this.modalDatas)
					} else {
						this.isLoadingDetailInfo = false
					}
				} else {
					this.CloseDialog()
				}
			},
			(error) => {
				this.isLoadingDetailInfo = false
				console.log(error)
			}
		)
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}

	ngOnInit() {
		this.paginationItemPerPage = 10

		this.FilterForm = new UntypedFormGroup({
			Dealer: new UntypedFormControl(),
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTimeEnd, disabled: true },
				Validators.required
			),
			ExternalId: new UntypedFormControl(''),
			RefStan: new UntypedFormControl(''),
			ServiceDrop: new UntypedFormControl(''),
			Method: new UntypedFormControl(''),
			Account: new UntypedFormControl(''),
			UserLogin: new UntypedFormControl(''),
			ResCodes: new UntypedFormControl(''),
		})
		this.InitProviderFilter()
	}
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, first } from 'rxjs/operators';
import { MatPaginator  } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';


import { AdministrationService } from '../../_services/administration.service';
import { DatePipe } from '@angular/common';
import { PaymentService } from '../../_services';
/** Constants used to fill up our data base. */

export interface ItemList {
	Value: string;
	Name: string;
}


@Component({
	templateUrl: 'payment-cancel.component.html',
	styleUrls: ['payment-cancel.scss'],
	providers: [DatePipe]
})
export class PaymentCancelComponent implements OnInit {
	public totalAmountBody = 0; // for display total 
	public totalAmountFee = 0; // for display total 
	public totalAmountTotal = 0; // for display total

	public paginationItems; // for display pagination from api
	public paginationItemPerPage; // for display pagination from api

	public currentPage = 0;
	isLoadingDetailInfo: boolean = true; // spinner


	//-------------------------------------
	Transactions_Datas;
	dealerListOptions: ItemList[]; // dealer List
	loading = false; // for disable fast response click
	pageEvent; // for fix error of pageEvent in Visual Studio
	// For change pages

	minDate = new Date(2000, 0, 1); // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
	events: string[] = []; // Date picker
	dealerFControl = new UntypedFormControl(); // Dealer


	dealerFilteredOptions: Observable<ItemList[]>; // dealer

	currentTime = new Date();
	//----------------- list of columns 
	displayedColumns: string[] = ['Id', 'DealerName', 'UserLogin', 'ServiceName', 'Status', 'AmountBody', 'AmountFee', 'AmountSum', 'Currency', 'TransactionDate'];

	dataSource = new MatTableDataSource(); // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator;

	constructor(public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private paymentService: PaymentService,
		private administrationService: AdministrationService,
	) {

	}

	FilterForm: UntypedFormGroup; // Form initial
	//dealerDropValidator(controls): ValidatorFn {
	//    return (control: AbstractControl): { [key: string]: boolean } | null => {
	//        if ((control.value.length > 0) && (controls.indexOf(control.value) < 0)) {
	//            return { 'dealer': true };
	//        }
	//        return null;
	//    };
	//}
	ngOnInit() {

		this.paginationItemPerPage = 10;

		this.InitDealerFilter();

		this.FilterForm = new UntypedFormGroup({
			'Dealer': new UntypedFormControl(), //, [this.dealerDropValidator(this.options)]),
			'dateFrom': new UntypedFormControl({ value: this.currentTime, disabled: true }, Validators.required),
			'dateTo': new UntypedFormControl({ value: this.currentTime, disabled: true }, Validators.required),
		});
		this.getFirstDatas();
	}
	//----------------------  
	public handlePage(e: any) {
		this.currentPage = e.pageIndex;
		this.paginationItemPerPage = e.pageSize;
		this.getFirstDatas();

	}
	// function for change tables page

	//----------------------- get a dealer list and put to option's 
	InitDealerFilter() {
		this.administrationService.getDealerDatasAsList().pipe(first())
			.subscribe(
				data => {
					this.dealerListOptions = data.ReturnObject.DmsDealerList.map(x => ({ Value: x.DealerId.toString(), Name: x.DealerName }));
					this.dealerListOptions.unshift({ Value: '2', Name: 'All' });
					this.dealerFilteredOptions = this.dealerFControl.valueChanges
						.pipe(
							startWith<string | ItemList>(''),
							map(value => typeof value === 'string' ? value : value.Name),
							map(name => name ? this._filter(name) : this.dealerListOptions.slice())
						);//dealer
					this.dealerFControl.setValue(this.dealerListOptions[0]);
				},
				error => {
					console.log(error);
				});

	}

	getFirstDatas() {
		this.loading = true;
		let start = this.datePipe.transform(this.FilterForm.controls.dateFrom.value, 'yyyy-MM-ddT00:00:00');
		let finish = this.datePipe.transform(this.FilterForm.controls.dateTo.value, 'yyyy-MM-ddT23:59:59');
		// Take datas from form
		let dealerId;

		if ((typeof (this.dealerFControl.value) === 'string') || (this.dealerFControl.value === null)) {
			dealerId = '2';
		}
		else {
			dealerId = this.dealerFControl.value.Value;
		}

		let currentPage = this.currentPage + 1;

		this.paymentService.getCancelPayments(dealerId, start, finish, currentPage, this.paginationItemPerPage)
			.pipe(first())
			.subscribe(
				data => {
					console.log(data);
					if (data.ResultCode === 0) {
						let obj = data.ReturnObject;
						if (obj !== null) {
							console.log(obj);
							this.Transactions_Datas = obj.Items;
							this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas
							this.totalAmountBody = obj.Total.AmountBody;
							this.totalAmountFee = obj.Total.AmountFee;
							this.totalAmountTotal = obj.Total.AmountTotal;
							this.paginationItems = obj.Pagination.ItemTotal;
						}
						else {
							this.paginationItems = 0;
							this.Transactions_Datas = [];
							this.dataSource = new MatTableDataSource(this.Transactions_Datas); // array of datas	
						}
					}
					this.loading = false;
				},
				error => {
					this.loading = false;
					console.log(error);
				});
	}
	onSubmit() {
		this.getFirstDatas();
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined;
	}

	private _filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase();
		return this.dealerListOptions.filter(option => option.Name.toLowerCase().indexOf(filterValue) === 0);
	}
	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0;
	}
	//------------------- private helpers 
	// convenience getter for easy access to form fields
	get f() { return this.FilterForm.controls; }


	modalDatas = {};// for display datas in modal

}
import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

// Import Containers
import { DefaultLayoutComponent } from './containers'

import { P404Component } from './views/error/404.component'
import { P500Component } from './views/error/500.component'
import { LoginComponent } from './views/login/login.component'
import { RegisterComponent } from './views/register/register.component'

import { AuthGuard } from './_guards'
import { ChangePasswordComponent } from './views/settings/changepassword.component'
import { PaymentPageComponent } from './views/payments/payment-page.component'
import { TransactionReportComponent } from './views/reports/transaction-report.component'
import { PaymentStepperComponent } from './views/payments/payment-stepper.component'
import { SystemRequestComponent } from './views/reports/system-request.component'
import { SystemErrorsComponent } from './views/reports/system-errors.component'
import { ProblemPaymentsComponent } from './views/reports/problem-payments.component'
import { UsersComponent } from './views/administation/users.component'
import { DepositOperationsComponent } from './views/reports/deposit-operations.component'
import { PaymentCancelComponent } from './views/payments/payment-cancel.component'
import { PaymentCashlessComponent } from './views/payments/payment-cashless.component'
import { RoleGuard } from './_guards/role.guard'
import { TranslateComponent } from './views/administation/translate.component'
import { DealersComponent } from './views/administation/dealers.component'
import { LicenseComponent } from './views/administation/license.component'
import { LicenseEditComponent } from './views/administation/license-edit.component'

import { RolesReportComponent } from './views/administation/roles-report.component'
import { CompanyComponent } from './views/administation/company.component'
import { CompanyEditComponent } from './views/administation/company-edit.component'
import { ProfileComponent } from './views/settings/profile.component'
import { KioskMonitoringComponent } from './views/kiosk/kiosk-monitoring.component'
import { KioskOverviewComponent } from './views/kiosk/kiosk-overview.component'
import { KioskEncashmentComponent } from './views/kiosk/kiosk-encashment.component'
import { KioskCreditCardComponent } from './views/kiosk/kiosk-credit-card.component'
import { KioskReconciliationComponent } from './views/kiosk/kiosk-reconciliation.component'
import { KioskCashRestComponent } from './views/kiosk/kiosk-cashrest.component'

import { PayOperationsComponent } from './views/pay/pay-operations.component'
import { PayCheckoutComponent } from './views/pay/pay-checkout.component'
import { DailyReportComponent } from './views/provider/daily-report.component'
import { ProviderTransactionReportComponent } from './views/provider/provider-transaction-report.component'
import { InvoiceMonitoringComponent } from './views/tools/invoice-monitoring.component'
import { InvoiceMonitoringHistoryComponent } from './views/tools/history.component'
import { InvoiceMonitoringUploadComponent } from './views/tools/upload.component'

export const routes: Routes = [
	{
		path: 'settings',
		redirectTo: 'change-password',
	},
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
		data: {
			title: 'Dashboard',
		},
	},
	{
		path: '404',
		component: P404Component,
		data: {
			title: 'Page 404',
		},
	},
	{
		path: '500',
		component: P500Component,
		data: {
			title: 'Page 500',
		},
	},
	{
		path: 'login',
		component: LoginComponent,
		data: {
			title: 'Login Page',
		},
	},
	{
		path: 'register',
		component: RegisterComponent,
		data: {
			title: 'Register Page',
		},
	},
	{
		path: '',
		component: DefaultLayoutComponent,
		data: {
			title: 'Home',
		},
		children: [
			{
				path: 'translate-system',
				component: TranslateComponent,
				canActivate: [AuthGuard],
				data: {
					pathCheck: 'translate_system',
					title: 'System Translating',
				},
			},
			{
				path: 'change-password',
				component: ChangePasswordComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Change Password',
				},
			},
			// {
			//     path: 'kiosk',
			//     canActivate: [AuthGuard],
			//     data: {
			//         title: 'kiosk'
			//     },
			// },
			{
				path: 'kiosk-monitoring',
				canActivate: [AuthGuard],
				component: KioskMonitoringComponent,
				data: {
					pathCheck: 'kiosk_monitoring',
					title: 'Kiosk monitoring',
				},
			},
			{
				path: 'kiosk-overview',
				canActivate: [AuthGuard],
				component: KioskOverviewComponent,
				data: {
					pathCheck: 'kiosk_monitoring',
					title: 'Kiosk Overview',
				},
			},
			{
				path: 'kiosk-encashment',
				canActivate: [AuthGuard],
				component: KioskEncashmentComponent,
				data: {
					pathCheck: 'kiosk_monitoring',
					title: 'Kiosk Encashment',
				},
			},
			{
				path: 'kiosk-credit-card',
				canActivate: [AuthGuard],
				component: KioskCreditCardComponent,
				data: {
					pathCheck: 'kiosk_monitoring',
					title: 'Kiosk Credit Card',
				},
			},
			{
				path: 'kiosk-reconciliation',
				canActivate: [AuthGuard],
				component: KioskReconciliationComponent,
				data: {
					pathCheck: 'kiosk_monitoring',
					title: 'Kiosk Reconciliation',
				},
			},
			{
				path: 'kiosk-cashrest',
				canActivate: [AuthGuard],
				component: KioskCashRestComponent,
				data: {
					pathCheck: 'kiosk_monitoring',
					title: 'Kiosk CashRest',
				},
			},
			{
				path: 'pay-operations',
				canActivate: [AuthGuard],
				component: PayOperationsComponent,
				data: {
					pathCheck: 'pay_operations',
					title: 'Pay Operations',
				},
			},
			{
				path: 'pay-checkout',
				canActivate: [AuthGuard],
				component: PayCheckoutComponent,
				data: {
					pathCheck: 'pay_operations',
					title: 'Pay Checkout',
				},
			},
			{
				path: 'dealers',
				canActivate: [AuthGuard],
				component: DealersComponent,
				data: {
					pathCheck: 'dealers',
					title: 'Dealer',
				},
			},
			{
				path: 'license',
				canActivate: [AuthGuard],
				component: LicenseComponent,
				data: {
					//  pathCheck: "license",
					title: 'Licenses',
				},
			},
			{
				path: 'license-edit',
				redirectTo: 'license',
			},
			{
				path: 'license-edit/:id',
				canActivate: [AuthGuard],
				component: LicenseEditComponent,
				data: {
					title: 'License Page',
				},
			},
			{
				path: 'users',
				canActivate: [AuthGuard],
				component: UsersComponent,
				data: {
					pathCheck: 'users',
					title: 'Users',
				},
			},
			{
				path: 'company',
				canActivate: [AuthGuard],
				component: CompanyComponent,
				data: {
					pathCheck: 'providers',
					title: 'Service Provider',
				},
			},
			{
				path: 'company/:id',
				canActivate: [AuthGuard],
				component: CompanyEditComponent,
				data: {
					pathCheck: 'providers',
					title: 'Company Edit',
				},
			},
			{
				path: 'company-new',
				canActivate: [AuthGuard],
				component: CompanyEditComponent,
				data: {
					pathCheck: 'providers',
					title: 'Company New',
				},
			},
			{
				path: 'payment',
				canActivate: [AuthGuard],
				component: PaymentPageComponent,
				data: {
					pathCheck: 'payment',
					title: 'Payment Page',
				},
			},
			{
				path: 'payment/:id',
				canActivate: [AuthGuard],
				component: PaymentStepperComponent,
				data: {
					title: 'Payment Stepper Page',
				},
			},
			{
				path: 'payment-cashless',
				canActivate: [AuthGuard],
				component: PaymentCashlessComponent,
				data: {
					pathCheck: 'payment_cashless',
					title: 'Payment Cashless',
				},
			},
			{
				path: 'payment-cancel',
				canActivate: [AuthGuard],
				component: PaymentCancelComponent,
				data: {
					pathCheck: 'payment_cancel',
					title: 'Cancel Request',
				},
			},
			{
				path: 'transaction-report',
				canActivate: [AuthGuard],
				component: TransactionReportComponent,
				data: {
					pathCheck: 'transaction_report',
					title: 'Transaction Report',
				},
			},
			{
				path: 'daily-report',
				canActivate: [AuthGuard],
				component: DailyReportComponent,
				data: {
					pathCheck: 'provider_report',
					title: 'Daily Report',
				},
			},
			{
				path: 'provider-transaction-report',
				canActivate: [AuthGuard],
				component: ProviderTransactionReportComponent,
				data: {
					pathCheck: 'provider_report',
					title: 'Provider Transaction Report',
				},
			},
			{
				path: 'invoice-monitoring',
				canActivate: [AuthGuard],
				component: InvoiceMonitoringComponent,
				data: {
					pathCheck: 'invoice_monitoring',
					title: 'Invoice Monitoring',
				},
			},
			{
				path: 'invoice-history/:id',
				canActivate: [AuthGuard],
				component: InvoiceMonitoringHistoryComponent,
				data: {
					pathCheck: 'invoice_monitoring',
					title: 'History',
				},
			},
			{
				path: 'invoice-upload',
				canActivate: [AuthGuard],
				component: InvoiceMonitoringUploadComponent,
				data: {
					pathCheck: 'invoice_monitoring',
					title: 'Upload',
				},
			},
			{
				path: 'deposit-operations',
				canActivate: [AuthGuard],
				component: DepositOperationsComponent,
				data: {
					pathCheck: 'deposit_operations',
					title: 'Deposit Operations',
				},
			},
			{
				path: 'system-request',
				canActivate: [AuthGuard],
				component: SystemRequestComponent,
				data: {
					pathCheck: 'system_request',
					title: 'System Request',
				},
			},
			{
				path: 'system-errors',
				canActivate: [AuthGuard],
				component: SystemErrorsComponent,
				data: {
					//pathCheck: 'system_errors',
					title: 'System Errors',
				},
			},
			{
				path: 'roles-report',
				component: RolesReportComponent,
				canActivate: [AuthGuard],
				data: {
					title: 'Roles Report',
					pathCheck: 'roles_report',
				},
			},
			{
				path: 'profile',
				canActivate: [AuthGuard],
				component: ProfileComponent,
				data: {
					title: 'Profile Page',
				},
			},
			{
				path: 'problem-payments',
				canActivate: [AuthGuard],
				component: ProblemPaymentsComponent,
				data: {
					pathCheck: 'problem_payments',
					title: 'Problem Payments',
				},
			},
			{
				path: 'dashboard',
				loadChildren: () =>
					import('./views/dashboard/dashboard.module').then(
						(m) => m.DashboardModule
					),
				canActivate: [AuthGuard],
				data: {
					title: 'Dashboard',
				},
			},

			{
				path: 'notifications',
				loadChildren: () =>
					import('./views/notifications/notifications.module').then(
						(m) => m.NotificationsModule
					),
				canActivate: [AuthGuard],
			},
		],
	},
]

export const routing = RouterModule.forRoot(routes)

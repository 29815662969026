import { Component, OnInit, ViewChild } from '@angular/core'
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'
import { ProviderService } from '../../_services'
import { MatPaginator } from '@angular/material/paginator'
import { DateAdapter } from '@angular/material/core'
import { MatDialog } from '@angular/material/dialog'
import { MatTableDataSource } from '@angular/material/table'
import { ReportsDatasService } from '../../_services/reportsdata.service'
import { AdministrationService } from '../../_services/administration.service'
import { DatePipe } from '@angular/common'
import { TranslateService } from '@ngx-translate/core'
import { NestedTreeControl } from '@angular/cdk/tree'
import { MatTreeNestedDataSource } from '@angular/material/tree'
import { ToastrService } from 'ngx-toastr'
import * as SecureLS from 'secure-ls'

interface DealerNode {
	name: string
	children?: DealerNode[]
}
export interface ItemList {
	Value: string
	Name: string
}

@Component({
	templateUrl: 'problem-payments.component.html',
	styleUrls: ['reports.scss'],
	providers: [DatePipe],
})
export class ProblemPaymentsComponent implements OnInit {
	constructor(
		public dialog: MatDialog,
		private translate: TranslateService,
		// Make service for post api datas
		private datePipe: DatePipe,
		private reportsDataService: ReportsDatasService,
		private providerService: ProviderService,
		private administrationService: AdministrationService,
		private dateAdapter: DateAdapter<Date>,
		public toastr: ToastrService
	) {}
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api

	public transactionCurrentID
	public transactionCurrentDealerName
	public transactionCurrentServiceName
	public currentPage = 0
	isLoadingDetailInfo: boolean = true // spinner

	//-------------------------------------
	providersList
	Transactions_Datas
	dealerListOptions: ItemList[]
	loading = false // for disable fast response click
	pageEvent
	panelOpenState = true
	services: ItemList[]

	account = '' // Account input
	refStan = '' // refStan input
	serviceSelected = '' // Service selected
	userLogin = '' // User Login input

	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	dealerFControl = new UntypedFormControl() // Dealer
	AutostopComment = new UntypedFormControl()

	dealerFilteredOptions: Observable<ItemList[]> // dealer
	currentTime = new Date(new Date().setHours(0, 0, 0))
	currentTimeEnd = new Date(new Date().setHours(23, 59, 59))
	//----------------- list of columns
	displayedColumns: string[] = [
		'RefStan',
		'Status',
		'UserLogin',
		'Dealer',
		'CompanyId',
		'CompanyName',

		'SubCompanyID',
		'Account',
		'Currency',
		'PaySourceType', 
		'Amount',
		'Commission',
		'RegDate',
	]
	allGroupsSelected: boolean = false
	firstTimeGroups: boolean = true
	firstTimeServices: boolean = true
	allServicesSelected: boolean = true


	filterPaymentType = [
		{
			Name: 'All',
			Value: '',
		},
		{
			Name: 'Cash',
			Value: '1',
		},
		{
			Name: 'Credit card',
			Value: '2',
		},
	]

	groupsClicked() {
		if (this.firstTimeGroups) {
			let allIDs = this.serviceGroups.map((x) => x.ID)
			this.allGroupsSelected = true
			this.serviceGroupSelected = allIDs
		}
	}
	serviceSubClicked() {
		if (this.firstTimeServices) {
			let allIDs = this.servicesToShow.map((x) => x.ID)
			this.allServicesSelected = true
			this.serviceSubSelected = allIDs
			this.firstTimeServices = false
		}
	}
	deselectorGroups() {
		let allIDs = this.serviceGroups.map((x) => x.ID)

		if (this.allGroupsSelected) {
			this.serviceGroupSelected = []
		} else {
			this.serviceGroupSelected = allIDs
			this.serviceHandler()
			setTimeout(() => {
				this.FilterForm.value.ServiceDrop = ''
			}, 0)
			this.firstTimeGroups = false
		}

		this.allGroupsSelected = !this.allGroupsSelected
	}
	deselectorServices() {
		let allIDs = this.servicesToShow.map((x) => x.ID)
		if (this.allServicesSelected) {
			this.serviceSubSelected = []
		} else {
			this.serviceSubSelected = allIDs
			setTimeout(() => {
				this.FilterForm.value.ServiceSub = ''
			}, 0)
		}
		this.allServicesSelected = !this.allServicesSelected
	}
	serviceHandler() {
		this.serviceSubSelected = []
		this.servicesToShow = this.defaultServices.filter((x) => {
			return this.serviceGroupSelected.includes(String(x.GroupID))
		})
		this.serviceSubSelected = this.servicesToShow.map((x) => x.ID)
		this.firstTimeServices = true
	}
	dataSource = new MatTableDataSource() // array of datas
	hasChild = (_: number, node: DealerNode) =>
		!!node.children && node.children.length > 0
	treeControl = new NestedTreeControl<DealerNode>((node) => node.children)
	dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
	treeData

	showTree: boolean = false
	currentDealer = {
		Name: '',
		Value: '',
	}
	growTree(arr) {
		let storageData = JSON.parse(sessionStorage.getItem('dealerTreeList'))
		this.dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
		let mainArray = arr
		if (!storageData) {
			mainArray.map((x) => {
				x.children = []
			})
			mainArray.map((z) => {
				if (z.Id !== 0) {
					let currentEl = mainArray.find((x) => x.Id === z.ParentId)
					if (currentEl) {
						currentEl.children.push(z)
					}
				}
			})
			let result = mainArray.splice(0, 1)
			this.treeData = result
			sessionStorage.setItem('dealerTreeList', JSON.stringify(result))
			this.dataTreeSource = result
		} else {
			this.dataTreeSource = storageData
		}
	}
	chooseDealer(smth) {
		this.CloseDialog()
		this.dealerFControl.patchValue(smth.Name)
		this.currentDealer = { Value: smth.Id, Name: smth.Name }
		this.showTree = false
		console.log('Choose Dealer value:', this.currentDealer)
		this.getFirstDatas()
	}
	stopClick(event) {
		event.preventDefault()
	}
	//----------------------- get a dealer list and put to option's
	InitDealerFilter() {
		this.administrationService
			.getDealerDatasAsList()
			.pipe(first())
			.subscribe(
				(data) => {
					this.treeData = data.ReturnObject
					this.dealerFControl.setValue(this.currentDealer.Name)
					this.growTree(data.ReturnObject)
				},
				(error) => {
					console.log(error)
				},
				() => {
					this.getFirstDatas(this.radioModel)
				}
			)
	}
	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
			minHeight: '580px',
		})
	}
	isLoadingTabInfo: boolean = false
	panelClosed: boolean = true
	sysInterctionData: []
	sysResponseData: []
	interLogData = {}
	isLoadingInterInfo: boolean = false
	getInterLog(event, key, index) {
		index = index.toString()
		if (!this.interLogData.hasOwnProperty(index)) {
			this.reportsDataService.getSystemInteractionLog(key).subscribe(
				(res) => {
					if (res['ResultCode'] === 0) {
						if (res['ReturnObject'] !== null) {
							this.interLogData[index] =
								res['ReturnObject'].Details
						} else {
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					console.log(error)
				}
			)
			this.panelClosed = false
		} else {
			console.log('IT HAS!')
		}
	}
	@ViewChild(MatPaginator) paginator: MatPaginator

	FilterForm: UntypedFormGroup // Form initial

	radioModel = 1
	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas(this.radioModel)
	}
	defaultServices: any
	serviceGroupSelected: any = '' // Service selected
	serviceSubSelected: any = ''
	serviceGroups: any = []
	servicesToShow: any = []
	// function for change tables page
	InitProviderFilter() {
		this.providerService.ProviderAllGroups().subscribe(
			(data) => {
				this.serviceGroups = data.map((x) => ({
					ID: x.ID.toString(),
					Name: x.Name,
				}))
			},
			(error) => {
				console.log(error)
			},
			() => {
				this.InitDealerFilter()
			}
		)
		this.providerService.AllList().subscribe(
			(data) => {
				this.defaultServices = data.map((x) => x)

				this.servicesToShow = this.defaultServices
			},
			(error) => {
				console.log(error)
			}
		)
	}
	//----------------------- get a dealer list and put to option's

	getFirstDatas(period?) {
		if (typeof period === 'number') this.radioModel = period

		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		// Take datas from form
		let dealerId = this.currentDealer.Value
		let filter = this.FilterForm.value
		let service =
			this.serviceSubSelected[0] !== '' ? this.serviceSubSelected : ''
		this.reportsDataService
			.getProblemrequestDatas(
				start,
				finish,
				this.radioModel,
				filter,
				service,
				dealerId,
				this.currentPage + 1,
				this.paginationItemPerPage
			)
			.subscribe(
				(data) => {
					console.log(data)
					if (data.ResultCode === 0) {
						let datas = []
						let itemTotal = 0
						if (data.ReturnObject !== null) {
							datas = data.ReturnObject.Payments
							itemTotal = data.ReturnObject.Pagination.ItemTotal
						}
						this.paginationItems = itemTotal
						this.dataSource = new MatTableDataSource(datas) // array of datas
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}
	onSubmit() {
		this.getFirstDatas(this.radioModel)
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}

	private _filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.dealerListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}
	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}
	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}
	StatusForm: UntypedFormGroup

	modalDatas = {
		Status: 0,
	} // for display datas in modal
	statusLists = [
		{},
		{},
		{
			text: 'Problem',
			value: 2,
			color: 'badge-danger',
		},
		{
			text: 'Autostop',
			value: 3,
			color: 'badge-danger',
		},
		{},
		{
			text: 'Inqueue',
			value: 5,
			color: 'badge-warning',
		},
		{
			text: 'Close',
			value: 6,
			color: 'badge-secondary',
		},
	]
	statusStates = [
		{ value: 2, viewValue: 'Problem' },
		{ value: 5, viewValue: 'Inqueue' },
		{ value: 6, viewValue: 'Close' },
	]
	modalStatusSelected: any
	modalStates = [
		{
			Name: 'Confirm',
			Value: 5,
		},
		{
			Name: 'Close',
			Value: 6,
		},
	]
	filterStates = [
		{
			Name: 'All',
			Value: '',
		},
		{
			Name: 'Problem',
			Value: 2,
		},
		{
			Name: 'Close',
			Value: 6,
		},
	]
	modalStatesToShow = []
	tabPrimary = true
	tabAnswer = ''
	showTextError: boolean = false
	autoSValue = ''
	getStatusResponse(refStan, method) {
		let comment = this.autoSValue
		console.log('Comment value::', comment, this.modalDatas['Account'])

		if (method === 'Repay') {
			if (comment.length > 0) {
				this.isLoadingDetailInfo = true
				this.tabPrimary = false

				this.reportsDataService
					.getStatusResponse(
						refStan,
						method,
						comment,
						this.modalDatas['Account']
					)
					.subscribe(
						(data) => {
							if (data['ReturnObject'] !== null) {
								this.tabAnswer =
									data['ReturnObject'].Description
								this.isLoadingDetailInfo = false
							} else {
								this.isLoadingDetailInfo = false
							}

							this.isLoadingDetailInfo = false
							this.getFirstDatas(this.radioModel)
						},
						(error) => {
							this.isLoadingDetailInfo = false
							console.log(error)
						}
					)
				this.showTextError = false
			} else {
				this.showTextError = true
			}
		} else {
			this.showTextError = false
			this.isLoadingDetailInfo = true
			this.tabPrimary = false

			this.reportsDataService
				.getStatusResponse(
					refStan,
					method,
					comment,
					this.modalDatas['Account']
				)
				.subscribe(
					(data) => {
						console.log('Status Response::', data)

						if (data['ReturnObject'] !== null) {
							this.tabAnswer = data['ReturnObject'].Description
							this.isLoadingDetailInfo = false
						} else {
							this.isLoadingDetailInfo = false
						}

						this.isLoadingDetailInfo = false
						this.getFirstDatas(this.radioModel)
					},
					(error) => {
						this.isLoadingDetailInfo = false
						console.log(error)
					}
				)
		}
	}

	modalStError: any = ''
	stFormSubmit() {
		let form = this.StatusForm
		if (form.valid) {
			this.loading = true
			let action = form.value.Status,
				userLogin = this.modalDatas['UserLogin'],
				account = this.modalDatas['Account'],
				comment = form.value.Comment,
				refstan = this.modalDatas['RefStan'],
				companyId = this.modalDatas['CompanyId']
			this.reportsDataService
				.saveModalStatus(
					userLogin,
					account,
					action,
					refstan,
					comment,
					companyId
				)
				.subscribe(
					(data) => {
						if (data.ResultCode === 0) {
							let theme = ''
							let body = ''
							this.translate
								.get('REPORT.MODAL_THEME_SUCCESS_' + action)
								.subscribe((text) => {
									theme = text
								})
							this.translate
								.get('REPORT.MODAL_BODY_SUCCESS_' + action)
								.subscribe((text) => {
									body = text
								})
							this.toastr.success(body, theme)
							this.loading = false
							this.CloseDialog()
						} else {
							let theme = ''
							this.translate
								.get('REPORT.MODAL_BODY_ERROR')
								.subscribe((text) => {
									theme = text
								}) // Text in component
							data.ResultMessage === null
								? (this.modalStError = '')
								: (this.modalStError = data.ResultMessage)
							this.toastr.error(this.modalStError, theme)
							this.loading = false
						}
						this.getFirstDatas(this.radioModel)
					},
					(error) => {
						let theme = ''
						this.translate
							.get('PAY.MODAL_SERVER_ERROR')
							.subscribe((text) => {
								theme = text
							}) // Text in component
						this.toastr.error(error, theme)
						this.loading = false
						console.log(error)
					}
				)
		}
	}
	checkTab(event) {
		this.panelClosed = true

		if (event.index === 1) {
			if (this.sysInterctionData.length == 0) {
				this.isLoadingInterInfo = true
				this.reportsDataService
					.getSystemInteraction(this.modalDatas['RefStan'])
					.subscribe(
						(res) => {
							if (res['ResultCode'] === 0) {
								if (res['ReturnObject'] !== null) {
									this.sysInterctionData =
										res['ReturnObject'].Items
									this.isLoadingInterInfo = false
								} else {
									this.isLoadingInterInfo = false
								}
							} else {
								this.CloseDialog()
							}
						},
						(error) => {
							this.isLoadingInterInfo = false
							console.log(error)
						}
					)
			}
		} else if (event.index === 2) {
		}
	}

	selectRow(templateRef, id) {
		this.isLoadingDetailInfo = true
		this.tabPrimary = true
		this.tabAnswer = ''
		this.StatusForm.reset()
		this.AutostopComment.patchValue('')
		this.autoSValue = ''
		this.sysResponseData = []
		this.sysInterctionData = []
		this.interLogData = {}
		this.StatusForm.controls.Status.setValue(this.modalStates[0].Value)
		this.reportsDataService.getAdvanceProblem(id).subscribe(
			(data) => {
				console.log(data)
				if (data.ResultCode === 0) {
					if (data.ReturnObject !== null) {
						this.modalDatas = data.ReturnObject.Transaction
						this.isLoadingDetailInfo = false
						console.log('ModalDatas::', this.modalDatas)
						if (this.modalDatas.Status === 3) {
							this.modalStatesToShow = this.modalStates.slice(
								0,
								-1
							)
						} else {
							this.modalStatesToShow = this.modalStates
						}
					} else {
						this.isLoadingDetailInfo = false
					}
				} else {
					this.isLoadingDetailInfo = false
				}
			},
			(error) => {
				this.isLoadingDetailInfo = false
				console.log(error)
			}
		)
		const dialogRef = this.dialog.open(templateRef, {})
	}
	CloseDialog(): void {
		this.dialog.closeAll()
	}
	statusPermission
	ngOnInit() {
		this.paginationItemPerPage = 10
		this.translate.get('REPORT.PROBLEM_ST_PROBLEM').subscribe((text) => {
			this.statusLists[2].text = text
		})
		this.translate.get('REPORT.PROBLEM_ST_AUTOSTOP').subscribe((text) => {
			this.statusLists[3].text = text
		})
		this.translate.get('REPORT.PROBLEM_ST_INQUEUE').subscribe((text) => {
			this.statusLists[5].text = text
		})
		this.translate.get('REPORT.PROBLEM_ST_CLOSE').subscribe((text) => {
			this.statusLists[6].text = text
		})
		this.translate.get('REPORT.PROBLEM_STS_CONFIRM').subscribe((text) => {
			this.modalStates[0].Name = text
		})

		this.translate.get('REPORT.PROBLEM_STS_CLOSE').subscribe((text) => {
			this.modalStates[1].Name = text
			this.filterStates[3].Name = text
		})
		this.translate.get('REPORT.PROBLEM_STS_AUTOSTOP').subscribe((text) => {
			// this.modalStates[2].Name = text;
			//  this.filterStates[2].Name = text;
		})
		this.translate.get('REPORT.PROBLEM_STS_ALL').subscribe((text) => {
			this.filterStates[0].Name = text
		})
		this.translate.get('REPORT.PROBLEM_STS_PROBLEM').subscribe((text) => {
			this.filterStates[1].Name = text
		})
		let ls = new SecureLS()
		this.statusPermission =
			ls.get('permissions')['get_status'] === null
				? false
				: ls.get('permissions')['get_status'].write
		this.FilterForm = new UntypedFormGroup({
			Dealer: new UntypedFormControl(), //, [this.dealerDropValidator(this.options)]),
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTimeEnd, disabled: true },
				Validators.required
			),
			Account: new UntypedFormControl(''),
			ServiceDrop: new UntypedFormControl(''),
			ServiceSub: new UntypedFormControl(''),
			RefStan: new UntypedFormControl(''),
			UserName: new UntypedFormControl(''),
			UserLogin: new UntypedFormControl(''),
			Status: new UntypedFormControl(this.filterStates[0].Value),
			PaymentSourceType: new UntypedFormControl(
				this.filterPaymentType[0].Value
			)
		})
		this.StatusForm = new UntypedFormGroup({
			Comment: new UntypedFormControl(''),
			Status: new UntypedFormControl(this.modalStates[0].Value),
			RefStan: new UntypedFormControl(''),
		})

		this.InitProviderFilter()


		 
	}
}

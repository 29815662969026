import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	Validators,
	UntypedFormControl,
	FormGroupDirective,
	NgForm,
} from '@angular/forms'
import { first } from 'rxjs/operators'

import { AlertService, UserService } from '../../_services'
import { MatDialog } from '@angular/material/dialog'
import { ProfileService } from '../../_services/profile.service'
import { LangChangeEvent, TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { untilDestroyed } from 'ngx-take-until-destroy'
import { AdministrationService } from '../../_services/administration.service'
import { environment } from '../../../environments/environment'

@Component({
	templateUrl: 'company-edit.component.html',
	styleUrls: ['administration.scss'],
})
export class CompanyEditComponent implements OnInit {
	loading: boolean = false
	baseUrl: string = environment.apiUrl
	isLoadingDetailInfo: boolean = false
	submitted: boolean = false
	saveEditError = ''
	upload = {
		logo: false,
		info: false,
	}
	productId // Parameter for initialize custom page
	FieldTypeList = []
	mainInfo = {
		General: {
			CompanyId: 0,
			Order: 0,
			Popularity: 0,
			Group: [],
			CurrentGroupKey: '',
			CurrentImgKey: '',
			Strings: [
				{
					Id: 0,
					Key: '',
					EntityKey: '',
					LanguageKey: '',
					LanguageName: '',
					Name: '',
					Description: '',
				},
			],
		},
		Profile: {
			TestAccount: [],
			ProvidersList: [],
			ProviderId: '',
		},
		Services: [],
		FieldsFilter: {
			Services: [],
			FieldType: [],
			TemplateType: [],
		},
	}

	modalDatas
	pageLoad = false

	constructor(
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		public translate: TranslateService,
		public toastr: ToastrService,
		private route: ActivatedRoute,
		public dialog: MatDialog,
		private administrationService: AdministrationService
	) {
		this.productId = this.route.snapshot.paramMap.get('id')
		translate.onLangChange
			.pipe(untilDestroyed(this))
			.subscribe((event: LangChangeEvent) => {
				// location.reload();
				console.log(this.router.onSameUrlNavigation)
				let target: any = this.route
				this.redirectTo(target.url.value[0].path, this.productId)
				// this.router.navigate([target.url.value[0].path], { queryParams: { id: this.productId } });
			})
	}

	redirectTo(uri, params) {
		this.router.onSameUrlNavigation = 'ignore'
		if (params) {
			this.router
				.navigateByUrl('/', { skipLocationChange: true })
				.then(() =>
					this.router.navigate([uri], { queryParams: { id: params } })
				)
		} else {
			this.router
				.navigateByUrl('/', { skipLocationChange: true })
				.then(() => this.router.navigate([uri]))
		}
	}

	fieldsloaded = false
	servicesloaded = false
	servicesList
	fieldsList = []
	new_service_flag = false
	MainForm: UntypedFormGroup
	ServicesForm: UntypedFormGroup
	StringsForm: UntypedFormGroup
	string_id

	previewImage(event, id_type) {
		if (event.target.files.length) {
			let file = event.target.files[0]
			this.upload[id_type] = true
			let image_error_theme = ''
			let image_error_body = ''
			if (file.type !== 'image/png' && file.type != 'image/jpeg') {
				this.translate
					.get('PROFILE.THEME_IMG_FORMAT_' + id_type.toUpperCase())
					.subscribe((text) => {
						image_error_theme = text
					})
				this.translate
					.get('PROFILE.BODY_IMG_FORMAT_' + id_type.toUpperCase())
					.subscribe((text) => {
						image_error_body = text
					})
				this.upload[id_type] = false
				this.toastr.warning(image_error_body, image_error_theme)
			} else if (file.size > 524288) {
				this.translate
					.get('PROFILE.THEME_IMG_SIZE_' + id_type.toUpperCase())
					.subscribe((text) => {
						image_error_theme = text
					})
				this.translate
					.get('PROFILE.BODY_IMG_SIZE_' + id_type.toUpperCase())
					.subscribe((text) => {
						image_error_body = text
					})
				this.upload[id_type] = false
				this.toastr.warning(image_error_body, image_error_theme)
			} else {
				let type = file.type
				let reader: any
				target: EventTarget
				reader = new FileReader()

				reader.readAsDataURL(event.target.files[0]) // read file as data url
				reader.onload = (event) => {
					// called once readAsDataURL is completed
					let code = event.target.result.split(',')[1]
					this.administrationService
						.saveImageCompany(this.productId, type, code, id_type)
						.pipe(first())
						.subscribe(
							(data) => {
								console.log(data)
								if (data.ResultCode === 0) {
									;(<HTMLImageElement>(
										document.getElementById(
											'preview_' + id_type
										)
									)).src = 'data:' + type + ';base64,' + code //
									let theme = ''
									let body = ''
									this.translate
										.get(
											'PROFILE.THEME_IMG_SUCCESS_' +
												id_type.toUpperCase()
										)
										.subscribe((text) => {
											theme = text
										})
									this.translate
										.get(
											'PROFILE.BODY_IMG_SUCCESS_' +
												id_type.toUpperCase()
										)
										.subscribe((text) => {
											body = text
										})
									this.toastr.success(body, theme)
								} else {
									let theme = ''
									let body = ''
									data.ResultMessage === null
										? (body = '')
										: (body = data.ResultMessage)
									this.translate
										.get(
											'PROFILE.THEME_IMG_ERROR_' +
												id_type.toUpperCase()
										)
										.subscribe((text) => {
											theme = text
										})
									this.toastr.error(body, theme)
								}
							},
							(error) => {
								console.log(error)
								let theme = ''
								this.translate
									.get(
										'PROFILE.THEME_IMG_ERROR_' +
											id_type.toUpperCase()
									)
									.subscribe((text) => {
										theme = text
									})
								this.toastr.error(error, theme)
								console.log(error)
							},
							() => {
								this.upload[id_type] = false
							}
						)
				}
			}
		}
	}

	onSubmit() {
		this.MainForm.markAsTouched()
		if (this.MainForm.valid && this.productId) {
			let final_strings = []
			for (let str in this.mainInfo.General.Strings) {
				// let target = control.split('_');
				let name = this.MainForm.value['Name_' + str]
				let desc = this.MainForm.value['Description_' + str]
				final_strings.push({
					Key: this.mainInfo.General.Strings[str].Key,
					EntityKey: this.mainInfo.General.Strings[str].EntityKey,
					LanguageKey: this.mainInfo.General.Strings[str].LanguageKey,
					LanguageName:
						this.mainInfo.General.Strings[str].LanguageName,
					Name: name,
					Description: desc,
				})
			}
			this.loading = true
			this.administrationService
				.editCompany(this.mainInfo, this.MainForm.value, this.productId)
				.pipe(first())
				.subscribe(
					(data) => {
						console.log(data)
						if (data.ResultCode === 0) {
							this.loading = false
							let theme = ''
							let body = ''
							this.translate
								.get('PROFILE.THEME_EDITCOMPANY_SUCCESS')
								.subscribe((text) => {
									theme = text
								})
							this.translate
								.get('PROFILE.BODY_EDITCOMPANY_SUCCESS')
								.subscribe((text) => {
									body = text
								})
							this.toastr.success(body, theme)
						} else {
							this.loading = false
							let theme = ''
							let body = ''
							data.ResultMessage === null
								? (body = '')
								: (body = data.ResultMessage)
							this.translate
								.get('PROFILE.THEME_EDITCOMPANY_ERROR')
								.subscribe((text) => {
									theme = text
								})
							this.toastr.error(body, theme)
						}
					},
					(error) => {
						this.loading = false
						let theme = ''
						this.translate
							.get('PROFILE.THEME_COMPANY_SERVERERR')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.error(error, theme)
						console.log(error)
					}
				)
		} else if (this.MainForm.valid) {
			console.log('Save')
			let final_strings = []
			for (let str in this.mainInfo.General.Strings) {
				// let target = control.split('_');
				let name = this.MainForm.value['Name' + str]
				let desc = this.MainForm.value['Description' + str]
				final_strings.push({
					Key: this.mainInfo.General.Strings[str].Key,
					EntityKey: this.mainInfo.General.Strings[str].EntityKey,
					LanguageKey: this.mainInfo.General.Strings[str].LanguageKey,
					LanguageName:
						this.mainInfo.General.Strings[str].LanguageName,
					Name: name,
					Description: desc,
				})
			}
			this.loading = true
			this.administrationService
				.saveNewCompany(this.mainInfo, this.MainForm.value, null)
				.pipe(first())
				.subscribe(
					(data) => {
						console.log('saveNewCompany', data.ReturnObject)
						if (data.ResultCode === 0) {
							console.log('mainform', this.MainForm.value)
							this.loading = false
							let theme = ''
							let body = ''
							this.translate
								.get('PROFILE.THEME_NEWCOMPANY_SUCCESS')
								.subscribe((text) => {
									theme = text
								})
							this.translate
								.get('PROFILE.BODY_NEWCOMPANY_SUCCESS')
								.subscribe((text) => {
									body = text
								})
							this.toastr.success(body, theme)
							this.router.navigateByUrl(
								'/company/' + data.ReturnObject
							)
						} else {
							this.loading = false
							let theme = ''
							let body = ''
							data.ResultMessage === null
								? (body = '')
								: (body = data.ResultMessage)
							this.translate
								.get('PROFILE.THEME_NEWCOMPANY_ERROR')
								.subscribe((text) => {
									theme = text
								})
							this.toastr.error(body, theme)
						}
					},
					(error) => {
						this.loading = false
						let theme = ''
						this.translate
							.get('PROFILE.THEME_COMPANY_SERVERERR')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.error(error, theme)
						console.log(error)
					}
				)
		}
		// console.log(this.ChangePasswordForm);
		// this.ChangePasswordForm.markAsTouched;
		// if (this.ChangePasswordForm.valid === true) {
		// 	this.loading = true;
		// 	this.submitted = false;
		// 	this.profileService.profileChangePassword(this.ChangePasswordForm.value.OldPassword, this.ChangePasswordForm.value.Password, this.ChangePasswordForm.value.RepeatPassword, this.new_service_flag)
		// 		.pipe(first())
		// 		.subscribe(
		// 			data => {
		// 				console.log(data);
		// 				if (data.ResultCode === 0) {
		// 					console.log('Change Password');
		// 					this.loading = false;
		// 					this.ChangePasswordForm.reset();
		// 					let theme = '';
		// 					let body = '';
		// 					this.translate.get('PROFILE.THEME_NEWPASSWORD_SUCCESS').subscribe((text) => { theme = text; });
		// 					this.translate.get('PROFILE.BODY_NEWPASSWORD_SUCCESS').subscribe((text) => { body = text; });
		// 					this.toastr.success(body, theme);
		// 				}
		// 				else {
		// 					this.loading = false;
		// 					let theme = '';
		// 					let body = '';
		// 					data.ResultMessage === null ? body = '' : body = data.ResultMessage;
		// 					this.translate.get('PROFILE.THEME_NEWPASSWORD_ERROR').subscribe((text) => { theme = text; });
		// 					this.toastr.error(body, theme);
		// 				}
		// 			},
		// 			error => {
		// 				this.loading = false;
		// 				let theme = '';
		// 				this.translate.get('PROFILE.THEME_NEWPASSWORD_ERROR').subscribe((text) => { theme = text; });
		// 				this.toastr.error(error, theme);
		// 				console.log(error);
		// 			});
		// }
	}

	ObjectSize(obj) {
		var size = 0,
			key
		for (key in obj) {
			if (obj.hasOwnProperty(key)) {
				size++
			}
		}
		return size
	}

	openInfo(templateRef, id) {
		this.string_id = id
		this.ServicesForm.reset()
		this.saveEditError = ''
		this.loading = true
		this.servicesloaded = false
		this.isLoadingDetailInfo = true
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
		if (this.ObjectSize(this.ServicesForm.controls) > 0) {
			for (let control in this.ServicesForm.controls) {
				this.ServicesForm.removeControl(control)
			}
		}
		if (id !== null) {
			this.administrationService
				.getServiceInfo(id)
				.pipe(first())
				.subscribe(
					(data) => {
						console.log(data)
						if (data.ResultCode == '0') {
							let detail_services = data.ReturnObject
							for (
								let i = 0;
								i < this.mainInfo.General.Strings.length;
								i++
							) {
								this.ServicesForm.addControl(
									'Name_' + i,
									new UntypedFormControl(
										detail_services[i].Name,
										Validators.required
									)
								)
								this.ServicesForm.addControl(
									'Description_' + i,
									new UntypedFormControl(
										detail_services[i].Description,
										Validators.required
									)
								)
							}
							this.ServicesForm.addControl(
								'Id',
								new UntypedFormControl(
									detail_services[0].ServiceId,
									Validators.required
								)
							)
							this.ServicesForm.addControl(
								'Order',
								new UntypedFormControl(
									detail_services[0].Order,
									Validators.required
								)
							)
						} else {
							this.CloseDialog()
						}
						console.log(this.ServicesForm)
						this.isLoadingDetailInfo = false
						this.loading = false
						this.servicesloaded = true
					},
					(error) => {
						this.loading = false
						this.isLoadingDetailInfo = false
						this.servicesloaded = true
						console.log(error)
						this.CloseDialog()
					}
				)
		} else {
			this.new_service_flag = true
			for (let i = 0; i < this.mainInfo.General.Strings.length; i++) {
				this.ServicesForm.addControl(
					'Name_' + i,
					new UntypedFormControl('', Validators.required)
				)
				this.ServicesForm.addControl(
					'Description_' + i,
					new UntypedFormControl('', Validators.required)
				)
			}
			this.ServicesForm.addControl(
				'Id',
				new UntypedFormControl('', Validators.required)
			)
			// this.ServicesForm.addControl('PayOut', new FormControl(false, Validators.required));
			this.ServicesForm.addControl(
				'Order',
				new UntypedFormControl('', Validators.required)
			)
			this.loading = false
			this.isLoadingDetailInfo = false
			this.servicesloaded = true
		}
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}

	saveServices() {
		let final_strings = []
		if (this.ServicesForm.valid) {
			for (let str in this.mainInfo.General.Strings) {
				// let target = control.split('_');
				let name = this.ServicesForm.value['Name_' + str]
				let desc = this.ServicesForm.value['Description_' + str]
				final_strings.push({
					// 'Key': this.mainInfo.General.Strings[str].Key,
					// 'EntityKey': this.mainInfo.General.Strings[str].EntityKey,
					LanguageKey: this.mainInfo.General.Strings[str].LanguageKey,
					Name: name,
					Description: desc,
					OrderEntity: this.ServicesForm.value.Order,
					CompanyId: this.mainInfo.General.CompanyId,
					ServiceId: this.ServicesForm.value.Id,
				})
			}
			this.administrationService
				.editServices(final_strings, this.string_id)
				.pipe(first())
				.subscribe(
					(data) => {
						console.log(data)
						if (data.ResultCode === '000') {
							this.loading = false
							let theme = ''
							let body = ''
							this.translate
								.get('PROFILE.THEME_EDITSERVICE_SUCCESS')
								.subscribe((text) => {
									theme = text
								})
							if (this.string_id === null) {
								this.translate
									.get('PROFILE.BODY_EDITSERVICE_SUCCESS_NEW')
									.subscribe((text) => {
										body = text
									})
							} else {
								this.translate
									.get(
										'PROFILE.BODY_EDITSERVICE_SUCCESS_EDIT'
									)
									.subscribe((text) => {
										body = text
									})
							}
							this.toastr.success(body, theme)
							this.getServices()
							this.CloseDialog()
						} else {
							this.loading = false
							let theme = ''
							let body = ''
							data.ResultMessage === null
								? (body = '')
								: (body = data.ResultMessage)
							this.translate
								.get('PROFILE.THEME_EDITSERVICE_ERROR')
								.subscribe((text) => {
									theme = text
								})
							this.toastr.error(body, theme)
						}
					},
					(error) => {
						this.loading = false
						let theme = ''
						this.translate
							.get('PROFILE.THEME_EDITSERVICE_ERROR')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.error(error, theme)
						console.log(error)
					}
				)
		}
		console.log(final_strings)
		console.log(this.ServicesForm.value)
		console.log('New flag - ' + this.new_service_flag)
		// If Success make new Get for all Fields
	}

	fieldDataDetails

	openStrings(templateRef, id) {
		this.StringsForm.reset()
		this.saveEditError = ''
		this.loading = true
		this.isLoadingDetailInfo = true
		this.fieldsloaded = false
		this.fieldDataDetails = null
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
		if (this.ObjectSize(this.StringsForm.controls) > 0) {
			for (let control in this.StringsForm.controls) {
				this.StringsForm.removeControl(control)
			}
		}
		if (id !== null) {
			this.administrationService
				.getFieldDetail(this.productId, id)
				.pipe(first())
				.subscribe(
					(data) => {
						console.log(data)
						if (data.ResultCode === 0) {
							this.fieldDataDetails = data.ReturnObject
							for (
								let i = 0;
								i <
								this.fieldDataDetails.StringEntityArray.length;
								i++
							) {
								this.StringsForm.addControl(
									'Name_' + i,
									new UntypedFormControl(
										this.fieldDataDetails.StringEntityArray[
											i
										].Name,
										Validators.required
									)
								)
								this.StringsForm.addControl(
									'Description_' + i,
									new UntypedFormControl(
										this.fieldDataDetails.StringEntityArray[
											i
										].Description,
										Validators.required
									)
								)
								console.log('asd')
							}
							// this.ServicesForm.addControl('PayOut', new FormControl('', Validators.required));
							this.StringsForm.addControl(
								'Order',
								new UntypedFormControl(
									this.fieldDataDetails.InputField.Order,
									Validators.required
								)
							)
							this.StringsForm.addControl(
								'Value',
								new UntypedFormControl(
									this.fieldDataDetails.InputField.Value,
									Validators.nullValidator
								)
							)
							this.StringsForm.addControl(
								'Regex',
								new UntypedFormControl(
									this.fieldDataDetails.InputField.ValueTemplate,
									Validators.required
								)
							)
							this.StringsForm.addControl(
								'FieldType',
								new UntypedFormControl(
									this.fieldDataDetails.InputField.InputType
								)
							)
							this.StringsForm.addControl(
								'TemplateType',
								new UntypedFormControl(
									this.fieldDataDetails.TemplateType,
									Validators.required
								)
							)
							// console.log(this.mainInfo.FieldsFilter.TemplateType.find(value => value.Key === this.fieldDataDetails.TemplateType).Key);
							// console.log('______________');
							// console.log(this.fieldDataDetails.InputField.InputType);
							// console.log(this.fieldDataDetails.TemplateType);
							// console.log('______________');
							// console.log(this.mainInfo.FieldsFilter.TemplateType);
							// console.log(this.mainInfo.FieldsFilter.FieldType);
							// this.StringsForm.controls.TemplateType.setValue(dataDetails.TemplateType);
							// this.StringsForm.controls.TemplateType.setValue(this.mainInfo.FieldsFilter.TemplateType.find(value => value.Key === dataDetails.TemplateType).Key);
							//this.StringsForm.controls.FieldsType.setValue(this.fieldDataDetails.InputField.InputType);
						} else {
							this.CloseDialog()
						}
						this.isLoadingDetailInfo = false
						this.loading = false
						this.fieldsloaded = true
					},
					(error) => {
						this.loading = false
						this.isLoadingDetailInfo = false
						this.fieldsloaded = true
						console.log(error)
						this.CloseDialog()
					}
				)
		} else {
			this.fieldsloaded = true
			this.new_service_flag = true
			for (let i = 0; i < this.mainInfo.General.Strings.length; i++) {
				this.StringsForm.addControl(
					'Name_' + i,
					new UntypedFormControl('', Validators.required)
				)
				this.StringsForm.addControl(
					'Description_' + i,
					new UntypedFormControl('', Validators.required)
				)
			}
			this.StringsForm.addControl(
				'FieldType',
				new UntypedFormControl(
					this.mainInfo.FieldsFilter.FieldType[0].Key
				)
			)
			this.StringsForm.addControl(
				'TemplateType',
				new UntypedFormControl(
					this.mainInfo.FieldsFilter.TemplateType[0].Key,
					Validators.required
				)
			)
			this.StringsForm.addControl('Value', new UntypedFormControl(''))
			this.StringsForm.addControl(
				'Regex',
				new UntypedFormControl('', Validators.required)
			)
			this.StringsForm.addControl(
				'Order',
				new UntypedFormControl('', Validators.required)
			)
			this.isLoadingDetailInfo = false
			this.loading = false
		}
		// this.reportsDataService.getRoleInfo(id).pipe(first())
		// 	.subscribe(
		// 		data => {
		// 			console.log(data);
		// 			if (data.ResultCode === 0) {
		// 				this.modalDatas = data.ReturnObject;
		// 			}
		// 			console.log(this.ServicesForm);
		// 			this.isLoadingDetailInfo = false;
		// 			this.loading = false;
		// 		},
		// 		error => {
		// 			this.loading = false;
		// 			this.isLoadingDetailInfo = false;
		// 			console.log(error);
		// 		});
		// delete all down!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
	}

	saveFields() {
		let final_strings = []
		if (this.StringsForm.valid) {
			for (let str in this.mainInfo.General.Strings) {
				// let target = control.split('_');
				let name = this.StringsForm.value['Name_' + str]
				let desc = this.StringsForm.value['Description_' + str]
				if (this.fieldDataDetails) {
					final_strings.push({
						Key: this.fieldDataDetails.StringEntityArray[str]
							.EntityKey,
						EntityKey:
							this.fieldDataDetails.StringEntityArray[str]
								.EntityKey,
						LanguageKey:
							this.mainInfo.General.Strings[str].LanguageKey,
						LanguageName:
							this.mainInfo.General.Strings[str].LanguageName,
						Name: name,
						Description: desc,
					})
				} else {
					final_strings.push({
						LanguageKey:
							this.mainInfo.General.Strings[str].LanguageKey,
						LanguageName:
							this.mainInfo.General.Strings[str].LanguageName,
						Name: name,
						Description: desc,
					})
				}
			}
			let service =
				this.MainForm.value.Filter_Services === 'null'
					? null
					: this.MainForm.value.Filter_Services
			this.administrationService
				.editFields(
					final_strings,
					this.StringsForm.value,
					this.productId,
					service
				)
				.pipe(first())
				.subscribe(
					(data) => {
						console.log(data)
						if (data.ResultCode === 0) {
							this.loading = false
							let theme = ''
							let body = ''
							this.translate
								.get('PROFILE.THEME_EDITFIELDS_SUCCESS')
								.subscribe((text) => {
									theme = text
								})
							if (this.string_id === null) {
								this.translate
									.get('PROFILE.BODY_EDITFIELDS_SUCCESS_NEW')
									.subscribe((text) => {
										body = text
									})
							} else {
								this.translate
									.get('PROFILE.BODY_EDITFIELDS_SUCCESS_EDIT')
									.subscribe((text) => {
										body = text
									})
							}
							this.toastr.success(body, theme)
							this.getFields()
							this.CloseDialog()
						} else {
							this.loading = false
							let theme = ''
							let body = ''
							data.ResultMessage === null
								? (body = '')
								: (body = data.ResultMessage)
							this.translate
								.get('PROFILE.THEME_EDITFIELDS_ERROR')
								.subscribe((text) => {
									theme = text
								})
							this.toastr.error(body, theme)
						}
					},
					(error) => {
						this.loading = false
						let theme = ''
						this.translate
							.get('PROFILE.THEME_EDITFIELDS_ERROR')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.error(error, theme)
						console.log(error)
					}
				)
		}
	}

	getFields(counter?) {
		console.log(this.MainForm.value.Filter_Services)
		console.log(this.MainForm.value.Filter_Field)
		console.log(this.MainForm.value.Filter_Template)
		// Services
		let service_Filter =
			this.MainForm.value.Filter_Services === 'null'
				? null
				: this.MainForm.value.Filter_Services
		this.administrationService
			.getFields(
				this.productId,
				service_Filter,
				this.MainForm.value.Filter_Field,
				this.MainForm.value.Filter_Template
			)
			.pipe(first())
			.subscribe(
				(data) => {
					console.log(data)
					if (data.ResultCode === 0 && data.ReturnObject != null) {
						this.fieldsList = data.ReturnObject
					} else {
						this.fieldsList = []
					}
				},
				(error) => {
					console.log(error)
				},
				() => {
					if (counter) {
						this.pageLoad = true
					}
				}
			)
	}

	getServices() {
		// Services

		this.administrationService
			.getCompanyPage(this.productId)
			.pipe(first())
			.subscribe(
				(data) => {
					console.log(data)
					if (data.ResultCode === 0) {
						this.mainInfo.Services = data.ReturnObject.Services
						this.servicesList = []
						this.mainInfo.Services.forEach((value) => {
							this.servicesList = [...this.servicesList, value]
						})
					}
				},
				(error) => {
					console.log(error)
				},
				() => {}
			)
	}

	getFirstDatas() {
		if (this.productId != null) {
			this.administrationService
				.getCompanyPage(this.productId)
				.pipe(first())
				.subscribe(
					(data) => {
						console.log(data)
						if (data.ResultCode === 0) {
							this.mainInfo = data.ReturnObject
							this.servicesList = this.mainInfo.Services
							this.FieldTypeList = []
							this.FieldTypeList = [
								...this.mainInfo.FieldsFilter.FieldType,
							]
							if (this.FieldTypeList) {
								this.FieldTypeList.shift()
							}
							// General
							let counter = 0
							// if (this.ObjectSize(this.MainForm.controls) > 0) {
							// 	for (let control in this.MainForm.controls) {
							// 		this.MainForm.removeControl(control);
							// 	}
							// } // May be uncomment when make new get method for Services
							this.mainInfo.General.Strings.forEach((string) => {
								string.Id = counter
								this.MainForm.addControl(
									'Name_' + counter,
									new UntypedFormControl(
										string.Name,
										Validators.required
									)
								)
								this.MainForm.addControl(
									'Description_' + counter,
									new UntypedFormControl(
										string.Description,
										Validators.required
									)
								)
								counter++
							})
							this.MainForm.addControl(
								'Order',
								new UntypedFormControl(
									this.mainInfo.General.Order,
									Validators.required
								)
							)
							this.MainForm.addControl(
								'Popularity',
								new UntypedFormControl(
									this.mainInfo.General.Popularity,
									Validators.required
								)
							)
							this.MainForm.addControl(
								'Group',
								new UntypedFormControl(
									this.mainInfo.General.CurrentGroupKey,
									Validators.required
								)
							)
							// Profile
							this.MainForm.addControl(
								'ProvidersList',
								new UntypedFormControl(
									this.mainInfo.Profile.ProviderId,
									Validators.required
								)
							)
							// FieldsFilter
							this.mainInfo.FieldsFilter.Services[0].Key === null
								? (this.mainInfo.FieldsFilter.Services[0].Key =
										'null')
								: ''
							this.MainForm.addControl(
								'Filter_Services',
								new UntypedFormControl(
									this.mainInfo.FieldsFilter.Services[0].Key
								)
							)
							this.MainForm.addControl(
								'Filter_Field',
								new UntypedFormControl(
									this.mainInfo.FieldsFilter.FieldType[0].Key
								)
							)
							this.MainForm.addControl(
								'Filter_Template',
								new UntypedFormControl(
									this.mainInfo.FieldsFilter.TemplateType[0].Key
								)
							)
							// this.MainForm.addControl('Filter_Field', new FormControl(this.mainInfo.FieldsFilter.FieldType, Validators.required));
							// this.MainForm.addControl('Filter_Template', new FormControl(this.mainInfo.FieldsFilter.TemplateType, Validators.required));

							// "CurrentImgKey": "eddb6743-e0b2-48f1-8f3d-01c8cca3c6ca",
						} else {
							// this.router.navigate(['/company']);
						}
					},
					(error) => {
						console.log(error)
						this.router.navigate(['/company'])
					},
					() => {
						this.getFields(true)
					}
				)
		} else {
			this.administrationService
				.getNewCompany()
				.pipe(first())
				.subscribe(
					(data) => {
						console.log('NewCompany', data)
						if (data.ResultCode === 0) {
							this.mainInfo = data.ReturnObject
							this.servicesList = this.mainInfo.Services
							// this.FieldTypeList = []
							// this.FieldTypeList = [
							// 	...this.mainInfo.FieldsFilter.FieldType,
							// ]
							// if (this.FieldTypeList) {
							// 	this.FieldTypeList.shift()
							// }
							let counter = 0
							// General
							this.mainInfo.General.Strings.forEach((string) => {
								//string.Id = counter
								this.MainForm.addControl(
									'Name' + counter,
									new UntypedFormControl(
										string.Name,
										Validators.required
									)
								)
								this.MainForm.addControl(
									'Description' + counter,
									new UntypedFormControl(
										string.Description,
										Validators.required
									)
								)
								counter++
							})

							this.MainForm.addControl(
								'Order',
								new UntypedFormControl(
									this.mainInfo.General.Order,
									Validators.required
								)
							)
							this.MainForm.addControl(
								'Popularity',
								new UntypedFormControl(
									this.mainInfo.General.Popularity,
									Validators.required
								)
							)
							this.MainForm.addControl(
								'CompanyId',
								new UntypedFormControl(
									this.mainInfo.General.CompanyId,
									Validators.required
								)
							)
							this.MainForm.addControl(
								'Group',
								new UntypedFormControl(
									this.mainInfo.General.CurrentGroupKey,
									Validators.required
								)
							)
							this.pageLoad = true
							// Profile
							// FieldsFilter
							// this.mainInfo.FieldsFilter.Services
							// this.MainForm.addControl(
							// 	'NewFilter_Services',
							// 	new UntypedFormControl(
							// 		this.mainInfo.FieldsFilter.Services
							// 	)
							// )
							// this.MainForm.addControl(
							// 	'NewFilter_Field',
							// 	new UntypedFormControl(
							// 		this.mainInfo.FieldsFilter.FieldType
							// 	)
							// )
							// this.MainForm.addControl(
							// 	'NewFilter_Template',
							// 	new UntypedFormControl(
							// 		this.mainInfo.FieldsFilter.TemplateType
							// 	)
							// )
							// this.MainForm.addControl('Filter_Field', new FormControl(this.mainInfo.FieldsFilter.FieldType, Validators.required));
							// this.MainForm.addControl('Filter_Template', new FormControl(this.mainInfo.FieldsFilter.TemplateType, Validators.required));

							// "CurrentImgKey": "eddb6743-e0b2-48f1-8f3d-01c8cca3c6ca",
						} else {
							// this.router.navigate(['/company']);
						}
					},
					(error) => {
						console.log(error)
						this.router.navigate(['/company'])
					}
				)

			// this.mainInfo.General.Strings
			// this.mainInfo.General.Strings.forEach((string) => {
			// 	string.Name, string.Description, string.Id
			// })
			// this.mainInfo.General.Order, this.mainInfo.General.Popularity
		}
	}

	ngOnInit() {
		// if (isNaN(this.productId)) {
		// 	this.router.navigate(['/company']);
		// }
		// if (!isNaN(this.productId)) {
		this.getFirstDatas()
		// }
		// Initialize forms for steps
		// Initialize form inputs with validations
		this.MainForm = this.formBuilder.group({})
		this.ServicesForm = this.formBuilder.group({})
		this.StringsForm = this.formBuilder.group({})
	}

	ngOnDestroy() {}
}

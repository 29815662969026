import { Component, OnInit, ViewChild } from '@angular/core'
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { first } from 'rxjs/operators'
import { ProviderService } from '../../_services'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'
import { DateAdapter } from '@angular/material/core'
import { MatSort } from '@angular/material/sort'

import { DatePipe } from '@angular/common'
import { AdministrationService } from '../../_services/administration.service'
import { ReportsDatasService } from '../../_services/reportsdata.service'
import { saveAs } from 'file-saver'
import { TranslateService } from '@ngx-translate/core'
import { NestedTreeControl } from '@angular/cdk/tree'
import { MatTreeNestedDataSource } from '@angular/material/tree'
import { Sort } from '@angular/material/sort'
import { KioskService } from '../../_services/kiosk.service'

export interface standartType {
	value: string
	viewValue: string
} // standart Interface

export interface ItemList {
	Value: string
	Name: string
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
}
@Component({
	templateUrl: 'kiosk-reconciliation.component.html',
	styleUrls: ['../reports/reports.scss'],
	providers: [DatePipe],
})
export class KioskReconciliationComponent implements OnInit {
	dataSource = new MatTableDataSource() // array of datas
	secondTableSource = new MatTableDataSource() // array of datas

	stopClick(event) {
		event.preventDefault()
	}
	//----------------------- get a dealer list and put to option's

	public totalAmountBody = 0
	public totalAmountFee = 0
	public totalAmountTotal = 0
	public totalRevenue = 0

	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	saleFilteredOptions: Observable<ItemList[]> // salePoints

	public transactionCurrentID
	public transactionCurrentDealerName
	public transactionCurrentServiceName
	public currentPage = 0
	isLoadingDetailInfo: boolean = false
	modalDatas = {} // for display datas in modal

	//-------------------------------------
	providersList
	Transactions_Datas
	dealerListOptions: ItemList[]
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	radioModel = 1
	isDownloadingFile = false

	cardNumber = ''
	cardHolderName = ''
	refStan = '' // refStan input
	defaultServices: any
	serviceGroupSelected: any = '' // Service selected
	serviceSubSelected: any = ''
	serviceGroups: any = []
	servicesToShow: any = []

	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	dealerFControl = new UntypedFormControl() // Dealer
	saleListOptions: ItemList[]

	paidTypes: standartType[] = [
		{ value: 'all', viewValue: 'All transactions' },
		{ value: 'cash', viewValue: 'Cash' },
		{ value: 'ewallet', viewValue: 'E-Wallet' },
	] // Select Channel

	operations: standartType[] = [
		{ value: 'in', viewValue: 'Cash In' },
		{ value: 'out', viewValue: 'Cash Out' },
	] // Select Channel

	dealerFilteredOptions: Observable<ItemList[]> // dealer
	currentTime = new Date(new Date().setHours(0, 0, 0))
	currentTimeEnd = new Date(new Date().setHours(23, 59, 59))
	//----------------- list of columns
	displayedColumns: string[] = [
		'Created',
		'User',
		'ResponseCode',
		'StatusCode',
	]

	services = [
		{ Value: 190, Name: 'Turkcell' },
		{ Value: 193, Name: 'Kibtek' },
		{ Value: 19, Name: 'VodafoneCyprus' },
	]

	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private providerService: ProviderService,
		private administrationService: AdministrationService,
		public kioskService: KioskService,
		private dateAdapter: DateAdapter<Date>,
		private reportsDatasService: ReportsDatasService
	) {
		dateAdapter.setLocale('en-EN') // change this for change format date in select
	}

	FilterForm: UntypedFormGroup // Form initial

	@ViewChild(MatSort) sort: MatSort

	//----------------------- get a provider list and put to option's

	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getSecondDatas(this.radioModel)
	}

	getSecondDatas(period?) {
		if (typeof period === 'number') {
			this.radioModel = period
		}
		console.log('getSecondDatas')
		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		// Take datas from form

		let filter = this.FilterForm.value
		this.kioskService
			.getKioskReconciliationReport(
				start,
				finish,
				this.radioModel,
				filter,
				this.currentPage + 1,
				this.paginationItemPerPage
			)
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.paginationItems =
								data.ReturnObject.PagingInfo.TotalItems
							this.dataSource = new MatTableDataSource(
								data.ReturnObject.KioskRecociliationList
							)
						} else {
							console.log('ITS NULL DATA!')
						}
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}
	onSubmit() {
		this.getSecondDatas(this.radioModel)
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}

	// private _filter(item: string): ItemList[] {
	//   const filterValue = item.toLowerCase();
	//   return this.dealerListOptions.filter(
	//     option => option.Name.toLowerCase().indexOf(filterValue) === 0
	//   );
	// }

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}

	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}
	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
			minHeight: '580px',
		})
	}
	selectRow(templateRef, row) {
		this.isLoadingDetailInfo = true
		this.kioskService
			.getKioskReconciliationDetails(row.Id)
			.pipe(first())
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas = data.ReturnObject
							this.isLoadingDetailInfo = false
						} else {
							this.isLoadingDetailInfo = false
						}
					} else {
						this.CloseDialog()
					}
				},
				(error) => {
					this.isLoadingDetailInfo = false
					console.log(error)
				}
			)
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}

	ngOnInit() {
		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			Dealer: new UntypedFormControl('', Validators.required),
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTimeEnd, disabled: true },
				Validators.required
			),
			Service: new UntypedFormControl(this.services[0].Value),
		})
		//this.InitProviderFilter();
	}
}

import { Component, OnInit, ViewChild } from '@angular/core'
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'
import { DateAdapter } from '@angular/material/core'
import { MatSort } from '@angular/material/sort'

import { DatePipe } from '@angular/common'
import { saveAs } from 'file-saver'
import { TranslateService } from '@ngx-translate/core'
import { NestedTreeControl } from '@angular/cdk/tree'
import { MatTreeNestedDataSource } from '@angular/material/tree'
import { Sort } from '@angular/material/sort'
import { KioskService } from '../../_services/kiosk.service'

/** Constants used to fill up our data base. */
interface DealerNode {
	name: string
	children?: DealerNode[]
}

export interface standartType {
	value: string
	viewValue: string
} // standart Interface

export interface ItemList {
	Value: string
	Name: string
}

export interface KioskList {
	Value: string
	Name: string
}
function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
}
@Component({
	templateUrl: 'kiosk-encashment.component.html',
	styleUrls: ['../reports/reports.scss', 'kiosk.scss'],
	providers: [DatePipe],
})
export class KioskEncashmentComponent implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,

		public kioskService: KioskService,
		private dateAdapter: DateAdapter<Date>,
		private translate: TranslateService
	) {
		dateAdapter.setLocale('en-EN') // change this for change format date in select
	}

	FilterForm: UntypedFormGroup // Form initial

	@ViewChild(MatSort) sort: MatSort

	dataSource = new MatTableDataSource() // array of datas
	secondTableSource = new MatTableDataSource() // array of datas

	hasChild = (_: number, node: DealerNode) =>
		!!node.children && node.children.length > 0
	treeControl = new NestedTreeControl<DealerNode>((node) => node.children)
	dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
	treeData
	kiosksList: [KioskList] = [{ Value: '', Name: 'ALL' }]

	initKioskList() {
		this.translate.get('KIOSK.LBL_KIOSKS_ALL').subscribe((text) => {
			this.kiosksList[0].Name = text
		})

		this.kioskService.getAllUsers().subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					data.ReturnObject.map((x) =>
						this.kiosksList.push({ Value: x.Login, Name: x.Name })
					)
				}
			},
			(error) => {
				console.log(error)
			}
		)
	}

	showTree: boolean = false
	currentDealer = {
		Name: '',
		Value: '',
	}
	stopClick(event) {
		event.preventDefault()
	}
	//----------------------- get a dealer list and put to option's

	public totalAmountBody = 0
	public totalAmountFee = 0
	public totalAmountTotal = 0
	public totalRevenue = 0

	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api
	saleFilteredOptions: Observable<ItemList[]> // salePoints

	public transactionCurrentID
	public transactionCurrentDealerName
	public transactionCurrentServiceName
	public currentPage = 0
	isLoadingDetailInfo: boolean = false
	modalDatas = {} // for display datas in modal

	//-------------------------------------
	providersList
	isDownloadingFile: boolean = false
	Transactions_Datas
	dealerListOptions: ItemList[]
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	radioModel = 1
	services: ItemList[]

	account = '' // Account input
	refStan = '' // refStan input
	defaultServices: any
	serviceGroupSelected: any = '' // Service selected
	serviceSubSelected: any = ''
	serviceGroups: any = []
	servicesToShow: any = []
	userLogin = '' // User Login input

	minDate = new Date(2000, 0, 1) // Date picker min date
	maxDate = new Date(new Date().getFullYear() + 5, 0, 1) // Date picker max date
	events: string[] = [] // Date picker
	dealerFControl = new UntypedFormControl() // Dealer
	saleListOptions: ItemList[]

	paidTypes: standartType[] = [
		{ value: 'all', viewValue: 'All transactions' },
		{ value: 'cash', viewValue: 'Cash' },
		{ value: 'ewallet', viewValue: 'E-Wallet' },
	] // Select Channel

	operations: standartType[] = [
		{ value: 'in', viewValue: 'Cash In' },
		{ value: 'out', viewValue: 'Cash Out' },
	] // Select Channel

	dealerFilteredOptions: Observable<ItemList[]> // dealer
	currentTime = new Date(new Date().setHours(0, 0, 0))
	currentTimeEnd = new Date(new Date().setHours(23, 59, 59))
	//----------------- list of columns
	displayedColumns: string[] = [
		'KioskID',
		'EncsDate',
		'Bill1',
		'Bill2',
		'Card1',
		'Card2',
		'Total1',
		'Total2',
		'Total3',
		'Difference',
	]
	displayedHeaderColumns: string[] = [
		'KioskIDHD',
		'EncsDateHD',
		'BillAcceptorDevice',
		'CardDispencerDevice',
		'TransactionTotals',
	]

	displayedSecondColumns: string[] = [
		'KioskID',
		'Count',
		'EncsDate',
		'Bill1',
		'Bill2',
		'Card1',
		'Card2',
		'Total1',
		'Total2',
		'Total3',
		'Difference',
	]
	displayedSecondHeaderColumns: string[] = [
		'KioskIDHD',
		'CountHD',
		'EncsDateHD',
		'BillAcceptorDevice',
		'CardDispencerDevice',
		'TransactionTotals',
	]

	activeFirstTab: boolean = true
	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.activeFirstTab
			? this.getFirstDatas(this.radioModel)
			: this.getSecondDatas(this.radioModel)
	}

	displaySale(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}

	private sale_filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.saleListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}

	maxColumns = 3
	columnArray: any = []
	currentSecondData: any[any] = []
	currentSecondDataTotal = { dname: '', ls: [] }
	displayedCompanyColumns = []
	checkDivide(number) {
		return number % 3 == 0
			? 'pivot_col_color_id_' + (number % 3) + ' border-it'
			: 'pivot_col_color_id_' + (number % 3)
	}
	columnTextColor(number) {
		return 'pivot_col_tcolor_id_' + (number % 3)
	}

	displayKiosk(item?: KioskList): string | undefined {
		return item ? item.Name : undefined
	}
	changeTab(number?) {
		if (number === 1 && !this.activeFirstTab && !this.loading) {
			this.activeFirstTab = true
			this.getFirstDatas()
		} else if (number === 2 && this.activeFirstTab && !this.loading) {
			this.activeFirstTab = false
			this.getSecondDatas(this.radioModel)
		}
	}
	firstDatasAll: any = []
	checkTotals(rowName) {
		let result = 0
		if (rowName === 'Diff') {
			let totals = 0,
				bills = 0
			this.firstDatasAll.forEach((el) => {
				totals += Number(el['TOTAL_AMOUNTS'])
				bills += Number(el['BILLS_AMOUNT'])
			})
			return totals - bills
		}
		if (this.firstDatasAll.length > 0) {
			this.firstDatasAll.forEach((el) => {
				result += Number(el[rowName])
			})
		}

		return result
	}
	pagingInfo: any = {
		TotalAmount: 0,
		TotalAmount2: 0,
		TotalAmount3: 0,
	}
	getFirstDatas(period?) {
		if (typeof period === 'number') {
			this.radioModel = period
		}

		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		// Take datas from form
		let user =
			typeof this.FilterForm.value.KioskId == 'object'
				? this.FilterForm.value.KioskId.Value
				: this.FilterForm.value.KioskId
		this.kioskService
			.getKioskEnc(
				start,
				finish,
				this.radioModel,
				user,
				this.currentPage + 1,
				this.paginationItemPerPage
			)
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						let datas = []
						let itemTotal = 0
						let totalRevenue = 0
						this.firstDatasAll = []
						;(this.pagingInfo.TotalAmount = 0),
							(this.pagingInfo.TotalAmount2 = 0),
							(this.pagingInfo.TotalAmount3 = 0)
						if (data.ReturnObject !== null) {
							datas = data.ReturnObject.DmsKioskEncashments
							itemTotal = data.ReturnObject.PagingInfo.TotalItems
							this.firstDatasAll = datas
							this.pagingInfo.TotalAmount =
								data.ReturnObject.PagingInfo.TotalAmount
							this.pagingInfo.TotalAmount2 =
								data.ReturnObject.PagingInfo.TotalAmount2
							this.pagingInfo.TotalAmount3 =
								data.ReturnObject.PagingInfo.TotalAmount3
						}
						this.paginationItems = itemTotal
						this.dataSource = new MatTableDataSource(datas) // array of datas
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	getSecondDatas(period?) {
		if (typeof period === 'number') {
			this.radioModel = period
		}

		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		// Take datas from form
		let user =
			typeof this.FilterForm.value.KioskId == 'object'
				? this.FilterForm.value.KioskId.Value
				: this.FilterForm.value.KioskId
		this.kioskService
			.getKioskTotals(
				start,
				finish,
				this.radioModel,
				user,
				this.currentPage,
				this.paginationItemPerPage
			)
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						let datas = []
						let itemTotal = 0
						let totalRevenue = 0
						if (data.ReturnObject !== null) {
							datas = data.ReturnObject.KioskEncashmentTotal
							this.secondTableSource.sort = this.sort // activate sort after insert datas
							// itemTotal = data.ReturnObject.PagingInfo.TotalItems;
						}
						// this.paginationItems = itemTotal;
						this.secondTableSource = new MatTableDataSource(datas) // array of datas
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	download(format) {
		this.isDownloadingFile = true
		this.loading = true
		let start = this.datePipe.transform(
			this.FilterForm.controls.dateFrom.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let finish = this.datePipe.transform(
			this.FilterForm.controls.dateTo.value,
			'yyyy-MM-ddTHH:mm:ss'
		)
		let user =
			typeof this.FilterForm.value.KioskId == 'object'
				? this.FilterForm.value.KioskId.Value
				: this.FilterForm.value.KioskId

		if (this.activeFirstTab) {
			this.kioskService
				.getFileEncash(
					start,
					finish,
					this.radioModel,
					user,
					format,
					this.currentPage + 1,
					this.paginationItemPerPage
				)
				.subscribe(
					(res) => {
						this.isDownloadingFile = false
						this.loading = false
						saveAs(res, finish + '.' + format, {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						})
					},
					(error) => {
						console.log(error)
						this.loading = false
						this.isDownloadingFile = false
					}
				)
		} else {
			// getFilePivot(date_from, date_to, period, login, format) {
			this.kioskService
				.getFilePivot(start, finish, this.radioModel, user, format)
				.subscribe(
					(res) => {
						this.isDownloadingFile = false
						this.loading = false
						saveAs(res, finish + '.' + format, {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						})
					},
					(error) => {
						console.log(error)
						this.loading = false
						this.isDownloadingFile = false
					}
				)
		}
	}
	onSubmit() {
		this.activeFirstTab
			? this.getFirstDatas(this.radioModel)
			: this.getSecondDatas(this.radioModel)
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}

	private _filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.dealerListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}

	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	CloseDialog(): void {
		this.dialog.closeAll()
	}
	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
			minHeight: '580px',
		})
	}

	ngOnInit() {
		this.paginationItemPerPage = 10
		this.FilterForm = new UntypedFormGroup({
			Dealer: new UntypedFormControl('', Validators.required),
			dateFrom: new UntypedFormControl(
				{ value: this.currentTime, disabled: true },
				Validators.required
			),
			dateTo: new UntypedFormControl(
				{ value: this.currentTimeEnd, disabled: true },
				Validators.required
			),
			Account: new UntypedFormControl(''),
			RefStan: new UntypedFormControl(''),
			UserName: new UntypedFormControl(''),
			ServiceDrop: new UntypedFormControl(''),
			ServiceSub: new UntypedFormControl(''),
			ChannelDrop: new UntypedFormControl(''),
			PaidTypeDrop: new UntypedFormControl(''),
			OperationDrop: new UntypedFormControl(''),
			KioskId: new UntypedFormControl(''),
		})
		this.pagingInfo = {
			TotalAmount: 0,
			TotalAmount2: 0,
			TotalAmount3: 0,
		}
		this.initKioskList()
		this.getFirstDatas()
	}
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'AmountPipe'
})
export class AmountPipe implements PipeTransform {
    transform(value: number, currency: string = ''): string {
        if (currency === undefined)
            currency = '';
        return (value / 100).toFixed(2) + ' ' + currency;
    }
}

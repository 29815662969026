import { Component, HostListener, OnInit, Input } from "@angular/core";
import { navItems } from "./../../_nav";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import * as screenfull from "screenfull";
import { MainService } from "../../_services/main.service";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import * as SecureLS from "secure-ls";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html"
})
export class DefaultLayoutComponent implements OnInit {
  public navItems = navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;
  mobileSidebarToggler;
  currentLang;
  constructor(
    public translate: TranslateService,
    private mainService: MainService,
    private router: Router,
    public route: ActivatedRoute,
    private titleService: Title
  ) {
    if (localStorage.getItem("currentLang")) {
      console.log("Cookie");
      this.currentLang = localStorage.getItem("currentLang");
    } else {
      this.currentLang = translate.getBrowserLang();
    }
    translate.addLangs(["en", "tr"]);
    // translate.setDefaultLang('en');
    const browserLang = this.currentLang; // This variable change lang
    translate.use(browserLang.match(/en|tr/) ? browserLang : "tr");
    // Takes info from Local Storage
    // 1st
    // translate.get('HOME.TITLE').subscribe((text) => {console.log(text);}); // Text in component
    // translate.stream('HOME.TITLE').subscribe((asd:any) => {
    //   console.log('i am ', asd)
    // });
    // 2nd
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event);
      this.currentLang = event.lang;
      localStorage.setItem("currentLang", this.currentLang);
    }); // Detect changes on the page
  }

  // Variables
  permissions = {};

  Info;
  showMenu: boolean = false;

  // End Variables
/*
  toggleFull() {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }
  */
  getCurrentTranslateRoute() {
    let str = this.titleService
        .getTitle()
        .match(/.+\|/)[0]
        .slice(0, -2),
      dms = "Dealer management system (DMS)";
    this.translate.get(`GLOBAL.TITLE_DMS`).subscribe(text => {
      dms = text;
    });
    str.match(/GLOBAL\./)
      ? this.translate.get(str).subscribe(text => {
          str = text;
        })
      : str;
    this.setTitle(`${str}  | ${dms}`);
    return str;
  }
  getUserDatas() {
    this.mainService
      .getProductPage()
      .pipe()
      .subscribe(
        data => {
          console.log(data);
          if (data.ResultCode === 0) {
            this.Info = data.ReturnObject;
            console.log(this.Info);
          }
          this.showMenu = true;
        },
        error => {
          console.log(error);
          this.showMenu = false;
        },
        () => {
          this.getRoles();
        }
      );
  }

  getRoles() {
    let ls = new SecureLS(); // for Secure Local storage
    this.mainService
      .getRoles()
      .pipe()
      .subscribe(
        data => {
          console.log(data);
          if (data.ResultCode === 0) {
            ls.set("permissions", data.ReturnObject);
          }
        },
        error => {
          console.log(error);
          this.showMenu = false;
        },
        () => {
          if (ls.get("permissions") !== null) {
            this.permissions = ls.get("permissions");
            this.showMenu = true;
            let path = this.route.children[0].data["value"].pathCheck;
            if (
              typeof this.permissions[path] === "undefined" &&
              typeof path !== "undefined"
            ) {
              this.router.navigate(["/dashboard"]);
            }
          } else {
            this.router.navigate(["/login"]);
          }
        }
      );
  }
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  getNotification() {
    this.mainService
      .getNotification(this.Info.User.UserId)
      .pipe()
      .subscribe(
        data => {
          console.log(data);
          setTimeout(() => {
            this.getNotification();
          }, 60000);
        },
        error => {
          console.log(error);
          setTimeout(() => {
            this.getNotification();
          }, 60000);
        }
      );
  }

  ngOnInit() {
    this.getUserDatas();
  }

  ngOnDestroy() {
    // this.getNotification = function () { };
    sessionStorage.removeItem("dealerTreeList");
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import * as SecureLS from 'secure-ls';
import { environment } from './../../environments/environment';


const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        'Accept-Language': 'en-EN',
    })
};

const httpJsonOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept-Language': 'en-EN',
    })
};
@Injectable()
export class AuthenticationService {
    basUrl: string = environment.apiUrl;

    constructor(private http: HttpClient, private router: Router) { }
    /*
        login(username: string, password: string, language: string) {
            return this.http.post<any>(`/users/authenticate`, { username: username, password: password })
                .pipe(map(user => {
                    if (user && user.token){
                        localStorage.setItem('currentUser', JSON.stringify({ token: user.token, login: user.firstName}));
                        localStorage.setItem('currentLang', language);
                    }
                    return user;
                }));
        }
        */
    // For web Api new   var parameter = JSON.stringify({type:"user", username:user_email, password:user_password});
    login(username: string, password: string, language: string) {

        let body = new HttpParams();
        body = body.set('username', username);
        body = body.set('password', password);
        body = body.set('grant_type', 'password');
        body = body.set('versionName', 'v0.1');
        body = body.set('versionCode', '3');

        //var parameters = JSON.stringify({ grant_type: "password", Login: username, Password: password });

        return this.http.post<any>(this.basUrl+ `/auth`, body, { headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' } })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                if (user !== null) {
                    user.resultCode = 0;
                    // let date = user.expires_in; // takes from api time
                    let date = user.expires_in - 600; // takes from api time -600 sec(10 min) 
                    date = date * 1000;
                    let currentTimeLogin = new Date().valueOf();
                    let expiration_date = currentTimeLogin + date;
                    localStorage.setItem('currentUser', JSON.stringify({ token: user.access_token, login: username, tokenTime: expiration_date }));
                    localStorage.setItem('currentLang', language);
                    let ls = new SecureLS(); // for Secure Local storage
                    ls.set('permissions', {
                        pages: {
                            dashboard: {
                                state: true,
                            },
                        }
                    });
                } else {
                    user = new User();
                    user.resultCode = 64;
                    user.msg = "Invalid login or password !";
                }
                return user;
            }));
    }


    isTokenExpired(): boolean {
        let token = JSON.parse(localStorage.getItem('currentUser'));
        if (!token) return true;

        const date = token.tokenTime;

        if (date === undefined) return false;

        return !(date.valueOf() > new Date().valueOf());
    }

    checkTokenResponse(): boolean {
        if (this.isTokenExpired()) {
            this.router.navigate(['/login'], { queryParams: { error: '401' } });
            return false;
        }
        return true;
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem('permissions');
        localStorage.removeItem('providerAll');
        localStorage.removeItem('providerTop');
        localStorage.removeItem('providerGroup');
        localStorage.removeItem('dealerAllList');
    }
    // For web Api old
    // constructor(private http: HttpClient) { }
    // login(username: string, password: string){
    //   const datas = 'grant_type=password&username='+username+'&password='+password;
    //   return this.http.post<any>(`https://bypasscors.herokuapp.com/api/?url=https://test.paynet.md:4462/auth`,  datas, httpOptions)
    //     .pipe(map(user => {
    //       // login successful if there's a jwt token in the response
    //       if (user && user.token) {
    //         // store user details and jwt token in local storage to keep user logged in between page refreshes
    //         localStorage.setItem('currentUser', JSON.stringify(user));
    //       }
    //       return user;
    //     }));
    // }
    //
}

export class User {
    token: string;
    login: string;
    tokenTime: string;
    resultCode: number;
    msg: string;
}

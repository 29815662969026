import { Component, OnInit, ViewChild, HostListener, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatTreeNestedDataSource } from "@angular/material/tree";

import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DateAdapter } from '@angular/material/core';

import { AdministrationService } from "../../_services/administration.service";
import { PaymentService } from "../../_services/payment.service";
import { NestedTreeControl } from "@angular/cdk/tree";
import { saveAs } from "file-saver"

import { DatePipe } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { AlertService, UserService, ProviderService } from '../../_services';
import { Console } from 'console';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { ToastrService } from 'ngx-toastr';


interface DealerNode {
  name: string;
  children?: DealerNode[];
}
@Component({
  templateUrl: 'payment-cashless.component.html',
  styleUrls: ['payment-page.scss'],
  providers: [DatePipe],
})
export class PaymentCashlessComponent implements OnInit {
  constructor(public dialog: MatDialog,
    private translate: TranslateService,  
    // Make service for post api datas
    private datePipe: DatePipe,
    private dateAdapter: DateAdapter<Date>,
    private administrationService: AdministrationService,
    private paymentService: PaymentService,
    public toastr: ToastrService) {
      dateAdapter.setLocale("en-EN"); // change this for change format date in select
    }
  FilterForm: UntypedFormGroup; // Form initial 
  isLoading: boolean = false; // Disable page when is loading datas
  currentTime = new Date();
  showTree: boolean = false;
  dealerFControl = new UntypedFormControl(); // Dealer
  currentDealer = {
    Name: "",
    Value: "",
  };
  treeData;
  loading = false; // for disable fast response click
  radioModel = 1;
  dataTreeSource = new MatTreeNestedDataSource<DealerNode>();
  serviceSubSelected: any = "";
  public currentPage = 0;
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api
  dataSource = new MatTableDataSource(); // array of datas
  hasChild = (_: number, node: DealerNode) =>
    !!node.children && node.children.length > 0;
  treeControl = new NestedTreeControl<DealerNode>((node) => node.children);
  minDate = new Date(2000, 0, 1); // Date picker min date
  maxDate = new Date(new Date().getFullYear() + 5, 0, 1); // Date picker max date
  fileToUpload: string | null = null;
  isUploading: boolean = false;
  paymentStartButtonState = true
  dataFileSource = new MatTableDataSource();
  datas = null;
  totalAmount = 0;
  totalRows = 0;
  totalSuccess = 0;
  totalProblem = 0;
  pageEvent;
  userName = "";
  fileName = "";
  displayedColumns: string[] = [
    "FileNumber",
    "UploadDate",
    "DealerName",
    "UserName",
    "FileName",
    "Rows",
    "TotalAmount",
    "Success",
    "Problem",
    "Status",
    "Action",
  ];

  statusLists = [
    {
      text: "Loaded",
      value: 0,
      color: "badge-info",
    },
    {
      text: "Started",
      value: 1,
      color: "badge-warning",
    },
    {
      text: "InProgress",
      value: 2,
      color: "badge-warning",
    },
    {
      text: "Completed",
      value: 3,
      color: "badge-success",
    },
    {
      text: "Completed with Error",
      value: 4,
      color: "badge-danger",
    },
    {
      text: "Rejected",
      value: 5,
      color: "badge-danger",
    },
    {
      text: "Paused",
      value: 6,
      color: "badge-info",
    }
  ];

  ngOnInit(): void {
    this.paginationItemPerPage = 10;
    console.log('cashless')
    this.isLoading = true;
    this.FilterForm = new UntypedFormGroup({
      Dealer: new UntypedFormControl(), //, [this.dealerDropValidator(this.options)]),
      dateFrom: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      dateTo: new UntypedFormControl(
        { value: this.currentTime, disabled: true },
        Validators.required
      ),
      FileName: new UntypedFormControl(""),
      UserName: new UntypedFormControl(""),
    });
    this.InitDealerFilter();

  }

  growTree(arr) {
    let storageData = JSON.parse(sessionStorage.getItem("dealerTreeList"));
    this.dataTreeSource = new MatTreeNestedDataSource<DealerNode>();
    let mainArray = arr;
    if (!storageData) {
      mainArray.map((x) => {
        x.children = [];
      });
      mainArray.map((z) => {
        if (z.Id !== 0) {
          let currentEl = mainArray.find((x) => x.Id === z.ParentId);
          if (currentEl) {
            currentEl.children.push(z);
          }
        }
      });
      let result = mainArray.splice(0, 1);
      this.treeData = result;
      sessionStorage.setItem("dealerTreeList", JSON.stringify(result));
      this.dataTreeSource = result;
    } else {
      this.dataTreeSource = storageData;
    }
  }
  CloseDialog(): void {
    this.dialog.closeAll();
  }
  chooseDealer(smth) {
    console.log('chooseDealer', smth);
    this.CloseDialog();
    this.dealerFControl.patchValue(smth.Name);
    this.currentDealer = { Value: smth.Id, Name: smth.Name };
    this.showTree = false;
    console.log("Choose Dealer value:", this.currentDealer);
    this.getFirstDatas();
  }
  stopClick(event) {
    event.preventDefault();
  }
  //----------------------- get a dealer list and put to option's
  InitDealerFilter() {

    this.administrationService
      .getDealerDatasAsList()
      .pipe(first())
      .subscribe(
        (data) => {
          this.treeData = data.ReturnObject;
          this.dealerFControl.setValue(this.currentDealer.Name);
          this.growTree(data.ReturnObject);
          this.isLoading = false;
        },
        (error) => {
          console.log(error);
        }
        ,
        () => {
          this.getFirstDatas(this.radioModel);
        }
      );

  }



  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas(this.radioModel);
  }
  PaginationCurentPageReset() {
    this.currentPage = 0;
  }
  getFirstDatas(period?) {
    if (typeof period === "number") this.radioModel = period;

    this.totalAmount = 0;
    this.totalRows = 0;
    this.totalSuccess = 0;
    this.totalProblem = 0;
    this.loading = true;
    let start = this.datePipe.transform(
      this.FilterForm.controls.dateFrom.value,
      "yyyy-MM-ddT00:00:00"
    );
    let finish = this.datePipe.transform(
      this.FilterForm.controls.dateTo.value,
      "yyyy-MM-ddT23:59:59"
    );
    // Take datas from form
    let dealerId = this.currentDealer.Value;
    let filter = this.FilterForm.value;
    let service =
      this.serviceSubSelected[0] !== "" ? this.serviceSubSelected : "";
    filter.FileName = filter.FileName != "" ? filter.FileName : "";
    filter.UserName = filter.UserName != "" ? filter.UserName : "";
    this.paymentService.getCashlessPayment(this.radioModel, start, finish, filter.FileName, filter.UserName, this.currentPage + 1, this.paginationItemPerPage, dealerId)
      .subscribe(
        (data) => {
          console.log(data);
          if (data.ResultCode === 0) {

            let itemTotal = 0;
            let itemPerPage = this.paginationItemPerPage;
            if (data.ReturnObject !== null) {
              this.datas = data.ReturnObject.PaymentFile;
              itemTotal = data.ReturnObject.Pagination.ItemTotal;
              itemPerPage = data.ReturnObject.Pagination.ItemPerPage;
              this.totalAmount = data.ReturnObject.Totals.TotalAmount;
              this.totalRows = data.ReturnObject.Totals.TotalRows;
              this.totalProblem = data.ReturnObject.Totals.TotalProblem;
              this.totalSuccess = data.ReturnObject.Totals.TotalSuccessed;
            }
            this.paginationItemPerPage = itemPerPage;
            this.paginationItems = itemTotal;
            this.dataSource = new MatTableDataSource(this.datas); // array of datas
          }
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      );

  }
      dialogText = '';
      currentFile = null;
      openPayConfirmationDialog(templateRef, rowId) {

        if (this.datas != null) {    
          this.currentFile = this.datas.find(x => x.ID == rowId);

          if (this.currentFile != null) {
            this.loading = true;
            this.paymentService.getDealerDeposit(this.currentFile.ID)
              .subscribe((data) => {
                if (data.ReturnObject != null) {         
                    this.dialogText = data.ResultMessage   
                    this.paymentStartButtonState = data.ReturnObject
                                 
                    const dialogRef = this.dialog.open(templateRef, {
                            panelClass: "modal_transaction"
                    });
                }
                this.loading = false;
              },
                (error) => {
                  this.loading = false;
                  console.log(error); 
                  let theme;
                  let body;
                  this.translate.get("PAYMENT.THEME_PAY_START_FAIL").subscribe((text) => {
                    theme = text
                  })
                  this.translate.get("PAYMENT.BODY_PAY_START_FAIL").subscribe((text) => {
                    body = text
                  })
                  this.toastr.error(  body, theme)
                })

          }
        }    
  }

  openRejectDialog(templateRef, rowId) {
    if (this.datas != null) {
      this.currentFile = this.datas.find(x => x.ID == rowId);
      if (this.currentFile != null) {

      }
    }

    this.loading = false;
    const dialogRef = this.dialog.open(templateRef, {
      panelClass: "modal_transaction"
    });
  }
  openPauseDialog(templateRef, rowId) {
    if (this.datas != null) {
      this.currentFile = this.datas.find(x => x.ID == rowId);
      if (this.currentFile != null) {

      }
    }

    this.loading = false;
    const dialogRef = this.dialog.open(templateRef, {
      panelClass: "modal_transaction"
    });
  }

  processPause() {
 
    if (this.currentFile == null) {
      return;
    }
    this.loading = true
    let fileId = this.currentFile.ID;
    this.paymentService.proccessPause(fileId)
      .subscribe(
        (data) => {
          let theme = ""
          if (data != null) {
              if (data.ResultCode === 0) {
                this.loading = false
                this.translate.get("PAYMENT.THEME_PAY_PAUSE_SUCCESS").subscribe((text) => {
                  theme = text
                })           
                this.toastr.success(data.ResultMessage, theme)

                this.getFirstDatas();
              } else {
                this.translate.get("PAYMENT.THEME_PAY_PAUSE_FAIL").subscribe((text) => {
                  theme = text
                })    
                this.toastr.error(data.ResultMessage, theme)
              }
              this.loading = false
          } else 
          {
            this.loading = false
          }
          this.CloseDialog()
        },
        (error) => {
          let theme = ""
          let body = ""
          this.translate.get("PAYMENT.THEME_PAY_PAUSE_FAIL").subscribe((text) => {
            theme = text
          })
          this.translate.get("PAYMENT.BODY_PAY_PAUSE_FAIL").subscribe((text) => {
            body = text
          })
          this.toastr.error(body, theme)
         
          this.loading = false
          console.log(error)
          this.CloseDialog()

        },
        () => {
          this.isUploading = false

        })
  }

  processPay() {
    if (this.currentFile == null) {
      return;
    }
    this.loading = true
    let fileId = this.currentFile.ID;
    this.paymentService.proccessPay(fileId)
      .subscribe(
        (data) => {
          let theme = ""
          if (data != null) {
            if (data.ResultCode === 0) {

              this.loading = false
              this.translate.get("PAYMENT.THEME_PAY_START_SUCCESS").subscribe((text) => {
                theme = text
              })

              this.toastr.success(data.ResultMessage, theme)

              this.getFirstDatas();
            } else {
              this.translate.get("PAYMENT.THEME_PAY_START_FAIL").subscribe((text) => {
                theme = text
              })

              this.toastr.error(data.ResultMessage, theme)
            }
            this.loading = false
          } else {
            this.loading = false
          }
          this.CloseDialog()
        },
        (error) => {
          this.loading = false
          console.log(error)
          this.CloseDialog()

        },
        () => {
          this.isUploading = false

        })
  }
  processReject() {
    if (this.currentFile == null) {
      return;
    }
    let fileId = this.currentFile.ID;
    this.loading = true;
    let theme = "";
    let body = "";
    this.paymentService.proccessReject(fileId)

      .subscribe(
        (data) => {
          if (data != null) {
            if (data.ResultCode === 0) {    

                this.translate.get("PAYMENT.THEME_REJECT_SUCCESS").subscribe((text) => {
                theme = text
              })
              this.toastr.success(data.ResultMessage, theme)
            } else {
              this.translate.get("PAYMENT.THEME_REJECT_FAIL").subscribe((text) => {
                theme = text
              })
              this.toastr.success(data.ResultMessage, theme)
            }          
          } 
          this.loading = false
          this.CloseDialog()
          this.getFirstDatas()
        },
        (error) => {
          this.translate.get("PAYMENT.THEME_REJECT_FAIL").subscribe((text) => {
            theme = text
          })
          this.translate.get("PAYMENT.BODY_REJECT_FAIL").subscribe((text) => {
            body = text
          })
          this.toastr.error(error.length > 0 ?
            error : body, theme)
          this.loading = false
          console.log(error)
          this.CloseDialog()

        })
  }

  processDownload(fileId) {
    this.loading = true
    this.paymentService.getMassPaymentFile(fileId).pipe().subscribe(
      (res) => {
        this.loading = false
        let fileName = `mass_payment_${fileId}.xlsx`;
        const contentDisposition = res.headers.get('Content-Disposition');
        if (contentDisposition) {
          const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = fileNameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
          }
        }
        saveAs(
          res.body,
          fileName
          , {
            type:
              "application/vnd.ms-excel;charset=utf-8",
          })
      },
      (error) => {
        console.log(error)
        this.loading = false
      }
    )

  }
  onSubmit() {
    this.getFirstDatas(this.radioModel);
  }

  handleFileInput(files: FileList, target) {
    this.fileToUpload = null
    this.isUploading = true
    if (files && files[0]) {
      let reader: any,
        _name = files[0].name
      if (_name.length < 3 || _name.length > 128 || this.currentDealer.Value == "") {
        let theme = ""
        let body = ""
        this.translate.get("PAYMENT.THEME_FILEUPLOAD_FAIL").subscribe((text) => {
          theme = text
        })
        this.translate.get("PAYMENT.BODY_FILEUPLOAD_" + (this.currentDealer.Value == "" ?"DEALER_WRONG": "LENGTH_ER")).subscribe((text) => {
          body = text
        })
        this.toastr.error(body, theme)

      }
      else {
        reader = new FileReader()
        reader.readAsDataURL(files[0]) // read file as data url
        reader.onload = (event) => {
          this.fileToUpload = event.target.result.split(",")[1]
          if (!this.fileToUpload) { return false }

          this.SendFile(this.fileToUpload, _name)  
        }
      }
      target.value = ""
    }
  }

  SendFile(file, fileName) {
    this.dataFileSource = new MatTableDataSource([])
    this.loading = true
    let dealerId = this.currentDealer.Value;
    let theme = ""
    let body = ""
    this.paymentService.uploadCashlessFile(file, fileName, dealerId)
      .subscribe(
        (data) => {

          if (data != null) {
            if (data.ResultCode === 0) {
              if (data.ReturnObject !== null) {

                this.loading = false
                this.translate.get("PAYMENT.THEME_FILEUPLOAD_SUCCESS").subscribe((text) => {
                  theme = text
                })
          
                this.toastr.success(data.ResultMessage, theme)
                this.getFirstDatas();

              } else {
                this.loading = false
                this.translate.get("PAYMENT.THEME_FILEUPLOAD_FAIL").subscribe((text) => {
                  theme = text
                })
          
                this.toastr.error(data.ResultMessage, theme)
                this.loading = false

              }
            } else {
              this.translate.get("PAYMENT.THEME_FILEUPLOAD_FAIL").subscribe((text) => {
                theme = text
              })
        
              this.toastr.error(data.ResultMessage, theme)
            }
            this.loading = false
          } else {
            this.loading = false
          }
        },
        (error) => {

          this.translate.get("PAYMENT.THEME_FILEUPLOAD_FAIL").subscribe((text) => {
            theme = text
          })
          this.translate.get("PAYMENT.BODY_FILEUPLOAD_FAIL").subscribe((text) => {
            body = text
          })
          this.toastr.error(body, theme)

          this.loading = false
          console.log(error)
          this.CloseDialog()

        },
        () => {
          this.isUploading = false

        }
      )
  }
}

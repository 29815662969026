import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { environment } from './../../environments/environment';


@Injectable()

export class MainService {

    constructor(private http: HttpClient, private authService: AuthenticationService) {


    }
    basUrl: string = environment.apiUrl;

    getProductPage() {
        return this.http.get<any>(this.basUrl+`/api/profile/get?Version=3&VersionCode=4`)
            .pipe(map(response => {
                if (this.authService.checkTokenResponse()) {
                    return response;
                }
                // login successful if there's a jwt token in the response
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                
            }));
    }
    getNotification(id){
        return this.http.get<any>(this.basUrl+`/api/account/TimeGet?id=` + id)
        .pipe(map(response => {
            if (this.authService.checkTokenResponse()) {
                return response;
            }
        }));
    }

    getRoles(){
        return this.http.get<any>(this.basUrl+`/api/profile/RightGets`)
        .pipe(map(response => {
            if (this.authService.checkTokenResponse()) {
                return response;
            }
        }));
    }
    
}



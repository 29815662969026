import { Injectable } from '@angular/core'
import {
	HttpClient,
	HttpHeaders,
	HttpParams,
	HttpClientModule,
} from '@angular/common/http'
import { map, first } from 'rxjs/operators'
import { AuthenticationService } from './authentication.service'
import { Cacheable, CacheBuster } from 'ngx-cacheable'
import { Subject } from 'rxjs'
import { Validators } from '@angular/forms'
import { environment } from './../../environments/environment'

// const httpOptions = {
//   headers: new HttpHeaders({
//     'Content-Type':  'application/x-www-form-urlencoded;charset=UTF-8',
//     'Accept-Language': 'en-EN',
// })
// };
const cacheBuster$ = new Subject<void>()

@Injectable()
export class AdministrationService {
	constructor(
		private http: HttpClient,
		private authService: AuthenticationService
	) {}
	basUrl: string = environment.apiUrl

	// _______________   Dealer page

	// @Cacheable({
	// 	cacheBusterObserver: cacheBuster$
	// })
	getDealerDatas(dealerId, page, itemsPerPage) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/dealer/Gets?id=` +
					dealerId +
					`&page=` +
					page +
					`&items=` +
					itemsPerPage
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	// @CacheBuster({
	// 	cacheBusterNotifier: cacheBuster$
	// })
	getRevenueConfig(id) {
		return this.http.get<any>(`/api/dealer/RevenueConfig?id=` + id).pipe(
			map((response) => {
				return response
			})
		)
	}

	getChangeLog(id) {
		return this.http
			.get<any>(`/api/dealer/RevenueConfigHistory?id=` + id)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	saveRevenueUpdateItemList(data) {
		let body = {
			Items: data,
		}
		return this.http
			.post<any>(`/api/dealer/RevenueUpdateItemList`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					//Save Dealer Info if token exist
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getDealerInfo(id) {
		return this.http
			.get<any>(this.basUrl + `/api/dealer/Get?id=` + id)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getCurrentProfileId() {
		return this.http
			.get<any>(this.basUrl + `/api/profile/Get?Version=&VersionCode=`)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getDealerDatasAsList() {
		return this.http.get<any>(this.basUrl + `/api/dealer/GetsAll`).pipe(
			map((response) => {
				return response
			})
		)
	}

	saveDeposit(data) {
		let body = {
			DealerId: data.Id,
			Amount: data.ChangeAmount * 100,
			Note: data.Comment,
			SendNote: data.SendEmail,
			Type: data.Type,
			TransactionType: data.Action,
		}
		return this.http
			.post<any>(this.basUrl + `/api/dealer/DepositUpdate`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					//Save Dealer Info if token exist
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	saveServicesLicense(data) {
		return this.http
			.post<any>(this.basUrl + `/api/Licence/Update`, data, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	saveServicesNewLicense(data) {
		return this.http
			.post<any>(this.basUrl + `/api/Licence/Create`, data, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	editCompany(oldData, newData, key) {
		let body = {
			General: {
				CompanyId: oldData.General.CompanyId,
				Order: newData.Order,
				Popularity: newData.Popularity,
				CurrentGroupKey: newData.Group,
				CompanyKey: key,
				Strings: [],
			},
			Profile: {
				ProviderId: newData.ProvidersList,
			},
		}
		let counter = 0
		oldData.General.Strings.forEach((string) => {
			body.General.Strings.push({
				Key: string.Key,
				LanguageKey: string.LanguageKey,
				Name: newData['Name_' + counter],
				Description: newData['Description_' + counter],
			})
			counter++
		})
		return this.http
			.post<any>(this.basUrl + `/api/provider/EditCompany`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	saveNewCompany(oldData, newData, key) {
		let body = {
			General: {
				CompanyId: newData.CompanyId,
				Order: newData.Order,
				Popularity: newData.Popularity,
				CurrentGroupKey: newData.Group,
				CompanyKey: key,
				Strings: [],
			},
			Profile: {
				ProviderId: '',
			},
		}
		let counter = 0
		oldData.General.Strings.forEach((string) => {
			body.General.Strings.push({
				Key: string.Key,
				LanguageKey: string.LanguageKey,
				Name: newData['Name' + counter],
				Description: newData['Description' + counter],
			})
			counter++
		})
		return this.http
			.post<any>(this.basUrl + '/api/provider/SaveNewCompany', body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	// _____________ Users Page
	// int? pDealerid=null ,string pEmail=null , string pName=null , string pLogin = null, int? page = null, int? items = null

	getUsersList(
		DealerId = null,
		Email = null,
		Name = null,
		Login = null,
		Status = null,
		CurrentPage,
		ItemsPerPage
	) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/User/Gets?pDealerid=${DealerId}&pEmail=${Email}&pName=${Name}&pLogin=${Login}&pStatus=${Status}&page=${CurrentPage}&items=${ItemsPerPage}`
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getUserInfo(userId) {
		return this.http
			.get<any>(this.basUrl + `/api/User/Get?id=${userId}`)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	changeStatus(userId: string, status: string) {
		let body = new HttpParams()
		body = body.set('UserId', userId)
		body = body.set('Status', status)

		return this.http
			.post<any>(`/api/User/ChangeStatus`, body, {
				headers: {
					'Content-Type':
						'application/x-www-form-urlencoded;charset=UTF-8',
				},
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	// Fisc
	getFisc(id) {
		return this.http
			.get<any>(`/api/User/Fisc?id=${id}`, {
				headers: { 'Accept-Language': this.GetLang() },
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	endFiscSession(id) {
		return this.http
			.post<any>(`/api/User/FiscSession?id=${id}`, {
				headers: { 'Accept-Language': this.GetLang() },
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	saveFisc(data) {
		return this.http
			.post<any>(`/api/User/Fisc`, data, {
				headers: {
					'Content-Type': 'application/json',
					'Accept-Language': this.GetLang(),
				},
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	getRolesUser() {
		return this.http.get<any>(this.basUrl + `/api/User/AllRoles/`).pipe(
			map((response) => {
				if (this.authService.checkTokenResponse()) {
					return response
				}
			})
		)
	}
	//-------------------------- update a profile of dealer user ------------------------------------------
	editUser(defaultDatas, newUserDatas, rolesQuantity) {
		let RoleList = []
		for (let i = 0; i < rolesQuantity; i++) {
			RoleList.push({
				ItemKey: newUserDatas['Key_' + i],
				IsOn: newUserDatas['Value_' + i],
			})
		}
		let Info = {
			Key: defaultDatas.UserKey,
			DealerUserId: defaultDatas.Id,
			Login: newUserDatas.Login,
			UserName: newUserDatas.UserName,
			UserLastName: newUserDatas.UserSurname,
			Email: newUserDatas.UserEmail,
			UserMobileNum: newUserDatas.UserMobile,
			Status: newUserDatas.Status ? 1 : 0,
			UserLangKey: newUserDatas.Language,
			Address: newUserDatas.Address,
			Latitude: +newUserDatas.Coordx,
			Longitude: +newUserDatas.Coordy,
			UserSalePointId: newUserDatas.SalePointTypeId,
			Roles: RoleList,
		}
		return this.http
			.post<any>(this.basUrl + `/api/User/UserDealerUpdate`, Info, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	//-------------------- create a new dealer user --------------------------------
	CreateUser(DealerId, newUserDatas, rolesQuantity) {
		let Roles = []
		for (let i = 0; i < rolesQuantity; i++) {
			Roles.push({
				ItemKey: newUserDatas['Key_' + i],
				IsOn: newUserDatas['Value_' + i],
			})
		}

		let info = {
			Address: newUserDatas.Address,
			UserLogin: newUserDatas.Login,
			Status: newUserDatas.Status ? 1 : 0,
			UserName: newUserDatas.UserName,
			Email: newUserDatas.UserEmail,
			UserMobileNum: newUserDatas.UserMobile,
			UserLastName: 'TestPost8',
			UserLangKey: newUserDatas.Language,
			UserSalePointId: newUserDatas.SalePointTypeId,
			DealerId: DealerId,
			Latitude: +newUserDatas.Coordx,
			Longitude: +newUserDatas.Coordy,
			Roles: Roles,
		}

		return this.http
			.post<any>(this.basUrl + `/api/User/UserDealerCreate`, info, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	saveDealerInfo(newDealerDatas) {
		let body = {
			ParentId: '1',
			DealerId: newDealerDatas.DealerId,
			DealerName: newDealerDatas.DealerName,
			Email: newDealerDatas.Email,
			Address: newDealerDatas.Address,
			PhoneNumber: newDealerDatas.PhoneNumber,
			Status: newDealerDatas.Status,
			WorkMode: newDealerDatas.WorkMode,
			SendTransactionSummary: newDealerDatas.SendSummary,
			// 'RegionKey': newDealerDatas.RegionKey,
			// 'CityKey': newDealerDatas.CityKey,
			InstListId: newDealerDatas.License,
			AbleCreateNewDealer: +newDealerDatas.AbleCreateNewDealer,
			// 'CheckAmountBalance': +newDealerDatas.CheckAmountBalance,
			Min: newDealerDatas.Min * 100,
			Max: newDealerDatas.Max * 100,
			UseBalance: newDealerDatas.UseBalance == true ? 1 : 0,
			NotificationBalanceValue: newDealerDatas.SumMin * 100,
			NotificationBalance: +newDealerDatas.NotificationBalance,
			CheckMinMax: newDealerDatas.CheckMinMax,
			ContractNumber: newDealerDatas.ContractNumber,
		}
		return this.http
			.post<any>(this.basUrl + `/api/dealer/dealerupdate`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	saveNewDealer(newDealerDatas) {
		let body = {
			ParentId: newDealerDatas.ParentId,
			DealerName: newDealerDatas.DealerName,
			Email: newDealerDatas.Email,
			Address: newDealerDatas.Address,
			PhoneNumber: newDealerDatas.PhoneNumber,
			Status: newDealerDatas.Status,
			WorkMode: newDealerDatas.WorkMode,
			SendTransactionSummary: newDealerDatas.SendSummary,
			// 'RegionKey': newDealerDatas.RegionKey,
			// 'CityKey': newDealerDatas.CityKey,
			InstListId: newDealerDatas.License,
			AbleCreateNewDealer: +newDealerDatas.AbleCreateNewDealer,
			CheckAmountBalance: +newDealerDatas.CheckAmountBalance,
			Min: newDealerDatas.Min * 100,
			Max: newDealerDatas.Max * 100,
			NotificationBalanceValue: newDealerDatas.SumMin * 100,
			NotificationBalance: +newDealerDatas.NotificationBalance,
			CheckMinMax: newDealerDatas.CheckMinMax,
			ContractNumber: newDealerDatas.ContractNumber,
			UseBalance: +newDealerDatas.UseBalance,
		}
		return this.http
			.post<any>(this.basUrl + `/api/Dealer/DealerCreate`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	changePassword(
		userId: string,
		new_password: string,
		confirm_password: string
	) {
		let body = new HttpParams()
		body = body.set('UserId', userId)
		body = body.set('PasswordNew', new_password)
		body = body.set('PasswordConfirm', confirm_password)
		// let body = {
		//     "UserId": userId,
		//     "PasswordNew": new_password,
		//     "PasswordConfirm": confirm_password
		// };
		// var parameters = JSON.stringify({ UserId: userId, PasswordNew: new_password, PasswordConfirm: password });

		return this.http
			.post<any>(this.basUrl + `/api/User/PasswordSet`, body, {
				headers: {
					'Content-Type':
						'application/x-www-form-urlencoded;charset=UTF-8',
					'Accept-Language': this.GetLang(),
				},
			})
			.pipe(
				map((response) => {
					// login successful if there's a jwt token in the response
					// store user details and jwt token in local storage to keep user logged in between page refreshes
					return response
				})
			)
	}

	getCities() {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/Entries/GetCityList?CurrentPage=1&ItemsPerPage=1000`
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getLocation(id) {
		return this.http
			.get<any>(this.basUrl + `/api/Entity/GetLocation?id=` + id)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getLicenses(currPage = null, itemPerPage = null, name = null) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/Licence/Gets?CurPage=${currPage}&ItemPerPage=${itemPerPage}&pName=${name}`,
				{
					headers: { 'Accept-Language': this.GetLang() },
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getLicensesServices(id) {
		return this.http
			.get<any>(this.basUrl + `/api/Licence/GetGroup?id=${id}`, {
				headers: { 'Accept-Language': this.GetLang() },
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getSalePoints(all?: string) {
		if (all) {
			return this.http
				.get<any>(
					this.basUrl + `/api/Entries/GetSalePointList?id=` + all
				)
				.pipe(
					map((response) => {
						return response
					})
				)
		}
		return this.http
			.get<any>(this.basUrl + `/api/Entries/GetSalePointList`)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getLanguagesList() {
		return this.http.get<any>(this.basUrl + `/api/Entries/languages`).pipe(
			map((response) => {
				if (this.authService.checkTokenResponse()) {
					return response
				}
			})
		)
	}

	getFieldDetail(companyKey, fieldKey) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/provider/GetFieldsDetails?pCompanyKey=` +
					companyKey +
					`&pKey=` +
					fieldKey +
					`&pFieldServiceId=&pTemplateType=0`
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	// Translate page
	getFirstDropdown() {
		return this.http.get<any>(this.basUrl + `/api/Entity/GetObjTypes`).pipe(
			map((response) => {
				return response
			})
		)
	}

	generateDropdown(id) {
		return this.http
			.get<any>(this.basUrl + `/api/Entity/GetSysTypeList?objType=` + id)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getTranslates(form, page, pagination) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/entity/GetSimpleList?TypeKey=` +
					form.Type +
					`&Name=` +
					form.Name +
					`&CodeStr=` +
					form.Code +
					`&CodeNum=` +
					form.NrCode +
					`&OrderBy=` +
					form.Sort +
					`&CurrentPage=` +
					page +
					`&Items=` +
					pagination,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	newTranslate(form, type_key, editor_eng, editor_my) {
		let body = {
			Key: '',
			TypeKey: type_key,
			Code: form.Code,
			CodeNum: form.NrCode,
			NameDef: form.Name,
			DescriptionDef: form.Description,
			StringSave: [],
		}
		if (
			(form.English !== null && form.English.length > 0) ||
			(form.Myanmar !== null && form.Myanmar.length > 0) ||
			(form.En_Desc !== null && form.En_Desc.length > 0) ||
			(form.My_Desc !== null && form.My_Desc.length > 0)
		) {
			if (editor_eng.length) {
				body.StringSave.push({
					Name: form.English,
					Description: editor_eng,
					LanguageCode: 'en',
				})
			} else {
				body.StringSave.push({
					Name: form.English,
					Description: form.En_Desc,
					LanguageCode: 'en',
				})
			}
			if (editor_my.length) {
				body.StringSave.push({
					Name: form.Myanmar,
					Description: editor_my,
					LanguageCode: 'tr',
				})
			} else {
				body.StringSave.push({
					Name: form.Myanmar,
					Description: form.My_Desc,
					LanguageCode: 'tr',
				})
			}
		}
		return this.http
			.post<any>(this.basUrl + `/api/Entity/SaveSimple`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	changeTranslate(form, type_key, keys_api, editor_eng, editor_my) {
		let body = {
			Key: form.Key,
			TypeKey: type_key,
			Code: form.Code,
			CodeNum: form.NrCode,
			NameDef: form.Name,
			DescriptionDef: form.Description,
			StringSave: [],
		}
		let api_k = {
			en: '',
			tr: '',
		}
		keys_api.Strings.forEach((lang) => {
			api_k[lang.LanguageCode] = lang.Key
		})
		if (
			(form.English !== null && form.English.length > 0) ||
			(form.Myanmar !== null && form.Myanmar.length > 0) ||
			(form.En_Desc !== null && form.En_Desc.length > 0) ||
			(form.My_Desc !== null && form.My_Desc.length > 0)
		) {
			if (editor_eng.length) {
				body.StringSave.push({
					Key: api_k.en,
					SimpleKey: form.Key,
					Name: form.English,
					Description: editor_eng,
					LanguageCode: 'en',
				})
			} else {
				body.StringSave.push({
					Key: api_k.en,
					SimpleKey: form.Key,
					Name: form.English,
					Description: form.En_Desc,
					LanguageCode: 'en',
				})
			}
			if (editor_my.length) {
				body.StringSave.push({
					Key: api_k.tr,
					SimpleKey: form.Key,
					Name: form.Myanmar,
					Description: editor_my,
					LanguageCode: 'tr',
				})
			} else {
				body.StringSave.push({
					Key: api_k.tr,
					SimpleKey: form.Key,
					Name: form.Myanmar,
					Description: form.My_Desc,
					LanguageCode: 'tr',
				})
			}
		}
		return this.http
			.post<any>(this.basUrl + `/api/Entity/SaveSimple`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	editServices(data, key) {
		let body = {
			CompanyServices: data,
		}
		body.CompanyServices.forEach((service) => {
			service['Key'] = key
		})
		return this.http
			.put<any>(this.basUrl + `/api/provider/EditServices`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	openEditTranslate(key) {
		return this.http
			.get<any>(this.basUrl + `/api/Entity/GetSimple?Key=` + key, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getEditStrings(key) {
		return this.http
			.get<any>(this.basUrl + `/api/Entity/GetString?SimpleKey=` + key, {
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			})
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getServiceInfo(key) {
		return this.http
			.get<any>(
				this.basUrl + `/api/provider/GetServicesDetails?pKey=` + key,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	saveImage(simple_key, type_key, lang, image) {
		let body = {
			SimpleKey: simple_key,
			TypeKey: type_key,
			MimeType: image.type,
			LangCode: lang,
			Base64Data: image.code,
		}
		return this.http
			.post<any>(this.basUrl + `/api/Entity/SaveImage`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	saveContract(key, code, type) {
		let body = {
			Key: key,
			ImageData: code,
			MimeType: type,
		}
		return this.http
			.post<any>(this.basUrl + `/api/Dealer/LoadContract`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}
	getContract(key) {
		return this.http.get(
			this.basUrl + `/api/Dealer/GetDocument?key=${key}`,
			{
				headers: { 'Accept-Language': this.GetLang() },
				responseType: 'blob',
			}
		)
	}
	saveImageCompany(key, type, code, target_img) {
		let body = {
			General: {
				CompanyKey: key,
			},
			Base64Logo: null,
			LogoMimeType: null,
			Base64InfoImg: null,
			InfoImgMimeType: null,
		}
		if (target_img === 'info') {
			body.Base64InfoImg = code
			body.InfoImgMimeType = type
		} else {
			body.Base64Logo = code
			body.LogoMimeType = type
		}
		return this.http
			.post<any>(this.basUrl + `/api/Provider/EditCompanyImage`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getImage(key, type, lang) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/Entity/GetImage?SimpleKey=` +
					key +
					`&pType=` +
					type +
					`&pLangCode=` +
					lang,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	// Providers page

	getCompanyList(
		companyName,
		companyId,
		providerId,
		groupId,
		orderBy,
		serviceDrop,
		totalItems,
		currentPage
	) {
		let stupid_filter = {
			provider: null,
			group: null,
		}
		if (orderBy === 1) {
			stupid_filter.provider = 1
		} else if (orderBy === 2) {
			stupid_filter.group = 1
		}
		return this.http
			.get<any>(
				this.basUrl +
					`/api/Provider/GetFiltered?CompanyName=` +
					companyName +
					`&CompanyID=` +
					companyId +
					`&ProviderId=` +
					providerId +
					`&GroupID=` +
					groupId +
					`&OrderByGroup=` +
					stupid_filter.group +
					`&OrdeByProvider=` +
					stupid_filter.provider +
					`&ServiceID=` +
					serviceDrop +
					`&ItemCount=` +
					totalItems +
					`&CurrentPage=` +
					currentPage,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
						'Accept-Language': this.GetLang(),
					},
				}
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	createCompany(data) {
		console.log(data)

		// return this.http.post<any>(`/api/Entity/SaveImage`, body, {headers: {'Content-Type': 'application/json'}})
		// 	.pipe(map(response => {
		// 		if (this.authService.checkTokenResponse()) {
		// 			return response;
		// 		}
		// 	}));
	}

	getCompanyPage(companyId) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/Provider/GetCompanyDetails?CompanyKey=` +
					companyId,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	getNewCompany() {
		return this.http.get<any>(
			this.basUrl + `/api/Provider/CreateNewCompany`,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			}
		)
	}

	getFields(companyId, serviceId, fieldType, templateType) {
		return this.http
			.get<any>(
				this.basUrl +
					`/api/provider/GetFieldsList?pCompanyKey=` +
					companyId +
					`&pFieldServiceId=` +
					serviceId +
					`&pFieldType=` +
					fieldType +
					`&pTemplateType=` +
					templateType,
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				}
			)
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	editFields(strings, data, key, service) {
		let body = {
			StringEntityArray: strings,
			CompanyKey: key,
			SubServiceId: service,
			TemplateType: data.TemplateType,
			InputField: {
				SubServiceID: service,
				Name: null,
				Description: null,
				Value: data.Value,
				InputType: data.FieldType,
				ValueTemplate: data.Regex,
				Order: data.Order,
			},
		}
		return this.http
			.post<any>(this.basUrl + `/api/provider/SaveFields`, body, {
				headers: { 'Content-Type': 'application/json' },
			})
			.pipe(
				map((response) => {
					if (this.authService.checkTokenResponse()) {
						return response
					}
				})
			)
	}

	private GetLang() {
		if (localStorage.getItem('currentLang') === 'tr') {
			return 'tr-TR'
		}
		return 'en-US'
	}
	// Licenses
}

export const navItems = [
  {
    title: true,
    name: 'Main menu'
  },
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-home',
  },
  {
    name: 'Administration',
    url: '/administration',
    icon: 'icon-shield',
    children: [
      {
        name: 'Dealers',
        url: '#',
        icon: 'icon-briefcase'
      },
      {
        name: 'Users',
        url: '#',
        icon: 'icon-people'
      },
      {
        name: 'City management',
        url: '#',
        icon: 'icon-calculator'
      }
    ]
  },
  {
    name: 'Payments',
    url: '/payments',
    icon: 'icon-credit-card',
    children: [
      {
        name: 'Payments',
        url: '/payment-page',
        icon: 'icon-diamond'
      },
     
      {
        name: 'Cancel Request',
        url: '#',
        icon: 'icon-info'
      }
    ]
  },
  {
    name: 'Reports',
    url: '/reports',
    icon: 'icon-info',
    children: [
      {
        name: 'Transaction Report',
        url: '/transaction-report',
        icon: 'icon-flag'
      },
      {
        name: 'Cancel Request',
        url: '#',
        icon: 'icon-ban'
      },
      {
        name: 'Deposit oper.',
        url: '#',
        icon: 'icon-bag'
      },
      {
        name: 'System request',
        url: '#',
        icon: 'icon-target'
      },
    ]
  },
  
  {
    name: 'POS Managment',
    url: '/pos-management',
    icon: 'icon-bag',
    children: [
      {
        name: 'Monitoring',
        url: '#',
        icon: 'icon-speedometer'
      },
      {
        name: 'Report',
        url: '#',
        icon: 'icon-info'
      }
    ]
  },
  
  {
    name: 'Kiosk Management',
    url: '/kiosk-management',
    icon: 'icon-book-open',
    children: [
      {
        name: 'Monitoring',
        url: '#',
        icon: 'icon-speedometer'
      },
      {
        name: 'Report',
        url: '#',
        icon: 'icon-info'
      }
    ]
  },
  
  {
    name: 'USSD Managment',
    url: '/ussd-managment',
    icon: 'icon-drawer',
    children: [
      {
        name: 'Monitoring',
        url: '#',
        icon: 'icon-speedometer'
      },
      {
        name: 'Report',
        url: '#',
        icon: 'icon-info'
      }
    ]
  },
  {
    name: 'Settings',
    url: '/settings',
    icon: 'icon-settings',
    children: [
      {
        name: 'Change password',
        url: '/change-password',
        icon: 'icon-lock-open'
      },
      {
        name: 'Exit',
        icon: 'icon-logout',
        url: '/login',
      }
    ]
  },
];

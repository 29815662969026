import { Component, OnInit, ViewChild } from '@angular/core'
import { Inject } from '@angular/core'
import { ToolsService } from '../../_services/tools.service'
import { DatePipe } from '@angular/common'
import { DateAdapter } from '@angular/material/core'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { first } from 'rxjs/operators'
import { ProviderService } from '../../_services'
import {
	MatDialog,
	MAT_DIALOG_DATA,
	MatDialogRef,
} from '@angular/material/dialog'

export interface ItemList {
	Value: string
	Name: string
}

export interface DialogData {
	id: string,
	name:string
}

@Component({
	templateUrl: 'upload.component.html',
	styleUrls: ['../reports/reports.scss'],
	providers: [DatePipe],
})
export class InvoiceMonitoringUploadComponent implements OnInit {
	constructor(
		private toolsService: ToolsService,
		private datePipe: DatePipe,
		private dateAdapter: DateAdapter<Date>,
		private translate: TranslateService,
		public toastr: ToastrService,
		private providerService: ProviderService,
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<InvoiceMonitoringUploadComponent>,
		@Inject(MAT_DIALOG_DATA) public data: DialogData
	) {
		dateAdapter.setLocale('en-EN') // change this for change format date in select
	}

	comment = ''
	fileName='';
	selectedService
	services: ItemList[]
	Upload: UntypedFormGroup
	fileToUpload: string | null = null;
	isUploading: boolean = false;

	ngOnInit(): void {
		//this.InitProviderFilter()
		this.selectedService =this.data.name;
		this.Upload = new UntypedFormGroup({
			Comment: new UntypedFormControl(''),
			File: new UntypedFormControl(''),
		})
	
	}

	InitProviderFilter() {
		let p = localStorage.getItem('providerAll')
		if (p === null) {
			this.providerService
				.AllList()
				.pipe(first())
				.subscribe(
					(data) => {
						localStorage.setItem(
							'providerAll',
							JSON.stringify(data)
						)
						this.services = data.map((x) => ({
							Value: x.ID.toString(),
							Name: x.Name,
						}))
						this.services.unshift({ Value: '', Name: 'All' })
						this.selectedService = this.services.filter(
							(x) => x.Value == this.data.id
						)[0].Name
					},
					(error) => {
						console.log(error)
					},
					() => {}
				)
		} else {
			let prvs = JSON.parse(p)
			this.services = prvs.map((x) => ({
				Value: x.ID.toString(),
				Name: x.Name,
			}))
			this.selectedService = this.services.filter((x) => x.Value == this.data.id)[0].Name
		}
	}

	closeDialog(): void {
		this.dialog.closeAll()
	}

	loading = false
	// upload() {
	// 	//const serviceID = this.selectedService
	// 	//const viewData = this.Upload.value

	// 	this.dataFileSource = new MatTableDataSource([])
	// 	this.loading = true
	// 	let dealerId = this.currentDealer.Value;
	// 	let theme = ""
	// 	let body = ""


	// 	var OUT = {}
	// 	//this.toolsService.upload()
	// }
	

	handleFileInput(files: FileList, target) {
		this.fileToUpload = null
		this.isUploading = true
		if (files && files[0]) {
		  let reader: any; 
		  this.fileName = files[0].name
		  if ( this.fileName.length < 3 ||  this.fileName.length > 128 || this.selectedService.Value == "") {
			let theme = ""
			let body = ""
			this.translate.get("INVOICE_MONITORING.THEME_FILEUPLOAD_FAIL").subscribe((text) => {
			  theme = text
			})
			this.translate.get("INVOICE_MONITORING.BODY_FILEUPLOAD_" + (this.selectedService.Value == "" ?"DEALER_WRONG": "LENGTH_ER")).subscribe((text) => {
			  body = text
			})
			this.toastr.error(body, theme)
		  }
		  else {
			reader = new FileReader()
			reader.readAsDataURL(files[0]) // read file as data url
			reader.onload = (event) => {
			  this.fileToUpload = event.target.result.split(",")[1]
			  if (!this.fileToUpload) { return false } 		
			}
	
		  }
		}
	  }
	  SendFile(file, fileName) { 
		this.loading = true
		let providerId = this.data.id;
		this.comment= this.Upload.value["Comment"]

		let theme = ""
		let body = ""
		this.toolsService.upload(file, fileName, providerId,this.comment)
		  .subscribe(
			(data) => {
	
			  if (data != null) {
				if (data.ResultCode === 0) {
				  if (data.ReturnObject !== null) {
	
					this.loading = false
					this.translate.get("INVOICE_MONITORING.THEME_FILEUPLOAD_SUCCESS").subscribe((text) => {
					  theme = text
					})
			  
					this.toastr.success(data.ResultMessage, theme)
				//	this.getFirstDatas();
	
				  } else {
					this.loading = false
					this.translate.get("INVOICE_MONITORING.THEME_FILEUPLOAD_FAIL").subscribe((text) => {
					  theme = text
					})
			  
					this.toastr.error(data.ResultMessage, theme)
					this.loading = false
	
				  }
				} else {
				  this.translate.get("INVOICE_MONITORING.THEME_FILEUPLOAD_FAIL").subscribe((text) => {
					theme = text
				  })
			
				  this.toastr.error(data.ResultMessage, theme)
				}
				this.loading = false
			  } else {
				this.loading = false
			  }
			},
			(error) => {
	
			  this.translate.get("INVOICE_MONITORING.THEME_FILEUPLOAD_FAIL").subscribe((text) => {
				theme = text
			  })
			  this.translate.get("INVOICE_MONITORING.BODY_FILEUPLOAD_FAIL").subscribe((text) => {
				body = text
			  })
			  this.toastr.error(body, theme)
	
			  this.loading = false
			  console.log(error)
			//  this.CloseDialog()
	
			},
			() => {
			  this.isUploading = false
	
			}
		  )
	  }

	  
	  upload() {
	   this.SendFile(this.fileToUpload,  this.fileName);
  
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../_models';
import { environment } from './../../environments/environment';

@Injectable()
export class UserService {
    constructor(private http: HttpClient) { }
	basUrl: string = environment.apiUrl;

    getAll() {
        return this.http.get<User[]>(`/users`);
    }
	/*
    getById(id: number) {
        return this.http.get(`/users/` + id);
    }
	*/
    register(user: User) {
        return this.http.post(this.basUrl+`/users/register`, user);
    }
	/*
    update(user: User) {
        return this.http.put(`/users/` + user.id, user);
    }
	*/
    delete(id: number) {
        return this.http.delete(this.basUrl+`/users/` + id);
    }
}


// Old setting down 
// import { Injectable } from '@angular/core';
// import { HttpClient } from '@angular/common/http';

// import { environment } from '../../environments/environment';
// import { User } from '../_models';

// @Injectable()
// export class UserService {
//     constructor(private http: HttpClient) { }

//     getAll() {
//         return this.http.get<User[]>(`${environment.apiUrl}/users`);
//     }

//     getById(id: number) {
//         return this.http.get(`${environment.apiUrl}/users/` + id);
//     }

//     register(user: User) {
//         return this.http.post(`${environment.apiUrl}/users/register`, user);
//     }

//     update(user: User) {
//         return this.http.put(`${environment.apiUrl}/users/` + user.id, user);
//     }

//     delete(id: number) {
//         return this.http.delete(`${environment.apiUrl}/users/` + id);
//     }
// }
import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	AbstractControl,
	ValidatorFn,
	FormGroupDirective,
	NgForm,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'
import { AlertService, UserService, ProviderService } from '../../_services'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'
import { ErrorStateMatcher } from '@angular/material/core'

import { ReportsDatasService } from '../../_services/reportsdata.service'
import { AdministrationService } from '../../_services/administration.service'
import { DatePipe } from '@angular/common'
import * as SecureLS from 'secure-ls'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'

import { NestedTreeControl } from '@angular/cdk/tree'
import { MatTreeNestedDataSource } from '@angular/material/tree'
export interface ItemList {
	Value: string
	Name: string
}

export interface CityList {
	Value: string
	Name: string
}

export interface SaleList {
	Value: string
	Name: string
}
interface booleanListItem {
	text: string
	value: boolean
}
interface FiscUser {
	UserId: number
	Idno: string
	Name: string
	Number: string
	FiscNumber: string
	Addr: string

	IsEnabled: boolean
	SavedBy: string
	Date?: Date
	User: string
}
// public int UserId { get; set; }
// 		public string Idno { get; set; }
// 		public string Name { get; set; }
// 		public string Number { get; set; }
// 		public string FiscNumber { get; set; }
// 		public string Addr { get; set; }
// 		public bool IsEnabled { get; set; }
interface FiscSave {
	UserId: number
	Idno: string
	Name: string
	Number: string
	FiscNumber: string
	Addr: string
	IsEnabled: boolean
}
interface FiscSession {
	UserId: number
	Id: number
	ParentId: number
	Status: string
	Started?: Date
	StartedStr?: string
	TotalSec: number
	TotalWorkTime: string
	OfflineStarted?: Date
	OfflineStartedStr?: string
	OfflineWorkTime: string
	IsExpired: boolean
	IsOffline: boolean
	Date?: Date
	User: string
	SessionFiscNum: string
	NextlocId: number
}
interface FiscViewModel {
	Config?: FiscUser
	Sesion?: FiscSession
}

interface DealerNode {
	name: string
	children?: DealerNode[]
}
// For custom Error Validations Messages
export class MyErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(
		control: UntypedFormControl | null,
		form: FormGroupDirective | NgForm | null
	): boolean {
		const invalidCtrl = !!(
			control &&
			control.invalid &&
			control.parent.dirty
		)
		const invalidParent = !!(
			control &&
			control.parent &&
			control.parent.invalid &&
			control.parent.dirty
		)

		return invalidCtrl || invalidParent
	}
}

@Component({
	templateUrl: 'users.component.html',
	styleUrls: ['administration.scss'],
	providers: [DatePipe],
})
export class UsersComponent implements OnInit {
	matcher = new MyErrorStateMatcher() // for my Error state | Colors and other
	permissions
	changePasswordError = ''
	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api

	public transactionCurrentID
	public transactionCurrentDealerName
	public transactionCurrentServiceName
	public currentPage = 0
	isLoadingDetailInfo: boolean = true // spinner

	//

	//

	//-------------------------------------
	providersList
	Transactions_Datas
	dealerListOptions: ItemList[] // = [{ Value: '1', Name: 'Mase 1' }, { Value: '2', Name: 'Dealer 222' }, { Value: '3', Name: 'Feadea' }, { Value: '4', Name: 'Grisha' }];
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	// For change pages
	dataSource = new MatTableDataSource() // array of datas
	changeLog = new MatTableDataSource() // array of datas

	hasChild = (_: number, node: DealerNode) =>
		!!node.children && node.children.length > 0
	treeControl = new NestedTreeControl<DealerNode>((node) => node.children)
	dataTreeSource
	treeData

	showTree: boolean = false
	currentDealer = {
		Name: '',
		Value: '',
	}
	growTree(arr) {
		let storageData = JSON.parse(sessionStorage.getItem('dealerTreeList'))
		this.dataTreeSource = new MatTreeNestedDataSource<DealerNode>()
		let mainArray = arr
		if (!storageData) {
			mainArray.map((x) => {
				x.children = []
			})
			mainArray.map((z) => {
				if (z.Id !== 0) {
					let currentEl = mainArray.find((x) => x.Id === z.ParentId)
					if (currentEl) {
						currentEl.children.push(z)
					}
				}
			})
			let result = mainArray.splice(0, 1)
			this.treeData = result
			sessionStorage.setItem('dealerTreeList', JSON.stringify(result))
			this.dataTreeSource = result
		} else {
			this.dataTreeSource = storageData
		}
	}
	chooseDealer(smth) {
		this.CloseDialog()
		this.FilterForm.controls.Dealer.setValue(smth.Name)
		this.currentDealer = { Value: smth.Id, Name: smth.Name }
		this.showTree = false
		console.log('Choose Dealer value:', this.currentDealer)
		this.getFirstDatas()
	}
	stopClick(event) {
		event.preventDefault()
	}

	services: ItemList[]

	account = '' // Account input
	refStan = '' // refStan input
	serviceSelected = '' // Service selected
	userLogin = '' // User Login input

	events: string[] = [] // Date picker
	dealerFControl = new UntypedFormControl() // Dealer

	salePoints = []
	citiesList = []
	languageList = []
	createUserError = '' // Validation on User
	disableNew = true
	dealerFilteredOptions: Observable<ItemList[]> // dealer
	currentTime = new Date()
	mainTabError: boolean = false
	locationTabError: boolean = false
	//----------------- list of columns
	displayedColumns: string[] = [
		'Id',
		'UserName',
		'DealerName',
		'Login',
		'UserEmail',
		'UserMobile',
		'DateLastSignUp',
		'Status',
		'Operations', 
	]
	@ViewChild(MatPaginator) paginator: MatPaginator

	constructor(
		public dialog: MatDialog,
		public toastr: ToastrService,
		// Make service for post api datas
		private datePipe: DatePipe,
		private reportsDataService: ReportsDatasService,
		private providerService: ProviderService,
		private administrationService: AdministrationService,
		private formBuilder: UntypedFormBuilder,
		public route: ActivatedRoute,
		private translate: TranslateService
	) {
		this.ChangePassword = this.formBuilder.group(
			{
				Password: [
					'',
					[
						Validators.required,
						Validators.pattern(
							/^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,16}$/
						),
						Validators.minLength(8),
						Validators.maxLength(16),
					],
				],
				RepeatPassword: [
					'',
					[
						Validators.required,
						Validators.pattern(
							/^(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,16}$/
						),
						Validators.minLength(8),
						Validators.maxLength(16),
					],
				],
			},
			{ validator: this.checkPasswords }
		)
		// Assign the data to the data source for the table to render
		// this.dataSource = new MatTableDataSource(users);
	}

	FilterForm: UntypedFormGroup // Form initial
	StatusForm: UntypedFormGroup // Form initial
	CreateUser: UntypedFormGroup
	EditForm: UntypedFormGroup // Form initial
	//FiscForm: UntypedFormGroup

	ChangePassword: UntypedFormGroup // Form initial
	//dealerDropValidator(controls): ValidatorFn {
	//    return (control: AbstractControl): { [key: string]: boolean } | null => {
	//        if ((control.value.length > 0) && (controls.indexOf(control.value) < 0)) {
	//            return { 'dealer': true };
	//        }
	//        return null;
	//    };
	//}
	Changehandler(e: any) {
		e.value.Value ? (this.disableNew = false) : (this.disableNew = true)
		this.currentDealer = { Name: e.value.Name, Value: e.value.Value }
	}
	checkPasswords(group: UntypedFormGroup) {
		// here we have the 'passwords' group and check repeat password | Custom validation
		let password = group.controls.Password.value
		let repeatpassword = group.controls.RepeatPassword.value
		return password === repeatpassword ? null : { notSame: true }
	}

	rolesList = []

	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas()
	}

	// function for change tables page
	InitProviderFilter() {
		let p = localStorage.getItem('providerAll')
		if (p === null) {
			this.providerService.AllList().subscribe(
				(data) => {
					localStorage.setItem('providerAll', JSON.stringify(data))
					this.services = data.map((x) => ({
						Value: x.ID.toString(),
						Name: x.Name,
					}))
					this.services.unshift({ Value: '', Name: 'All' })
				},
				(error) => {
					console.log(error)
				}
			)
		} else {
			let prvs = JSON.parse(p)
			this.services = prvs.map((x) => ({
				Value: x.ID.toString(),
				Name: x.Name,
			}))
			this.services.unshift({ Value: '', Name: 'All' })
		}
	}

	//----------------------- get a dealer list and put to option's
	InitDealerFilter() {
		// let dealers = localStorage.getItem('dealerAllList');
		// if (dealers === null) {
		this.administrationService.getDealerDatasAsList().subscribe(
			(data) => {
				this.dealerListOptions = data.ReturnObject.map((x) => ({
					Value: x.Id,
					Name: x.Name,
				}))
				this.dealerListOptions.unshift({ Value: '', Name: 'All' })
				this.translate
					.get('ADMIN.LBL_FDEALER_ALL')
					.subscribe((text) => {
						this.dealerListOptions[0].Name = text
					})
				// Header Form
				this.dealerFilteredOptions =
					this.dealerFControl.valueChanges.pipe(
						startWith<string | ItemList>(''),
						map((value) =>
							typeof value === 'string' ? value : value.Name
						),
						map((name) =>
							name
								? this._filter(name)
								: this.dealerListOptions.slice()
						)
					) //dealer
				// this.dealerFControl.setValue(this.dealerListOptions[0]);
				// this.FilterForm.patchValue({ ['Dealer']: this.dealerFilteredOptions[0] ? this.dealerFilteredOptions[0].Value : null });
				this.growTree(data.ReturnObject)
			},
			(error) => {
				console.log(error)
			}
		)
	}
	InitLocationFilter() {
		// this.administrationService.getCities().subscribe(
		//   (data) => {
		//     this.citiesList = data.ReturnObject.Cities;
		//   },
		//   (error) => {
		//     console.log(error);
		//   }
		// );
		this.administrationService.getSalePoints().subscribe(
			(data) => {
				this.salePoints = data.ReturnObject.SalePoints
				console.log('Sale Point:', this.salePoints)
			},
			(error) => {
				console.log(error)
			}
		)
		this.administrationService.getLanguagesList().subscribe(
			(data) => {
				this.languageList = data.ReturnObject
			},
			(error) => {
				console.log(error)
			}
		)
	}

	getFirstDatas() {
		this.loading = true
		// Take datas from form
		let dealerId,
			filter = this.FilterForm.value

		dealerId = this.currentDealer.Value

		// api for get Users
		this.administrationService
			.getUsersList(
				dealerId,
				filter.UserEmail,
				filter.UserName,
				filter.UserLogin,
				filter.Status,
				this.currentPage + 1,
				this.paginationItemPerPage
			)
			.subscribe(
				(data) => {
					let datas = []
					let totalItem = 0
					if (data.ResultCode === 0) {
						datas = data.ReturnObject.DmsDealerList
						totalItem = data.ReturnObject.PagingInfo.TotalItems
						this.Transactions_Datas = []
					}
					this.dataSource = new MatTableDataSource(datas) // array of datas
					this.paginationItems = totalItem
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}

	private _filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.dealerListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}

	editUserError = ''
	FilterStates = [
		{ Key: 'null', Name: 'All' },
		{ Key: 0, Name: 'Blocked' },
		{ Key: 1, Name: 'Active' },
	]

	States = [
		{ Key: 0, Name: 'Blocked' },
		{ Key: 1, Name: 'Active' },
	]

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}

	//------------------- private helpers
	// convenience getter for easy access to form fields
	get f() {
		return this.FilterForm.controls
	}

	saveStatus() {
		if (this.StatusForm.valid === true) {
			this.loading = true
			this.administrationService
				.changeStatus(this.modalDatas.Id, this.StatusForm.value.Status)
				.subscribe(
					(data) => {
						if (data.ResultCode === 0) {
							this.loading = false
							this.CloseDialog()
							this.getFirstDatas()
						} else {
							this.loading = false
						}
					},
					(error) => {
						this.loading = false
						console.log(error)
					}
				)
		}
	}

	editUser() {
		this.markFormGroupTouched(this.EditForm)
		this.editUserError = ''
		if (this.EditForm.valid === true) {
			// Make again gets all users()
			this.loading = true
			console.log('From modal before edit:', this.modalDatas)
			this.administrationService
				.editUser(
					this.modalDatas,
					this.EditForm.value,
					this.rolesList.length
				)
				.subscribe(
					(data) => {
						if (data.ResultCode === 0) {
							this.loading = false
							this.getFirstDatas()
							let theme = ''
							let body = ''
							this.translate
								.get('PROFILE.THEME_EDITUSER_SUCCESS')
								.subscribe((text) => {
									theme = text
								})
							this.translate
								.get('PROFILE.BODY_EDITUSER_SUCCESS')
								.subscribe((text) => {
									body = text
								})
							this.toastr.success(body, theme)
							this.CloseDialog()
						} else {
							this.loading = false
							let theme = ''
							this.translate
								.get('PROFILE.THEME_EDITUSER_ERROR')
								.subscribe((text) => {
									theme = text
								})
							data.ResultMessage === null
								? (this.editUserError = '')
								: (this.editUserError = data.ResultMessage)
							this.toastr.error(this.editUserError, theme)
						}
					},
					(error) => {
						this.loading = false
						let theme = ''
						this.translate
							.get('PROFILE.THEME_EDITUSER_ERROR')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.error(error, theme)
						console.log(error)
					}
				)
		} else {
			this.mainTabError = false
			this.locationTabError = false
			let tabErrors = this.findInvalidControls(this.EditForm)
			tabErrors.includes('DealerName') ||
			tabErrors.includes('UserName') ||
			tabErrors.includes('UserSurname') ||
			tabErrors.includes('UserEmail') ||
			tabErrors.includes('UserMobile') ||
			tabErrors.includes('SalePointTypeId') ||
			tabErrors.includes('Status')
				? (this.mainTabError = true)
				: console.log('No error at main tab')
			//  tabErrors.includes("Coordx") ||
			//   tabErrors.includes("Coordy") ||
			tabErrors.includes('Language') || tabErrors.includes('Address')
				? (this.locationTabError = true)
				: console.log('No error at location tab')
			console.log(
				'Invalid ctrls:',
				this.findInvalidControls(this.EditForm)
			)
			console.log('Edit form not valid:', this.EditForm)
			console.log('MainTabError:', this.mainTabError)
		}
	}

	changePassword() {
		this.markFormGroupTouched(this.ChangePassword)
		this.submitted = true
		this.changePasswordError = ''
		if (this.ChangePassword.valid === true) {
			this.loading = true
			this.administrationService
				.changePassword(
					this.modalDatas.UserKey,
					this.ChangePassword.value.Password,
					this.ChangePassword.value.RepeatPassword
				)
				.subscribe(
					(data) => {
						if (data.ResultCode === 0) {
							this.loading = false
							this.CloseDialog()
							let theme = ''
							let body = ''
							this.translate
								.get('PROFILE.THEME_CHANGEPASSWORD_SUCCESS')
								.subscribe((text) => {
									theme = text
								})
							this.translate
								.get('PROFILE.BODY_CHANGEPASSWORD_SUCCESS')
								.subscribe((text) => {
									body = text
								})
							this.toastr.success(body, theme)
						} else {
							this.loading = false
							let theme = ''
							this.translate
								.get('PROFILE.THEME_CHANGEPASSWORD_ERROR')
								.subscribe((text) => {
									theme = text
								})
							data.ResultMessage === null
								? (this.changePasswordError = '')
								: (this.changePasswordError =
										data.ResultMessage)
							this.toastr.error(this.changePasswordError, theme)
						}
					},
					(error) => {
						let theme = ''
						this.translate
							.get('PROFILE.THEME_CHANGEPASSWORD_ERROR')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.error(error, theme)
						this.loading = false
						console.log(error)
					}
				)
		}
	}

	private markFormGroupUnTouched(formGroup: UntypedFormGroup) {
		;(<any>Object).values(formGroup.controls).forEach((control) => {
			control.markAsUntouched()

			if (control.controls) {
				this.markFormGroupUnTouched(control)
			}
		})
	}
	findInvalidControls(formName) {
		const invalid = []
		const controls = formName.controls
		for (const name in controls) {
			if (controls[name].invalid) {
				invalid.push(name)
			}
		}
		return invalid
	}
	modalDatas = {
		Address: '',
		DealerId: '',
		DealerName: '',
		Id: '',
		Login: '',
		Status: '',
		UserEMail: '',
		UserId: '',
		UserKey: '',
		UserMobile: '',
		DateLastSignUp: '',
		UserName: '',
		UserSurname: '',
		SalePointTypeId: '',
		Language: '',
		CityKey: '',
		//Coordx: "",
		//Coordy: "",
	} // for display datas in modal

	statusStates = [
		{ value: false, viewValue: 'InActive' },
		{ value: true, viewValue: 'Active' },
	]

	CloseDialog(): void {
		this.dialog.closeAll()
	}
	public currentDialog
	openConfirmDialog(_dialog) {
		const dialogRef = this.dialog.open(_dialog)
		this.currentDialog = dialogRef
	}
	closeConfirmModal(_flag) {
		if (_flag) {
			this.currentDialog.close()
			//this.fiscLoading = true
			//this.endSession()
		} else {
			this.currentDialog.close()
		}
	}
 
	 
	CurrentUserDate: Date | string = new Date()
	CurrentSessionStarted: Date | string = new Date()
	CurrentSessionOfflineStarted: Date | string = new Date()
	CurrentSessionDate: Date | string = new Date()

	currentUserId = null
	isSessionOffline: boolean = false
 

	openCreateUser(templateRef) {
		this.createUserError = ''
		this.mainTabError = false
		this.locationTabError = false
		this.markFormGroupUnTouched(this.CreateUser)
		if (this.loading === false) {
			this.rolesList = []
			this.loading = true
			this.openModal(templateRef)
			this.isLoadingDetailInfo = true
			this.administrationService.getRolesUser().subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						this.rolesList = data.ReturnObject
						if (this.rolesList.length) {
							let counter = 0
							this.rolesList.forEach((role) => {
								role['Id'] = counter
								this.CreateUser.removeControl('Key_' + role.Id)
								this.CreateUser.removeControl(
									'Value_' + role.Id
								)
								this.CreateUser.addControl(
									'Key_' + role.Id,
									new UntypedFormControl(role.Key)
								) // Initialize form rules
								this.CreateUser.addControl(
									'Value_' + role.Id,
									new UntypedFormControl(role.IsOn)
								) // Initialize form rules
								counter++
							})
						}
					} else {
						this.createUserError = data.ResultMessage
					}
					this.isLoadingDetailInfo = false
					this.loading = false
				},
				(error) => {
					this.isLoadingDetailInfo = false
					this.loading = false
					console.log(error)
				}
			)
		}
	}

	// openStatus(templateRef, row) {
	//   if (this.loading === false) {
	//     this.isLoadingDetailInfo = true;
	//     this.openModal(templateRef);
	//     this.modalDatas = row;
	//       this.StatusForm.patchValue({ ['Status']: Boolean(row.State) });
	//   }
	// }

	openEdit(templateRef, row) {
		this.mainTabError = false
		this.locationTabError = false

		if (this.loading === false) {
			this.rolesList = []
			this.loading = true
			this.openModal(templateRef)
			this.isLoadingDetailInfo = true
			this.administrationService.getUserInfo(row.Id).subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						if (data.ReturnObject !== null) {
							this.modalDatas = data.ReturnObject.User
							this.rolesList = data.ReturnObject.RoleList
								? data.ReturnObject.RoleList
								: []
							if (this.rolesList.length) {
								let counter = 0
								this.rolesList.forEach((role) => {
									role['Id'] = counter
									this.EditForm.removeControl(
										'Key_' + role.Id
									)
									this.EditForm.removeControl(
										'Value_' + role.Id
									)
									this.EditForm.addControl(
										'Key_' + role.Id,
										new UntypedFormControl(role.RoleKey)
									) // Initialize form rules
									this.EditForm.addControl(
										'Value_' + role.Id,
										new UntypedFormControl(role.IsOn)
									) // Initialize form rules
									counter++
								})
							}
							this.editDatasPush(this.modalDatas, templateRef)
						} else {
							this.isLoadingDetailInfo = false
						}
						this.loading = false
					} else {
						this.loading = false
					}
				},
				(error) => {
					this.isLoadingDetailInfo = false
					this.loading = false
					console.log(error)
				}
			)
			// Api for modal detail information
		}
	}

	editDatasPush(datas, templateRef) {
		this.EditForm.patchValue({ ['Id']: datas.Id })
		this.EditForm.patchValue({ ['UserName']: datas.UserName })
		this.EditForm.patchValue({ ['UserSurname']: datas.UserSurname })
		this.EditForm.patchValue({ ['UserEmail']: datas.UserEMail })
		this.EditForm.patchValue({ ['UserLogin']: datas.Login })
		this.EditForm.patchValue({ ['UserMobile']: datas.UserMobile })
		this.EditForm.patchValue({ ['Status']: datas.Status })
		this.EditForm.patchValue({ ['SalePointTypeId']: datas.SalePointTypeId })
		this.EditForm.patchValue({ ['Login']: datas.Login })
		this.EditForm.patchValue({ ['Address']: datas.Address })
		// this.EditForm.patchValue({ ["Coordx"]: datas.Latitude });
		//  this.EditForm.patchValue({ ["Coordy"]: datas.Longitude });
		this.EditForm.patchValue({ ['Language']: datas.UserLangKey })

		this.isLoadingDetailInfo = false
	}

	submitted = false

	openChangePassword(templateRef, row) {
		this.changePasswordError = ''
		this.submitted = false
		if (this.loading === false) {
			this.isLoadingDetailInfo = true
			this.ChangePassword.reset()
			this.openModal(templateRef)
			this.modalDatas = row
		}
	}

	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}

	/**
	 * Marks all controls in a form group as touched
	 * @param formGroup - The form group to touch
	 */
	private markFormGroupTouched(formGroup: UntypedFormGroup) {
		;(<any>Object).values(formGroup.controls).forEach((control) => {
			control.markAsTouched()

			if (control.controls) {
				this.markFormGroupTouched(control)
			}
		})
	}

	newUser() {
		this.mainTabError = false
		this.locationTabError = false
		this.markFormGroupTouched(this.CreateUser)
		this.createUserError = ''
		if (this.CreateUser.valid === true) {
			// Make again gets all users()
			this.loading = true
			this.administrationService
				.CreateUser(
					this.currentDealer.Value,
					this.CreateUser.value,
					this.rolesList.length
				)
				.subscribe(
					(data) => {
						if (data.ResultCode === 0) {
							this.loading = false
							this.CreateUser.reset()
							let theme = ''
							let body = ''
							this.translate
								.get('PROFILE.THEME_NEWUSER_SUCCESS')
								.subscribe((text) => {
									theme = text
								})
							this.translate
								.get('PROFILE.BODY_NEWUSER_SUCCESS')
								.subscribe((text) => {
									body = text
								})
							this.toastr.success(body, theme)
							this.CloseDialog()
							this.getFirstDatas()
						} else {
							this.loading = false
							let theme = ''
							this.translate
								.get('PROFILE.THEME_NEWUSER_ERROR')
								.subscribe((text) => {
									theme = text
								})
							data.ResultMessage === null
								? (this.editUserError = '')
								: (this.editUserError = data.ResultMessage)
							this.toastr.error(this.editUserError, theme)
						}
					},
					(error) => {
						let theme = ''
						this.translate
							.get('PROFILE.THEME_NEWUSER_ERROR')
							.subscribe((text) => {
								theme = text
							})
						this.toastr.error(error, theme)
						this.loading = false
						console.log(error)
					}
				)
		} else {
			this.mainTabError = false
			this.locationTabError = false
			let tabErrors = this.findInvalidControls(this.EditForm)
			tabErrors.includes('Login') ||
			tabErrors.includes('UserName') ||
			tabErrors.includes('UserSurname') ||
			tabErrors.includes('UserEmail') ||
			tabErrors.includes('UserMobile') ||
			tabErrors.includes('SalePointTypeId') ||
			tabErrors.includes('Status')
				? (this.mainTabError = true)
				: console.log('No error at main tab')
			// tabErrors.includes("Coordx") ||
			// tabErrors.includes("Coordy") ||
			tabErrors.includes('Language') || tabErrors.includes('Address')
				? (this.locationTabError = true)
				: console.log('No error at location tab')
		}
	}
	// public fiscPermissions
	// public fiscInfo: FiscViewModel
	// public fiscLoading: boolean = true
	// public fiscSaving: boolean = false
	convertFiscDate(date) {
		if (date === null) return ''
		return typeof date !== 'string' ? date.toJSON() : date
	}
	 
	// @Input() permissions;
	ngOnInit() {
		let ls = new SecureLS()
		this.permissions =
			ls.get('permissions')[this.route.data['value'].pathCheck]
		 
	  
		this.paginationItemPerPage = 10
		this.translate.get('USER.STATUS_BLOCKED').subscribe((text) => {
			this.States[0].Name = text
		}) // Text in component
		this.translate.get('REPORT.ACTIVE').subscribe((text) => {
			this.States[1].Name = text
		}) // Text in component
		this.FilterForm = new UntypedFormGroup({
			UserName: new UntypedFormControl(''),
			UserLogin: new UntypedFormControl(''),
			UserEmail: new UntypedFormControl(''),
			Dealer: new UntypedFormControl(''),
			Status: new UntypedFormControl(this.FilterStates[0].Key),
		})
		this.InitProviderFilter()
		this.InitDealerFilter()
		this.InitLocationFilter()

		this.translate.get('USER.STATUS_ALL').subscribe((text) => {
			this.FilterStates[0].Name = text
		}) // Text in component
		this.translate.get('USER.STATUS_BLOCKED').subscribe((text) => {
			this.FilterStates[1].Name = text
		}) // Text in component
		this.translate.get('USER.STATUS_ACTIVE').subscribe((text) => {
			this.FilterStates[2].Name = text
		}) // Text in component
		this.translate.get('USER.ISENABLED_TRUE').subscribe((text) => {
			this.isEnabledList[0].text = text
		})
		this.translate.get('USER.ISENABLED_FALSE').subscribe((text) => {
			this.isEnabledList[1].text = text
		})
		this.translate.get('USER.ISEXPIRED_TRUE').subscribe((text) => {
			this.isExpiredList[0].text = text
		})
		this.translate.get('USER.ISEXPIRED_FALSE').subscribe((text) => {
			this.isExpiredList[1].text = text
		})
		this.translate.get('USER.ISOFFLINE_TRUE').subscribe((text) => {
			this.isOfflineList[0].text = text
		})
		this.translate.get('USER.ISOFFLINE_FALSE').subscribe((text) => {
			this.isOfflineList[1].text = text
		})
		this.getFirstDatas()

		this.CreateUser = this.formBuilder.group({
			Address: ['', Validators.required],
			Login: ['', Validators.required],
			Status: ['', Validators.required],
			SalePointTypeId: ['', Validators.required],
			UserName: ['', Validators.required],
			UserEmail: [
				'',
				[
					Validators.required,
					Validators.pattern(
						/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					),
				],
			],
			UserMobile: ['', Validators.pattern(/\d{8,12}$/)],
			// Coordx: ["", Validators.required],
			//  Coordy: ["", Validators.required],
			Language: ['', Validators.required],
		})

		this.EditForm = this.formBuilder.group({
			Address: ['', Validators.required],
			Login: ['', Validators.required],
			Status: ['', Validators.required],
			UserName: ['', Validators.required],
			UserSurname: ['', Validators.required],
			UserEmail: [
				'',
				[
					Validators.required,
					Validators.pattern(
						/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					),
				],
			],
			UserMobile: ['', Validators.pattern(/\d{11,12}$/)],
			//  Coordx: ["", Validators.required],
			// Coordy: ["", Validators.required],
			Language: ['', Validators.required],
			SalePointTypeId: ['', Validators.required],
		})
		 
		//   		public int UserId { get; set; }
		// public string Idno { get; set; }
		// public string Name { get; set; }
		// public string Number { get; set; }
		// public string Comment { get; set; }
		// public string CertNumber { get; set; }
		// public bool IsEnabled { get; set; }
		 
	}
	isEnabledList: booleanListItem[] = [
		{ text: 'Enabled', value: true },
		{ text: 'Disabled', value: false },
	]
	isExpiredList: booleanListItem[] = [
		{ text: 'Expired', value: true },
		{ text: 'NotExpired', value: false },
	]
	isOfflineList: booleanListItem[] = [
		{ text: 'Offline', value: true },
		{ text: 'NotOffline', value: false },
	]
}

import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { map } from 'rxjs/operators'

@Injectable()
export class ProviderReportsDataService {
	constructor(private http: HttpClient) {}
	basUrl: string = environment.apiUrl
	getList(filterData) {
		const params = new HttpParams()
			.set('pPeriodType', filterData.Period)
			.set('pDateFrom', filterData.DateFrom)
			.set('pDateTo', filterData.DateTo)
			.set('pRefStan', filterData.RefStan)
			.set('pAccount', filterData.Account)
			.set('pUserName', filterData.UserLogin)
			.set('pPaymentSourceType', filterData.PaymentType)
			.set('pSalesPointType', filterData.Channel)
			.set('pCurPage', filterData.CurrentPage)
			.set('pItemPerPage', filterData.ItemPerPage)

		return this.http
			.get<any>(
				`${this.basUrl}/api/Provider/ProviderTransactionReportGets`,
				{ params }
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	exportExcel(filterData) {
		const params = new HttpParams()
			.set('pPeriodType', filterData.Period)
			.set('pDateFrom', filterData.DateFrom)
			.set('pDateTo', filterData.DateTo)
			.set('pRefStan', filterData.RefStan)
			.set('pAccount', filterData.Account)
			.set('pUserName', filterData.UserLogin)
			.set('pPaymentSourceType', filterData.PaymentType)
			.set('pSalesPointType', filterData.Channel)
			.set('pCurPage', filterData.CurrentPage)
			.set('pItemPerPage', filterData.ItemPerPage)

		return this.http.get(
			`${this.basUrl}/api/Provider/ProviderTransactionReportToExcel`,
			{
				params,
				responseType: 'blob',
				headers: { 'Accept-Language': this.GetLang() },
			}
		)
	}

	private GetLang() {
		if (localStorage.getItem('currentLang') === 'tr') {
			return 'tr-TR'
		}
		return 'en-US'
	}
}

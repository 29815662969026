import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Cacheable } from "ngx-cacheable";
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class PayService {
  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) { }
  changeCheckoutStatus(
    checkId,
    opId,
    amount,
    comment
  ) {
    let body1;
    body1 = {
      CheckOutId: checkId,
      OperationId: opId,
      Amount: amount,
      Comment: comment
    };
     return this.http.post<any>(`/api/OplataOperation/CheckOutChangingStatus/`, body1
    ).pipe(
      map((response) => {
        if (this.authService.checkTokenResponse()) {
          return response;
        }
      }));

  }

  getOperationsList(
    period,
    dateFrom,
    dateTo,
    checkId,
    account,
    status,
    id,
    companyId,
    userName,
    curPage,
    itemPerPage
  ) {
    return this.http
      .get<any>(
        `/api/OplataOperation/gets?PeriodType=${period}&DateFrom=${dateFrom}&DateTo=${dateTo}&CheckID=${checkId}&Account=${account}&Status=${status}&Id=${id}&CompanyId=${companyId}&username=${userName}&curpage=${curPage}&itemperpage=${itemPerPage}`
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  saveStatus(operId, status, refStan, comment) {
    //flag = true - new, false = edit.
    let body;
    body = {
      OpKey: operId,
      RefStanNew: refStan,
      Status: status,
      Description: comment
    };

    return this.http
      .post<any>(`/api/OplataOperation/ChangingStatus/`, body, {
        headers: { "Accept-Language": this.GetLang() }
      })
      .pipe(
        map(response => {
          if (this.authService.checkTokenResponse()) {
            return response;
          }
        })
      );
  }
  getPay(id) {
    return this.http.get<any>(`/api/OplataOperation/get?id=${id}`).pipe(
      map(response => {
        return response;
      })
    );
  }
  getCheckOutList(period, dateFrom, dateTo, checkId, id, curPage, itemPerPage) {
    return this.http
      .get<any>(
        `/api/OplataOperation/CheckOutGets?PeriodType=${period}&DateFrom=${dateFrom}&DateTo=${dateTo}&CheckID=${checkId}&OperId=${id}&curpage=${curPage}&itemperpage=${itemPerPage}`
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }
  getCheckout(id) {
    return this.http.get<any>(`/api/OplataOperation/CheckOutGet?id=${id}`).pipe(
      map(response => {
        return response;
      })
    );
  }
  // ChartPeriodType PeriodType, DateTime DateFrom, DateTime DateTo, string Account, int ? Status,
  //     long ? Id, int ? CompanyId, string UserName, int CurPage = 1, int ItemPerPage = 2000000
  getPayFile(
    format,
    period,
    dateFrom,
    dateTo,
    account,
    status,
    Id,
    companyId,
    userName,
    curPage,
    itemPerPage
  ) {
    if (format === "pdf") {
      return this.http.get(
        `/api/OplataOperation/PayToPDF?PeriodType=${period}&DateFrom=${dateFrom}&DateTo=${dateTo}&Account=${account}&Status=${status}&Id=${Id}&CompanyId=${companyId}&UserName=${userName}&CurPage=${curPage}&ItemPerPage=${itemPerPage}`,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() }
        }
      );
    }
    return this.http.get(
      `/api/OplataOperation/PayToExcel?PeriodType=${period}&DateFrom=${dateFrom}&DateTo=${dateTo}&Account=${account}&Status=${status}&Id=${Id}&CompanyId=${companyId}&UserName=${userName}&CurPage=${curPage}&ItemPerPage=${itemPerPage}`,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() }
      }
    );
  }
  getCOFile(format, period, dateFrom, dateTo, Id, curPage, itemPerPage) {
    if (format === "pdf") {
      return this.http.get(
        `/api/OplataOperation/CheckOutToPDF?PeriodType=${period}&DateFrom=${dateFrom}&DateTo=${dateTo}&OperId=${Id}&CurPage=${curPage}&ItemPerPage=${itemPerPage}`,
        {
          responseType: "blob",
          headers: { "Accept-Language": this.GetLang() }
        }
      );
    }
    return this.http.get(
      `/api/OplataOperation/CheckOutToExcel?PeriodType=${period}&DateFrom=${dateFrom}&DateTo=${dateTo}&OperId=${Id}&CurPage=${curPage}&ItemPerPage=${itemPerPage}`,
      {
        responseType: "blob",
        headers: { "Accept-Language": this.GetLang() }
      }
    );
  }
  private GetLang() {
    if (localStorage.getItem("currentLang") === "tr") {
      return "tr-TR";
    }
    return "en-US";
  }
 
}

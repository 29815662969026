import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { MouseEvent } from '@agm/core'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	AbstractControl,
	ValidatorFn,
	FormGroupDirective,
	NgForm,
	ReactiveFormsModule,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first } from 'rxjs/operators'

import { MatTooltipModule } from '@angular/material/tooltip'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'

import { DataSource } from '@angular/cdk/table'
import { saveAs } from 'file-saver'

import { DatePipe } from '@angular/common'
import { KioskService } from '../../_services/kiosk.service'
import { AdministrationService } from '../../_services/administration.service'

/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { MatSort, Sort } from '@angular/material/sort'
import { LiveAnnouncer } from '@angular/cdk/a11y'

export interface ItemList {
	Value: string
	Name: string
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
	return (a < b ? -1 : 1) * (isAsc ? 1 : -1)
}

@Component({
	templateUrl: 'kiosk-monitoring.component.html',
	styleUrls: ['kiosk.scss'],
	providers: [DatePipe],
})
export class KioskMonitoringComponent implements OnInit, OnDestroy {
	public tableDatas

	public currentDealer = 1
	public currentStatus = 1

	dealerFilteredOptions: Observable<ItemList[]>
	FilterForm: UntypedFormGroup
	dealer
	currentKiosk = ''
	sortedData: any[]
	kioskData = new MatTableDataSource()
	kioskReportData = new MatTableDataSource()
	kioskEncashDetails = new MatTableDataSource()
	refreshList = [
		{
			name: '10',
			value: 10000,
		},
		{
			name: '20',
			value: 20000,
		},
		{
			name: '30',
			value: 30000,
		},
		{
			name: '45',
			value: 45000,
		},
		{
			name: '60',
			value: 60000,
		},
	]
	statusList = [
		{
			name: 'All',
			value: null,
		},
		{
			name: 'OK',
			value: '1',
		},
		{
			name: 'Warning',
			value: '2',
		},
		{
			name: 'Error',
			value: '3',
		},
	]

	// this.translate.get('REPORT.PROBLEM_ST_PROBLEM').subscribe((text) => { this.statusLists[2].text = text; });

	//
	dealerListOptions: ItemList[] // = [{ Value: '1', Name: 'Mase 1' }, { Value: '2', Name: 'Dealer 222' }, { Value: '3', Name: 'Feadea' }, { Value: '4', Name: 'Grisha' }];

	loading = true
	isDownloadingFile: boolean = false

	public modalDatas
	Transactions_Datas
	pageEvent
	public dealerId = 1
	public currentPage = 0
	isAscending: boolean = true
	kioskDevices = {}
	timerID: any
	currentSecondData: any[any] = []

	refreshInterval: any = 30000
	refreshFlag: boolean = true
	private _filter(item: string): ItemList[] {
		const filterValue = item.toLowerCase()
		return this.dealerListOptions.filter(
			(option) => option.Name.toLowerCase().indexOf(filterValue) === 0
		)
	}
	dateSlice(date) {
		console.log('Date iz dateslice', date)
		return date.slice(0, -1)
	}
	InitDealerFilter() {
		this.administrationService
			.getDealerDatasAsList()
			.pipe(first())
			.subscribe(
				(data) => {
					this.dealerListOptions = data.ReturnObject.map((x) => ({
						Value: x.Id,
						Name: x.Name,
					}))
					this.dealerListOptions.unshift({ Value: '', Name: 'All' })
					this.translate
						.get('ADMIN.LBL_FDEALER_ALL')
						.subscribe((text) => {
							this.dealerListOptions[0].Name = text
						})
					// Header Form
					this.dealerFilteredOptions =
						this.FilterForm.controls.Dealer.valueChanges.pipe(
							startWith<string | ItemList>(''),
							map((value) =>
								typeof value === 'string' ? value : value.Name
							),
							map((name) =>
								name
									? this._filter(name)
									: this.dealerListOptions.slice()
							)
						) //dealer
					this.FilterForm.patchValue({
						['Dealer']: this.dealerFilteredOptions[0],
					})
				},
				(error) => {
					console.log(error)
				}
			)
	}

	displayDealer(item?: ItemList): string | undefined {
		return item ? item.Name : undefined
	}
	selectRow(templateRef, row) {
		this.loading = true
		this.refreshFlag = false
		this.kioskService.getKiosk(this.dealerId, row.Name).subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					if (data !== null) {
						this.kioskDevices = data.ReturnObject // array of datas
					} else {
						this.kioskDevices = {} //empty array of datas
					}
				}
				this.loading = false
			},
			(error) => {
				this.loading = false
				console.log(error)
			}
		)
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}
	makeTrim(phrase) {
		if (phrase === null) return ''
		return phrase.replace(/\s/g, '_')
	}
	selectReportRow(templateRef, row) {
		this.loading = true
		this.refreshFlag = false
		this.kioskService.getEncashDetails(row.ID, this.currentKiosk).subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					if (data !== null) {
						this.kioskEncashDetails = new MatTableDataSource(
							data.ReturnObject.EncashmentDetailLog
						) // array of datas
					} else {
						this.kioskReportData = new MatTableDataSource([])
					}
				} else {
					this.kioskReportData = new MatTableDataSource([])
				}
				this.loading = false
			},
			(error) => {
				this.loading = false
				console.log(error)
			}
		)
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}
	openReportModal(templateRef, row) {
		this.loading = true
		this.refreshFlag = false
		this.currentKiosk = row.Name
		this.kioskService.getEncash(row.Name).subscribe(
			(data) => {
				if (data.ResultCode === 0) {
					if (data !== null) {
						this.kioskReportData = new MatTableDataSource(
							data.ReturnObject.Encashments
						)
					} else {
						this.kioskReportData = new MatTableDataSource([])
					}
				} else {
					this.kioskReportData = new MatTableDataSource([])
				}
				this.loading = false
			},
			(error) => {
				this.loading = false
				console.log(error)
			}
		)
		const dialogRef = this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}
	// For Open Dialog from table row, by click on row
	//

	displayedColumns: string[] = [
		'Actions',
		'KioskID',
		'SurName',
		'Address',
		'LastConnection',
		'PaymentsQuantity',
		'PaymentsAmount',
		'BillAccepterStatus',
		'BillAccepterQuantity',
		'BillAccepterAmount',
		'BillAccepterLastTaken',
		'PrinterStatus',
		'CardDispancer',
		'CardReader',
	]
	displayedReportColumns: string[] = [
		'ID',
		'TerminalNumber',
		'EncashmentDate',
		'CardAmount',
		'CardCount',
		'CoinsAmount',
		'CoinsCount',
		'BillsAmount',
		'BillsCount',
		'TotalAmount',
		'TotalPayments',
	]
	displayedReportDetailColumns: string[] = [
		'TerminalNumber',
		'SurName',
		'ID',
		'PaymentsId',
		'DeviceName',
		'Date',
		'Money',
		'Logs',
	]

	dataSource = new MatTableDataSource() // array of datas
	get f() {
		return this.FilterForm.controls
	}
	CloseDialog(): void {
		this.dialog.closeAll()
		this.refreshFlag = true
	}

	constructor(
		public dialog: MatDialog,
		public route: ActivatedRoute,
		public kioskService: KioskService,
		private translate: TranslateService,
		private administrationService: AdministrationService,
		private _liveAnnouncer: LiveAnnouncer,
		private datePipe: DatePipe,
		private formBuilder: UntypedFormBuilder
	) {
		this.sortedData = this.currentSecondData.slice()
	}
	// for header Filter
	@ViewChild(MatSort) sort: MatSort
	public handlePage(e: any) {
		this.getFirstDatas()
	}
	refreshRateChange($value) {
		this.refreshInterval = $value.value
		this.intervaler(this.refreshInterval)
	}

	intervaler(refreshRate) {
		if (this.timerID) {
			clearInterval(this.timerID)
		}
		this.timerID = setInterval(() => {
			this.refreshFlag
				? this.getFirstDatas()
				: console.log('Refresh disabled!')
		}, refreshRate)
	}
	kioskTotals = new Object()
	getFirstDatas() {
		this.loading = true

		let filterStatus = this.statusList[0].value
		if (typeof this.FilterForm.value.FilterStatus !== null) {
			filterStatus = this.FilterForm.value.FilterStatus
		} else {
			filterStatus = null
		}
		if (typeof this.FilterForm.value.Dealer === 'object') {
			this.dealerId = this.FilterForm.value.Dealer.Value
		} else {
			this.dealerId = null
		}
		this.kioskData = new MatTableDataSource([])
		this.kioskTotals = {}
		this.kioskService
			.getKioskList(this.dealerId, '', filterStatus)
			.subscribe(
				(data) => {
					if (data.ResultCode === 0) {
						console.log('kiosk monitoring data', data)
						if (data !== null) {
							this.kioskData = new MatTableDataSource(
								data.ReturnObject.Items
							) // array of datas
							this.currentSecondData = data.ReturnObject.Items
							this.kioskTotals = data.ReturnObject.Totals
							this.currentSecondData.sort = this.sort
						} else {
							this.kioskData = new MatTableDataSource([]) // array of datas
						}
					}
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	download(format) {
		this.isDownloadingFile = true
		this.loading = true
		let filterStatus = this.statusList[0].value
		if (typeof this.FilterForm.value.FilterStatus !== null) {
			filterStatus = this.FilterForm.value.FilterStatus
		} else {
			filterStatus = null
		}
		if (typeof this.FilterForm.value.Dealer === 'object') {
			this.dealerId = this.FilterForm.value.Dealer.Value
		} else {
			this.dealerId = null
		}
		this.kioskService
			.getKiostListExcel(this.dealerId, '', filterStatus)
			.subscribe(
				(res) => {
					this.isDownloadingFile = false
					this.loading = false
					saveAs(res, 'KioskMonitoringReport.' + format, {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					})
				},
				(error) => {
					console.log(error)
					this.loading = false
					this.isDownloadingFile = false
				}
			)
	}
	onSubmit() {
		this.getFirstDatas()
	}

	sortData(sort: Sort) {
		console.log('work sort')
		const data = this.currentSecondData.slice()
		if (!sort.active || sort.direction === '') {
			this.sortedData = data
			return
		}

		this.sortedData = data.sort((a, b) => {
			const isAsc = sort.direction == 'asc'
			switch (sort.active) {
				case 'Name':
					return compare(a.Name, b.Name, isAsc)
				case 'DateLast':
					return compare(a.DateLast, b.DateLast, isAsc)
				case 'Payments.Amount':
					return compare(a.Payments.Amount, b.Payments.Amount, isAsc)
				case 'Status':
					return compare(a.Status, b.Status, isAsc)
				case 'BillAccepter.Amount':
					return compare(
						a.BillAccepter.Amount,
						b.BillAccepter.Amount,
						isAsc
					)
				case 'EncashmentDate':
					return compare(a.EncashmentDate, b.EncashmentDate, isAsc)
				default:
					return 0
			}
		})
		this.currentSecondData = this.sortedData
	}

	ngOnInit() {
		this.translate.get('KIOSK.STATUS_ALL').subscribe((text) => {
			this.statusList[0].name = text
		})
		this.translate.get('KIOSK.STATUS_OK').subscribe((text) => {
			this.statusList[1].name = text
		})
		this.translate.get('KIOSK.STATUS_WARNING').subscribe((text) => {
			this.statusList[2].name = text
		})
		this.translate.get('KIOSK.STATUS_ERROR').subscribe((text) => {
			this.statusList[3].name = text
		})
		this.FilterForm = new UntypedFormGroup({
			FilterStatus: new UntypedFormControl(''),
			FilterRefresh: new UntypedFormControl(''),
			Dealer: new UntypedFormControl(''),
		})
		this.getFirstDatas()
		this.InitDealerFilter()
		this.intervaler(this.refreshInterval)
	}
	ngOnDestroy() {
		clearInterval(this.timerID)
	}
}
// just an interface for type safety. // google maps
interface marker {
	lat: number
	lng: number
	label?: string
	draggable: boolean
	info: string
}

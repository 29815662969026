import { NgModule }      from '@angular/core';
import { AmountPipe } from './amount.pipe';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations:   [AmountPipe],
    imports:        [CommonModule],
    providers: [AmountPipe],
    exports:        [AmountPipe],
})

export class CustomPipeModule {

} 
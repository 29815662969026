import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { Cacheable } from 'ngx-cacheable'
import { AuthenticationService } from './authentication.service'

import { environment } from './../../environments/environment'

@Injectable()
export class KioskService {
	constructor(
		private http: HttpClient,
		private authService: AuthenticationService
	) {}
	baseUrl: string = environment.apiUrl
	getCurrentProfileId() {
		return this.http
			.get<any>(this.baseUrl + `/api/profile?Version=&VersionCode`)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getAllUsers() {
		return this.http.get<any>(this.baseUrl + `/api/Kiosk/Terminals`).pipe(
			map((response) => {
				return response
			})
		)
	}
	getKioskPivot(date_from, date_to, period, filter, serviceName, dealerId) {
		dealerId = dealerId ? dealerId : 1
		return this.http
			.get<any>(
				this.baseUrl +
					`/api/Kiosk/TransactionPivot?DateFrom=` +
					date_from +
					`&DateTo=` +
					date_to +
					'&PeriodType=' +
					period +
					'&Account=' +
					filter.Account +
					'&UserLogin=' +
					filter.UserName +
					'&UserDealerId=' +
					dealerId +
					'&Srv=' +
					serviceName +
					'&Status=&RefStan=' +
					filter.RefStan +
					'&SalePointId=' +
					filter.ChannelDrop +
					'&OpType=' +
					filter.PaidTypeDrop
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getKioskEnc(date_from, date_to, period, kioskId, currentPage, itemPerPage) {
		return this.http
			.get<any>(
				this.baseUrl +
					`/api/Kiosk/KioskEncashmentGets?DateFrom=` +
					date_from +
					`&DateTo=` +
					date_to +
					'&PeriodType=' +
					period +
					'&userlogin=' +
					kioskId +
					'&CurPage=' +
					currentPage +
					'&ItemPerPage=' +
					itemPerPage
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getKioskTotals(
		date_from,
		date_to,
		period,
		kioskId,
		currentPage,
		itemPerPage
	) {
		return this.http
			.get<any>(
				this.baseUrl +
					`/api/Kiosk/KioskEncashmentTotals?DateFrom=` +
					date_from +
					`&DateTo=` +
					date_to +
					'&PeriodType=' +
					period +
					'&userlogin=' +
					kioskId +
					'&CurPage' +
					currentPage +
					'&ItemPerPage' +
					itemPerPage
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getFile(
		date_from,
		date_to,
		period,
		filter,
		service,
		dealerId,
		currentPage,
		item_per_page,
		format
	) {
		dealerId = dealerId ? dealerId : 1
		return this.http.get(
			this.baseUrl +
				`/api/Kiosk/PivotKioskToExcel?DateFrom=` +
				date_from +
				`&DateTo=` +
				date_to +
				'&PeriodType=' +
				period +
				'&Account=' +
				filter.Account +
				'&UserLogin=' +
				filter.UserName +
				'&UserDealerId=' +
				dealerId +
				'&Srv=' +
				service +
				'&Status=&RefStan=' +
				filter.RefStan +
				'&SalePointId=' +
				filter.ChannelDrop +
				'&OpType=' +
				filter.PaidTypeDrop +
				'&CurPage=' +
				currentPage +
				'&ItemPerPage=' +
				item_per_page,
			{
				responseType: 'blob',
				headers: { 'Accept-Language': this.GetLang() },
			}
		)
	}
	// https://localhost:44305/api/Kiosk/KioskEncashmentTotalsToExcel?DateFrom=2018-03-01T00:00:00&DateTo=2020-12-13T23:59:59&PeriodType=0&userlogin=
	getFileEncash(
		date_from,
		date_to,
		period,
		login,
		format,
		currentPage,
		itemsPerPage
	) {
		return this.http.get(
			this.baseUrl +
				`/api/Kiosk/KioskEncashmentToExcel?DateFrom=` +
				date_from +
				`&DateTo=` +
				date_to +
				'&PeriodType=' +
				period +
				'&userlogin=' +
				login +
				'&CurPage=' +
				currentPage +
				'&ItemPerPage=' +
				itemsPerPage,
			{
				responseType: 'blob',
				headers: { 'Accept-Language': this.GetLang() },
			}
		)
	}
	getFilePivot(date_from, date_to, period, login, format) {
		return this.http.get(
			this.baseUrl +
				`/api/Kiosk/KioskEncashmentTotalsToExcel?DateFrom=` +
				date_from +
				`&DateTo=` +
				date_to +
				'&PeriodType=' +
				period +
				'&userlogin=' +
				login,
			{
				responseType: 'blob',
				headers: { 'Accept-Language': this.GetLang() },
			}
		)
	}

	getKioskList(dealerId, kioskLogin, status) {
		return this.http
			.get<any>(
				this.baseUrl +
					`/api/kiosk/gets?DealerId=${dealerId}&KioskLogin=&Status=${status}`
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getKiostListExcel(dealerId, kioskLogin, status) {
		return this.http.get(
			this.baseUrl +
				`/api/kiosk/KioskMonitoringToExcel?DealerId=${dealerId}&KioskLogin=${kioskLogin}&Status=${status}`,
			{
				responseType: 'blob',
				headers: { 'Accept-Language': this.GetLang() },
			}
		)
	}
	getEncash(pkioskName) {
		return this.http
			.get<any>(
				this.baseUrl + `/api/kiosk/Encashments?pkioskName=${pkioskName}`
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getEncashDetails(pIdEncashment, pkioskName) {
		return this.http
			.get<any>(
				this.baseUrl +
					`/api/kiosk/EncashmentDetails?pIdEncashment=${pIdEncashment}&pkioskName=${pkioskName}`
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getKiosk(dealerId, kioskLogin) {
		return this.http
			.get<any>(
				this.baseUrl +
					`/api/kiosk/get?DealerId=${dealerId}&KioskLogin=${kioskLogin}`
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getKioskCreditCard(
		date_from,
		date_to,
		period,
		filter,
		dealerId,
		currentPage,
		itemPerPage
	) {
		dealerId = dealerId ? dealerId : 1
		return this.http
			.get<any>(
				this.baseUrl +
					`/api/Kiosk/KioskCreditCardGets?pPeriodType=` +
					period +
					'&pDateFrom=' +
					date_from +
					`&pDateTo=` +
					date_to +
					'&pDealerId=' +
					dealerId +
					'&pKioskId=' +
					filter.KioskId +
					'&pCardNumber=' +
					filter.CardNumber +
					'&pCardHolderName=' +
					filter.CardHolderName +
					'&pResponseCode=' +
					filter.ResponseCode +
					'&pCurPage=' +
					currentPage +
					'&pItemPerPage=' +
					itemPerPage
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getKioskCreditCardDetails(recordNumber) {
		return this.http
			.get<any>(
				this.baseUrl +
					`/api/Kiosk/KioskCreditCardGetDetails?pRecordNumber=` +
					recordNumber
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getKioskReconciliationReport(
		date_from,
		date_to,
		period,
		filter,
		currentPage,
		itemPerPage
	) {
		return this.http
			.get<any>(
				this.baseUrl +
					`/api/Kiosk/KioskReconciliationGets?pPeriodType=` +
					period +
					'&pDateFrom=' +
					date_from +
					`&pDateTo=` +
					date_to +
					'&pCompanyId=' +
					filter.Service +
					'&pCurPage=' +
					currentPage +
					'&pItemPerPage=' +
					itemPerPage
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getKioskReconciliationDetails(id) {
		return this.http
			.get<any>(
				this.baseUrl + `/api/Kiosk/KioskReconciliationDetails?pId=` + id
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getKioskCashRestReport(
		date_from,
		date_to,
		period,
		filter,
		currentPage,
		itemPerPage
	) {
		return this.http
			.get<any>(
				this.baseUrl +
					`/api/CashRest/Gets?pPeriodType=` +
					period +
					'&pDateStart=' +
					date_from +
					`&pDateEnd=` +
					date_to +
					'&pUserId=' +
					filter.UserLogin +
					'&pStatus=' +
					filter.Status +
					'&pCashRestId=' +
					filter.CashRestId +
					'&pIsUsed=' +
					filter.IsUsed +
					'&pCurPage=' +
					currentPage +
					'&pItemPerPage=' +
					itemPerPage
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getKioskCashRestDetails(id) {
		return this.http
			.get<any>(this.baseUrl + `/api/CashRest/Details?pId=` + id)
			.pipe(
				map((response) => {
					return response
				})
			)
	}
	getKioskCashRestSendEmails(id) {
		return this.http
			.post<any>(this.baseUrl + `/api/CashRest/SendInfo?pId=` + id, {})
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getKioskCashRestChangeStatus(id, status, comment) {
		return this.http
			.post<any>(
				this.baseUrl +
					`/api/CashRest/ChangeStatus?pId=` +
					id +
					'&pStatus=' +
					status +
					'&pComment=' +
					comment,

				{}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	getKioskCashRestReportExport(
		date_from,
		date_to,
		period,
		filter,
		currentPage,
		itemPerPage
	) {
		return this.http
			.get(
				this.baseUrl +
					`/api/CashRest/CashRestListToExcel?pPeriodType=` +
					period +
					'&pDateStart=' +
					date_from +
					`&pDateEnd=` +
					date_to +
					'&pUserId=' +
					filter.UserLogin +
					'&pStatus=' +
					filter.Status +
					'&pCashRestId=' +
					filter.CashRestId +
					'&pIsUsed=' +
					filter.IsUsed +
					'&pCurPage=' +
					currentPage +
					'&pItemPerPage=' +
					itemPerPage,
				{
					responseType: 'blob',
					headers: { 'Accept-Language': this.GetLang() },
				}
			)
			.pipe(
				map((response) => {
					return response
				})
			)
	}

	private GetLang() {
		if (localStorage.getItem('currentLang') === 'tr') {
			return 'tr-TR'
		}
		return 'en-US'
	}
}

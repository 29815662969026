import { Component, OnInit, ViewChild, Input } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import {
	UntypedFormBuilder,
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
	AbstractControl,
	ValidatorFn,
	FormGroupDirective,
	NgForm,
} from '@angular/forms'
import { Observable } from 'rxjs'
import { map, startWith, first, filter } from 'rxjs/operators'
import { AlertService, UserService, ProviderService } from '../../_services'
//import {MatPaginator, MatTableDataSource, MatDatepickerInputEvent, MatDialog, DateAdapter, ErrorStateMatcher} from '@angular/material';
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { MatDialog } from '@angular/material/dialog'

import { DataSource } from '@angular/cdk/table'
import { ReportsDatasService } from '../../_services/reportsdata.service'
import { AdministrationService } from '../../_services/administration.service'
import { DatePipe } from '@angular/common'
/** Constants used to fill up our data base. */
import * as SecureLS from 'secure-ls'
import { TranslateService } from '@ngx-translate/core'
import { ToastrService } from 'ngx-toastr'
import { environment } from '../../../environments/environment'

export interface ItemList {
	Value: string
	Name: string
}

@Component({
	templateUrl: 'company.component.html',
	styleUrls: ['administration.scss'],
	providers: [DatePipe],
})
export class CompanyComponent implements OnInit {
	permissions

	public paginationItems // for display pagination from api
	public paginationItemPerPage // for display pagination from api

	public transactionCurrentID
	public transactionCurrentDealerName
	public transactionCurrentServiceName
	public currentPage = 0
	isLoadingDetailInfo: boolean = true // spinner
	OrderBy = [
		{ Key: '', Name: 'None' },
		{ Key: 1, Name: 'Provider' },
		{ Key: 2, Name: 'Group' },
	]
	//--------------------------
	Transactions_Datas
	loading = false // for disable fast response click
	pageEvent // for fix error of pageEvent in Visual Studio
	panelOpenState = true
	baseUrl: string = environment.apiUrl
	// For change pages

	services: ItemList[]
	account = '' // Account input
	refStan = '' // refStan input
	serviceSelected = '' // Service selected
	userLogin = '' // User Login input
	events: string[] = [] // Date picker
	dealerFControl = new UntypedFormControl() // Dealer
	createUserError = '' // Validation on User

	currentTime = new Date()
	//----------------- list of columns

	displayedColumns: string[] = [
		'CompanyId',
		'CompanyName',
		'Description',
		'CompanyLogo',
		'GroupName',
		'ProviderName',
		'Operations',
	]
	dataSource = new MatTableDataSource() // array of datas
	@ViewChild(MatPaginator) paginator: MatPaginator
	groups

	groups_edit
	constructor(
		public dialog: MatDialog,
		// Make service for post api datas
		private datePipe: DatePipe,
		private reportsDataService: ReportsDatasService,
		private providerService: ProviderService,
		private administrationService: AdministrationService,
		private formBuilder: UntypedFormBuilder,
		public route: ActivatedRoute,
		private translate: TranslateService,
		public toastr: ToastrService
	) {}

	FilterForm: UntypedFormGroup // Form initial
	CreateCompany: UntypedFormGroup

	//----------------------
	public handlePage(e: any) {
		this.currentPage = e.pageIndex
		this.paginationItemPerPage = e.pageSize
		this.getFirstDatas()
	}

	InitProviderFilter() {
		let providers = localStorage.getItem('providerAll')
		if (providers === null) {
			this.providerService
				.ProviderAllFilter()
				.pipe(first())
				.subscribe(
					(data) => {
						localStorage.setItem(
							'providerAll',
							JSON.stringify(data)
						)
						this.services = data.map((x) => ({
							Value: x.ID.toString(),
							Name: x.Name,
						}))
						this.services.unshift({ Value: '', Name: 'All' })
					},
					(error) => {
						console.log(error)
					},
					() => {
						this.InitGroupFilter()
					}
				)
		} else {
			let prvs = JSON.parse(providers)
			this.services = prvs.map((x) => ({
				Value: x.ID.toString(),
				Name: x.Name,
			}))
			this.services.unshift({ Value: '', Name: 'All' })
			this.InitGroupFilter()
		}
	}

	InitGroupFilter() {
		this.providerService
			.ProviderAllGroups()
			.pipe(first())
			.subscribe(
				(data) => {
					this.groups = data.map((x) => ({
						Value: x.ID.toString(),
						Name: x.Name,
					}))
					this.groups_edit = data.map((x) => ({
						Value: x.ID.toString(),
						Name: x.Name,
					}))
					// this.provider = data.map((x) => ({
					// 	Value: x.ID.toString(),
					// 	Name: x.Name,
					// }))
					this.groups.unshift({ Value: '', Name: 'All' })
				},
				(error) => {
					console.log(error)
				},
				() => {
					// this.FilterForm.patchValue({
					// 	['ProviderId']: this.provider[0].Value,
					// })
					this.FilterForm.patchValue({
						['GroupId']: this.groups[0].Value,
					})
					this.FilterForm.patchValue({
						['OrderBy']: this.OrderBy[0].Key,
					})
					this.translate
						.get('REPORT.FILTER_ST_ALL')
						.subscribe((text) => {
							this.groups[0].Name = text
						}) // Text in component
					this.getFirstDatas()
				}
			)
	}

	getFirstDatas() {
		this.loading = true
		let f = this.FilterForm.value
		this.administrationService
			.getCompanyList(
				f.CompanyName,
				f.CompanyID,
				f.ProviderId,
				f.GroupId,
				f.OrderBy,
				f.ServiceDrop,
				this.paginationItemPerPage,
				this.currentPage + 1
			)
			.pipe(first())
			.subscribe(
				(data) => {
					console.log(data)
					let datas = []
					let totalItem = 0
					if (data.ResultCode === 0) {
						datas = data.ReturnObject.Companies
						totalItem = data.ReturnObject.PagingInfo.TotalItems
						this.Transactions_Datas = []
					}
					this.dataSource = new MatTableDataSource(datas) // array of datas
					this.paginationItems = totalItem
					this.loading = false
				},
				(error) => {
					this.loading = false
					console.log(error)
				}
			)
	}

	//----------------------
	PaginationCurentPageReset(e: any) {
		this.currentPage = 0
	}

	modalDatas = {
		UserId: '',
	} // for display datas in modal

	CloseDialog(): void {
		this.dialog.closeAll()
	}

	openCreateCompany(templateRef) {
		this.createUserError = ''
		if (this.loading === false) {
			this.CreateCompany.reset()
			this.openModal(templateRef)
			// this.CreateUser.patchValue({['DealerId']: this.dealerFControl.value.Value});
		}
	}

	openModal(templateRef) {
		this.isLoadingDetailInfo = false
		this.dialog.open(templateRef, {
			panelClass: 'modal_transaction',
		})
	}

	/**
	 * Marks all controls in a form group as touched
	 * @param formGroup - The form group to touch
	 */
	private markFormGroupTouched(formGroup: UntypedFormGroup) {
		;(<any>Object).values(formGroup.controls).forEach((control) => {
			control.markAsTouched()

			if (control.controls) {
				this.markFormGroupTouched(control)
			}
		})
	}

	// @Input() permissions;
	ngOnInit() {
		// console.log(this.permissions);
		let ls = new SecureLS()
		this.permissions =
			ls.get('permissions')[this.route.data['value'].pathCheck]
		console.log('permissions', this.permissions)
		// if (this.permissions.write === true) {
		//    this.displayedColumns.push('Operations');
		//// }
		this.paginationItemPerPage = 10
		this.translate.get('REPORT.FT_NONE').subscribe((text) => {
			this.OrderBy[0].Name = text
		}) // Text in component
		this.translate.get('REPORT.FT_PROVIDER').subscribe((text) => {
			this.OrderBy[1].Name = text
		}) // Text in component
		this.translate.get('REPORT.FT_GROUP').subscribe((text) => {
			this.OrderBy[2].Name = text
		}) // Text in component
		this.InitProviderFilter()

		this.FilterForm = new UntypedFormGroup({
			CompanyName: new UntypedFormControl(''),
			CompanyID: new UntypedFormControl(''),
			GroupId: new UntypedFormControl(''),
			OrderBy: new UntypedFormControl(''),
			ServiceDrop: new UntypedFormControl(''),
			// 'OrderByGroup': new FormControl(''),
		}) // for header Filter

		this.CreateCompany = this.formBuilder.group({
			Id: ['', Validators.required],
			Name: ['', Validators.required],
			Description: ['', Validators.required],
			Count: ['', Validators.required],
			GroupId: ['', Validators.required],
		})
	}
}

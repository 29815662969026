import { Component, OnInit, ViewChild } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  FormGroupDirective,
  NgForm
} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith, first } from "rxjs/operators";
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ErrorStateMatcher } from '@angular/material/core';
import { AdministrationService } from "../../_services/administration.service";
import { DatePipe, DecimalPipe } from "@angular/common";
import { saveAs } from "file-saver";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
/** Constants used to fill up our data base. */
import * as SecureLS from "secure-ls";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

export interface ItemList {
  Value: string;
  Name: string;
  AbleCreateNewDealer: boolean;
}
interface DealerNode {
  name: string;
  AbleCreateNewDealer: boolean;
  children?: DealerNode[];
}

// For custom Error Validations Messages
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );

    return invalidCtrl || invalidParent;
  }
}

@Component({
  templateUrl: "dealers.component.html",
  styleUrls: ["administration.scss"],
  providers: [DatePipe, DecimalPipe]
})
export class DealersComponent implements OnInit {
  matcher = new MyErrorStateMatcher(); // for my Error state | Colors and other
  permissions;
  commisionType = [
    { Value: 0, Name: "No" },
    { Value: 1, Name: "Percentage" },
    { Value: 2, Name: "Fixed" }
  ];
  public paginationItems; // for display pagination from api
  public paginationItemPerPage; // for display pagination from api

  public currentPage = 0;
  isLoadingDetailInfo: boolean = true; // spinner

  dealerListOptions: ItemList[]; // = [{ Value: '1', Name: 'Mase 1' }, { Value: '2', Name: 'Dealer 222' }, { Value: '3', Name: 'Feadea' }, { Value: '4', Name: 'Grisha' }];
  loading = true; // for disable fast response click
  isDownloadingFile: boolean = false; // loader for download file

  disableNew = true;
  pageEvent; // for fix error of pageEvent in Visual Studio
  panelOpenState = true;
  // For change pages
  dealerKey;
  isContract = false;
  account = ""; // Account input
  refStan = ""; // refStan input
  serviceSelected = ""; // Service selected
  userLogin = ""; // User Login input

  events: string[] = []; // Date picker
  createDealerError = ""; // Validation on User

  dealerFilteredOptions: Observable<ItemList[]>; // dealer
  //----------------- list of columns
  displayedColumns: string[] = [
    "DealerId",
    "DealerName",
    "Address",
    "Email",
    "PhoneNumber",
    "Balance",
    "Currency",
    "Status",
    "WorkMode"
  ];
  // displayedColumnsChangeLog: string[] = ['Login', 'Date', 'Param', 'UpdateValue', 'LastValue'];
  displayedColumnsChangeLog: string[] = ["Login", "Date", "Type", "Value"];

  dataSource = new MatTableDataSource(); // array of datas
  changeLog = new MatTableDataSource(); // array of datas
  hasChild = (_: number, node: DealerNode) =>
    !!node.children && node.children.length > 0;
  treeControl = new NestedTreeControl<DealerNode>(node => node.children);
  dataTreeSource;
  treeData;

  showTree: boolean = false;
  currentDealer = {
    Name: "",
    Value: "",
    AbleCreateNewDealer:false
  };
  growTree(arr) {
    let storageData = JSON.parse(sessionStorage.getItem("dealerTreeList"));
    this.dataTreeSource = new MatTreeNestedDataSource<DealerNode>();
    let mainArray = arr;
    if (!storageData) {
      mainArray.map(x => {
        x.children = [];
      });
      mainArray.map(z => {
        if (z.Id !== 0) {
          let currentEl = mainArray.find(x => x.Id === z.ParentId);
          if (currentEl) {
            currentEl.children.push(z);
          }
        }
      });
      let result = mainArray.splice(0, 1);
      this.treeData = result;
      sessionStorage.setItem("dealerTreeList", JSON.stringify(result));
      this.dataTreeSource = result;
    } else {
      this.dataTreeSource = storageData;
    }
  }
  chooseDealer(smth) {
    this.CloseDialog();
    this.FilterForm.controls.Dealer.setValue(smth.Name);
    this.currentDealer = { Value: smth.Id, Name: smth.Name,AbleCreateNewDealer:smth.AbleCreateNewDealer };
    this.showTree = false;
    console.log("Choose Dealer value:", this.currentDealer);
    this.getFirstDatas();
  }
  stopClick(event) {
    event.preventDefault();
  }
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    public dialog: MatDialog,
    // Make service for post api datas
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,

    public route: ActivatedRoute,
    private administrationService: AdministrationService,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    public toastr: ToastrService
  ) {}

  depositTypes = [
    { Value: 1, Name: "Default" },
    { Value: 2, Name: "Cash" },
    { Value: 3, Name: "Banktransfer" },
    { Value: 4, Name: "check" }
  ];

  citiesList: [];
  regionsList: [];
  licensesList: [];
  salePointsList: [];
  languagesList: [];
  mainTabError: boolean = false;
  profileTabError: boolean = false;
  balanceTabError: boolean = false;
  // Errors
  depositError = "";
  dealerMainError = "";
  // End Errors
  FilterForm: UntypedFormGroup; // Form initial
  CreateDealer: UntypedFormGroup;
  DepositInfo: UntypedFormGroup; // initialize form
  MainSettings: UntypedFormGroup; // Form initial
  ChangePayments: UntypedFormGroup; // Form initial
  depositStates = [
    { value: "decrease", viewValue: "Decrease" },
    { value: "increase", viewValue: "Increase" }
  ]; // for Deposit form
  upload = {
    logo: false,
    info: false,
    pdf: false
  };

  currentDealerId;

  //----------------------------------------------------
  InitDealerFilter() {
    this.administrationService
      .getDealerDatasAsList()
      .pipe(first())
      .subscribe(
        data => {
          // localStorage.setItem('dealerAllList', JSON.stringify(data.ReturnObject.DmsDealerList));
          console.log("tree data from compn", data);

          this.dealerListOptions = data.ReturnObject.map(x => ({
            Value: x.Id,
            Name: x.Name,
            AbleCreateNewDealer: x.AbleCreateNewDealer
          }));
          this.dealerListOptions.unshift({ Value: "", Name: "All", AbleCreateNewDealer: false });
          this.translate.get("ADMIN.LBL_FDEALER_ALL").subscribe(text => {
            this.dealerListOptions[0].Name = text;
          });

          // Header Form
          this.dealerFilteredOptions = this.FilterForm.controls.Dealer.valueChanges.pipe(
            startWith<string | ItemList>(""),
            map(value => (typeof value === "string" ? value : value.Name)),
            map(name =>
              name ? this._filter(name) : this.dealerListOptions.slice()
            )
          ); //dealer
          this.FilterForm.patchValue({
            ["Dealer"]: this.dealerFilteredOptions[0]
          });
          this.growTree(data.ReturnObject);
        },
        error => {
          console.log(error);
        }
      );
  }

  getCities() {
    this.administrationService
      .getLocation(1)
      .pipe(first())
      .subscribe(
        data => {
          if (data.ResultCode === 0) {
            this.citiesList = data.ReturnObject.Cities;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.getRegions();
        }
      );
  }

  getRegions() {
    this.administrationService
      .getLocation(0)
      .pipe(first())
      .subscribe(
        data => {
          if (data.ResultCode === 0) {
            this.regionsList = data.ReturnObject.Regions;
          }
        },
        error => {
          console.log(error);
        },
        () => {
          this.getLicenses();
        }
      );
  }
  getLicenses() {
    this.administrationService.getLicenses("","","").subscribe(
      data => {
        if (data.ResultCode === 0) {
          this.licensesList = data.ReturnObject.DmsLecenceList;
        }
        console.log('Licenses::',this.licensesList);
      },
      error => {
        console.log(error);
      },
      
    );
  }

  getLanguages() {
    this.administrationService
      .getLanguagesList()
      .pipe(first())
      .subscribe(
        data => {
          if (data.ResultCode === 0) {
            this.languagesList = data.ReturnObject;
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getFirstDatas() {
    this.loading = true;

    let dealerId = this.currentDealer.Value;
    this.administrationService
      .getDealerDatas(
        dealerId,
        this.currentPage + 1,
        this.paginationItemPerPage
      )
      .pipe(first())
      .subscribe(
        data => {
          if (data.ResultCode === 0) {
            if (data.ReturnObject !== null) {
              this.dataSource = new MatTableDataSource(
                data.ReturnObject.DmsDealerList
              ); // array of datas
              this.paginationItems = data.ReturnObject.PagingInfo.TotalItems;
            } else {
              this.paginationItems = 0;
              this.dataSource = new MatTableDataSource([]); // array of datas
            }
          }
          this.loading = false;
         // this.growTree(data.ReturnObject);
        },
        error => {
          this.loading = false;
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.getFirstDatas();
  }

  private _filter(item: string): ItemList[] {
    const filterValue = item.toLowerCase();
    return this.dealerListOptions.filter(
      option => option.Name.toLowerCase().indexOf(filterValue) === 0
    );
  }

  //----------------------
  PaginationCurentPageReset(e: any) {
    this.currentPage = 0;
  }
  Changehandler(e: any) {
    e.value.Value ? (this.disableNew = false) : (this.disableNew = true);
    this.currentDealer = { Name: e.value.Name, Value: e.value.Value, AbleCreateNewDealer:e.AbleCreateNewDealer };
    console.log("Change handler", this.currentDealer);
  }
  //------------------- private helpers
  // convenience getter for easy access to form fields
  get f() {
    return this.FilterForm.controls;
  }

  displayDealer(item?: ItemList): string | undefined {
    return item ? item.Name : undefined;
  }
  modalDatas = {
    DealerId: null,
    DealerName: "",
    Email: "",
    Address: "",
    PhoneNumber: "",
    Status: "",
    WorkMode: "",
    SendSummary: "",
    RegionKey: "",
    CityKey: "",
    License: "",
    AbleCreateNewDealer: false,
    NotificationBalance: "",
    Min: "",
    Max: "",
    Balance: "",
    CheckMinMax: "",
    ContractNumber: "",
    SumMin: "0",
    UseBalance: false
  }; // for display datas in modal

  statusStates = [
    { value: 0, viewValue: "InActive" },
    { value: 1, viewValue: "Active" }
  ];
  getTranslations() {
    this.translate
      .get([
        "PAGINATOR.ITEMS_PER_PAGE",
        "PAGINATOR.NEXT_PAGE",
        "PAGINATOR.PREVIOUS_PAGE",
        "PAGINATOR.RANGE"
      ])
      .subscribe(translation => {});
  }
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.paginationItemPerPage = e.pageSize;
    this.getFirstDatas();
  }

  openModal(templateRef) {
    this.isLoadingDetailInfo = false;

    this.dialog.open(templateRef, {
      panelClass: "modal_transaction",
      minHeight: templateRef !== "treeModal" ? "580px" : ""
    });
  }

  CloseDialog(): void {
    this.dialog.closeAll();
  }

  // Change log Modal
  openChangeLog(templateRef, row) {
    if (this.loading === false) {
      this.loading = true;
      this.openModal(templateRef);
      this.isLoadingDetailInfo = true;
      this.modalDatas = row;
      this.administrationService
        .getChangeLog(row.Id)
        .pipe(first())
        .subscribe(
          data => {
            if (data.ResultCode === 0) {
              if (data.ReturnObject.Items.length) {
                this.changeLog = new MatTableDataSource(
                  data.ReturnObject.Items
                ); // array of datas
              } else {
                this.changeLog = new MatTableDataSource([]);
              }
              this.isLoadingDetailInfo = false;
              this.loading = false;
            } else {
              this.changeLog = new MatTableDataSource([]);
              this.isLoadingDetailInfo = false;
              this.loading = false;
            }
          },
          error => {
            this.isLoadingDetailInfo = false;
            this.loading = false;
            console.log(error);
          }
        );
    }
  }

  // Deposit Modal
  openDepositModal(templateRef, row) {
    if (this.loading === false) {
      this.loading = true;
      this.openModal(templateRef);
      this.isLoadingDetailInfo = true;
      this.modalDatas = row;
      this.currentDealerId = row.Id;
      this.DepositInfo.reset();
      this.administrationService
        .getDealerInfo(row.Id)
        .pipe(first())
        .subscribe(
          data => {
            if (data.ResultCode === 0) {
              let info = data.ReturnObject.DealerMain;
              for (let key in info) {
                if (info[key] === null) {
                  info[key] = "";
                }
              }
              this.DepositInfo.patchValue({ ["DealerId"]: info.Id });
              this.DepositInfo.patchValue({ ["DealerName"]: info.Name });
              this.DepositInfo.patchValue({
                ["Amount"]:
                  this.decimalPipe.transform(info.BalanceAmount / 100) + " TRY"
              });
              // this.DepositInfo.patchValue({['ChangeAmount']: 0});
              this.DepositInfo.patchValue({
                ["MinSum"]: (info.MinAmount / 100).toFixed(2) + " TRY"
              });
              this.DepositInfo.patchValue({
                ["MaxSum"]: (info.MaxAmount / 100).toFixed(2) + " TRY"
              });
              this.DepositInfo.patchValue({ ["Action"]: "increase" });
              this.DepositInfo.patchValue({
                ["Type"]: this.depositTypes[0].Value
              });
              this.DepositInfo.patchValue({ ["SendEmail"]: false });
            }
            this.loading = false;
            this.isLoadingDetailInfo = false;
          },
          error => {
            console.log(error);
            this.loading = false;
            this.isLoadingDetailInfo = false;
          }
        );
    }
  }

  SaveDeposit() {
    this.markFormGroupTouched(this.DepositInfo);
    if (this.loading === false && this.DepositInfo.valid) {
      this.depositError = "";
      this.loading = true;
      this.DepositInfo.value.Id = this.currentDealerId;
      console.log("Dep Info:", this.DepositInfo.value);
      this.administrationService
        .saveDeposit(this.DepositInfo.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data.ResultCode === 0) {
              let theme = "";
              let body = "";
              this.translate
                .get(
                  "ADMIN.THEME_DEPOSIT_SUCCESS_" +
                    this.DepositInfo.value.Action.toUpperCase()
                )
                .subscribe(text => {
                  theme = text;
                });
              this.translate
                .get(
                  "ADMIN.BODY_DEPOSIT_SUCCESS_" +
                    this.DepositInfo.value.Action.toUpperCase()
                )
                .subscribe(text => {
                  body = text;
                });
              this.toastr.success(body, theme);
              this.loading = false;
              this.getFirstDatas();
              this.CloseDialog();
            } else {
              let theme = "";
              this.translate
                .get(
                  "REPORT.DEPOSIT_ERROR_" +
                    this.DepositInfo.value.Action.toUpperCase()
                )
                .subscribe(text => {
                  theme = text;
                }); // Text in component
              data.ResultMessage === null
                ? (this.depositError = "")
                : (this.depositError = data.ResultMessage);
              this.toastr.error(this.depositError, theme);
              this.loading = false;
            }
          },
          error => {
            let theme = "";
            this.translate
              .get("REPORT.SERVER_DEPOSIT_ERROR")
              .subscribe(text => {
                theme = text;
              }); // Text in component
            this.toastr.error(error, theme);
            this.loading = false;
            console.log(error);
          }
        );
    }
  }
  openContract() {
    console.log("Contract!");
  }
  download() {
    this.isDownloadingFile = true;
    this.loading = true;
    this.administrationService.getContract(this.dealerKey).subscribe(
      res => {
        this.isDownloadingFile = false;
        this.loading = false;
        saveAs(res, this.dealerKey + ".pdf", {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
      },
      error => {
        console.log(error);
        this.loading = false;
        this.isDownloadingFile = false;
      }
    );
  }

  // Main Settings
  openMainSettings(templateRef, row) {
    this.mainTabError = false;
    this.profileTabError = false;
    this.balanceTabError = false;
    if (this.loading === false) {
      this.dealerMainError = "";
      this.loading = true;
      this.openModal(templateRef);
      this.isLoadingDetailInfo = true;
      this.administrationService
        .getDealerInfo(row.Id)
        .pipe(first())
        .subscribe(
          data => {
            if (data.ResultCode === 0) {
              let settingsInfo = data.ReturnObject;
              if (data.ReturnObject !== null) {
                console.log("Data from service:", data);
                this.modalDatas = data.ReturnObject;
                //console.log('Modal Datas from settings:', this.modalDatas);
                this.MainSettings.patchValue({
                  ["DealerId"]: settingsInfo.DealerMain.Id
                });
                this.MainSettings.patchValue({
                  ["DealerName"]: settingsInfo.DealerMain.Name
                });
                this.MainSettings.patchValue({
                  ["Email"]: settingsInfo.DealerMain.Email
                });
                this.MainSettings.patchValue({
                  ["Address"]: settingsInfo.DealerMain.Address
                });
                this.MainSettings.patchValue({
                  ["PhoneNumber"]: settingsInfo.DealerMain.PhoneNumber
                });
                this.MainSettings.patchValue({
                  ["Status"]: settingsInfo.DealerMain.Status
                });
                this.MainSettings.patchValue({
                  ["WorkMode"]: settingsInfo.DealerMain.WorkMode
                });
                this.MainSettings.patchValue({
                  ["SendSummary"]:
                    settingsInfo.DealerProfile.SendTransactionSummary
                });
                // this.MainSettings.patchValue({ ['RegionKey']: settingsInfo.DealerProfile.RegionKey });
                // this.MainSettings.patchValue({ ['CityKey']: settingsInfo.DealerProfile.CityKey });
                this.MainSettings.patchValue({
                  ["License"]: settingsInfo.DealerMain.InstListId
                });
                this.MainSettings.patchValue({
                  ["AbleCreateNewDealer"]:
                    settingsInfo.DealerProfile.AbleCreateNewDealer
                });
                this.MainSettings.patchValue({
                  ["CheckMinMax"]: settingsInfo.DealerBalance.CheckMinMax
                });
                this.MainSettings.patchValue({
                  ["NotificationBalance"]:
                    settingsInfo.DealerProfile.NotificationBalance
                });
                this.MainSettings.patchValue({
                  ["ContractNumber"]: settingsInfo.DealerProfile.ContractNumber
                });
                this.MainSettings.patchValue({
                  ["UseBalance"]: settingsInfo.DealerBalance.UseBalance
                });
                this.MainSettings.patchValue({
                  ["Min"]: (settingsInfo.DealerBalance.Min / 100).toFixed(2)
                });
                this.MainSettings.patchValue({
                  ["Max"]: (settingsInfo.DealerBalance.Max / 100).toFixed(2)
                });
                this.MainSettings.patchValue({
                  ["Balance"]: settingsInfo.DealerBalance.Value
                });
                this.MainSettings.patchValue({
                  ["SumMin"]: (
                    settingsInfo.DealerProfile.NotificationBalanceValue / 100
                  ).toFixed(2)
                });
                this.dealerKey = settingsInfo.DealerMain.Key;
                // this.administrationService
                //   .getContract(this.dealerKey)
                //   .subscribe(
                //     data => {
                //       console.log("Contract is here!", data);
                //     },
                //     error => {
                //       this.isLoadingDetailInfo = false;
                //       this.loading = false;
                //       console.log(error);
                //     }
                //   );
                this.loading = false;
                this.isLoadingDetailInfo = false;
              } else {
                this.isLoadingDetailInfo = false;
              }
              this.loading = false;
            } else {
              this.loading = false;
            }
          },
          error => {
            this.isLoadingDetailInfo = false;
            this.loading = false;
            console.log(error);
          }
        );
      // Api for modal detail information
    }
  }

  // Main Settings
  newDealer(templateRef) {
    this.mainTabError = false;
    this.profileTabError = false;
    this.balanceTabError = false;
    if (this.loading === false) {
      this.dealerMainError = "";
      this.loading = true;
      this.openModal(templateRef);
      this.isLoadingDetailInfo = true;
      this.CreateDealer.reset();
      // this.CreateDealer.patchValue({ ['SendSms']: false });
      this.isLoadingDetailInfo = false;
      this.loading = false;
    }
  }

  newDealerSave() {
    this.dealerMainError = "";
    this.CreateDealer.value.ParentId = this.currentDealer.Value;
    this.markFormGroupTouched(this.CreateDealer);
    console.log("New Dealer Data: ", this.CreateDealer);

    if (this.CreateDealer.valid === true) {
      // Make again gets all users()
      this.loading = true;
      console.log("New Dealer info:", this.CreateDealer.value);
      this.administrationService
        .saveNewDealer(this.CreateDealer.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data.ResultCode === 0) {
              sessionStorage.removeItem("dealerTreeList");
              sessionStorage.removeItem("dealerCBTreeList");

              let theme = "";
              let body = "";
              this.translate
                .get("ADMIN.THEME_NEWDEALER_SUCCESS")
                .subscribe(text => {
                  theme = text;
                });
              this.translate
                .get("ADMIN.BODY_NEWDEALER_SUCCESS")
                .subscribe(text => {
                  body = text;
                });
              this.toastr.success(body, theme);
              this.getFirstDatas();
              this.CloseDialog();
            } else {
              let theme = "";
              this.translate.get("REPORT.NEWDEALER_ERROR").subscribe(text => {
                theme = text;
              }); // Text in component
              data.ResultMessage === null
                ? (this.dealerMainError = "")
                : (this.dealerMainError = data.ResultMessage);
              this.toastr.error(this.dealerMainError, theme);
            }
            this.loading = false;
          },
          error => {
            let theme = "";
            this.translate.get("REPORT.NEWDEALER_ERROR").subscribe(text => {
              theme = text;
            }); // Text in component
            this.toastr.error(error, theme);
            this.loading = false;
            console.log(error);
          }
        );
    } else {
      this.mainTabError = false;
      this.profileTabError = false;
      this.balanceTabError = false;

      let tabErrors = this.findInvalidControls(this.CreateDealer);
      tabErrors.includes("DealerName") ||
      tabErrors.includes("UserName") ||
      tabErrors.includes("Email") ||
      tabErrors.includes("PhoneNumber") ||
      tabErrors.includes("Address") ||
      tabErrors.includes("Status")
        ? (this.mainTabError = true)
        : console.log("No error at main tab");
      tabErrors.includes("ContractNumber") ||
      tabErrors.includes("License") ||
      tabErrors.includes("Language") ||
      tabErrors.includes("Address")
        ? (this.profileTabError = true)
        : console.log("No error at profile tab");
      tabErrors.includes("Min") || tabErrors.includes("Max")
        ? (this.balanceTabError = true)
        : console.log("No error at balance tab");
    }
  }
  findInvalidControls(formName) {
    const invalid = [];
    const controls = formName.controls;
    for (const name in controls) {
      if (controls[name].invalid ) {
        invalid.push(name);
      }
    }
    return invalid;
  }
  uploadContract(event, id_type) {
    if (event.target.files.length) {
      let file = event.target.files[0];
      this.upload[id_type] = true;
      let image_error_theme = "";
      let image_error_body = "";
      if (file.size > 111111111) {
        this.translate
          .get("ADMIN.THEME_IMG_SIZE_" + id_type.toUpperCase())
          .subscribe(text => {
            image_error_theme = text;
          });
        this.translate
          .get("ADMIN.BODY_IMG_SIZE_" + id_type.toUpperCase())
          .subscribe(text => {
            image_error_body = text;
          });
        this.upload[id_type] = false;
        this.toastr.warning(image_error_body, image_error_theme);
      } else {
        let type = file.type;
        let reader: any;
        target: EventTarget;
        reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url
        reader.onload = event => {
          // called once readAsDataURL is completed
          let code = event.target.result.split(",")[1];
          this.administrationService
            .saveContract(this.dealerKey, code, type)
            .pipe(first())
            .subscribe(
              data => {
                if (data.ResultCode === 0) {
                  let theme = "";
                  let body = "";
                  this.translate
                    .get("ADMIN.THEME_IMG_SUCCESS_" + id_type.toUpperCase())
                    .subscribe(text => {
                      theme = text;
                    });
                  this.translate
                    .get("ADMIN.BODY_IMG_SUCCESS_" + id_type.toUpperCase())
                    .subscribe(text => {
                      body = text;
                    });
                  this.toastr.success(body, theme);
                } else {
                  let theme = "";
                  let body = "";
                  data.ResultMessage === null
                    ? (body = "")
                    : (body = data.ResultMessage);
                  this.translate
                    .get("ADMIN.THEME_IMG_ERROR_" + id_type.toUpperCase())
                    .subscribe(text => {
                      theme = text;
                    });
                  this.toastr.error(body, theme);
                }
              },
              error => {
                console.log(error);
                let theme = "";
                this.translate
                  .get("ADMIN.THEME_IMG_ERROR_" + id_type.toUpperCase())
                  .subscribe(text => {
                    theme = text;
                  });
                this.toastr.error(error, theme);
                console.log(error);
              },
              () => {
                this.upload[id_type] = false;
              }
            );
        };
      }
    }
  }
  saveDealer() {
    this.dealerMainError = "";
    this.markFormGroupTouched(this.MainSettings);
    if (this.MainSettings.valid === true) {
      // Make again gets all users()
      this.loading = true;
      this.administrationService
        .saveDealerInfo(this.MainSettings.value)
        .pipe(first())
        .subscribe(
          data => {
            if (data.ResultCode === 0) {
              let theme = "";
              let body = "";
              this.translate
                .get("ADMIN.THEME_EDITDEALER_SUCCESS")
                .subscribe(text => {
                  theme = text;
                });
              this.translate
                .get("ADMIN.BODY_EDITDEALER_SUCCESS")
                .subscribe(text => {
                  body = text;
                });
              this.toastr.success(body, theme);
              this.getFirstDatas();
              this.CloseDialog();
            } else {
              let theme = "";
              this.translate.get("REPORT.EDITDEALER_ERROR").subscribe(text => {
                theme = text;
              }); // Text in component
              data.ResultMessage === null
                ? (this.dealerMainError = "")
                : (this.dealerMainError = data.ResultMessage);
              this.toastr.error(this.dealerMainError, theme);
            }
            this.loading = false;
          },
          error => {
            let theme = "";
            this.translate.get("REPORT.EDITDEALER_ERROR").subscribe(text => {
              theme = text;
            }); // Text in component
            this.toastr.error(error, theme);
            this.loading = false;
            console.log(error);
          }
        );
    } else {
      this.mainTabError = false;
      this.profileTabError = false;
      this.balanceTabError = false;

      let tabErrors = this.findInvalidControls(this.MainSettings);
      tabErrors.includes("DealerName") ||
      tabErrors.includes("UserName") ||
      tabErrors.includes("Email") ||
      tabErrors.includes("PhoneNumber") ||
      tabErrors.includes("Address") ||
      tabErrors.includes("Status")
        ? (this.mainTabError = true)
        : console.log("No error at main tab");
      tabErrors.includes("ContractNumber") ||
      tabErrors.includes("License") ||
      tabErrors.includes("Language") ||
      tabErrors.includes("Address")
        ? (this.profileTabError = true)
        : console.log("No error at profile tab");
      tabErrors.includes("Min") || tabErrors.includes("Max")
        ? (this.balanceTabError = true)
        : console.log("No error at balance tab");
    }
  }

  /**
   * Marks all controls in a form group as touched
   * @param formGroup - The form group to touch
   */
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  convertOnBlur(searchValue, formName, formValue) {
    if (searchValue > 0) {
      this[formName].patchValue({
        [formValue]: parseFloat(searchValue).toFixed(2)
      });
    }
  }

  serviceProviders;

  ngOnInit() {
    let ls = new SecureLS();
    this.permissions = ls.get("permissions")[
      this.route.data["value"].pathCheck
    ];
    if (this.permissions.write === true) {
      this.displayedColumns.push("Actions");
    }
    this.paginationItemPerPage = 10;

    this.translate.get("ADMIN.STATUS_INACTIVE").subscribe(text => {
      this.statusStates[0].viewValue = text;
    });
    this.translate.get("ADMIN.STATUS_ACTIVE").subscribe(text => {
      this.statusStates[1].viewValue = text;
    });

    this.FilterForm = new UntypedFormGroup({
      Dealer: new UntypedFormControl(""),
      UserName: new UntypedFormControl("")
    }); // for header Filter
    this.InitDealerFilter();
    this.getFirstDatas();
    this.getLicenses();

    this.CreateDealer = this.formBuilder.group({
      ParentId: [""],
      DealerName: ["", Validators.required],
      Email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
      Address: ["", Validators.required],
      PhoneNumber: ["", Validators.pattern(/\d{11,12}$/)],
      Status: [""],
      WorkMode: [""],
      SendSummary: [""],
      // 'RegionKey': ['', Validators.required],
      // 'CityKey': ['', Validators.required],
      License: ["", Validators.required],
      AbleCreateNewDealer: [false],
      NotificationBalance: [""],
      CheckMinMax: [""],
      SumMin: ["0"],
      UseBalance: [""],
      Min: ["0"],
      Max: ["0"],
      ContractNumber: [""]
    });

    this.MainSettings = this.formBuilder.group({
      DealerId: [""],
      ParentId: [""],
      DealerName: ["", Validators.required],
      Email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ]
      ],
      Address: [""],
      PhoneNumber: ["", Validators.pattern(/\d{11,12}$/)],
      Status: [""],
      WorkMode: [""],
      SendSummary: [""],
      // 'RegionKey': ['', Validators.required],
      // 'CityKey': ['', Validators.required],
      License: ["", Validators.required],
      AbleCreateNewDealer: [false],
      NotificationBalance: [""],
      CheckMinMax: [""],
      SumMin: ["0"],
      // 'Min': ['', Validators.min(0)],
      // 'Max': ['', Validators.max(1500000)],
      UseBalance: [""],
      Min: [""],
      Max: [""],
      ContractNumber: [""]
    });

    this.DepositInfo = this.formBuilder.group({
      Id: [""],
      DealerName: [{ value: "", disabled: true }],
      Amount: [{ value: "", disabled: true }],
      ChangeAmount: ["", [Validators.required, Validators.min(0.01)]],
      MinSum: [{ value: "", disabled: true }],
      MaxSum: [{ value: "", disabled: true }],
      Action: [""],
      Comment: ["", Validators.required],
      Type: ["", Validators.required],
      SendEmail: [false]
    }); // initialize commision form
    this.ChangePayments = this.formBuilder.group({});
  }
}
